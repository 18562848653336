<template>
    <div>
        <MenuBar v-model:query="searchQuery" :scoutCount="scoutCount"/>

        <div v-if="reportFilesViewFlag" class="relative">
            <FileManager 
                :filesUrls="reportFilesForView"
                :canDelete="false"
                @close="reportFilesViewFlag = false"
            />
        </div>

        <div v-if="selectedReport" @click="selectedReport = null" class="w-full h-full absolute top-0 left-0"></div>
        <div v-if="selectedReport" class="relative">
            <MedicalReportForm 
                :report="selectedReport"
                @save="updateMedicalReport"
            />
        </div>

        <div class="w-[90%] mx-auto mb-3 mt-[20px] rounded-[10px] overflow-hidden">
            <table class="w-full">
                <thead class="">
                    <tr class="text-white border-b-[1px] border-[#FFF] border-opacity-10">
                        <th class="px-3 py-2 text-center font-medium text-sm">фото</th>
                        <th class="px-3 py-2 text-start font-medium text-sm">гравець</th>
                        <th class="px-3 py-2 text-start font-medium text-sm">команда</th>
                        <th class="px-3 py-2 text-start font-medium text-sm">звіт</th>
                        <th class="px-3 py-2 text-center font-medium text-sm">документ</th>
                        <th class="px-3 py-2 text-start font-medium text-sm">медик</th>
                        <th class="px-3 py-2 text-start font-medium text-sm">статус</th>
                        <th class="px-3 py-2 text-center font-medium text-sm">картка</th>
                        <th class="px-3 py-2 text-center font-medium text-sm">дата</th>
                    </tr>
                </thead>
                <tbody>
                    
                    <tr v-for="report in allMedicalReports">
                        <td class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                            <div class="w-10 h-10 rounded-full overflow-hidden flex items-end bg-[#BD9135] bg-opacity-20 border-[#BD9135] border-[1px]">
                                <img v-if="report.student.photo" :src="apiURL + 'static/' + report.student.photo">
                                <div v-else class="w-full h-full  text-[#BD9135] flex justify-center items-center">
                                    {{ report.student.name[0] + report.student.surname[0] }}
                                </div>
                            </div>
                        </td>
                        <td class="px-3 py-2 text-start text-sm w-[15%] truncate font-bold">
                            <div class="flex flex-col align-middle cursor-pointer">
                                <div class="truncate text-[#FFF] text-[14px]">{{ report.student.surname }} {{ report.student.name }}</div>
                                <div class="truncate text-[10px] text-gray-400">{{ '#' + report.student.player_number + ' | ' + report.student.amplua + ' | ' + report.student.age + 'p. | ' + report.student.nationality }}</div>
                            </div>
                        </td>
                        <td class="px-3 py-2 text-start text-sm w-[15%] truncate">{{ report.student.group_name.length > 0 ? report.student.group_name : '—' }}</td>
                        <td class="px-3 py-2 text-start text-sm">{{ report.document_name }}</td>
                        <td class="px-3 py-2 text-start text-sm">
                            <div class="flex justify-center">
                                <img @click="selectRepostFiles(report.documents)" :src="document" alt="document" class="w-[17px] h-[20px]" />
                            </div>
                        </td>
                        <td class="px-3 py-2 text-start text-sm">
                            {{ report.doctor }}
                        </td>
                        <td class="px-3 py-2 text-start text-sm">
                            <div v-if="report.health_status == 'healthy'" class="px-[15px] w-[100px] h-max text-center rounded-xl bg-[#339989] text-[12px] py-[2px]">Здоровий</div>
                            <div v-if="report.health_status == 'injury'" class="px-[15px] h-max text-center rounded-xl bg-[#DA4167] text-[12px] py-[2px]">Травма</div>
                            <div v-if="report.health_status == 'recover'" class="px-[15px] h-max text-center rounded-xl bg-[#C59014] text-[12px] py-[2px]">Відновлення</div>
                        </td>
                        <td class="px-3 py-2 text-start text-sm">
                            <div class="flex justify-center">
                                <button 
                                    class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                    @click="selectedReport = report"
                                >
                                    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.544 7.04498C20.848 7.4713 21 7.68447 21 8C21 8.31553 20.848 8.52869 20.544 8.95501C19.1779 10.8706 15.6892 15 11 15C6.31078 15 2.8221 10.8706 1.45604 8.95502C1.15201 8.5287 1 8.31553 1 8C1 7.68447 1.15201 7.47131 1.45604 7.04499C2.8221 5.12944 6.31078 1 11 1C15.6892 1 19.1779 5.12944 20.544 7.04498Z" stroke="#339989" stroke-width="1.5"></path><path d="M14.0005 8C14.0005 6.34315 12.6573 5 11.0005 5C9.34363 5 8.00049 6.34315 8.00049 8C8.00049 9.65685 9.34363 11 11.0005 11C12.6573 11 14.0005 9.65685 14.0005 8Z" stroke="#339989" stroke-width="1.5"></path></svg>
                                </button>
                            </div>
                        </td>
                        <td class="px-3 py-2 text-start text-sm">
                            <div class="text-[12px] text-center">{{ report.date }}</div>
                            <div class="text-[12px] text-center">{{ report.time }}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { getAllMedicalReports, updateHealthReportByStudentId } from '@/services/apiRequests'
import MenuBar from './components/MenuBar.vue';
import FileManager from './components/FileManager.vue';
import MedicalReportForm from './components/MedicalReportForm.vue';
import document from "@/assets/refactored_assets/img/players/document.svg";

export default {
    name: 'Notes',

    setup() {
        const apiURL = ref(process.env.VUE_APP_API_URL)
        const allMedicalReports = ref([])

        const searchQuery = ref('')
        const scoutCount = ref(0)

        const reportFilesForView = ref([])
        const reportFilesViewFlag = ref(false)

        const selectedReport = ref(null)

        onMounted(async () => {
            allMedicalReports.value = await getAllMedicalReports()
            console.log('allMedicalReports.value -------- ', allMedicalReports.value)
            scoutCount.value = allMedicalReports.value.length
        })

        const selectRepostFiles = (files) => {
            reportFilesForView.value = files;
            reportFilesViewFlag.value = true;
        };

        const updateMedicalReport = async (data) => {
            const report = {
                id: data.id,
                document_name: data.document_name,
                date: data.date,
                time: data.time,
                documents: data.documentsUrls,
                descriptions: data.descriptions,
                doctor: data.doctor
            }
            await updateHealthReportByStudentId(data.id, report, data.documentsObjects);
            allMedicalReports.value = await getAllMedicalReports()
            selectedReport.value = null;
        };

        return {
            allMedicalReports,
            searchQuery,
            scoutCount,
            selectRepostFiles,
            apiURL,
            reportFilesForView,
            reportFilesViewFlag,
            selectedReport,
            updateMedicalReport,
            document: document
        }
    },

    components: {
        MenuBar,
        FileManager,
        MedicalReportForm
    }
}
</script>
<style lang="">
    
</style>