<template>
    <div>
      <div ref="chart" style="width: 100%; height: 400px;"></div>
    </div>
  </template>
  
  <script>
  import * as echarts from "echarts";
  
  export default {
    name: "MonthlyBarChart",

    props: {
      barColor: {
        type: String,
        default: "#DA4167",
      },

      data: {
        type: Array,
        default: [120, 200, 150, 80, 70, 110, 130, 170, 90, 100, 200, 180]
      }
    },

    data() {
      return {
        chart: null,
        months: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        // data: [120, 200, 150, 80, 70, 110, 130, 170, 90, 100, 200, 180], // Данные для столбцов
      };
    },
    methods: {
      initChart() {
        this.chart = echarts.init(this.$refs.chart);
  
        const barColor = this.barColor; // Цвет столбцов
  
        const option = {
          title: {
            text: "Monthly Data",
            left: "center",
            show: false,
          },
          tooltip: {
            trigger: "item",
            formatter: (params) => `${params.name}: ${params.value}`,
          },
          xAxis: {
            type: "category",
            data: this.months,
            axisLabel: {
              rotate: 45,
            },
            splitLine: {
              show: false,
            },
          },
          yAxis: {
            type: "value",
            splitLine: {
              show: false,
            },
          },
          series: [
            {
              data: this.data,
              type: "bar",
              itemStyle: {
                color: barColor,
              },
              label: {
                show: true, // Включаем отображение текста
                position: "top", // Расположение над баром
                fontSize: 12,
                color: barColor, // Цвет текста совпадает с цветом бара
                formatter: "{c}", // Отображаем только значение
              },
            },
          ],
        };
  
        this.chart.setOption(option);
      },
      resizeChart() {
        if (this.chart) {
          this.chart.resize();
        }
      },
    },
    mounted() {
      this.initChart();
      window.addEventListener("resize", this.resizeChart);
    },
    beforeUnmount() {
      window.removeEventListener("resize", this.resizeChart);
      if (this.chart) {
        this.chart.dispose();
      }
    },
  };
  </script>
  
  <style>
  /* Дополнительные стили */
  </style>
  