<template>
    <div>
        <div class="search-block w-[90%] mx-auto">
            <div class="flex justify-between mt-2">
                <div class="flex py-[3px]">
                    <div class="search-bar flex items-center border-[#FFF] border-[1px] bg-[#FFF] bg-opacity-30 px-[10px] rounded-[6px]">
                        <img src="@/assets/icons/magnify_icon.svg" class="w-3 h-3">
                        <input v-model="search" class="text-[#FFF] text-[14px] bg-transparent rounded-md px-2 py-[3.5px] placeholder:text-[#FFF] outline-none w-[250px]" placeholder="Пошук..." type="text">
                    </div>
                    <div class="ml-[20px] flex items-center w-[110px] justify-between mr-[140px] text-[#FFF]">
                        <img @click="prevPage" src="@/assets/icons/left_arrow.svg" class="cursor-pointer">
                        <div class="flex mx-[20px]">Сторінка: <input v-model="page" type="number" class="bg-transparent mx-2 w-[5ch] outline-none px-1"> / <span class="ml-[10px]">{{ maxPage }}</span></div>
                        <img @click="nextPage" src="@/assets/icons/right_arrow.svg" class="cursor-pointer">
                    </div>
                </div>
                <div class="flex gap-[20px]">
                    <div class="flex">
                        <div class="px-[15px] h-max text-center rounded-xl bg-[#339989] text-[12px] py-[2px]">Здоровий</div>
                        <div class="ml-[10px] font-bold text-[#339989]">{{ healthStatusStatistics.healthy }}</div>
                    </div>
                    <div class="flex">
                        <div class="px-[15px] h-max text-center rounded-xl bg-[#DA4167] text-[12px] py-[2px]">Травма</div>
                        <div class="ml-[10px] font-bold text-[#DA4167]">{{ healthStatusStatistics.injury }}</div>
                    </div>
                    <div class="flex">
                        <div class="px-[15px] h-max text-center rounded-xl bg-[#C59014] text-[12px] py-[2px]">Відновлення</div>
                        <div class="ml-[10px] font-bold text-[#C59014]">{{ healthStatusStatistics.recover }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class=" w-[90%] mx-auto mt-3 mb-5 rounded-[10px] overflow-y-auto">
            <table class="w-full">
                <thead class="text-white select-none">
                    <tr class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                        <th class="px-3 py-2 w-[5%] text-center font-medium text-sm"></th>
                        <th class="px-3 py-2 w-[15%] text-start font-medium text-sm">Ім'я</th>
                        <th class="px-3 py-2 w-[10%] text-start font-medium text-sm">Група</th>
                        <th class="px-3 py-2 w-[10%] text-start font-medium text-sm">Статус</th>
                        <th class="px-3 py-2 cursor-pointer select-none text-center font-medium flex justify-center align-middle items-center text-sm">
                            Статус встановив
                        </th>
                        <th class="px-3 py-2 text-center  relative font-medium text-sm">
                            Останній запис
                        </th>
                        <th class="px-3 py-2 text-center relative font-medium text-sm">
                            Прогноз одужання
                        </th>
                        <th class="px-3 w-[5%] py-2 text-start font-medium text-sm"></th>
                    </tr>
                </thead>
                <tbody v-if="allStudents.length > 0 && playerUploadStatus">
                    <tr v-for="student in allStudents" class="border-b-[1px] border-[#FFF] border-opacity-10">
                        <td class="pl-3 pr-2 w-[5%] py-2 text-start text-[14px] ">
                            <div class="w-10 h-10 border-[#BD9135] border-[1px] rounded-full overflow-hidden bg-[#BD9135] bg-opacity-20">
                                <img v-if="student.photo" :src="studentLogo(student.photo)">
                                <div v-else class="h-full w-full flex justify-center items-center text-[#BD9135]">{{ student.name[0] + student.surname[0] }}</div>
                            </div>
                        </td>
                        <td 
                            class="px-3 truncate py-2 text-start text-[14px] w-[15%]"  
                        >
                            <div @click="$router.push(`/students/${student.student_id}?tabName=MainTab`)" class="flex flex-col align-middle cursor-pointer">
                                <div class="truncate text-[#FFF] text-[14px]">{{ student.surname }} {{ student.name }}</div>
                                <div class="truncate text-[10px] text-gray-400">{{ '#' + student.player_number + ' | ' + student.amplua + ' | ' + student.age + 'p. | ' + student.nationality }}</div>
                            </div>
                        </td>
                        <td class="px-3 py-2 text-start text-[14px] w-[10%]">
                            {{ student.group_name }}
                        </td>
                        <td class="px-3 py-2 text-start text-[14px] w-[10%]">
                            <div v-if="student.playerHealth.status == 'healthy'" class="px-[15px] w-[100px] h-max text-center rounded-xl bg-[#339989] text-[12px] py-[2px]">Здоровий</div>
                            <div v-if="student.playerHealth.status == 'injury'" class="px-[15px] w-[100px] h-max text-center rounded-xl bg-[#DA4167] text-[12px] py-[2px]">Травма</div>
                            <div v-if="student.playerHealth.status == 'recover'" class="px-[15px] w-[100px] h-max text-center rounded-xl bg-[#C59014] text-[12px] py-[2px]">Відновлення</div>
                        </td>
                        <td class="px-3 py-2 text-center text-[14px]">{{ student.playerHealth.lastReportDoctor }}</td>
                        <td class="px-3 py-2 text-center text-[14px]">{{ student.playerHealth.lastReportDate }}</td>
                        <td class="px-3 py-2 text-center text-[14px]">{{ student.playerHealth.recoverDate.split('T')[0].split('-').reverse().join('.') }}</td>
                        <td class="px-3 py-2 text-start text-sm">
                            <div class="flex justify-center">
                                <button 
                                    class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                    @click="$router.push(`/students/${student.student_id}?tabName=HealthcareTab`)"
                                >
                                    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.544 7.04498C20.848 7.4713 21 7.68447 21 8C21 8.31553 20.848 8.52869 20.544 8.95501C19.1779 10.8706 15.6892 15 11 15C6.31078 15 2.8221 10.8706 1.45604 8.95502C1.15201 8.5287 1 8.31553 1 8C1 7.68447 1.15201 7.47131 1.45604 7.04499C2.8221 5.12944 6.31078 1 11 1C15.6892 1 19.1779 5.12944 20.544 7.04498Z" stroke="#339989" stroke-width="1.5"></path><path d="M14.0005 8C14.0005 6.34315 12.6573 5 11.0005 5C9.34363 5 8.00049 6.34315 8.00049 8C8.00049 9.65685 9.34363 11 11.0005 11C12.6573 11 14.0005 9.65685 14.0005 8Z" stroke="#339989" stroke-width="1.5"></path></svg>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { ref, onMounted, computed, watch } from 'vue'
import { getAllStudents, getStudentsCount, getPlayerHealthStatus, getPlayersHealthStatistics } from '@/services/apiRequests'

export default {
    name: "Players",

    setup() {
        const allStudents = ref([])
        const currentStatusFlag = ref(false)
        const currentStatuses = ref([])
        const balanceVector = ref('desc')
        const screenWidth = ref(window.innerWidth)

        const search = ref('')
        const page = ref(1)
        const maxPage = ref(1)

        const prevPage = () => {
            page.value > 1 ? page.value-- : page.value = 1
        }

        const nextPage = () => {
            page.value += 1
        }

        watch(page, async () => {
            await getStudents()
        })

        watch(search, async () => {
            page.value = 1
            await getStudents()
        })

        const playerUploadStatus = ref(false)

        const healthStatusStatistics = ref({})

        const getStudents = async () => {
            playerUploadStatus.value = false
            const allPlayersResponse = (await getAllStudents(10, (page.value - 1) * 10, null, search.value)).data
            allStudents.value = []
            for (let i = 0; i < allPlayersResponse.length; i++) {
                const playerHealthResponse = await getPlayerHealthStatus(allPlayersResponse[i].student_id)

                allStudents.value.push({
                    ...allPlayersResponse[i],
                    playerHealth: playerHealthResponse
                })
            }
            playerUploadStatus.value = true
            console.log('allStudents.value -------> ', allStudents.value)
        }

        const changeStatus = (status) => {
            if (currentStatuses.value.includes(status)) {
                currentStatuses.value = currentStatuses.value.filter(s => s !== status)
            } else {
                currentStatuses.value.push(status)
            }
        }

        const balanceNewFilter = () => {
            balanceVector.value = balanceVector.value === 'desc' ? 'asc' : 'desc'
            allStudents.value = allStudents.value.sort((a, b) => {
                if (balanceVector.value === 'desc') {
                    return a.balance - b.balance
                } else {
                    return b.balance - a.balance
                }
            })
        }

        const studentLogo = (photo) => {
            return process.env.VUE_APP_API_URL + 'static/' + photo
        }

        onMounted(async () => {
            await getStudents()
            maxPage.value = Math.ceil((await getStudentsCount()) / 10)

            healthStatusStatistics.value = await getPlayersHealthStatistics()
            console.log('healthStatusStatistics.value -----> ', healthStatusStatistics.value)
        })

        return {
            allStudents,
            currentStatusFlag,
            currentStatuses,
            balanceVector,
            screenWidth,
            changeStatus,
            balanceNewFilter,
            studentLogo,
            search,
            page,
            maxPage,
            prevPage,
            nextPage,
            playerUploadStatus,
            healthStatusStatistics
        }
    }
}
</script>
<style lang="">
    
</style>