<template>
  <div class="stats-tab">
    <!-- <StarsComponent v-if="dataLoaded" :rating="7" :key="452" class="scale-[1.5] opacity-0 origin-right mr-[10px]" /> -->
    <TableWrapper>
      <TableComponent :fullWidth="true">
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell v-for="item in header" :key="item" :value="item" />
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          <TableRow v-for="(row, id) in allMatches" :key="row.id">
            <TableCell :asChild="true">
              <DateComponent :day="row.date.day" :time="row.date.time" />
            </TableCell>
            <TableCell :value="row.match" />
            <TableCell :value="row.league" />
            <TableCell :value="row.season" />
            <TableCell :value="row.goals ? row.goals : '-'" />
            <TableCell :value="row.assists ? row.assists : '-'" />
            <TableCell :value="row.penalty ? row.penalty : '-'" />
            <TableCell :value="row.autoGoal ? row.autoGoal : '-'" />
            <TableCell
              :value="row.duration ? row.duration + '&nbsp;хв' : '-&nbsp;хв'"
            />
            <TableCell :asChild="true">
              <div class="stats-tab__card">
                <img v-if="row.cards.yellow" :src="yellowCard" />
              </div>
            </TableCell>
            <TableCell :asChild="true">
              <div class="stats-tab__card">
                <img v-if="row.cards.red" :src="redCard" />
              </div>
            </TableCell>
            <TableCell :asChild="true">
              
              <StarsComponent v-if="dataLoaded" :rating="row.rating" :key="id" class="" :title="row.rating.toFixed(2)" />
            </TableCell>
            <TableCell :asChild="true">
              <div class="stats-tab__card">
                <img :src="eyeIcon" class="cursor-pointer" @click="$router.push(`/player-mark/${playerId}/${row.id}`)" />
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </TableComponent>
    </TableWrapper>

    <div v-if="allMatches?.length <= 0" class="mt-[20px] text-center text-gray-400">
      Немає даних
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

import { getPlayerStatisticsByPlayerId } from '@/services/apiRequests'

import yellowCard from "@/assets/refactored_assets/img/players/yellow-card.svg";
import redCard from "@/assets/refactored_assets/img/players/red-card.svg";
import eyeIcon from "@/assets/refactored_assets/img/players/eye-icon.svg";

export default {
  name: "StatsTab",
  props: {
    playerId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const allMatches = ref([]);
    const dataLoaded = ref(false);

    const reportsMarks = ref([]);

    onMounted(async () => {

      allMatches.value = await getPlayerStatisticsByPlayerId(props.playerId);

      console.log(allMatches.value);

      allMatches.value = allMatches.value.map((match) => {
        reportsMarks.value.push(match.player_average_score);
        
        return {
          id: match.match_report_id,
          date: {
            day: match.date.split('T')[0].split('-').reverse().join('.'),
            time: match.time,
          },
          match: match.firstTeamName + " - " + match.secondTeamName,
          league: match.league,
          season: match.season,
          goals: match.player_goals,
          assists: match.player_assist,
          penalty: match.player_penalty,
          autoGoal: match.player_autogoals,
          duration: match.player_game_time,
          cards: {
            yellow: match.player_yellow_card,
            red: match.player_red_card,
          },
          rating: match?.player_match_reports ? match?.player_match_reports?.reduce((acc, report) => acc + report.rating, 0) / match.player_match_reports.length : 0,
          trauma: '',
        };
      });

      dataLoaded.value = true;
    });

    return {
      allMatches,
      dataLoaded,
      reportsMarks
    }
  },

  data() {
    return {
      yellowCard: yellowCard,
      redCard: redCard,
      eyeIcon: eyeIcon,
      header: [
        " ",
        "матч",
        "ліга",
        "сезон",
        "голи",
        "асисти",
        "пенальті",
        "автоголи",
        "хв. на полі",
        "жовті",
        "червоні",
        "середня оцінка",
        "звіт",
      ],
      rows: [
        {
          id: 1,
          date: {
            day: "28.08.2023",
            time: "17:00",
          },
          time: "17:00",
          match: "Оболонь - Верес",
          league: "УПЛ",
          season: "2023-2024",
          goals: 1,
          assists: null,
          penalty: null,
          autoGoal: null,
          missed: null,
          duration: "96",
          cards: {
            yellow: 1,
            red: 1,
          },
          rating: 4,
          trauma: "",
        },
        {
          id: 2,
          date: "28.07.2023",
          time: "17:00",
          match: "Оболонь - Верес",
          league: "УПЛ",
          season: "2023-2024",
          goals: null,
          assists: null,
          penalty: null,
          autoGoal: null,
          missed: null,
          duration: null,
          cards: {
            yellow: null,
            red: null,
          },
          rating: 3,
          trauma: null,
        },
      ],
    };
  },
};
</script>
