<template>
    <div>
        <svg width="88" height="34" viewBox="0 0 88 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M17.3926 32.2435L14.0939 8.69682" stroke="white" stroke-width="0.2"/>
                <path d="M19.5918 32.2435L16.2932 8.69682" stroke="white" stroke-width="0.2"/>
                <path d="M21.791 32.2435L18.4924 8.69682" stroke="white" stroke-width="0.2"/>
                <path d="M23.9902 32.2436L20.6916 8.69685" stroke="white" stroke-width="0.2"/>
                <path d="M15.1934 32.2436L11.8947 8.69682" stroke="white" stroke-width="0.2"/>
                <path d="M12.9941 32.2436L9.6955 8.69682" stroke="white" stroke-width="0.2"/>
                <path d="M10.7949 32.2436L7.49628 8.69682" stroke="white" stroke-width="0.2"/>
                <path d="M30.5879 32.2436L27.2893 8.69685" stroke="white" stroke-width="0.2"/>
                <path d="M32.7871 32.2436L29.4885 8.69684" stroke="white" stroke-width="0.2"/>
                <path d="M34.9863 32.2436L31.6877 8.69684" stroke="white" stroke-width="0.2"/>
                <path d="M37.1855 32.2436L33.8869 8.69684" stroke="white" stroke-width="0.2"/>
                <path d="M28.3887 32.2436L25.09 8.69685" stroke="white" stroke-width="0.2"/>
                <path d="M26.1895 32.2436L22.8908 8.69685" stroke="white" stroke-width="0.2"/>
                <path d="M45.9814 32.2436L42.6828 8.69683" stroke="white" stroke-width="0.2"/>
                <path d="M48.1807 32.2436L44.882 8.69683" stroke="white" stroke-width="0.2"/>
                <path d="M50.3799 32.2436L47.0812 8.69683" stroke="white" stroke-width="0.2"/>
                <path d="M52.5791 32.2436L49.2805 8.69683" stroke="white" stroke-width="0.2"/>
                <path d="M43.7832 32.2436L40.4846 8.69684" stroke="white" stroke-width="0.2"/>
                <path d="M41.584 32.2436L38.2853 8.69684" stroke="white" stroke-width="0.2"/>
                <path d="M39.3848 32.2436L36.0861 8.69684" stroke="white" stroke-width="0.2"/>
                <path d="M59.1768 32.2436L55.8781 8.69682" stroke="white" stroke-width="0.2"/>
                <path d="M61.376 32.2436L58.0773 8.69682" stroke="white" stroke-width="0.2"/>
                <path d="M63.5752 32.2436L60.2766 8.69682" stroke="white" stroke-width="0.2"/>
                <path d="M65.7744 32.2436L62.4758 8.69682" stroke="white" stroke-width="0.2"/>
                <path d="M56.9775 32.2436L53.6789 8.69682" stroke="white" stroke-width="0.2"/>
                <path d="M54.7783 32.2436L51.4797 8.69683" stroke="white" stroke-width="0.2"/>
                <path d="M72.3701 32.2436L69.0715 8.69684" stroke="white" stroke-width="0.2"/>
                <path d="M74.5693 32.2436L71.2707 8.69683" stroke="white" stroke-width="0.2"/>
                <path d="M76.7686 32.2436L73.4699 8.69683" stroke="white" stroke-width="0.2"/>
                <path d="M78.9678 32.2436L75.6691 8.69683" stroke="white" stroke-width="0.2"/>
                <path d="M70.1709 32.2436L66.8723 8.69684" stroke="white" stroke-width="0.2"/>
                <path d="M67.9717 32.2436L64.673 8.69684" stroke="white" stroke-width="0.2"/>
                <path d="M81.166 32.2436L77.8674 8.69683" stroke="white" stroke-width="0.2"/>
                <path d="M83.3652 32.2436L80.0666 8.69683" stroke="white" stroke-width="0.2"/>
                <path d="M85.8965 25.7436L84.3965 7.74358" stroke="white" stroke-width="0.2"/>
                <path d="M84.8965 31.2436L82.2658 8.69682" stroke="white" stroke-width="0.2"/>
                <path d="M3.89648 28.2436H85.8965" stroke="white" stroke-width="0.2"/>
                <path d="M3.89648 30.2436H85.8965" stroke="white" stroke-width="0.2"/>
                <path d="M3.89648 26.2436H85.8965" stroke="white" stroke-width="0.2"/>
                <path d="M3.89648 24.2436H85.8965" stroke="white" stroke-width="0.2"/>
                <path d="M3.09961 21.8914L85.8965 21.8914" stroke="white" stroke-width="0.2"/>
                <path d="M1.99902 19.6923L85.8965 19.6923" stroke="white" stroke-width="0.2"/>
                <path d="M2 17.4932L86.534 17.4932" stroke="white" stroke-width="0.2"/>
                <path d="M1.99902 15.2941L87.0512 15.2941" stroke="white" stroke-width="0.2"/>
                <path d="M2 13.095L87.0512 13.095" stroke="white" stroke-width="0.2"/>
                <path d="M1.99902 10.8959L86.8965 10.8959" stroke="white" stroke-width="0.2"/>
                <path d="M5.29883 8.69682L86.8965 8.69682" stroke="white" stroke-width="0.2"/>
                <path d="M8.5957 32.2436L5.29707 8.69682" stroke="white" stroke-width="0.2"/>
                <path d="M6.39648 32.2436L3.09785 8.69682" stroke="white" stroke-width="0.2"/>
                <path class="color_changed_stroke" d="M8.59728 1L2 7.59728M2 7.59728L4.19909 32.8868H85.5655L87.7646 7.59728M2 7.59728H87.7646M87.7646 7.59728C87.7646 7.59728 83.7437 3.5764 81.1673 1" stroke="white" stroke-width="2"/>
                <path d="M87.3965 7.74358L84.8965 32.7436L4.39648 32.7436L2.39648 7.74358L87.3965 7.74358Z" fill="#D9D9D9" fill-opacity="0.05"/>
            </g>
        </svg>

    </div>

    
    
</template>

<script>
    export default {
        name: 'TopBackGates',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>