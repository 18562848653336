<template>
    <div>
        <div v-if="status == 'select_club'">
            <div>
                <div class="flex justify-center items-center">
                    <div>
                        <div class="text-2xl font-bold text-center">{{ currentStudent?.name }} {{ currentStudent?.surname }}</div>
                        <div class="text-[#BD9135] text-[12px] mt-[8px]">перелік матчів у яких приймав участь гравець</div>
                    </div>
                </div>  
            </div>
    
            <div class="w-[80%] mx-auto mt-[56px]">
                <TableWrapper>
                    <TableComponent :fullWidth="true">
                        <TableHeader>
                        <TableHeaderRow>
                            <TableHeaderCell v-for="item in header" :key="item" :value="item" />
                        </TableHeaderRow>
                        </TableHeader>
                        <TableBody v-if="allMatches.length > 0">
                            <TableRow v-for="(row, id) in allMatches" :key="row.id">
                                <TableCell :asChild="true">
                                    <DateComponent :day="row.date.day" :time="row.date.time" />
                                </TableCell>
                                <TableCell :value="row.match" />
                                <TableCell :value="row.league" />
                                <TableCell :value="row.season" />
                                <TableCell :value="row.goals ? row.goals : '-'" />
                                <TableCell :value="row.assists ? row.assists : '-'" />
                                <TableCell :value="row.penalty ? row.penalty : '-'" />
                                <TableCell :value="row.autoGoal ? row.autoGoal : '-'" />
                                <TableCell
                                    :value="row.duration ? row.duration + '&nbsp;хв' : '-&nbsp;хв'"
                                />
                                <TableCell :asChild="true">
                                <div class="stats-tab__card">
                                    <img v-if="row.cards.yellow" :src="yellowCard" />
                                </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                <div class="stats-tab__card">
                                    <img v-if="row.cards.red" :src="redCard" />
                                </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <StarsComponent :rating="row.rating" />
                                </TableCell>
                                <TableCell :asChild="true">
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div class="custom-checkbox">
                                        <input @change="changeMatch(row)" :checked="selectedMatch?.id == row.id" type="checkbox" :id="'checkbox-' + id" class="checkbox-input">
                                        <label :for="'checkbox-' + id" class="checkbox-box"></label>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
    
                        <tbody v-else>
                            <tr>
                                <td :colspan="header.length" class="pt-[20px] text-center text-gray-400">Немає даних</td>
                            </tr>
                        </tbody>
                    </TableComponent>
                </TableWrapper>
            </div>
    
            <div class="flex flex-col justify-center items-center mt-[53px] gap-[12px]">
                <ButtonComponent :disabled="!selectedMatch" @click="status = 'create_report'" :class="{
                    'opacity-50' : !selectedMatch
                }">
                    Додати звіт
                </ButtonComponent>
                <ButtonComponent @click="$router.push('/player-summary-report/' + playerId)">
                    Створити підсумковий звіт
                </ButtonComponent>
                <ButtonComponent :gray="true" @click="$router.go(-1)">
                    Назад
                </ButtonComponent>
            </div>
        </div>

        <div v-if="status == 'create_report'" class="w-[50%] mx-auto">
            <div class="flex justify-end">
                <ButtonComponent v-if="updateBaseDataFlag == false" :outlined="true" @click="updateBaseDataFlag = true" class="mr-[20px]">
                    Редагувати всі дані
                </ButtonComponent>
                <ButtonComponent v-else :positive="true" @click="updateBaseDataFlag = false" class="mr-[20px]">
                    Завершити редагування
                </ButtonComponent>
                <ButtonComponent @click="status = 'select_club'">
                    Назад
                </ButtonComponent>
            </div>

            <div class="flex justify-center mt-[20px] border-y-[1px] border-[#BD913533] items-center">
                <div class="w-[33.3333%] flex justify-center">
                    <div class="match-report__banner" style="background-color: transparent; border: none;">
                        <div class="match-report__banner--title">
                            <div>{{ matchReport.league }}</div>
                            <div>Тур {{ matchReport.tour }}</div>
                        </div>

                        <div class="match-report__banner--teams">
                            <div class="match-report__banner--team">
                                <img :src="matchReport.firstTeamLogo ? apiURL + 'static/' + matchReport.firstTeamLogo : require('@/assets/kolos_team.svg')" class="w-max" alt="">
                                <div>{{ matchReport.firstTeamName }}</div>
                            </div>
                            <div class="match-report__banner--score">
                                <div>{{ matchReport.firstTeamScore }}:{{ matchReport.secondTeamScore }}</div>
                                <div>{{ matchTime }}''</div>

                            </div>
                            <div class="match-report__banner--team">
                                <img :src="matchReport.secondTeamLogo ? apiURL + 'static/' + matchReport.secondTeamLogo : require('@/assets/kolos_team.svg')" class="w-max" alt="">
                                <div>{{ matchReport.secondTeamName }}</div>
                            </div>
                        </div>

                        <div class="match-report__banner--info">
                            <!-- <div>{{ matchReport.date.split('T')[0].split('-').reverse().join('.') }}</div> -->
                            <div>({{ matchReport.time }})</div>
                            <div>{{ matchReport.stadium }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-col items-center justify-center py-[15px] border-y-[1px] border-[#BD913533]">
                <AvatarContainer :src="apiURL + 'static/' + currentStudent.photo" />
                <div class="text-[21px] font-bold">{{ currentStudent.name }} {{ currentStudent.surname }}</div>
            </div>

            <div class="flex gap-[30px] mt-[30px] justify-center">
                <div class="documents-tab__column">
                    <InputComponent 
                        :label="'клуб'" 
                        :placeholder="''" 
                        :name="'клуб'" 
                        :value="currentStudent.group_name"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.group_name = e" 
                    />
                    <InputComponent 
                        :label="'ім\'я'" 
                        :placeholder="''" 
                        :name="'ім\'я'" 
                        :value="currentStudent.name"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.name = e" 
                    />
                    <InputComponent 
                        :label="'прізвище'" 
                        :placeholder="''" 
                        :name="'прізвище'" 
                        :value="currentStudent.surname"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.surname = e" 
                    />
                    <InputComponent 
                        :label="'вік'" 
                        :placeholder="''" 
                        :name="'вік'" 
                        :value="currentStudent.age"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.age = e" 
                    />
                    <InputComponent 
                        :label="'дата народження'" 
                        :placeholder="''" 
                        :name="'дата народження'" 
                        type="date"
                        :value="currentStudent.birthday"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.birthday = e" 
                    />
                    <InputComponent 
                        :label="'національність'" 
                        :placeholder="''" 
                        :name="'національність'" 
                        :value="currentStudent.nationality"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.nationality = e" 
                    />
                    <InputComponent 
                        :label="'місце народження'" 
                        :placeholder="''" 
                        :name="'місце народження'" 
                        :value="currentStudent.address"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.address = e" 
                    />
                </div>
                <div class="documents-tab__column">
                    <InputComponent 
                        :label="'стандартна позиція'" 
                        :placeholder="''" 
                        :name="'позиція'"
                        :value="currentStudent.amplua"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.amplua = e" 
                    />
                    <InputComponent 
                        :label="'зріст'" 
                        :placeholder="''" 
                        :name="'зріст'" 
                        :value="currentStudent.height"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.height = e" 
                    />
                    <InputComponent 
                        :label="'вага'" 
                        :placeholder="''" 
                        :name="'вага'"
                        :value="currentStudent.weight"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.weight = e" 
                    />
                    <InputComponent 
                        :label="'нога'" 
                        :placeholder="''" 
                        :name="'нога'" 
                        :value="currentStudent.leading_foot"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.leading_foot = e"
                    />
                    <InputComponent 
                        :label="'попередній клуб'" 
                        :placeholder="''" 
                        :name="'попередній клуб'" 
                        :value="currentStudent.leading_foot"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.leading_foot = e"
                    />
                    <InputComponent 
                        :label="'асистів в матчі'" 
                        :placeholder="''" 
                        :name="'асистів в матчі'" 
                        :value="selectedMatch.assists"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => selectedMatch.assists = e" 
                    />
                    <InputComponent 
                        :label="'голів в матчі'" 
                        :placeholder="''" 
                        :name="'голів в матчі'" 
                        :value="selectedMatch.goals"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => selectedMatch.goals = e" 
                    />
                </div>
                <div class="documents-tab__column">
                    <InputComponent 
                        :label="'закінчення контракта'" 
                        :placeholder="''" 
                        :name="'закінчення контракта'"
                        :type="'date'" 
                        :value="currentStudent.contract_term"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.contract_term = e"
                    />
                    <InputComponent 
                        :label="'агент'" 
                        :placeholder="''" 
                        :name="'агент'"
                        :value="currentStudent.representative"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.representative = e"
                    />
                    <InputComponent 
                        :label="'агенція'" 
                        :placeholder="''" 
                        :name="'агенція'"
                        :value="currentStudent.agency"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.agency = e"
                    />
                    <div class="border-[1px] border-[#BD913566] relative top-[-5px] h-[233px] border-dashed rounded-[10px] flex flex-col gap-[7px] py-[18px] px-[5px]">
                        <div class="mt-[-11px]">
                            <SelectComponent
                                :options="['Стовп', 'Бомбардир', 'Мобільний', 'Інсайд', 'Плеймейкер', 'Конектор', 'Ранер', 'Латераль', 'Диспетчер', 'Руйнівник', 'Оборонець', 'Гравець', 'Атакуючий']" 
                                :label="'роль в цьому матчі'"
                                @change="e => matchRole = e"
                            />
                        </div>
                        <div>
                            <SelectComponent
                                class="block mt-[20px]"
                                :options="['A - Відмінно', 'B - Добре', 'C - Задовільно', 'D - Погано']" 
                                :label="'матч рейтинг'"
                                @change="e => matchRating = e"
                            />
                        </div>
                        <div>
                            <SelectComponent
                                class="block mt-[20px]"
                                :options="['А – ЗІРКА', 'B – ТАЛАНТ', 'C – МАЙСТЕР', 'D – ПРОФЕСІОНАЛ', 'E – АМАТОР', 'F – НОВАЧОК']" 
                                :label="'оцінка потенціалу'"
                                @change="e => grading = e"
                            />
                        </div>
                        <div>
                            <SelectComponent
                                class="block mt-[20px]"
                                :options="['A - Відмінно', 'B - Добре', 'C - Задовільно', 'D - Погано']" 
                                :label="'рекомендація'"
                                @change="e => nextSteps = e"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-col gap-[20px] border-[1px] border-[#BD913566] border-dashed mt-[30px] rounded-[10px] p-[10px]">
                <TextArea :value="comment1" @update:modelValue="e => comment1 = e" :label="'#1 Comment'" :backgroundColor="'#47484966'" />
                <TextArea :value="comment2" @update:modelValue="e => comment2 = e" :label="'#2 Comment'" :backgroundColor="'#47484966'" />
            </div>

            <div class="mt-[15px] flex gap-[14px] justify-center">
                <div v-for="(markType, id) in markTypes" class="flex flex-col w-max justify-center items-center">
                    <div class="text-[14px] truncate">{{ markType }}</div>
                    <div 
                        class="w-[98px] p-[5px] rounded-[6px]"
                        :class="{
                            'bg-[#DA4167]': marks[id] == null || marks[id] <= 4,
                            'bg-[#C59014]': marks[id] > 4 && marks[id] <= 7,
                            'bg-[#1A936F]': marks[id] > 7,
                        }"    
                    >
                        <input v-model="marks[id]" type="text" class="w-full text-center font-bold placeholder:text-[#FFFFFF80]" placeholder="0.0">
                    </div>
                </div>
            </div>

            <div class="flex justify-center mt-[30px] mb-[50px]">
                <ButtonComponent @click="addReport">
                    Зберегти
                </ButtonComponent>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getPlayerStatisticsByPlayerId, getStudentById, getMatchReportById, getPlayerFullCardByStudentId, addPlayerScoutReport, getAdmin } from '@/services/apiRequests'
import { useGlobalStore } from '@/storage/globalStorage'

export default {
    name: 'PlayerScoutReportForm',   
    
    setup(props) {
        const route = useRoute();
        const router = useRouter();
        const apiURL = process.env.VUE_APP_API_URL;

        const status = ref('select_club')

        const playerId = ref(route.params.student_id);

        const store = useGlobalStore();

        const currentStudent = ref({});
        const currentStudentFullCard = ref({});

        const markTypes = ref(currentStudent.value.amplua == 'ВР' ? Object.values(store.$state.scoringRadarChartSettings.goalkeeper).slice(2).filter(value => value !== null) : Object.values(store.$state.scoringRadarChartSettings.field_player).slice(2).filter(value => value !== null));

        const allMatches = ref([]);

        const selectedMatch = ref(null);

        const matchReport = ref({})

        const marks = ref(Array(9).fill(null));

        const matchRole = ref('')
        const matchRating = ref('')
        const grading = ref('')
        const nextSteps = ref('')
        const comment1 = ref('')
        const comment2 = ref('')

        const updateBaseDataFlag = ref(false);

        const admin = ref({});

        watch(marks, () => {
            marks.value.forEach((mark, index) => {
                if (mark > 10) {
                    marks.value[index] = 10;
                }
            });
        }, { deep: true });

        const changeMatch = async (match) => {
            selectedMatch.value = match;
            console.log('selectedMatch.value ------ ', selectedMatch.value);
            matchReport.value = await getMatchReportById(match.id);
        }

        onMounted(async () => {
            allMatches.value = await getPlayerStatisticsByPlayerId(playerId.value);

            const adminResponse = await getAdmin()
            admin.value = adminResponse.data

            console.log(allMatches.value);

            allMatches.value = allMatches.value.map((match) => {
                return {
                    id: match.match_report_id,
                    date: {
                        day: match.date.split('T')[0].split('-').reverse().join('.'),
                        time: match.time,
                    },
                    match: match.firstTeamName + " - " + match.secondTeamName,
                    league: match.league,
                    season: match.season,
                    goals: match.player_goals,
                    assists: match.player_assist,
                    penalty: match.player_penalty,
                    autoGoal: match.player_autogoals,
                    duration: match.player_game_time,
                    cards: {
                        yellow: match.player_yellow_card,
                        red: match.player_red_card,
                    },
                    rating: match.player_average_score,
                    trauma: '',
                };
            });

            currentStudent.value = (await getStudentById(playerId.value)).data.student;
            currentStudentFullCard.value = await getPlayerFullCardByStudentId(playerId.value);

            markTypes.value = currentStudent.value.amplua == 'ВР' ? Object.values(store.$state.scoringRadarChartSettings.goalkeeper).slice(2).filter(value => value !== null) : Object.values(store.$state.scoringRadarChartSettings.field_player).slice(2).filter(value => value !== null);

            currentStudent.value.birthday = currentStudent.value.birthday?.split('T')[0];
            currentStudent.value.contract_term = currentStudent.value.contract_term?.split('T')[0];
        });

        const addReport = async () => {
            console.log('currentStudent.value.birthday ----- ', new Date(currentStudent.value.birthday));
            currentStudent.value.birthday = new Date(currentStudent.value.birthday).toISOString();
            currentStudent.value.contract_term = currentStudent.value.contract_term ? new Date(currentStudent.value.contract_term).toISOString() : null;
            const report = {
                student_id: playerId.value,
                student_name: currentStudent.value.name,
                student_surname: currentStudent.value.surname,
                student_photo: currentStudent.value.photo,
                scout_id: admin.value.admin_id,
                scout_name: admin.value.admin_name,
                scout_surname: admin.value.admin_surname,
                report_date: new Date().toISOString(),
                student_club: currentStudent.value.group_name,
                student_age: currentStudent.value.age,
                student_birth_date: currentStudent.value.birthday,
                student_nationality: currentStudent.value.nationatily,
                student_birth_place: currentStudent.value.address,
                student_position: currentStudent.value.amplua,
                student_height: currentStudent.value.height,
                student_weight: currentStudent.value.weight,
                student_leg: currentStudent.value.leading_foot,
                student_previous_club: '-',
                student_assists: selectedMatch.value.assists,
                student_goals: selectedMatch.value.goals,
                student_contract_end_date: currentStudent.value.contract_term,
                student_agent: currentStudent.value.representative,
                student_agency: currentStudent.value.agency,
                role_in_current_game: matchRole.value ? matchRole.value : 'Стовп',
                match_rating: matchRating.value ? matchRating.value : 'A - Відмінно',
                grading: grading.value ? grading.value : 'A - Відмінно',
                next_steps: nextSteps.value ? nextSteps.value : 'A - Відмінно',
                comment_1: comment1.value,
                comment_2: comment2.value,
                first_point: marks.value[0] ? marks.value[0] : 0,
                second_point: marks.value[1] ? marks.value[1] : 0,
                third_point: marks.value[2] ? marks.value[2] : 0,
                fourth_point: marks.value[3] ? marks.value[3] : 0,
                fifth_point: marks.value[4] ? marks.value[4] : 0,
                sixth_point: marks.value[5] ? marks.value[5] : 0,
                seventh_point: marks.value[6] ? marks.value[6] : 0,
                eights_point: marks.value[7] ? marks.value[7] : 0,
                nineth_point: marks.value[8] ? marks.value[8] : 0,
                match_id: selectedMatch.value.id,
                match_firts_team: matchReport.value.firstTeamName,
                match_second_team: matchReport.value.secondTeamName,
                match_date: matchReport.value.date,

                first_team_match_logo: matchReport.value.firstTeamLogo,
                second_team_match_logo: matchReport.value.secondTeamLogo,
                
                match_id: selectedMatch.value.id,
                match_league: matchReport.value.league,
                match_tour: matchReport.value.tour,
                first_team_name: matchReport.value.firstTeamName,
                first_team_score: matchReport.value.firstTeamScore,
                second_team_name: matchReport.value.secondTeamName,
                second_team_score: matchReport.value.secondTeamScore,
                match_time: matchReport.value.matchTime,
                match_date: matchReport.value.date,
                match_stadium: matchReport.value.stadium
            }
            console.log('report ----- ', report);

            await addPlayerScoutReport(report);
            router.go(-1);
        }

        return {
            allMatches,
            changeMatch,
            selectedMatch,
            currentStudent,
            status,
            matchReport,
            apiURL,
            currentStudentFullCard,
            marks,
            matchRole,
            matchRating,
            grading,
            nextSteps,
            comment1,
            comment2,
            updateBaseDataFlag,
            addReport,
            markTypes,
            playerId,
            header: [
                " ",
                "матч",
                "ліга",
                "сезон",
                "голи",
                "асисти",
                "пенальті",
                "автоголи",
                "хв. на полі",
                "жовті",
                "червоні",
                "середня оцінка",
                "",
                "",
            ],
        }
    },
}
</script>

<style lang="scss">
.custom-checkbox {
    position: relative;
    display: inline-block;

    .checkbox-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked + .checkbox-box {
            background-color: #C59014;
            border-color: #C59014;

            &:after {
                display: block;
            }
        }
    }

    .checkbox-box {
        width: 20px;
        height: 20px;
        background-color: #474849;
        border: 2px solid #C59014;
        border-radius: 3px;
        position: relative;
        cursor: pointer;
        display: block;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }
}


</style>