<template>
    <div class="input-component" :style="{ width: width }">
        <label :for="name" class="input-component__label">{{ label }}</label>
        <div 
            class="input-component__field"
            :class="{'input-component__field--negative': negative}"    
        >
            <input 
                autocomplete="new-password"
                :value="value"
                @change="e => $emit('update:modelValue', e.target.value)" 
                @focusin="$emit('focusin')"
                :id="name" 
                :name="name" 
                :placeholder="placeholder" 
                class="outline-none" 
                :disabled="disabled"
                :class="{'input-component--hidden': hidden}" 
                style="width: 100%;"
                :style="{ textAlign: textAlign }"
                :type="type == 'date' || type == 'time' || type == 'datetime-local' ? type : inputType" 
            />
            <img :src="eye_hidden"  v-if="hidden" type="button" @click="toggleVisibility"/>
        </div>
    </div>
</template>

<script>
import eye_hidden from '@/assets/refactored_assets/img/form/eye_hidden.svg';
export default {
    name: 'InputComponent',
    props: {
        label: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        hidden: {
            type: Boolean,
            default: false
        },
        value: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        negative: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: '100%'
        },
        textAlign: {
            type: String,
            default: 'left'
        }
    },
    data() {
        return {
            show: false,
            eye_hidden: eye_hidden
        }
    },
    computed: {
        inputType() {
            return this.hidden ? (this.show ? 'text' : 'password') : 'text';
        }
    },
    methods: {
        toggleVisibility() {
            this.show = !this.show;
        }
    }
}
</script>

<style scoped lang="scss">

input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(100%);
}

</style>