<template>
    <div>
        <svg width="88" height="34" viewBox="0 0 88 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M71.5039 2.00003L74.8025 25.5468" stroke="white" stroke-width="0.2"/>
                <path d="M69.3047 2.00003L72.6033 25.5468" stroke="white" stroke-width="0.2"/>
                <path d="M67.1055 2.00003L70.4041 25.5468" stroke="white" stroke-width="0.2"/>
                <path d="M64.9062 2L68.2049 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M73.7031 2.00002L77.0018 25.5468" stroke="white" stroke-width="0.2"/>
                <path d="M75.9023 2.00002L79.201 25.5468" stroke="white" stroke-width="0.2"/>
                <path d="M78.1016 2.00002L81.4002 25.5468" stroke="white" stroke-width="0.2"/>
                <path d="M58.3086 2L61.6072 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M56.1094 2.00001L59.408 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M53.9102 2.00001L57.2088 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M51.7109 2.00001L55.0096 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M60.5078 2L63.8065 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M62.707 2L66.0057 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M42.915 2.00002L46.2137 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M40.7158 2.00002L44.0145 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M38.5166 2.00002L41.8152 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M36.3174 2.00002L39.616 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M45.1133 2.00001L48.4119 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M47.3125 2.00001L50.6111 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M49.5117 2.00001L52.8104 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M29.7197 2.00002L33.0184 25.5468" stroke="white" stroke-width="0.2"/>
                <path d="M27.5205 2.00002L30.8191 25.5468" stroke="white" stroke-width="0.2"/>
                <path d="M25.3213 2.00002L28.6199 25.5468" stroke="white" stroke-width="0.2"/>
                <path d="M23.1221 2.00002L26.4207 25.5468" stroke="white" stroke-width="0.2"/>
                <path d="M31.9189 2.00002L35.2176 25.5468" stroke="white" stroke-width="0.2"/>
                <path d="M34.1182 2.00002L37.4168 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M16.5264 2.00001L19.825 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M14.3271 2.00002L17.6258 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M12.1279 2.00002L15.4266 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M9.92871 2.00002L13.2273 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M18.7256 2.00001L22.0242 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M20.9248 2.00001L24.2234 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M7.73047 2.00002L11.0291 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M5.53125 2.00002L8.82989 25.5467" stroke="white" stroke-width="0.2"/>
                <path d="M3 8.5L4.5 26.5" stroke="white" stroke-width="0.2"/>
                <path d="M4 3L6.63067 25.5468" stroke="white" stroke-width="0.2"/>
                <path d="M85 6L3 6" stroke="white" stroke-width="0.2"/>
                <path d="M85 4L3 4" stroke="white" stroke-width="0.2"/>
                <path d="M85 8L3 8" stroke="white" stroke-width="0.2"/>
                <path d="M85 10L3 10" stroke="white" stroke-width="0.2"/>
                <path d="M85.7969 12.3522L3 12.3522" stroke="white" stroke-width="0.2"/>
                <path d="M86.8975 14.5513L3 14.5513" stroke="white" stroke-width="0.2"/>
                <path d="M86.8965 16.7504L2.36243 16.7504" stroke="white" stroke-width="0.2"/>
                <path d="M86.8975 18.9495L1.84525 18.9495" stroke="white" stroke-width="0.2"/>
                <path d="M86.8965 21.1486L1.84525 21.1486" stroke="white" stroke-width="0.2"/>
                <path d="M86.8975 23.3476L2 23.3476" stroke="white" stroke-width="0.2"/>
                <path d="M83.5977 25.5468L2 25.5468" stroke="white" stroke-width="0.2"/>
                <path d="M80.3008 2.00002L83.5994 25.5468" stroke="white" stroke-width="0.2"/>
                <path d="M82.5 2.00002L85.7986 25.5468" stroke="white" stroke-width="0.2"/>
                <path class="color_changed_stroke" d="M80.2992 33.2436L86.8965 26.6463M86.8965 26.6463L84.6974 1.35674L3.33098 1.35674L1.13189 26.6463M86.8965 26.6463L1.13189 26.6463M1.13189 26.6463C1.13189 26.6463 5.15277 30.6672 7.72917 33.2436" stroke="white" stroke-width="2"/>
                <path d="M1.5 26.5L4 1.5H84.5L86.5 26.5H1.5Z" fill="#D9D9D9" fill-opacity="0.05"/>
            </g>
        </svg>

    </div>

    
</template>

<script>
    export default {
        name: 'TopFrontGates',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>