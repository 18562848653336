<template>
    <svg v-if="screenWidth >= 1280" class="field" width="880" height="640" viewBox="0 0 880 640" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1343_1406)">
        <path d="M0 -0.000244141V640H880V-0.000244141H0Z" fill="#40A769"/>
        <path d="M320 26.999L320 612.999H368L368 26.999H320Z" fill="#4EC283"/>
        <path d="M224 26.999L224 612.999H272L272 26.999H224Z" fill="#4EC283"/>
        <path d="M128 26.999L128 612.999H176L176 26.999H128Z" fill="#4EC283"/>
        <path d="M31 27L31 613H79L79 27H31Z" fill="#4EC283"/>
        <path d="M416 26.999L416 612.999H464L464 26.999H416Z" fill="#4EC283"/>
        <path d="M512 26.999V612.999H560V26.999H512Z" fill="#4EC283"/>
        <path d="M608 26.999V612.999H656V26.999H608Z" fill="#4EC283"/>
        <path d="M704 26.999V612.999H752V26.999H704Z" fill="#4EC283"/>
        <path d="M800 27V613H848V27H800Z" fill="#4EC283"/>
        <path d="M439.104 260.314C472.011 260.314 498.707 287.207 498.707 320.407C498.707 353.607 472.011 380.5 439.104 380.5C406.197 380.5 379.5 353.607 379.5 320.407C379.5 287.207 406.197 260.314 439.104 260.314Z" stroke="#EFEFEF" stroke-width="3"/>
        <path d="M443.024 320.46C443.024 318.505 441.452 316.92 439.512 316.92C437.573 316.92 436 318.505 436 320.46C436 322.415 437.573 324 439.512 324C441.452 324 443.024 322.415 443.024 320.46Z" fill="#EFEFEF"/>
        <path d="M439.5 28.5L439.5 611.5H846.5V28.5H439.5Z" stroke="#EFEFEF" stroke-width="3"/>
        <mask id="mask0_1343_1406" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="718" y="266" width="25" height="109">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M743 374.272V266.702C727.499 279.371 718 297.426 718 320.001C717.999 343.777 727.498 361.879 743 374.272Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_1343_1406)">
        <path d="M743 374.272L741.126 376.616L746 380.512V374.272H743ZM743 266.702H746V260.376L741.101 264.38L743 266.702ZM746 374.272V266.702H740V374.272H746ZM721 320.001C721 298.359 730.068 281.145 744.898 269.025L741.101 264.38C724.929 277.597 715 296.492 715 320.001H721ZM744.873 371.929C730.095 360.114 720.999 342.88 721 320.001H715C714.999 344.674 724.901 363.644 741.126 376.616L744.873 371.929Z" fill="#EFEFEF"/>
        </g>
        <path d="M809.5 259.5V380.5H846.5V259.5H809.5Z" stroke="#EFEFEF" stroke-width="3"/>
        <path d="M846.5 295.5V344.5H865.5V295.5H846.5Z" stroke="#EFEFEF" stroke-width="3"/>
        <path d="M770.024 320.46C770.024 318.505 768.452 316.92 766.512 316.92C764.573 316.92 763 318.505 763 320.46C763 322.415 764.573 324 766.512 324C768.452 324 770.024 322.415 770.024 320.46Z" fill="#EFEFEF"/>
        <path d="M833.052 27.9692L847.014 41.9306" stroke="#EFEFEF" stroke-width="3"/>
        <path d="M832.948 611.931L846.909 597.97" stroke="#EFEFEF" stroke-width="3"/>
        <path d="M741.5 189.5V450.5H846.5V189.5H741.5Z" stroke="#EFEFEF" stroke-width="3"/>
        <path d="M439.5 611.5L439.5 28.5L32.5 28.5L32.5 611.5H439.5Z" stroke="#EFEFEF" stroke-width="3"/>
        <mask id="mask1_1343_1406" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="136" y="265" width="25" height="109">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M136 265.728L136.001 373.298C151.501 360.629 161 342.574 161 319.999C161.001 296.223 151.502 278.121 136 265.728Z" fill="white"/>
        </mask>
        <g mask="url(#mask1_1343_1406)">
        <path d="M136 265.728L137.874 263.384L133 259.488V265.728H136ZM136.001 373.298H133.001V379.624L137.899 375.62L136.001 373.298ZM133 265.728L133.001 373.298H139.001L139 265.728H133ZM158 319.999C158 341.641 148.932 358.855 134.102 370.975L137.899 375.62C154.071 362.403 164 343.508 164 319.999H158ZM134.127 268.071C148.905 279.886 158.001 297.12 158 319.999H164C164.001 295.326 154.099 276.356 137.874 263.384L134.127 268.071Z" fill="#EFEFEF"/>
        </g>
        <path d="M69.5 380.5L69.5 259.5H32.5L32.5 380.5H69.5Z" stroke="#EFEFEF" stroke-width="3"/>
        <path d="M32.5 344.5V295.5H13.5L13.5 344.5H32.5Z" stroke="#EFEFEF" stroke-width="3"/>
        <path d="M108.976 319.54C108.976 321.495 110.548 323.08 112.488 323.08C114.427 323.08 116 321.495 116 319.54C116 317.585 114.427 316 112.488 316C110.548 316 108.976 317.585 108.976 319.54Z" fill="#EFEFEF"/>
        <path d="M45.9478 612.031L31.9864 598.07" stroke="#EFEFEF" stroke-width="3"/>
        <path d="M46.0522 28.0691L32.0908 42.0305" stroke="#EFEFEF" stroke-width="3"/>
        <path d="M137.5 450.5L137.5 189.5H32.5L32.5 450.5H137.5Z" stroke="#EFEFEF" stroke-width="3"/>
        <line x1="137.5" y1="611" x2="137.5" y2="28" stroke="white" stroke-width="3" stroke-dasharray="6 6"/>
        <line x1="31" y1="259.5" x2="848" y2="259.5" stroke="white" stroke-width="3" stroke-dasharray="6 6"/>
        <line x1="31" y1="380.5" x2="848" y2="380.5" stroke="white" stroke-width="3" stroke-dasharray="6 6"/>
        <line x1="741.5" y1="613" x2="741.5" y2="30" stroke="white" stroke-width="3" stroke-dasharray="6 6"/>
        <line x1="606.5" y1="611" x2="606.5" y2="28" stroke="white" stroke-width="3" stroke-dasharray="6 6"/>
        <line x1="270.5" y1="613" x2="270.5" y2="30" stroke="white" stroke-width="3" stroke-dasharray="6 6"/>
      </g>
      <defs>
      <clipPath id="clip0_1343_1406">
      <rect width="880" height="640" fill="white"/>
      </clipPath>
      </defs>
    </svg>
      <svg v-else-if="screenWidth < 1280 && screenWidth > 1000" width="660" height="480" viewBox="0 0 660 480" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <marker
            id="arrow-end"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,5 L5,2.5 Z" fill="black" />
          </marker>
          <marker
            id="arrow-start"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto-start-reverse"
            markerUnits="strokeWidth"
          >
            <path d="M5,2.5 L0,5 L0,0 Z" fill="black" />
          </marker>
          
        </defs>
        <g>
          <path d="M0 0H880V640H0V0Z" fill="#40A769"/>
          <rect x="37.126" y="433.898" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="303.729" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="173.559" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="42.0339" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="564.068" width="805.75" height="33.8983" fill="#4EC283" fill-opacity="0.3"/>
          <path d="M524.131 595.395C524.131 549.637 486.213 512.542 439.44 512.542C392.667 512.542 354.75 549.637 354.75 595.395" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="439.367" cy="595.948" rx="4.86726" ry="4.76163" fill="#EFEFEF"/>
          <rect x="840.645" y="595.736" width="801.29" height="551.472" transform="rotate(180 840.645 595.736)" stroke="#EFEFEF" stroke-width="4.46"/>
          <mask id="path-10-inside-1_1256_1644" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M514.625 184.408L366.716 184.408C384.135 205.426 408.96 218.306 440.002 218.306C472.693 218.306 497.584 205.426 514.625 184.408Z"/>
          </mask>
          <path d="M514.625 184.408L518.089 187.216L523.983 179.948L514.625 179.948L514.625 184.408ZM366.716 184.408L366.716 179.948L357.227 179.948L363.282 187.254L366.716 184.408ZM440.002 218.306L440.002 213.846L440.002 218.306ZM514.625 179.948L366.716 179.948L366.716 188.868L514.625 188.868L514.625 179.948ZM440.002 213.846C410.324 213.846 386.745 201.586 370.15 181.562L363.282 187.254C381.525 209.266 407.597 222.766 440.002 222.766L440.002 213.846ZM511.16 181.599C494.988 201.546 471.384 213.846 440.002 213.846L440.002 222.766C474.003 222.766 500.179 209.307 518.089 187.216L511.16 181.599Z" fill="#EFEFEF" mask="url(#path-10-inside-1_1256_1644)"/>
          <rect x="523.02" y="94.0412" width="166.04" height="49.7773" transform="rotate(180 523.02 94.0412)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="473.52" y="43.8718" width="67.04" height="25.3705" transform="rotate(180 473.52 43.8718)" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="440.633" cy="152.526" rx="4.86726" ry="4.76163" transform="rotate(180 440.633 152.526)" fill="#EFEFEF"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(0.712027 -0.702153 0.717602 0.696453 39.9268 63.7288)" stroke="#EFEFEF" stroke-width="4.46"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(-0.712027 -0.702152 0.717602 -0.696453 842.875 61.0168)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="619.27" y="186.245" width="358.54" height="141.981" transform="rotate(180 619.27 186.245)" stroke="#EFEFEF" stroke-width="4.46"/>
        </g>
      </svg>
      <svg v-else-if="screenWidth <= 1000 && screenWidth > 880" width="550" height="400" viewBox="0 0 550 400" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <marker
            id="arrow-end"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,5 L5,2.5 Z" fill="black" />
          </marker>
          <marker
            id="arrow-start"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto-start-reverse"
            markerUnits="strokeWidth"
          >
            <path d="M5,2.5 L0,5 L0,0 Z" fill="black" />
          </marker>
          
        </defs>
        <g>
          <path d="M0 0H880V640H0V0Z" fill="#40A769"/>
          <rect x="37.126" y="433.898" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="303.729" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="173.559" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="42.0339" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="564.068" width="805.75" height="33.8983" fill="#4EC283" fill-opacity="0.3"/>
          <path d="M524.131 595.395C524.131 549.637 486.213 512.542 439.44 512.542C392.667 512.542 354.75 549.637 354.75 595.395" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="439.367" cy="595.948" rx="4.86726" ry="4.76163" fill="#EFEFEF"/>
          <rect x="840.645" y="595.736" width="801.29" height="551.472" transform="rotate(180 840.645 595.736)" stroke="#EFEFEF" stroke-width="4.46"/>
          <mask id="path-10-inside-1_1256_1644" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M514.625 184.408L366.716 184.408C384.135 205.426 408.96 218.306 440.002 218.306C472.693 218.306 497.584 205.426 514.625 184.408Z"/>
          </mask>
          <path d="M514.625 184.408L518.089 187.216L523.983 179.948L514.625 179.948L514.625 184.408ZM366.716 184.408L366.716 179.948L357.227 179.948L363.282 187.254L366.716 184.408ZM440.002 218.306L440.002 213.846L440.002 218.306ZM514.625 179.948L366.716 179.948L366.716 188.868L514.625 188.868L514.625 179.948ZM440.002 213.846C410.324 213.846 386.745 201.586 370.15 181.562L363.282 187.254C381.525 209.266 407.597 222.766 440.002 222.766L440.002 213.846ZM511.16 181.599C494.988 201.546 471.384 213.846 440.002 213.846L440.002 222.766C474.003 222.766 500.179 209.307 518.089 187.216L511.16 181.599Z" fill="#EFEFEF" mask="url(#path-10-inside-1_1256_1644)"/>
          <rect x="523.02" y="94.0412" width="166.04" height="49.7773" transform="rotate(180 523.02 94.0412)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="473.52" y="43.8718" width="67.04" height="25.3705" transform="rotate(180 473.52 43.8718)" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="440.633" cy="152.526" rx="4.86726" ry="4.76163" transform="rotate(180 440.633 152.526)" fill="#EFEFEF"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(0.712027 -0.702153 0.717602 0.696453 39.9268 63.7288)" stroke="#EFEFEF" stroke-width="4.46"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(-0.712027 -0.702152 0.717602 -0.696453 842.875 61.0168)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="619.27" y="186.245" width="358.54" height="141.981" transform="rotate(180 619.27 186.245)" stroke="#EFEFEF" stroke-width="4.46"/>
        </g>
      </svg>
      <svg v-else-if="screenWidth <= 880 && screenWidth > 600" width="550" height="400" viewBox="0 0 550 400" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <marker
            id="arrow-end"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,5 L5,2.5 Z" fill="black" />
          </marker>
          <marker
            id="arrow-start"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto-start-reverse"
            markerUnits="strokeWidth"
          >
            <path d="M5,2.5 L0,5 L0,0 Z" fill="black" />
          </marker>
          
        </defs>
        <g>
          <path d="M0 0H880V640H0V0Z" fill="#40A769"/>
          <rect x="37.126" y="433.898" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="303.729" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="173.559" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="42.0339" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="564.068" width="805.75" height="33.8983" fill="#4EC283" fill-opacity="0.3"/>
          <path d="M524.131 595.395C524.131 549.637 486.213 512.542 439.44 512.542C392.667 512.542 354.75 549.637 354.75 595.395" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="439.367" cy="595.948" rx="4.86726" ry="4.76163" fill="#EFEFEF"/>
          <rect x="840.645" y="595.736" width="801.29" height="551.472" transform="rotate(180 840.645 595.736)" stroke="#EFEFEF" stroke-width="4.46"/>
          <mask id="path-10-inside-1_1256_1644" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M514.625 184.408L366.716 184.408C384.135 205.426 408.96 218.306 440.002 218.306C472.693 218.306 497.584 205.426 514.625 184.408Z"/>
          </mask>
          <path d="M514.625 184.408L518.089 187.216L523.983 179.948L514.625 179.948L514.625 184.408ZM366.716 184.408L366.716 179.948L357.227 179.948L363.282 187.254L366.716 184.408ZM440.002 218.306L440.002 213.846L440.002 218.306ZM514.625 179.948L366.716 179.948L366.716 188.868L514.625 188.868L514.625 179.948ZM440.002 213.846C410.324 213.846 386.745 201.586 370.15 181.562L363.282 187.254C381.525 209.266 407.597 222.766 440.002 222.766L440.002 213.846ZM511.16 181.599C494.988 201.546 471.384 213.846 440.002 213.846L440.002 222.766C474.003 222.766 500.179 209.307 518.089 187.216L511.16 181.599Z" fill="#EFEFEF" mask="url(#path-10-inside-1_1256_1644)"/>
          <rect x="523.02" y="94.0412" width="166.04" height="49.7773" transform="rotate(180 523.02 94.0412)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="473.52" y="43.8718" width="67.04" height="25.3705" transform="rotate(180 473.52 43.8718)" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="440.633" cy="152.526" rx="4.86726" ry="4.76163" transform="rotate(180 440.633 152.526)" fill="#EFEFEF"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(0.712027 -0.702153 0.717602 0.696453 39.9268 63.7288)" stroke="#EFEFEF" stroke-width="4.46"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(-0.712027 -0.702152 0.717602 -0.696453 842.875 61.0168)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="619.27" y="186.245" width="358.54" height="141.981" transform="rotate(180 619.27 186.245)" stroke="#EFEFEF" stroke-width="4.46"/>
        </g>
      </svg>
      <svg v-else-if="screenWidth <= 600" width="344" height="250" viewBox="0 0 344 250" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <marker
            id="arrow-end"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,5 L5,2.5 Z" fill="black" />
          </marker>
          <marker
            id="arrow-start"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto-start-reverse"
            markerUnits="strokeWidth"
          >
            <path d="M5,2.5 L0,5 L0,0 Z" fill="black" />
          </marker>
          
        </defs>
        <g>
          <path d="M0 0H880V640H0V0Z" fill="#40A769"/>
          <rect x="37.126" y="433.898" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="303.729" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="173.559" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="42.0339" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="564.068" width="805.75" height="33.8983" fill="#4EC283" fill-opacity="0.3"/>
          <path d="M524.131 595.395C524.131 549.637 486.213 512.542 439.44 512.542C392.667 512.542 354.75 549.637 354.75 595.395" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="439.367" cy="595.948" rx="4.86726" ry="4.76163" fill="#EFEFEF"/>
          <rect x="840.645" y="595.736" width="801.29" height="551.472" transform="rotate(180 840.645 595.736)" stroke="#EFEFEF" stroke-width="4.46"/>
          <mask id="path-10-inside-1_1256_1644" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M514.625 184.408L366.716 184.408C384.135 205.426 408.96 218.306 440.002 218.306C472.693 218.306 497.584 205.426 514.625 184.408Z"/>
          </mask>
          <path d="M514.625 184.408L518.089 187.216L523.983 179.948L514.625 179.948L514.625 184.408ZM366.716 184.408L366.716 179.948L357.227 179.948L363.282 187.254L366.716 184.408ZM440.002 218.306L440.002 213.846L440.002 218.306ZM514.625 179.948L366.716 179.948L366.716 188.868L514.625 188.868L514.625 179.948ZM440.002 213.846C410.324 213.846 386.745 201.586 370.15 181.562L363.282 187.254C381.525 209.266 407.597 222.766 440.002 222.766L440.002 213.846ZM511.16 181.599C494.988 201.546 471.384 213.846 440.002 213.846L440.002 222.766C474.003 222.766 500.179 209.307 518.089 187.216L511.16 181.599Z" fill="#EFEFEF" mask="url(#path-10-inside-1_1256_1644)"/>
          <rect x="523.02" y="94.0412" width="166.04" height="49.7773" transform="rotate(180 523.02 94.0412)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="473.52" y="43.8718" width="67.04" height="25.3705" transform="rotate(180 473.52 43.8718)" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="440.633" cy="152.526" rx="4.86726" ry="4.76163" transform="rotate(180 440.633 152.526)" fill="#EFEFEF"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(0.712027 -0.702153 0.717602 0.696453 39.9268 63.7288)" stroke="#EFEFEF" stroke-width="4.46"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(-0.712027 -0.702152 0.717602 -0.696453 842.875 61.0168)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="619.27" y="186.245" width="358.54" height="141.981" transform="rotate(180 619.27 186.245)" stroke="#EFEFEF" stroke-width="4.46"/>
        </g>
      </svg>
</template>

<script>
    export default {
        name: 'Field',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
        }
    }
</script>