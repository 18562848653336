<template>
    <div>
        <svg width="34" height="89" viewBox="0 0 34 89" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M32.2432 72.3717L8.69643 75.6704" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 70.1725L8.69643 73.4711" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 67.9733L8.69643 71.2719" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 65.7741L8.69643 69.0727" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 74.5709L8.69643 77.8696" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 76.7702L8.69643 80.0688" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 78.9694L8.69643 82.268" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 59.1764L8.69643 62.475" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 56.9772L8.69643 60.2758" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 54.778L8.69643 58.0766" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 52.5788L8.69643 55.8774" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 61.3756L8.69643 64.6743" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 63.5748L8.69643 66.8735" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 43.7829L8.69643 47.0815" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 41.5836L8.69643 44.8823" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 39.3844L8.69643 42.6831" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 37.1852L8.69643 40.4838" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 45.9811L8.69643 49.2797" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 48.1803L8.69643 51.479" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 50.3795L8.69643 53.6782" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 30.5875L8.69643 33.8862" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 28.3883L8.69643 31.687" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 26.1891L8.69643 29.4877" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 23.9899L8.69643 27.2885" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 32.7868L8.69643 36.0854" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 34.986L8.69643 38.2846" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 17.3942L8.69643 20.6928" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 15.195L8.69643 18.4936" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 12.9957L8.69643 16.2944" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 10.7965L8.69643 14.0952" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 19.5934L8.69643 22.892" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 21.7926L8.69643 25.0913" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 8.59828L8.69643 11.8969" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 6.39906L8.69643 9.6977" stroke="white" stroke-width="0.2"/>
                <path d="M25.7432 3.86745L7.74316 5.36745" stroke="white" stroke-width="0.2"/>
                <path d="M31.2432 4.86781L8.69641 7.49848" stroke="white" stroke-width="0.2"/>
                <path d="M28.2432 85.8678L28.2432 3.86781" stroke="white" stroke-width="0.2"/>
                <path d="M30.2432 85.8678L30.2432 3.86781" stroke="white" stroke-width="0.2"/>
                <path d="M26.2432 85.8678L26.2432 3.86781" stroke="white" stroke-width="0.2"/>
                <path d="M24.2432 85.8678L24.2432 3.86781" stroke="white" stroke-width="0.2"/>
                <path d="M21.8916 86.6643L21.8916 3.8674" stroke="white" stroke-width="0.2"/>
                <path d="M19.6924 87.765L19.6924 3.86754" stroke="white" stroke-width="0.2"/>
                <path d="M17.4932 87.764L17.4932 3.22994" stroke="white" stroke-width="0.2"/>
                <path d="M15.2939 87.7651L15.2939 2.71293" stroke="white" stroke-width="0.2"/>
                <path d="M13.0947 87.7641L13.0947 2.7129" stroke="white" stroke-width="0.2"/>
                <path d="M10.8955 87.7653L10.8955 2.86781" stroke="white" stroke-width="0.2"/>
                <path d="M8.69629 84.4655L8.69629 2.86786" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 81.1686L8.69643 84.4672" stroke="white" stroke-width="0.2"/>
                <path d="M32.2432 83.3678L8.69643 86.6665" stroke="white" stroke-width="0.2"/>
                <path class="color_changed_stroke" d="M0.999883 81.1673L7.59716 87.7646M7.59716 87.7646L32.8867 85.5655L32.8867 4.1991L7.59716 2.00001M7.59716 87.7646L7.59716 2.00001M7.59716 2.00001C7.59716 2.00001 3.57628 6.02089 0.999883 8.59729" stroke="white" stroke-width="2"/>
                <path d="M7.74316 2.36816L32.7432 4.86816L32.7432 85.3682L7.74316 87.3682L7.74316 2.36816Z" fill="#D9D9D9" fill-opacity="0.05"/>
            </g>
        </svg>

    </div>

</template>

<script>
    export default {
        name: 'TopRightGates',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>