<template>
    <div class="px-[65px]">
        <div @click="$router.go(-1)" class="bg-[#BD9135] mb-[20px] flex justify-center items-center w-[228px] h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
            <span>Назад</span>
        </div>

        <div class="flex items-center my-[20px]">
            <div class="text-[20px] font-bold uppercase text-[#C59014] mr-[30px]">
                {{ elementObj.name }}
            </div>
            <div class="flex gap-[5px]">
                <div v-for="scoreType in elementObj.distributionWeightsIndicators?.values">
                    <div v-if="scoreType.value > 0" class="lowercase px-[10px] text-center rounded-md bg-[#4E4E4E] bg-opacity-50" :style="[`color: ${generateColor(scoreType.name)};`]">
                        <span v-if="scoreType.value > 0">{{ scoreType.name }}<span class="text-[11px]"> ({{ scoreType.value }})</span></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-[100%] mx-auto flex justify-between items-center mb-[30px] gap-[10px] mt-[20px]">
            <div class="flex items-center gap-[10px]">
                <div class="text-[#C59014]">
                    Сортувати: 
                </div>
                <div class="flex gap-[6px]">
                    <div 
                        v-for="name in filterOptions"
                        :class="{
                            'border-[#C59014]': name.selected,
                            'border-[#A9AAAB66]': !name.selected
                        }"
                        @click="() => {name.vector = getVectorFilter(name.vector); name.selected = name.vector != null}"
                        class="bg-[#47484966] relative text-center w-[200px] h-[50px] align-middle flex items-center justify-center cursor-pointer select-none font-bold text-[16px] pt-[2px] border-[2px] text-[#C59014] rounded-[8px]"
                    >
                        <div class="absolute left-[10px]">
                            <div v-if="name.vector == 'down'">
                                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L6 6L11 1" stroke="#BD9135" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div v-if="name.vector == 'up'">
                                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11 6L6 1L1 6" stroke="#BD9135" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                        <div class="text-[inherit]">
                            {{ name.name }}
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div 
                    @click="cleanFilters"
                    class="bg-[#DA4167] bg-opacity-20 border-[#DA4167] relative text-center w-[200px] h-[50px] align-middle flex items-center justify-center cursor-pointer select-none font-bold text-[16px] pt-[2px] border-[2px] text-[#DA4167] rounded-[8px]"
                >
                    Скинути
                </div>
            </div>
        </div>

        <div>
            <TableWrapper>
                <TableComponent :fullWidth="true">
                    <TableHeader>
                    <TableHeaderRow>
                        <TableHeaderCell :textAlign="'start'" :value="'дата'" />
                        <TableHeaderCell :value="'команда'" />
                        <TableHeaderCell :value="'протестовано'" />
                        <TableHeaderCell :value="'результат'" />
                        <TableHeaderCell :value="'найкращий результат'" />
                        <TableHeaderCell :value="'найгірший результат'" />
                        <TableHeaderCell :value="'звіт'" :textAlign="'end'" />
                    </TableHeaderRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow v-for="test in allTesting">
                            <TableCell @click="$router.push(`/testing/${groupId}/${elementId}/${test.training_id}`)" class="cursor-pointer" :textAlign="'start'" :asChild="true">
                                {{ test.date.split('T')[0].split('-').reverse().join('.') }}
                            </TableCell>
                            <TableCell :asChild="true">
                                {{ test.group_label }}
                            </TableCell>
                            <TableCell :asChild="true">
                                <span 
                                    :class="{
                                        'text-green-500': test.test_complete_count >= test.all_group_players,
                                        'text-red-500': test.test_complete_count < test.all_group_players
                                    }"
                                >{{ test.test_complete_count }}</span><span>/{{ test.all_group_players }}</span>
                            </TableCell>
                            <TableCell :asChild="true">
                                <span class="text-green-500">{{ test.high_result_count }}</span> <span class="text-yellow-500">{{ test.middle_result_count }}</span> <span class="text-red-500">{{ test.low_result_count }}</span>
                            </TableCell>
                            <TableCell :asChild="true">
                                <span class="font-bold text-green-500">{{ (+test.high_result).toFixed(2) }}</span>
                            </TableCell>
                            <TableCell :asChild="true">
                                <span class="font-bold text-red-500">{{ (+test.low_result).toFixed(2)}}</span>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div @click="$router.push(`/testing/${groupId}/${elementId}/${test.training_id}`)" class="flex justify-end cursor-pointer">
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 15.5L5.5 11.5" stroke="#C59014" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M10.5 15.5L10.5 5.5" stroke="#C59014" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M15.5 15.5L15.5 9.5" stroke="#C59014" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M1 10.5C1 6.02166 1 3.78249 2.39124 2.39124C3.78249 1 6.02166 1 10.5 1C14.9783 1 17.2175 1 18.6088 2.39124C20 3.78249 20 6.02166 20 10.5C20 14.9783 20 17.2175 18.6088 18.6088C17.2175 20 14.9783 20 10.5 20C6.02166 20 3.78249 20 2.39124 18.6088C1 17.2175 1 14.9783 1 10.5Z" stroke="#C59014" stroke-width="1.5" stroke-linejoin="round"/>
                                    </svg>

                                </div>
                            </TableCell>
                            
                        </TableRow>
                    </TableBody>
                </TableComponent>
            </TableWrapper>
        </div>
    </div>
</template>
<script>
import { ref, onMounted, onUpdated, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import md5 from 'blueimp-md5'
import { getAllStandardGroups, getElementByIdRequest, getPlayersThatCompleteStandardByTraining, getAllStudentStandardsByStudentId, getStudentsCountByGroupId, getElementTrainingsCompleted, getGroupById } from '@/services/apiRequests'

function generateColor(word) {
  // Хешируем слово с использованием алгоритма MD5

  const hash = md5(word.toLowerCase());
  
  // Преобразуем первые 6 символов хеша в компоненты RGB
  const r = parseInt(hash.substring(0, 2), 16);
  const g = parseInt(hash.substring(2, 4), 16);
  const b = parseInt(hash.substring(4, 6), 16);

  // Возвращаем цвет в формате rgb
  return `rgba(${r}, ${g}, ${b})`;
}

export default {
    name: 'TestingView',

    props: {
        pageName: {
            type: String,
            required: true
        }
    },

    setup(props, { emit }) {
        const route = useRoute()
        const router = useRouter()

        const elementId = ref(route.params.testing_id)
        const groupId = ref(route.params.group_id)
        const elementObj = ref({})

        const filterOptions = ref([
            {
                name: 'по даті',
                vector: null,
                selected: false
            },
            {
                name: 'по віку',
                vector: null,
                selected: false
            },
            {
                name: 'кращий результат',
                vector: null,
                selected: false
            },
            {
                name: 'гірший результат',
                vector: null,
                selected: false
            }
        ])

        const cleanFilters = () => {
            for (const filter of filterOptions.value) {
                filter.vector = null
                filter.selected = false
            }
        }

        const allTesting = ref([])

        const getVectorFilter = (currentVector) => {
            if (currentVector == null) {
                return 'down'
            } else if (currentVector == 'down') {
                return 'up'
            } else if (currentVector == 'up') {
                return null
            }
        }

        watch(filterOptions, async (value) => {

            for (const filter of filterOptions.value) {
                if (filter.selected) {
                    allTesting.value.sort((a, b) => {
                        if (filter.vector == 'down') {
                            if (filter.name == 'по даті') {
                                return new Date(a.date) - new Date(b.date)
                            } else if (filter.name == 'по віку') {
                                return a.age - b.age
                            } else if (filter.name == 'кращий результат') {
                                return a.high_result - b.high_result
                            } else if (filter.name == 'гірший результат') {
                                return a.low_result - b.low_result
                            }
                        } else if (filter.vector == 'up') {
                            if (filter.name == 'по даті') {
                                return new Date(b.date) - new Date(a.date)
                            } else if (filter.name == 'по віку') {
                                return b.age - a.age
                            } else if (filter.name == 'кращий результат') {
                                return b.high_result - a.high_result
                            } else if (filter.name == 'гірший результат') {
                                return b.low_result - a.low_result
                            }
                        }
                    })
                }
            }
        }, { deep: true })


        onMounted(async () => {
            elementObj.value = await getElementByIdRequest(elementId.value)
            console.log(elementObj.value)
            emit('updatePageName', props.pageName.split('|')[0] + ' | ' + props.pageName.split('|')[1] + ' | ' + elementObj.value.name)

            const completedTrainings = await getElementTrainingsCompleted(elementId.value)
            console.log(completedTrainings)
            allTesting.value = completedTrainings

            for (const test of allTesting.value) {
                const groupPlayersCount = await getStudentsCountByGroupId(test.group_id)
                console.log(groupPlayersCount)
                test.all_group_players = groupPlayersCount.data
                const testingReports = await getPlayersThatCompleteStandardByTraining(elementObj.value.base_standard_id, test.training_id)
                console.log('elementObj.value.score_board_type ----- ', elementObj.value.score_board_type)
                test.test_complete_count = testingReports.length

                const allTestingResults = testingReports.map(report => +report.result)

                if (elementObj.value.score_board_type == 'time') {
                    test.middle_result_count = allTestingResults.filter(result => result >= +elementObj.value.minimal_value && result <= +elementObj.value.maximal_value).length 
                    test.high_result_count = allTestingResults.filter(result => result < +elementObj.value.minimal_value).length
                    test.low_result_count = allTestingResults.filter(result => result > +elementObj.value.maximal_value).length
                } else {
                    test.middle_result_count = allTestingResults.filter(result => result <= +elementObj.value.minimal_value && result >= +elementObj.value.maximal_value).length 
                    test.low_result_count = allTestingResults.filter(result => result > +elementObj.value.minimal_value).length
                    test.high_result_count = allTestingResults.filter(result => result < +elementObj.value.maximal_value).length
                }

                test.low_result = elementObj.value.score_board_type != 'time' ? Math.min(...allTestingResults) : Math.max(...allTestingResults)
                test.high_result = elementObj.value.score_board_type != 'time' ? Math.max(...allTestingResults) : Math.min(...allTestingResults)
            }
        })

        return {
            elementObj,
            allTesting,
            elementId,
            groupId,
            generateColor: generateColor,
            filterOptions,
            getVectorFilter,
            cleanFilters
        }
    }
}
</script>
<style lang="">
    
</style>