<template>
  <aside class="menu">
    <button class="menu__toggle toggle-menu" @click="onAsideArrow">
      <img src="@/assets/refactored_assets/img/menu/chevrons-right.svg" alt="" />
    </button>
    <div class="menu__wrapper">
      <div class="menu__logo">
        <router-link :to="'/'">
          <img src="@/assets/refactored_assets/img/logo.png" alt="Sport Kit" />
        </router-link>
      </div>

      <nav class="menu__nav">
        <ul class="menu__list">
          <li
            class="menu__item"
            v-for="(item, index) in menuItems"
            :key="index"
          >
            <router-link
              :to="item.link"
              class="menu__link"
              :class="{ active: item.isActive }"
              @click.prevent="() => toggleSubmenu(item)"
            >
              <img
                :src="require('@/assets/refactored_assets/img/menu/' + item.icon)"
                :alt="item.name"
              />
              <span>{{ item.name }}</span>
            </router-link>
            <ul
              v-if="
                item.sublinks && item.sublinks.length && item.isSubmenuVisible
              "
              class="menu__sub-list"
            >
              <li
                class="menu__sub-item"
                v-for="(sublink, subIndex) in item.sublinks"
                :key="`sublink-${subIndex}`"
              >
                <router-link
                  :to="sublink.link"
                  class="menu__sub-link"
                  :class="{ active: sublink.isActive }"
                  @click.prevent="() => setActive(sublink)"
                >
                  <span>{{ sublink.name }}</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>

      <div class="menu__switch switch">
        <span :class="['switch__text', { 'switch__text--active': !isChecked }]"
          >UA</span
        >
        <div class="switch__1">
          <input id="switch-1" type="checkbox" v-model="isChecked" />
          <label for="switch-1"></label>
        </div>
        <span :class="['switch__text', { 'switch__text--active': isChecked }]"
          >EN</span
        >
      </div>
      <button @click="$emit('exit')" class="menu__button">
        <img src="@/assets/refactored_assets/img/menu/exit.svg" alt="" />
        <span>Вийти</span>
      </button>
    </div>
  </aside>
</template>

<script>
import { watch, toRefs, ref } from "vue";

export default {
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
    onAsideArrow: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isChecked: false,
    };
  },
  setup(props) {
    const { menuItems } = toRefs(props);
    const userType = ref(localStorage.userType);

    const setActive = (itemToActivate) => {
      const setItemActive = (items) => {
        let found = false;

        console.log('itemToActivate --- ', itemToActivate)

        items.forEach((item) => {
          item.isActive = false; // Reset the active state for all items

          if (item.sublinks && item.sublinks.length > 0) {
            const sublinkFound = setItemActive(item.sublinks);
            if (sublinkFound) {
              item.isActive = true; // Set parent as active if any sublink is active
              found = true;
            }
          }

          if (item.name === itemToActivate.name) {
            item.isActive = true;
            found = true;
          }
        });

        return found;
      };

      // Start the recursive search with the top-level menu items
      setItemActive(menuItems.value);
    };

    const toggleSubmenu = (item) => {
      menuItems.value.forEach((menuItem) => {
        if (menuItem !== item) {
          menuItem.isSubmenuVisible = false;
        }
      });
      item.isSubmenuVisible = !item.isSubmenuVisible;
      setActive(item);
    };

    watch(
      menuItems,
      (newVal) => {
        console.log("Змінено menuItems:", newVal);
      },
      { deep: true },
    );

    return { setActive, toggleSubmenu, userType };
  },
};
</script>