<template>
    <svg v-if="screenWidth >= 1280" class="field" width="880" height="640" viewBox="0 0 880 640" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <marker
            id="arrow-end"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,5 L5,2.5 Z" fill="black" />
          </marker>
          <marker
            id="arrow-start"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto-start-reverse"
            markerUnits="strokeWidth"
          >
            <path d="M5,2.5 L0,5 L0,0 Z" fill="black" />
          </marker>
        </defs>
        <g>
          <path d="M0 0H880V640H0V0Z" fill="#40A769"/>
          <rect x="37.126" y="433.898" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="303.729" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="173.559" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="42.0339" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="564.068" width="805.75" height="33.8983" fill="#4EC283" fill-opacity="0.3"/>
          <path d="M524.131 595.395C524.131 549.637 486.213 512.542 439.44 512.542C392.667 512.542 354.75 549.637 354.75 595.395" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="439.367" cy="595.948" rx="4.86726" ry="4.76163" fill="#EFEFEF"/>
          <rect x="840.645" y="595.736" width="801.29" height="551.472" transform="rotate(180 840.645 595.736)" stroke="#EFEFEF" stroke-width="4.46"/>
          <mask id="path-10-inside-1_1256_1644" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M514.625 184.408L366.716 184.408C384.135 205.426 408.96 218.306 440.002 218.306C472.693 218.306 497.584 205.426 514.625 184.408Z"/>
          </mask>
          <path d="M514.625 184.408L518.089 187.216L523.983 179.948L514.625 179.948L514.625 184.408ZM366.716 184.408L366.716 179.948L357.227 179.948L363.282 187.254L366.716 184.408ZM440.002 218.306L440.002 213.846L440.002 218.306ZM514.625 179.948L366.716 179.948L366.716 188.868L514.625 188.868L514.625 179.948ZM440.002 213.846C410.324 213.846 386.745 201.586 370.15 181.562L363.282 187.254C381.525 209.266 407.597 222.766 440.002 222.766L440.002 213.846ZM511.16 181.599C494.988 201.546 471.384 213.846 440.002 213.846L440.002 222.766C474.003 222.766 500.179 209.307 518.089 187.216L511.16 181.599Z" fill="#EFEFEF" mask="url(#path-10-inside-1_1256_1644)"/>
          <rect x="523.02" y="94.0412" width="166.04" height="49.7773" transform="rotate(180 523.02 94.0412)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="473.52" y="43.8718" width="67.04" height="25.3705" transform="rotate(180 473.52 43.8718)" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="440.633" cy="152.526" rx="4.86726" ry="4.76163" transform="rotate(180 440.633 152.526)" fill="#EFEFEF"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(0.712027 -0.702153 0.717602 0.696453 39.9268 63.7288)" stroke="#EFEFEF" stroke-width="4.46"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(-0.712027 -0.702152 0.717602 -0.696453 842.875 61.0168)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="619.27" y="186.245" width="358.54" height="141.981" transform="rotate(180 619.27 186.245)" stroke="#EFEFEF" stroke-width="4.46"/>
        </g>
      </svg>
      <svg v-else-if="screenWidth < 1280 && screenWidth > 1000" width="660" height="480" viewBox="0 0 660 480" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <marker
            id="arrow-end"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,5 L5,2.5 Z" fill="black" />
          </marker>
          <marker
            id="arrow-start"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto-start-reverse"
            markerUnits="strokeWidth"
          >
            <path d="M5,2.5 L0,5 L0,0 Z" fill="black" />
          </marker>
          
        </defs>
        <g>
          <path d="M0 0H880V640H0V0Z" fill="#40A769"/>
          <rect x="37.126" y="433.898" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="303.729" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="173.559" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="42.0339" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="564.068" width="805.75" height="33.8983" fill="#4EC283" fill-opacity="0.3"/>
          <path d="M524.131 595.395C524.131 549.637 486.213 512.542 439.44 512.542C392.667 512.542 354.75 549.637 354.75 595.395" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="439.367" cy="595.948" rx="4.86726" ry="4.76163" fill="#EFEFEF"/>
          <rect x="840.645" y="595.736" width="801.29" height="551.472" transform="rotate(180 840.645 595.736)" stroke="#EFEFEF" stroke-width="4.46"/>
          <mask id="path-10-inside-1_1256_1644" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M514.625 184.408L366.716 184.408C384.135 205.426 408.96 218.306 440.002 218.306C472.693 218.306 497.584 205.426 514.625 184.408Z"/>
          </mask>
          <path d="M514.625 184.408L518.089 187.216L523.983 179.948L514.625 179.948L514.625 184.408ZM366.716 184.408L366.716 179.948L357.227 179.948L363.282 187.254L366.716 184.408ZM440.002 218.306L440.002 213.846L440.002 218.306ZM514.625 179.948L366.716 179.948L366.716 188.868L514.625 188.868L514.625 179.948ZM440.002 213.846C410.324 213.846 386.745 201.586 370.15 181.562L363.282 187.254C381.525 209.266 407.597 222.766 440.002 222.766L440.002 213.846ZM511.16 181.599C494.988 201.546 471.384 213.846 440.002 213.846L440.002 222.766C474.003 222.766 500.179 209.307 518.089 187.216L511.16 181.599Z" fill="#EFEFEF" mask="url(#path-10-inside-1_1256_1644)"/>
          <rect x="523.02" y="94.0412" width="166.04" height="49.7773" transform="rotate(180 523.02 94.0412)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="473.52" y="43.8718" width="67.04" height="25.3705" transform="rotate(180 473.52 43.8718)" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="440.633" cy="152.526" rx="4.86726" ry="4.76163" transform="rotate(180 440.633 152.526)" fill="#EFEFEF"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(0.712027 -0.702153 0.717602 0.696453 39.9268 63.7288)" stroke="#EFEFEF" stroke-width="4.46"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(-0.712027 -0.702152 0.717602 -0.696453 842.875 61.0168)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="619.27" y="186.245" width="358.54" height="141.981" transform="rotate(180 619.27 186.245)" stroke="#EFEFEF" stroke-width="4.46"/>
        </g>
      </svg>
      <svg v-else-if="screenWidth <= 1000 && screenWidth > 880" width="550" height="400" viewBox="0 0 550 400" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <marker
            id="arrow-end"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,5 L5,2.5 Z" fill="black" />
          </marker>
          <marker
            id="arrow-start"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto-start-reverse"
            markerUnits="strokeWidth"
          >
            <path d="M5,2.5 L0,5 L0,0 Z" fill="black" />
          </marker>
          
        </defs>
        <g>
          <path d="M0 0H880V640H0V0Z" fill="#40A769"/>
          <rect x="37.126" y="433.898" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="303.729" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="173.559" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="42.0339" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="564.068" width="805.75" height="33.8983" fill="#4EC283" fill-opacity="0.3"/>
          <path d="M524.131 595.395C524.131 549.637 486.213 512.542 439.44 512.542C392.667 512.542 354.75 549.637 354.75 595.395" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="439.367" cy="595.948" rx="4.86726" ry="4.76163" fill="#EFEFEF"/>
          <rect x="840.645" y="595.736" width="801.29" height="551.472" transform="rotate(180 840.645 595.736)" stroke="#EFEFEF" stroke-width="4.46"/>
          <mask id="path-10-inside-1_1256_1644" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M514.625 184.408L366.716 184.408C384.135 205.426 408.96 218.306 440.002 218.306C472.693 218.306 497.584 205.426 514.625 184.408Z"/>
          </mask>
          <path d="M514.625 184.408L518.089 187.216L523.983 179.948L514.625 179.948L514.625 184.408ZM366.716 184.408L366.716 179.948L357.227 179.948L363.282 187.254L366.716 184.408ZM440.002 218.306L440.002 213.846L440.002 218.306ZM514.625 179.948L366.716 179.948L366.716 188.868L514.625 188.868L514.625 179.948ZM440.002 213.846C410.324 213.846 386.745 201.586 370.15 181.562L363.282 187.254C381.525 209.266 407.597 222.766 440.002 222.766L440.002 213.846ZM511.16 181.599C494.988 201.546 471.384 213.846 440.002 213.846L440.002 222.766C474.003 222.766 500.179 209.307 518.089 187.216L511.16 181.599Z" fill="#EFEFEF" mask="url(#path-10-inside-1_1256_1644)"/>
          <rect x="523.02" y="94.0412" width="166.04" height="49.7773" transform="rotate(180 523.02 94.0412)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="473.52" y="43.8718" width="67.04" height="25.3705" transform="rotate(180 473.52 43.8718)" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="440.633" cy="152.526" rx="4.86726" ry="4.76163" transform="rotate(180 440.633 152.526)" fill="#EFEFEF"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(0.712027 -0.702153 0.717602 0.696453 39.9268 63.7288)" stroke="#EFEFEF" stroke-width="4.46"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(-0.712027 -0.702152 0.717602 -0.696453 842.875 61.0168)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="619.27" y="186.245" width="358.54" height="141.981" transform="rotate(180 619.27 186.245)" stroke="#EFEFEF" stroke-width="4.46"/>
        </g>
      </svg>
      <svg v-else-if="screenWidth <= 880 && screenWidth > 600" width="550" height="400" viewBox="0 0 550 400" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <marker
            id="arrow-end"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,5 L5,2.5 Z" fill="black" />
          </marker>
          <marker
            id="arrow-start"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto-start-reverse"
            markerUnits="strokeWidth"
          >
            <path d="M5,2.5 L0,5 L0,0 Z" fill="black" />
          </marker>
          
        </defs>
        <g>
          <path d="M0 0H880V640H0V0Z" fill="#40A769"/>
          <rect x="37.126" y="433.898" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="303.729" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="173.559" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="42.0339" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="564.068" width="805.75" height="33.8983" fill="#4EC283" fill-opacity="0.3"/>
          <path d="M524.131 595.395C524.131 549.637 486.213 512.542 439.44 512.542C392.667 512.542 354.75 549.637 354.75 595.395" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="439.367" cy="595.948" rx="4.86726" ry="4.76163" fill="#EFEFEF"/>
          <rect x="840.645" y="595.736" width="801.29" height="551.472" transform="rotate(180 840.645 595.736)" stroke="#EFEFEF" stroke-width="4.46"/>
          <mask id="path-10-inside-1_1256_1644" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M514.625 184.408L366.716 184.408C384.135 205.426 408.96 218.306 440.002 218.306C472.693 218.306 497.584 205.426 514.625 184.408Z"/>
          </mask>
          <path d="M514.625 184.408L518.089 187.216L523.983 179.948L514.625 179.948L514.625 184.408ZM366.716 184.408L366.716 179.948L357.227 179.948L363.282 187.254L366.716 184.408ZM440.002 218.306L440.002 213.846L440.002 218.306ZM514.625 179.948L366.716 179.948L366.716 188.868L514.625 188.868L514.625 179.948ZM440.002 213.846C410.324 213.846 386.745 201.586 370.15 181.562L363.282 187.254C381.525 209.266 407.597 222.766 440.002 222.766L440.002 213.846ZM511.16 181.599C494.988 201.546 471.384 213.846 440.002 213.846L440.002 222.766C474.003 222.766 500.179 209.307 518.089 187.216L511.16 181.599Z" fill="#EFEFEF" mask="url(#path-10-inside-1_1256_1644)"/>
          <rect x="523.02" y="94.0412" width="166.04" height="49.7773" transform="rotate(180 523.02 94.0412)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="473.52" y="43.8718" width="67.04" height="25.3705" transform="rotate(180 473.52 43.8718)" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="440.633" cy="152.526" rx="4.86726" ry="4.76163" transform="rotate(180 440.633 152.526)" fill="#EFEFEF"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(0.712027 -0.702153 0.717602 0.696453 39.9268 63.7288)" stroke="#EFEFEF" stroke-width="4.46"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(-0.712027 -0.702152 0.717602 -0.696453 842.875 61.0168)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="619.27" y="186.245" width="358.54" height="141.981" transform="rotate(180 619.27 186.245)" stroke="#EFEFEF" stroke-width="4.46"/>
        </g>
      </svg>
      <svg v-else-if="screenWidth <= 600" width="344" height="250" viewBox="0 0 344 250" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <marker
            id="arrow-end"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,5 L5,2.5 Z" fill="black" />
          </marker>
          <marker
            id="arrow-start"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto-start-reverse"
            markerUnits="strokeWidth"
          >
            <path d="M5,2.5 L0,5 L0,0 Z" fill="black" />
          </marker>
          
        </defs>
        <g>
          <path d="M0 0H880V640H0V0Z" fill="#40A769"/>
          <rect x="37.126" y="433.898" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="303.729" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="173.559" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="42.0339" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="564.068" width="805.75" height="33.8983" fill="#4EC283" fill-opacity="0.3"/>
          <path d="M524.131 595.395C524.131 549.637 486.213 512.542 439.44 512.542C392.667 512.542 354.75 549.637 354.75 595.395" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="439.367" cy="595.948" rx="4.86726" ry="4.76163" fill="#EFEFEF"/>
          <rect x="840.645" y="595.736" width="801.29" height="551.472" transform="rotate(180 840.645 595.736)" stroke="#EFEFEF" stroke-width="4.46"/>
          <mask id="path-10-inside-1_1256_1644" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M514.625 184.408L366.716 184.408C384.135 205.426 408.96 218.306 440.002 218.306C472.693 218.306 497.584 205.426 514.625 184.408Z"/>
          </mask>
          <path d="M514.625 184.408L518.089 187.216L523.983 179.948L514.625 179.948L514.625 184.408ZM366.716 184.408L366.716 179.948L357.227 179.948L363.282 187.254L366.716 184.408ZM440.002 218.306L440.002 213.846L440.002 218.306ZM514.625 179.948L366.716 179.948L366.716 188.868L514.625 188.868L514.625 179.948ZM440.002 213.846C410.324 213.846 386.745 201.586 370.15 181.562L363.282 187.254C381.525 209.266 407.597 222.766 440.002 222.766L440.002 213.846ZM511.16 181.599C494.988 201.546 471.384 213.846 440.002 213.846L440.002 222.766C474.003 222.766 500.179 209.307 518.089 187.216L511.16 181.599Z" fill="#EFEFEF" mask="url(#path-10-inside-1_1256_1644)"/>
          <rect x="523.02" y="94.0412" width="166.04" height="49.7773" transform="rotate(180 523.02 94.0412)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="473.52" y="43.8718" width="67.04" height="25.3705" transform="rotate(180 473.52 43.8718)" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="440.633" cy="152.526" rx="4.86726" ry="4.76163" transform="rotate(180 440.633 152.526)" fill="#EFEFEF"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(0.712027 -0.702153 0.717602 0.696453 39.9268 63.7288)" stroke="#EFEFEF" stroke-width="4.46"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(-0.712027 -0.702152 0.717602 -0.696453 842.875 61.0168)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="619.27" y="186.245" width="358.54" height="141.981" transform="rotate(180 619.27 186.245)" stroke="#EFEFEF" stroke-width="4.46"/>
        </g>
      </svg>
</template>

<script>
    export default {
        name: 'Field',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
        }
    }
</script>