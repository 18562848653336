<template>
    <div>
        <MenuBar @addScout="showForm = true" v-model:query="searchQuery" :scoutCount="scoutCount"/>

        <DoctorTable :allScouts="allScouts" @deleteScout="deleteScout" @selectScout="scout => {selectedScout = scout; viewScoutFlag = true}" />

        <DoctorForm v-if="showForm" @addNewScout="addNewScout" @close="showForm = false" />
        <DoctorView v-if="viewScoutFlag" :scout="selectedScout" @close="viewScoutFlag = false" @addNewScout="updateScout" />
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { addDoctorProfile, getDoctorProfiles, deleteDoctorProfile, updateDoctorProfile, getCountDoctors } from '@/services/apiRequests';

import MenuBar from './components/MenuBar.vue';
import DoctorTable from './components/DoctorTable.vue';
import DoctorForm from './components/DoctorForm.vue';
import DoctorView from './components/DoctorView.vue';

export default {
    name: 'Doctors',

    setup() {
        const showForm = ref(false);

        const allScouts = ref([]);
        const bufferedScouts = ref([]);

        const selectedScout = ref({});
        const viewScoutFlag = ref(false);

        const scoutCount = ref(0);

        const searchQuery = ref('');

        watch(searchQuery, (newVal) => {
            allScouts.value = bufferedScouts.value.filter(scout => {
                return scout.name.toLowerCase().includes(newVal.toLowerCase()) || scout.surname.toLowerCase().includes(newVal.toLowerCase());
            });

            if (newVal === '') {
                allScouts.value = bufferedScouts.value;
            }
        });

        onMounted(async () => {
            allScouts.value = await getDoctorProfiles();
            bufferedScouts.value = Object.assign([], allScouts.value);

            scoutCount.value = await getCountDoctors();
            console.log(scoutCount.value);
        });

        const addNewScout = async (newScout) => {
            let scout = newScout.scout;
            let photo = newScout.photo;
            scout.birthdate = new Date(scout.birthdate).toISOString();
            scout.start_working_date = new Date(scout.start_working_date).toISOString();
            scout.status = 'active';
            await addDoctorProfile(scout, photo);
            allScouts.value = await getDoctorProfiles();
            showForm.value = false;
        }

        const updateScout = async (updateScout) => {
            let scout = updateScout.scout;
            let photo = updateScout.photo;

            scout.birthdate = new Date(scout.birthdate).toISOString();
            scout.start_working_date = new Date(scout.start_working_date).toISOString();

            await updateDoctorProfile(scout.id, scout, photo);
            allScouts.value = await getDoctorProfiles();

            viewScoutFlag.value = false;
        }

        const deleteScout = async (scout) => {
            await deleteDoctorProfile(scout.id);
            allScouts.value = await getDoctorProfiles();
        }

        return {
            showForm,
            allScouts,
            deleteScout,
            addNewScout,
            selectedScout,
            viewScoutFlag,
            updateScout,
            scoutCount,
            searchQuery
        }
    },
    
    components: {
        MenuBar,
        DoctorTable,
        DoctorForm,
        DoctorView  
    }
}
</script>

<style>
    
</style>