<template>
    <div>
        <div v-if="status == 'select_club'">

            <div class="font-semibold text-center mb-[30px]">
                Опишіть матч по якому ви хочете зробити звіт
            </div>

            <div class="flex justify-center mb-[20px]">
                <ButtonComponent v-if="selectMatchFlag" @click="() => {selectMatchFlag = !selectMatchFlag; selectedMatch = null}">
                    Створити матч
                </ButtonComponent>
                <ButtonComponent v-else @click="selectMatchFlag = !selectMatchFlag">
                    Вибрати матч
                </ButtonComponent>
            </div>

            <div v-if="selectMatchFlag" class="flex justify-center mb-[20px]">
                <TableWrapper class="mx-auto w-max flex">
                    <TableComponent :fullWidth="false" :tablePosition="'center'">
                        <TableHeader>
                        <TableHeaderRow>
                            <TableHeaderCell :value="'дата'" />
                            <TableHeaderCell :value="'матч'" />
                            <TableHeaderCell :value="'ліга'" />
                            <TableHeaderCell :value="'тур'" />
                            <TableHeaderCell :value="'рахунок'" />
                            <TableHeaderCell :value="'тривалість'" />
                            <TableHeaderCell :value="''" />
                        </TableHeaderRow>
                        </TableHeader>
                        <TableBody>
                            <TableRow v-for="(match, id) in otherMatches">
                                <TableCell :value="match?.match_date?.split('T')[0].split('-').reverse().join('.')"/>
                                <TableCell :asChild="true">
                                    <b>{{ match?.first_team_name }} - {{ match?.second_team_name }}</b>
                                </TableCell>
                                <TableCell :asChild="true">
                                    {{ match?.match_league }}
                                </TableCell>
                                <TableCell :asChild="true">
                                    {{ match?.match_tour }}
                                </TableCell>
                                <TableCell :asChild="true">
                                    <b>{{ match?.first_team_score }}:{{ match?.second_team_score }}</b>
                                </TableCell>
                                <TableCell :asChild="true">
                                    {{ match?.match_time }}
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div class="custom-checkbox">
                                        <input type="checkbox" class="checkbox-input" @change="changeMatch(match)" :checked="selectedMatch?.match_id == match.match_id" :id="'checkbox-' + id">
                                        <label :for="'checkbox-' + id" class="checkbox-box"></label>
                                    </div>
                                </TableCell>
                            </TableRow>
                            
                        </TableBody>
                    </TableComponent>
                </TableWrapper>
            </div>

            <div v-else>
                <div class="flex justify-center mb-[30px] gap-[20px]">
                    <div>
                        <InputComponent :value="matchReport.league" @update:modelValue="e => matchReport.league = e" :textAlign="'center'" :placeholder="'ліга'" :width="'100px'" />
                    </div>
                    <div>
                        <InputComponent :value="matchReport.tour" @update:modelValue="e => matchReport.tour = e" :textAlign="'center'" :placeholder="'тур'" :width="'100px'" />
                    </div>
                </div>

                <div class="flex gap-[20px] justify-center">
                    <div class="flex flex-col justify-center items-center">
                        <div>
                            <img v-if="newFirstTeamLogo" @click="addLogoToFirstTeam" :src="newFirstTeamLogo" alt="team logo" class="h-[50px] cursor-pointer" />
                            <img v-else @click="addLogoToFirstTeam" src="@/assets/icons/upload_icon.svg" alt="team logo" class="h-[50px] cursor-pointer" />
                            <input ref="newFirstTeamLogoAdd" type="file" @change="proccessNewFirstTeamLogo" class="hidden" />
                        </div>
                        <div class="mt-[20px]">
                            <InputComponent :value="matchReport.firstTeamName" @update:modelValue="e => matchReport.firstTeamName = e" :textAlign="'center'" :placeholder="'команда 1'" :width="'100px'"/>
                        </div>
                        <div class="mt-[20px]">
                            <InputComponent :value="matchReport.firstTeamScore" @update:modelValue="e => matchReport.firstTeamScore = e" :textAlign="'center'" :placeholder="'рахунок'" :width="'100px'"/>
                        </div>
                    </div>
                    <div class="flex flex-col justify-center items-center">
                        <div>
                            <img v-if="newSecondTeamLogo" @click="addLogoToSecondTeam" :src="newSecondTeamLogo" alt="team logo" class="h-[50px] cursor-pointer" />
                            <img v-else @click="addLogoToSecondTeam" src="@/assets/icons/upload_icon.svg" alt="team logo" class="h-[50px] cursor-pointer" />
                            <input ref="newSecondTeamLogoAdd" type="file" @change="proccessNewSecondTeamLogo" class="hidden" />
                        </div>
                        <div class="mt-[20px]">
                            <InputComponent :value="matchReport.secondTeamName" @update:modelValue="e => matchReport.secondTeamName = e" :textAlign="'center'" :placeholder="'команда 2'" :width="'100px'"/>
                        </div>
                        <div class="mt-[20px]">
                            <InputComponent :value="matchReport.secondTeamScore" @update:modelValue="e => matchReport.secondTeamScore = e" :textAlign="'center'" :placeholder="'рахунок'" :width="'100px'"/>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col justify-center items-center">
                    <div class="mt-[20px]">
                        <InputComponent :value="matchReport.time" @update:modelValue="e => matchReport.time = e" :textAlign="'center'" :placeholder="'час гри'" :width="'220px'"/>
                    </div>
                    <div class="mt-[20px]">
                        <InputComponent :value="matchReport.date" @update:modelValue="e => matchReport.date = e" :textAlign="'center'" :type="'date'" :placeholder="'дата'" :width="'220px'"/>
                    </div>
                    <div class="mt-[20px]">
                        <InputComponent :value="matchReport.stadium" @update:modelValue="e => matchReport.stadium = e" :textAlign="'center'" :placeholder="'стадіон'" :width="'220px'"/>
                    </div>
                </div>
            </div>

            <div class="font-semibold text-center mt-[30px]">
                Дії гравця за матч
            </div>

            <div class="flex flex-col justify-center items-center">
                <div class="mt-[20px]">
                    <InputComponent :value="matchReport.goals" @update:modelValue="e => matchReport.goals = e" :textAlign="'center'" :placeholder="'голи'" :width="'220px'"/>
                </div>
                <div class="mt-[20px]">
                    <InputComponent :value="matchReport.assists" @update:modelValue="e => matchReport.assists = e" :textAlign="'center'" :placeholder="'асисти'" :width="'220px'"/>
                </div>
                <div class="mt-[20px]">
                    <InputComponent :type="'number'" :value="playerGameTime" @update:modelValue="e => playerGameTime = +e" :textAlign="'center'" :placeholder="'зіграний час'" :width="'220px'"/>
                </div>
            </div>

            <div>
                <div class="flex flex-col items-center justify-center mt-[20px]">
                    <ButtonComponent @click="status = 'create_report'">
                        Перейти до звіту
                    </ButtonComponent>
                    <ButtonComponent class="mt-[10px]" @click="$router.push('/alien_player_summary_report/' + $route.params.player_id)">
                        Створити підсумковий звіт
                    </ButtonComponent>
                    <ButtonComponent :gray="true" class="mt-[10px]" @click="$router.go(-1)">
                        Назад
                    </ButtonComponent>
                </div>
            </div>
        </div>

        <div v-if="status == 'create_report'" class="w-[50%] mx-auto">
            <div class="flex justify-end">
                <ButtonComponent v-if="updateBaseDataFlag == false" :outlined="true" @click="updateBaseDataFlag = true" class="mr-[20px]">
                    Редагувати всі дані
                </ButtonComponent>
                <ButtonComponent v-else :positive="true" @click="updateBaseDataFlag = false" class="mr-[20px]">
                    Завершити редагування
                </ButtonComponent>
                <ButtonComponent @click="status = 'select_club'">
                    Назад
                </ButtonComponent>
            </div>

            <div class="flex justify-center mt-[20px] border-y-[1px] border-[#BD913533] items-center">
                <div class="w-[33.3333%] flex justify-center">
                    <div class="match-report__banner" style="background-color: transparent; border: none;">
                        <div class="match-report__banner--title">
                            <div>{{ matchReport.league }}</div>
                            <div>Тур {{ matchReport.tour }}</div>
                        </div>

                        <div class="match-report__banner--teams">
                            <div class="match-report__banner--team">
                                <img :src="newFirstTeamLogo ? newFirstTeamLogo : require('@/assets/kolos_team.svg')" class="w-max" alt="">
                                <div>{{ matchReport.firstTeamName }}</div>
                            </div>
                            <div class="match-report__banner--score">
                                <div>{{ matchReport.firstTeamScore }}:{{ matchReport.secondTeamScore }}</div>
                                <div>{{ matchReport.time }}''</div>

                            </div>
                            <div class="match-report__banner--team">
                                <img :src="newSecondTeamLogo ? newSecondTeamLogo : require('@/assets/kolos_team.svg')" class="w-max" alt="">
                                <div>{{ matchReport.secondTeamName }}</div>
                            </div>
                        </div>

                        <div class="match-report__banner--info">
                            <!-- <div>{{ matchReport.date.split('T')[0].split('-').reverse().join('.') }}</div> -->
                            <div>({{ matchReport.date }})</div>
                            <div>{{ matchReport.stadium }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-col items-center justify-center py-[15px] border-y-[1px] border-[#BD913533]">
                <AvatarContainer :src="apiURL + 'static/' + currentStudent.photo" />
                <div class="text-[21px] font-bold">{{ currentStudent.name }} {{ currentStudent.surname }}</div>
            </div>

            <div class="flex gap-[30px] mt-[30px] justify-center">
                <div class="documents-tab__column">
                    <InputComponent 
                        :label="'клуб'" 
                        :placeholder="''" 
                        :name="'клуб'" 
                        :value="currentStudent.group_name"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.group_name = e" 
                    />
                    <InputComponent 
                        :label="'ім\'я'" 
                        :placeholder="''" 
                        :name="'ім\'я'" 
                        :value="currentStudent.name"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.name = e" 
                    />
                    <InputComponent 
                        :label="'прізвище'" 
                        :placeholder="''" 
                        :name="'прізвище'" 
                        :value="currentStudent.surname"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.surname = e" 
                    />
                    <InputComponent 
                        :label="'вік'" 
                        :placeholder="''" 
                        :name="'вік'" 
                        :value="currentStudent.age"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.age = e" 
                    />
                    <InputComponent 
                        :label="'дата народження'" 
                        :placeholder="''" 
                        :name="'дата народження'" 
                        type="date"
                        :value="currentStudent.birthday"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.birthday = e" 
                    />
                    <InputComponent 
                        :label="'національність'" 
                        :placeholder="''" 
                        :name="'національність'" 
                        :value="currentStudent.nationatily"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.nationatily = e" 
                    />
                    <InputComponent 
                        :label="'місце народження'" 
                        :placeholder="''" 
                        :name="'місце народження'" 
                        :value="currentStudent.address"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.address = e" 
                    />
                </div>
                <div class="documents-tab__column">
                    <InputComponent 
                        :label="'зріст'" 
                        :placeholder="''" 
                        :name="'зріст'" 
                        :value="currentStudent.height"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.height = e" 
                    />
                    <InputComponent 
                        :label="'вага'" 
                        :placeholder="''" 
                        :name="'вага'"
                        :value="currentStudent.weight"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.weight = e" 
                    />
                    <InputComponent 
                        :label="'нога'" 
                        :placeholder="''" 
                        :name="'нога'" 
                        :value="currentStudent.leading_foot"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.leading_foot = e"
                    />
                    <InputComponent 
                        :label="'попередній клуб'" 
                        :placeholder="''" 
                        :name="'попередній клуб'" 
                        :value="currentStudent.previous_club"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.previous_club = e"
                    />
                    <InputComponent 
                        :label="'асистів в матчі'" 
                        :placeholder="''" 
                        :name="'асистів в матчі'" 
                        :value="matchReport.assists"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => matchReport.assists = e" 
                    />
                    <InputComponent 
                        :label="'голів в матчі'" 
                        :placeholder="''" 
                        :name="'голів в матчі'" 
                        :value="matchReport.goals"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => matchReport.goals = e" 
                    />
                    <InputComponent 
                        :label="'зіграні хвилини'" 
                        :placeholder="''" 
                        :name="'зіграні хвилини'"
                        :value="playerGameTime"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => playerGameTime = e" 
                    />
                </div>
                <div class="documents-tab__column">
                    <InputComponent 
                        :label="'закінчення контракта'" 
                        :placeholder="''" 
                        :name="'закінчення контракта'"
                        :type="'date'" 
                        :value="currentStudent.contract_term"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.contract_term = e"
                    />
                    <InputComponent 
                        :label="'агент/агенція'" 
                        :placeholder="''" 
                        :name="'агент'"
                        :value="currentStudent.representative"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.representative = e"
                    />
                    <InputComponent 
                        :label="'стандартна позиція'" 
                        :placeholder="''" 
                        :name="'позиція'"
                        :value="currentStudent.amplua"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.amplua = e" 
                    />
                    <div class="border-[1px] border-[#BD913566] relative top-[-5px] h-[233px] border-dashed rounded-[10px] flex flex-col gap-[7px] py-[18px] px-[5px]">
                        <div class="mt-[-11px]">
                            <SelectComponent
                                :options="['Стовп', 'Бомбардир', 'Мобільний', 'Інсайд', 'Плеймейкер', 'Конектор', 'Ранер', 'Латераль', 'Диспетчер', 'Руйнівник', 'Оборонець', 'Гравець', 'Атакуючий']" 
                                :label="'роль в цьому матчі'"
                                @change="e => matchRole = e"
                            />
                        </div>
                        <div>
                            <div class="mt-[20px]">
                                <div class="select-component__label">
                                    матч рейтинг
                                </div>
                                <div class="flex justify-center">
                                    <StarsComponent :rating="averageMatchRating" />
                                    <div class="ml-[30px] pt-[5px]">
                                    {{ averageMatchRating.toFixed(2) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <SelectComponent
                                class="block mt-[8px]"
                                :options="['А – ЗІРКА', 'B – ТАЛАНТ', 'C – МАЙСТЕР', 'D – ПРОФЕСІОНАЛ', 'E – АМАТОР', 'F – НОВАЧОК']" 
                                :label="'оцінка потенціалу'"
                                @change="e => grading = e"
                            />
                        </div>
                        <div>
                            <SelectComponent
                                class="block mt-[20px]"
                                :options="['Рекомендовано', 'Спостереження', 'Нема потенціалу']" 
                                :label="'рекомендація'"
                                @change="e => nextSteps = e"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-col gap-[20px] border-[1px] border-[#BD913566] border-dashed mt-[30px] rounded-[10px] p-[10px]">
                <TextArea :value="comment1" @update:modelValue="e => comment1 = e" :label="'#1 Comment'" :backgroundColor="'#47484966'" />
                <TextArea :value="comment2" @update:modelValue="e => comment2 = e" :label="'#2 Comment'" :backgroundColor="'#47484966'" />
            </div>

            <div class="mt-[15px] flex gap-[14px] justify-center">
                <div v-for="(markType, id) in markTypes" class="flex flex-col w-max justify-center items-center">
                    <div class="text-[14px]">{{ markType }}</div>
                    <div 
                        class="w-[98px] p-[5px] rounded-[6px]"
                        :class="{
                            'bg-[#DA4167]': marks[id] == null || marks[id] <= 4,
                            'bg-[#C59014]': marks[id] > 4 && marks[id] <= 7,
                            'bg-[#1A936F]': marks[id] > 7,
                        }"    
                    >
                        <input v-model="marks[id]" type="text" class="w-full text-center font-bold placeholder:text-[#FFFFFF80]" placeholder="0.0">
                    </div>
                </div>
            </div>

            <div class="flex justify-center mt-[30px] mb-[50px]">
                <ButtonComponent @click="addReport">
                    Зберегти
                </ButtonComponent>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getAlienPlayerById, getOtherMatches, addAlienPlayerScoutReport, getScoutByTokens, getAdmin, getAverageScoreByPlayerId } from '@/services/apiRequests'
import { useGlobalStore } from '@/storage/globalStorage'

export default {
    name: 'PlayerScoutReportForm',   
    
    setup(props) {
        const route = useRoute();
        const router = useRouter();
        const apiURL = process.env.VUE_APP_API_URL;

        const store = useGlobalStore();

        const status = ref('select_club')

        const playerId = ref(route.params.player_id);

        const allMatches = ref([]);
        const currentStudent = ref({
            name: '',
            surname: '',
            photo: '',
            age: '',
            birthday: '',
            nationatily: '',
            address: '',
            amplua: '',
            height: '',
            weight: '',
            leading_foot: '',
            contract_term: '',
            representative: '',
            agency: '',
        });
        const currentStudentFullCard = ref({});

        const markTypes = ref(currentStudent.value.amplua == 'ВР' ? Object.values(store.$state.scoringRadarChartSettings.goalkeeper).slice(2).filter(value => value !== null) : Object.values(store.$state.scoringRadarChartSettings.field_player).slice(2).filter(value => value !== null));

        const selectedMatch = ref(null);

        const matchReport = ref({
            firstTeamName: '',
            secondTeamName: '',
            firstTeamLogo: '',
            secondTeamLogo: '',
            firstTeamScore: '',
            secondTeamScore: '',
            date: '',
            time: '',
            stadium: '',
            league: '',
            tour: '',

            goals: '',
            assists: '',
        })

        const playerAge = ref(0);

        const selectMatchFlag = ref(true);

        const marks = ref(Array(7).fill(null));

        const matchRole = ref('')
        const matchRating = ref('')
        const grading = ref('')
        const nextSteps = ref('')
        const comment1 = ref('')
        const comment2 = ref('')

        const playerGameTime = ref('')

        const updateBaseDataFlag = ref(false);

        const admin = ref({});

        const otherMatches = ref([]);

        const adminRole = ref('');

        watch(marks, () => {
            marks.value.forEach((mark, index) => {
                if (mark > 10) {
                    marks.value[index] = 10;
                }
            });
        }, { deep: true });

        const calculateAge = (birthday) => {
            const ageDifMs = Date.now() - new Date(birthday).getTime();
            const ageDate = new Date(ageDifMs);
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        }

        const changeMatch = (match) => {
            selectedMatch.value = match;

            matchReport.value.match_id = match.match_id;
            matchReport.value.firstTeamName = match.first_team_name;
            matchReport.value.secondTeamName = match.second_team_name;
            matchReport.value.firstTeamScore = match.first_team_score;
            matchReport.value.secondTeamScore = match.second_team_score;
            matchReport.value.date = match.match_date;
            matchReport.value.time = match.match_time;
            matchReport.value.stadium = match.match_stadium;
            matchReport.value.league = match.match_league;
            matchReport.value.tour = match.match_tour;
            matchReport.value.firstTeamLogo = match.first_team_match_logo;
            matchReport.value.secondTeamLogo = match.second_team_match_logo;

            newFirstTeamLogo.value = apiURL + 'static/' + match.first_team_match_logo;
            newSecondTeamLogo.value = apiURL + 'static/' + match.second_team_match_logo;
        }

        const averageMatchRating = ref(0);

        onMounted(async () => {
            const authorRes = (await getAdmin()).data;
            admin.value = authorRes;
            console.log('author.value ------ ', admin.value);

            adminRole.value = authorRes?.role;

            if (admin.value?.status == 'error') {
                const scout = await getScoutByTokens();
                console.log('scout ------ ', scout);

                admin.value = {
                    admin_name: scout.name,
                    admin_surname: scout.surname
                }
            }

            const alienPlayer = await getAlienPlayerById(playerId.value);
            console.log('alienPlayer ----- ', alienPlayer);

            const allPoints = await getAverageScoreByPlayerId(playerId.value);
            console.log('allPoints ----- ', allPoints);
            const notNullPoints = allPoints.filter((point) => point !== 0);
            averageMatchRating.value = allPoints.reduce((acc, cur) => acc + cur, 0) / (notNullPoints.length || 1);

            currentStudent.value.name = alienPlayer.name;
            currentStudent.value.surname = alienPlayer.surname;
            currentStudent.value.photo = alienPlayer.photo;
            currentStudent.value.age = calculateAge(alienPlayer.birthdate);
            currentStudent.value.birthday = alienPlayer.birthdate;
            currentStudent.value.nationatily = alienPlayer.nationality;
            currentStudent.value.address = alienPlayer.birth_place;
            currentStudent.value.amplua = alienPlayer.amplua;
            currentStudent.value.height = alienPlayer.height;
            currentStudent.value.weight = alienPlayer.weight;
            currentStudent.value.leading_foot = alienPlayer.leadingLeg;
            currentStudent.value.contract_term = alienPlayer.contractTerm?.split('T')[0];
            currentStudent.value.representative = alienPlayer.agent;
            currentStudent.value.agency = alienPlayer.agency;
            currentStudent.value.group_name = alienPlayer.teamName;
            currentStudent.value.previous_club = alienPlayer.careerList ? alienPlayer.careerList[alienPlayer.careerList.length - 1]?.club : '';
            currentStudent.value.address = alienPlayer.address;

            // currentStudent.value = (await getStudentById(playerId.value)).data.student;
            // currentStudentFullCard.value = await getPlayerFullCardByStudentId(playerId.value);

            currentStudent.value.birthday = currentStudent.value.birthday.split('T')[0];
            currentStudent.value.contract_term = currentStudent.value.contract_term?.split('T')[0];

            markTypes.value = currentStudent.value.amplua == 'ВР' ? Object.values(store.$state.scoringRadarChartSettings.goalkeeper).slice(2).filter(value => value !== null) : Object.values(store.$state.scoringRadarChartSettings.field_player).slice(2).filter(value => value !== null);

            otherMatches.value = await getOtherMatches(playerId.value);
            console.log('otherMatches ----- ', otherMatches.value);
        });

        const addReport = async () => {
            console.log('currentStudent.value.birthday ----- ', new Date(currentStudent.value.birthday));
            currentStudent.value.birthday = new Date(currentStudent.value.birthday).toISOString();
            currentStudent.value.contract_term = currentStudent.value.contract_term ? new Date(currentStudent.value.contract_term).toISOString() : null;
            const report = {
                student_id: playerId.value,
                student_name: currentStudent.value.name,
                student_surname: currentStudent.value.surname,
                student_photo: currentStudent.value.photo,
                scout_id: admin.value.admin_id,
                scout_name: admin.value.admin_name,
                scout_surname: admin.value.admin_surname,
                report_date: new Date().toISOString(),
                student_club: currentStudent.value.group_name,
                student_age: currentStudent.value.age,
                student_birth_date: currentStudent.value.birthday,
                student_nationality: currentStudent.value.nationatily,
                student_birth_place: currentStudent.value.address,
                student_position: currentStudent.value.amplua,
                student_height: currentStudent.value.height,
                student_weight: currentStudent.value.weight,
                student_leg: currentStudent.value.leading_foot,
                student_previous_club: currentStudent.value.previous_club,
                student_assists: matchReport.value.assists || 0,
                student_goals: matchReport.value.goals || 0,
                student_contract_end_date: currentStudent.value.contract_term,
                student_agent: currentStudent.value.representative,
                student_agency: currentStudent.value.agency,
                role_in_current_game: matchRole.value ? matchRole.value : 'Стовп',
                match_rating: matchRating.value ? matchRating.value : 0,
                grading: grading.value ? grading.value : 'A – ЗІРКА',
                next_steps: nextSteps.value ? nextSteps.value : 'Рекомендовано',
                comment_1: comment1.value,
                comment_2: comment2.value,
                first_point: marks.value[0] ? marks.value[0] : 0,
                second_point: marks.value[1] ? marks.value[1] : 0,
                third_point: marks.value[2] ? marks.value[2] : 0,
                fourth_point: marks.value[3] ? marks.value[3] : 0,
                fifth_point: marks.value[4] ? marks.value[4] : 0,
                sixth_point: marks.value[5] ? marks.value[5] : 0,
                seventh_point: marks.value[6] ? marks.value[6] : 0,

                player_game_time: playerGameTime.value || 0,
                
                first_team_match_logo: null,
                second_team_match_logo: null,

                player_average_rating: averageMatchRating.value,

                match_id: matchReport.value.match_id,
                first_team_match_logo: matchReport.value.firstTeamLogo,
                second_team_match_logo: matchReport.value.secondTeamLogo,
                
                match_league: matchReport.value.league,
                match_tour: matchReport.value.tour,
                first_team_name: matchReport.value.firstTeamName,
                first_team_score: matchReport.value.firstTeamScore,
                second_team_name: matchReport.value.secondTeamName,
                second_team_score: matchReport.value.secondTeamScore,
                match_time: matchReport.value.time,
                match_date: new Date(matchReport.value.date).toISOString(),
                match_stadium: matchReport.value.stadium,
            }
            console.log('report ----- ', report);
            console.log('matchReport.value ----- ', matchReport.value);

            console.log('newFirstTeamLogo.value ---- ', newFirstTeamLogo.value)
            console.log('newSecondTeamLogo.value ---- ', newSecondTeamLogo.value)
            console.log('newFirstTeamLogoObject.value ---- ', newFirstTeamLogoObject.value)
            console.log('newSecondTeamLogoObject.value ---- ', newSecondTeamLogoObject.value)

            await addAlienPlayerScoutReport(report, newFirstTeamLogo.value, newSecondTeamLogo.value);
            router.go(-1);
        }


        const newFirstTeamLogoAdd = ref(null)

        const newFirstTeamLogo = ref(null)
        const newFirstTeamLogoObject = ref(null)

        const addLogoToFirstTeam = () => {
            newFirstTeamLogoAdd.value.click()
        }

        const proccessNewFirstTeamLogo = () => {
            const file = newFirstTeamLogoAdd.value.files[0]
            newFirstTeamLogoObject.value = file
            const reader = new FileReader()
            reader.onload = () => {
                newFirstTeamLogo.value = reader.result
            }
            reader.readAsDataURL(file)
        }


        const newSecondTeamLogoAdd = ref(null)

        const newSecondTeamLogo = ref(null)
        const newSecondTeamLogoObject = ref(null)

        const addLogoToSecondTeam = () => {
            newSecondTeamLogoAdd.value.click()
        }

        const proccessNewSecondTeamLogo = () => {
            const file = newSecondTeamLogoAdd.value.files[0]
            newSecondTeamLogoObject.value = file
            const reader = new FileReader()
            reader.onload = () => {
                newSecondTeamLogo.value = reader.result
            }
            reader.readAsDataURL(file)
        }

        return {
            allMatches,
            selectedMatch,
            currentStudent,
            status,
            matchReport,
            apiURL,
            currentStudentFullCard,
            marks,
            matchRole,
            matchRating,
            grading,
            nextSteps,
            comment1,
            comment2,
            updateBaseDataFlag,
            addReport,

            averageMatchRating,

            newFirstTeamLogo,
            newFirstTeamLogoObject,
            addLogoToFirstTeam,
            proccessNewFirstTeamLogo,
            newFirstTeamLogoAdd,

            newSecondTeamLogo,
            newSecondTeamLogoObject,
            addLogoToSecondTeam,
            proccessNewSecondTeamLogo,
            newSecondTeamLogoAdd,

            otherMatches,
            selectMatchFlag,
            changeMatch,
            playerGameTime,

            header: [
                " ",
                "матч",
                "ліга",
                "сезон",
                "голи",
                "асисти",
                "пенальті",
                "автоголи",
                "хв. на полі",
                "жовті",
                "червоні",
                "середня оцінка",
                "",
                "",
            ],
            markTypes
        }
    },
}
</script>

<style lang="scss">
.custom-checkbox {
    position: relative;
    display: inline-block;

    .checkbox-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked + .checkbox-box {
            background-color: #C59014;
            border-color: #C59014;

            &:after {
                display: block;
            }
        }
    }

    .checkbox-box {
        width: 20px;
        height: 20px;
        background-color: #474849;
        border: 2px solid #C59014;
        border-radius: 3px;
        position: relative;
        cursor: pointer;
        display: block;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }
}


</style>