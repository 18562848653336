<template>
    <div ref="chartRef" style="height: 500px; width: 100%;"></div>
  </template>
  
  <script>
  import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import * as echarts from 'echarts';

export default defineComponent({
  props: {
    chartData: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },

  setup(props, { emit }) {
    const chartRef = ref(null);
    let chartInstance = null;
    let selectedPointIndex = null;

    const generateRandomData = () => {
        const categoryData = [];
        const values = [];
        const startDate = new Date(2020, 0, 1);
        let value = 100;
  
        for (let i = 0; i < 100; i++) {
          const date = new Date(startDate);
          date.setDate(startDate.getDate() + i);
          categoryData.push(date.toISOString().split('T')[0]);
  
          value = value + (Math.random() - 0.5) * 10;
          values.push(value.toFixed(2));
        }
  
        return { categoryData, values };
      };

    const chartColors = {
      backgroundColor: 'transparent',
      lineColor: '#BD9135',
      areaColor: 'rgba(189, 145, 53, 0.2)',
      axisLineColor: '#BD9135',
      gridLineColor: 'transparent',
      tooltipBackgroundColor: '#ffffff',
      tooltipTextColor: '#000000',
      legendTextColor: '#333333',
      dataZoomColor: 'transparent',
      dataZoomBackground: 'transparent',
      selectedPointColor: '#00FF00' // Green color for the selected point
    };

    const initChart = () => {
      const data = props.chartData;
      chartInstance = echarts.init(chartRef.value);

      const getSeriesData = () => {
        return data.values.map((value, index) => ({
          value,
          itemStyle: index === selectedPointIndex
            ? {
                color: chartColors.selectedPointColor,
                borderColor: chartColors.selectedPointColor,
                borderWidth: 4
              }
            : {}
        }));
      };

      chartInstance.setOption({
        backgroundColor: chartColors.backgroundColor,
        color: [chartColors.lineColor],
        animation: false,
        legend: { show: false },
        tooltip: {
          backgroundColor: chartColors.tooltipBackgroundColor,
          trigger: 'axis',
          axisPointer: { type: 'cross' },
          textStyle: { color: chartColors.tooltipTextColor }
        },
        axisPointer: { link: [{ xAxisIndex: 'all' }] },
        toolbox: {
          feature: { dataZoom: { yAxisIndex: false } }
        },
        grid: {
          left: '10%',
          right: '8%',
          bottom: 150,
          containLabel: true,
          backgroundColor: 'transparent'
        },
        xAxis: {
          type: 'category',
          data: data.categoryData,
          boundaryGap: false,
          axisLine: { lineStyle: { color: chartColors.axisLineColor } },
          min: 'dataMin',
          max: 'dataMax',
          splitLine: { show: false },
          splitArea: { show: false }
        },
        yAxis: {
          scale: true,
          axisLine: { lineStyle: { color: chartColors.axisLineColor } },
          splitLine: { show: false },
          splitArea: { show: false }
        },
        dataZoom: [
          { type: 'inside', start: 98, end: 100, minValueSpan: 10 },
          {
            show: true,
            type: 'slider',
            bottom: 60,
            start: 98,
            end: 100,
            minValueSpan: 10,
            fillerColor: chartColors.dataZoomBackground,
            borderColor: chartColors.dataZoomColor,
            handleStyle: { color: chartColors.dataZoomColor }
          }
        ],
        series: [
          {
            name: 'Результат',
            type: 'line',
            smooth: true,
            data: getSeriesData(),
            lineStyle: { color: chartColors.lineColor, width: 2 },
            areaStyle: { color: chartColors.areaColor },
            encode: { x: 0, y: 1 }
          }
        ]
      });

      // Add a click event listener to emit the date and value, and highlight the selected point
      chartInstance.on('click', (params) => {
        const { name: date, dataIndex, value } = params;
        selectedPointIndex = dataIndex;  // Update the selected point index
        emit('pointClick', { date, value });
        updateChart();  // Re-render the chart to show the selected point
      });
    };

    const updateChart = () => {
      if (chartInstance) {
        chartInstance.setOption({
          series: [{
            data: props.chartData.values.map((value, index) => ({
              value,
              itemStyle: index === selectedPointIndex
                ? {
                    color: chartColors.selectedPointColor,
                    borderColor: chartColors.selectedPointColor,
                    borderWidth: 10
                  }
                : {}
            }))
          }]
        });
      }
    };

    onMounted(() => {
      initChart();
      window.addEventListener('resize', resizeChart);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', resizeChart);
      if (chartInstance) {
        chartInstance.dispose();
      }
    });

    const resizeChart = () => {
      if (chartInstance) {
        chartInstance.resize();
      }
    };

    return { chartRef, chartColors };
  }
});

  </script>
  
  <style scoped>
  /* Стили для контейнера графика */
  </style>
  