<template>
  <div class="testing-tab">

    <!-- <div class=" text-center text-gray-400">
      В розробці
    </div> -->
    <div v-for="group in allBaseStandardsGroups">
      <div class="flex items-center border-y-[1px] py-[10px] w-[55%] border-opacity-5 border-white"> 
        <div @click="group.open = !group.open" class="cursor-pointer">
          <svg v-if="!group.open" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 9L11.2929 14.2929C11.6262 14.6262 11.7929 14.7929 12 14.7929C12.2071 14.7929 12.3738 14.6262 12.7071 14.2929L18 9" stroke="#BD9135" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>    
          </svg>
          <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.99991 15.0001L11.2928 9.70717C11.6261 9.37383 11.7928 9.20717 11.9999 9.20717C12.207 9.20717 12.3737 9.37383 12.707 9.70717L17.9999 15.0001" stroke="#BD9135" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="font-semibold text-[21px]">
          {{ group.group_name }}
        </div>
      </div>
      <TableWrapper v-if="group.open" class="mt-[20px]">
        <TableComponent>
          <TableHeader>
            <TableHeaderRow>
              <TableHeaderCell v-for="item in header" :key="item" :value="item" />
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            <TableRow v-for="(row, index) in group.standards" :key="index">
              <TableCell :value="row.skill" />
              <!-- <TableCell :asChild="true" :width="50"> -->
                <!-- <div class="testing-tab__rating-cell">
                  {{ row.rating }}
                </div> -->
              <!-- </TableCell> -->
              <TableCell :asChild="true">
                <LineChart class="cursor-pointer" :values="row.results.map(e => +e.value)" :dates="row.results.map(e => e.date)" />
              </TableCell>
            </TableRow>
            <td
              colspan="3"
              class="text-center text-gray-400 py-10"
              v-if="group.standards.length <= 0"
            >
              Немає даних
            </td>
          </TableBody>
        </TableComponent>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watchEffect } from "vue";
import { getAllStandardsRequest } from '@/services/apiRequests'
import graph from "@/assets/refactored_assets/img/players/graph.png";
import LineChart from "../widgets/LineChart.vue";
import * as echarts from "echarts";

import { getAllStudentStandardsByStudentId } from '@/services/apiRequests'
import { all } from "axios";

function groupById(arr) {
    return arr.reduce((acc, obj) => {
        // Если группа с таким id еще не существует, создаем ее
        if (!acc[obj.base_standard_id]) {
            acc[obj.base_standard_id] = [];
        }
        // Добавляем объект в соответствующую группу
        acc[obj.base_standard_id].push(obj);
        return acc;
    }, {});
}

function formatValue(value) {
  const floatValue = parseFloat(value);
  return floatValue.toFixed(2);
}

export default {
  name: "TestingTab",
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      graph: graph,
      header: ["назва нормативу", "динаміка"],
    };
  },

  setup(props) {
    const standards = ref([]);

    const standartResults = ref([]);

    const chartContainer = ref(null);

    const allBaseStandards = ref([]);

    const allBaseStandardsGroups = ref([]);

    watchEffect(() => {

    });

    onMounted(async () => {
      console.log('props.student.student_id', props.student);
      const res = await getAllStudentStandardsByStudentId(props.student.student_id);
      allBaseStandards.value = await getAllStandardsRequest();
      console.log('allBaseStandards.value ------- ', allBaseStandards.value);
      allBaseStandardsGroups.value = allBaseStandards.value.map(standard => {
        return {
          group_id: standard.group_id,
          group_name: standard.group_name
        }
      });
      allBaseStandardsGroups.value = new Set(allBaseStandardsGroups.value.map(JSON.stringify));
      allBaseStandardsGroups.value = Array.from(allBaseStandardsGroups.value).map(JSON.parse);
      console.log('allBaseStandardsGroups.value ------- ', allBaseStandardsGroups.value);
      standards.value = res;

      const standardsByGroup = groupById(standards.value)

      for(let baseStandardId in standardsByGroup) {
        if (allBaseStandards.value.find(standard => standard.id === baseStandardId) === undefined) {
          continue;
        }
        const results = standardsByGroup[baseStandardId].map(standard => {
          console.log('standard.report_id', standard.report_id)
          return {
            value: standard.result,
            date: new Date(+standard.report_id).toISOString().slice(0, 10).split('-').reverse().join('.'),
          }
        })

        const currentSkillName = allBaseStandards.value.find(standard => standard.id === baseStandardId).name;
        console.log('currentSkillName', currentSkillName);

        standartResults.value.push({
          skill: currentSkillName,
          group_id: allBaseStandards.value.find(standard => standard.id === baseStandardId).group_id,
          results: results,
          rating: 5,
          dynamic: null,
        })
      }

      allBaseStandardsGroups.value = allBaseStandardsGroups.value.map(group => {
        group.standards = standartResults.value.filter(standard => standard.group_id === group.group_id);
        group.open = false;
        return group;
      });

      console.log('allBaseStandardsGroups.value', allBaseStandardsGroups.value);
    });

    return {
      standards,
      standartResults,
      chartContainer,
      allBaseStandardsGroups,
      formatValue: formatValue,
    }
  },

  components: {
    LineChart,
  },
};
</script>
