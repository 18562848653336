<template>
    <div ref="chart" style="width: 100%; height: 100%;"></div>
  </template>
  
  <script>
  import { onMounted, ref } from 'vue';
  import * as echarts from 'echarts';
  
  export default {

    props: {
      data: {
        type: Object,
        required: true,
      },
      xLabels: {
        type: Array,
        required: true,
      },
    },

    setup(props, { emit }) {
      const chart = ref(null);
  
      const createChart = () => {
        const chartInstance = echarts.init(chart.value);
  
        // Оценочные категории
        const categories = ['ЗІРКА', 'ТАЛАНТ', 'МАЙСТЕР', 'ПРОФЕСІОНАЛ', 'АМАТОР', 'НОВАЧОК'].reverse();
  
        const option = {
          title: {
            text: 'Оцінки потенціалу',
          },
          tooltip: {
            trigger: 'axis',
            formatter: function (params) {
                const index = params[0].dataIndex; // Получаем индекс точки
                const value = params[0].value; // Получаем значение точки
                return categories[value]; // Возвращаем соответствующую категорию
            },
            },
          xAxis: {
            type: 'category',
            data: props.xLabels, // Индексы значений массива [3, 4, 3, 5]
            axisLabel: {
              formatter: (value) => `Звіт по даті ${value}`, // Формат для отображения индексов
            },
          },
          yAxis: {
            type: 'category', // Категориальная ось Y
            data: categories, // Массив категорий для оси Y
          },
          series: [
            {
              data: props.data, // Массив числовых значений
              type: 'line',
              
              lineStyle: {
                color: '#C59014', // Цвет линии
            },
            itemStyle: {
                color: '#C59014', // Цвет точек на линии
            },
            },
          ],
        };
  
        chartInstance.setOption(option);
      };
  
      onMounted(() => {
        createChart();
      });
  
      return {
        chart,
      };
    },
  };
  </script>
  
  <style>
  /* Дополнительные стили, если нужно */
  </style>
  