<template>
    <NewAdminForm v-if="adminFormFlag" @update-admins="loadAdmins" @close="adminFormFlag = false" />
    <AdminView v-if="adminViewFlag" @update-admins="loadAdmins" @close="adminViewFlag = false" :admin="adminObject" />
    <DeleteAdminForm v-if="adminDeletionFlag" @close="adminDeletionFlag = false" :admin="adminForDelete" @acceptDeleteAdmin="admin => processDeleteAdmin(admin)" />
    <div class="overflow-y-auto">
        <menu-bar 
            v-model="searchQuery" 
            v-model:page="currentPage" 
            :maxPage="maxPage" 
            @startFilter="filterAllStudents" 
            @openFilters="" 
            @addStudent="showNewStudentForm = true" 
        />
        
        <div class="flex justify-end px-[30px]">
            <button @click="adminFormFlag = true" class="bg-[#BD9135] hover:bg-[#BD9135d6] w-[228px] text-white px-4 py-[6px] text-[14px] rounded-md">Додати</button>
        </div>

        <keep-alive>
        <div class=" w-[90%] mx-auto mt-3 mb-5 rounded-[10px] overflow-y-auto">
            <table class="w-full">
                <thead class="text-white select-none">
                    <tr class="border-b-[1px] border-[#252526] text-white">
                        <th class=" py-2 w-[5%] text-center font-medium text-sm"></th>
                        <th class=" py-2 w-[20%] text-start font-medium text-sm">Ім'я</th>
                        <th class=" py-2 w-[25%] text-end font-medium text-sm">Готівковий баланс</th>
                        <th class=" py-2 w-[25%] text-end font-medium text-sm">Інвестиційний баланс</th>
                        <th class=" w-[25%] py-2 text-start font-medium text-sm"></th>
                    </tr>
                </thead>
                <tbody v-if="!loading">
                    <tr v-for="admin in allAdmins" class="cursor-pointer border-b-[1px] border-[#252526]">
                        <td @click="setAdminObject(admin)" class="py-3 pl-4"> 
                            <div class="w-10 h-10 border-[#FFFFFF]  rounded-full overflow-hidden">
                                <div class="h-full w-full flex justify-center items-center text-[#FFFFFF] bg-[#FFFFFF] bg-opacity-20">{{ admin.admin_name[0] + admin.admin_surname[0] }}</div>
                            </div>
                        </td>
                        <td @click="setAdminObject(admin)" class="text-[#FFFFFF] py-3 text-[14px]">{{ admin.admin_name + ' ' + admin.admin_surname }}</td>
                        <td class="text-[#4EAE3B] py-3 text-end text-[14px]">{{ admin.cash_balance_state.toFixed(2) }}</td>
                        <td class="text-[#BD9135] py-3 text-end text-[14px]">{{ admin.invest_balance_state.toFixed(2) }}</td>
                        <td>
                            <div class="flex justify-end">
                                <div @click="deleteAdmin(admin)" class="w-[30px] h-[30px] rounded-full flex justify-center items-center cursor-pointer">
                                    <img src="@/assets/icons/trash_icon.svg" alt="">
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        </keep-alive>
        <div v-if="loading" class="z-50 fixed w-full h-full">
            <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#F9245A] border-t-transparent absolute top-[50%] left-[50%] ml-[-160px] mt-[-90px]"></div>
        </div>
        <div class="w-full mt-[14px] flex items-center justify-center" v-else-if="allAdmins.length == 0">
            <p class="text-[#A3A3C2] text-[16px]">Адмінів не знайдено</p>
        </div>  
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { getAllAdmins, getBalanceState, deleteAdminRequest } from '@/services/apiRequests'
    import NewAdminForm from './components/NewAdminForm.vue'
    import AdminView from './components/AdminView.vue'
    import ExitConfirmForm from './components/ui/ExitConfirmForm.vue'
    import DeleteAdminForm from './components/ui/DeleteAdminForm.vue'

    export default {
        setup() {
            const allAdmins = ref([])

            const loading = ref(false)

            const loadAdmins = async () => {
                loading.value = true
                allAdmins.value = []
                const res = await getAllAdmins()
                for (const admin of res) {
                    const cashBalanceState = await getBalanceState(admin.cash_balance_id)
                    const investBalanceState = await getBalanceState(admin.invest_balance_id)
                    admin.cash_balance_state = cashBalanceState
                    admin.invest_balance_state = investBalanceState
                    allAdmins.value.push(admin)
                }
                loading.value = false
            }

            const adminObject = ref({})
            const adminFormFlag = ref(false)
            const adminViewFlag = ref(false)
            const adminForDelete = ref({})
            const adminDeletionFlag = ref(false)

            onMounted(async () => {
                await loadAdmins()
            })

            const deleteAdmin = async (admin) => {
                adminForDelete.value = admin
                adminDeletionFlag.value = true
            }

            const processDeleteAdmin = async (admin) => {
                console.log(admin)
                await deleteAdminRequest(admin.admin_id)
                adminDeletionFlag.value = false
                await loadAdmins()
            }

            const setAdminObject = (admin) => {
                adminObject.value = admin
                adminViewFlag.value = true
            }

            return {
                allAdmins,
                loading,
                adminFormFlag,
                setAdminObject,
                adminViewFlag,
                adminObject,
                loadAdmins,
                deleteAdmin,
                adminDeletionFlag,
                adminForDelete,
                processDeleteAdmin
            }
        },

        components: {
            NewAdminForm,
            AdminView,
            ExitConfirmForm,
            DeleteAdminForm
        }
    }

</script>