<template>
    <div class="mx-auto w-[90%]">
        <!-- <MainIndicators 
            :countAlienReports="1"
            :countClubReports="1"
            :countAlienPlayers="2"
            :countClubPlayers="3"
            :subscribedPlayersCount="1"
            :signingPlayersCount="2"
            :alienReportsUniqueMatchesCount="1"
        /> -->

        <DoctorList :allDoctors="allDoctors" />

        <div class="grid grid-cols-3 mt-[100px]">
            <div class="flex flex-col items-center">
                <div class="font-semibold">
                    Баланс по статусу гравців
                </div>
                <div class="w-full">
                    <PieChart :key="chartKey" :data="[playersHealthStatistic.healthy, playersHealthStatistic.recover, playersHealthStatistic.injury]" /> 
                </div>   
                <div>
                    <div class="px-[15px] rounded-xl bg-[#339989] text-[12px] py-[2px]">Здоровий</div>
                </div>
            </div>
            <div>
                <div class="flex flex-col items-center">
                    <div class="font-semibold">
                        Баланс по статусу гравців
                    </div>
                    <div class="w-full">
                        <BarChart :key="chartKey" :barColor="'#DA4167'" :data="[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, playersHealthStatistic.injury]" />
                    </div>   
                    <div>
                        <div class="px-[15px] rounded-xl bg-[#DA4167] text-[12px] py-[2px]">Травма</div>
                    </div>
                </div>
            </div>
            <div>
                <div class="flex flex-col items-center">
                    <div class="font-semibold">
                        Баланс по статусу гравців
                    </div>
                    <div class="w-full">
                        <BarChart :key="chartKey" :barColor="'#C59014'" :data="[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, playersHealthStatistic.recover]" />
                    </div>   
                    <div>
                        <div class="px-[15px] rounded-xl bg-[#C59014] text-[12px] py-[2px]">Відновлення</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onMounted } from 'vue';
import { getDoctorProfiles, getPlayersHealthStatistics } from '@/services/apiRequests';

import MainIndicators from './components/MainIndicators.vue';
import DoctorList from './components/DoctorList.vue';
import BarChart from './components/BarChart.vue';
import PieChart from './components/PieChart.vue';

export default {
    name: 'MedicalDashboard',

    setup() {
        const allDoctors = ref([]);

        const playersHealthStatistic = ref({})
        const chartKey = ref(new Date().getTime())

        onMounted(async () => {
            console.log('MedicalDashboard mounted');
            allDoctors.value = await getDoctorProfiles();
            playersHealthStatistic.value = await getPlayersHealthStatistics();

            chartKey.value = new Date().getTime()
        });

        return {
            allDoctors,
            playersHealthStatistic,
            chartKey
        }
    },

    components: {
        MainIndicators,
        DoctorList,
        BarChart,
        PieChart
    }
}
</script>
<style lang="">
    
</style>