<template>
    <div class="mt-[100px]">
        <div class="w-full text-center uppercase text-[16px]">скаути</div>

        <div class="flex overflow-x-auto mt-[32px]">
            <div v-for="scout in allScouts" class="w-[275px] flex flex-col items-center">
                <div class="text-[15px] uppercase text-[#C59014]">{{ scout.name + ' ' + scout.surname }}</div>
                <div class="mt-[25px]">
                    <AvatarContainer :src="apiURL + 'static/' + scout.photo"/>
                </div>
                <div class="lowercase text-[14px] mt-[22px]">{{ parseJobTitle(scout.job_title) }}</div>
                <div class="mt-[26px]">
                    <div class="lowercase text-[16px] text-[#C59014]">Звітів по грацях</div>
                    <div class="flex justify-between">
                        <div>
                            <div class="text-[#C59014] text-[36px] text-center font-bold">0</div>
                            <div class="text-[#C59014] text-[10px]">виконано</div>
                        </div>
                        <div>
                            <div class="text-[#C59014] text-[36px] text-center font-bold">0</div>
                            <div class="text-[#C59014] text-[10px]">виконано</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'

export default {
    name: 'ScoutsList',

    props: {
        allScouts: {
            type: Array,
            required: true
        }
    },

    setup(props) {
        const apiURL = ref(process.env.VUE_APP_API_URL)

        const parseJobTitle = (jobTitle) => {
            switch (jobTitle) {
                case 'scout':
                    return 'Скаут'
                case 'lead_scout':
                    return 'Шеф-скаут'
            }
        }

        return {
            apiURL,
            parseJobTitle
        }
    }
}
</script>

<style>
    
</style>