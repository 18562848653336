<template>
  <div class="stats-section">
    <div class="stats-section__rating">
      <div class="flex">
        <StarsComponent class="scale-[1.5]" :rating="averageMatchRating" />
        <div class="ml-[30px] pt-[5px]">
          {{ averageMatchRating.toFixed(2) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import circle from "@/assets/refactored_assets/img/players/stats-ring.png";

export default {
  name: "StatsHeaderSection",
  props: {
    visitings_number: Number,
    trainings_number: Number,
    rating: Number,
    averageMatchRating: Number,
  },
  data() {
    return {
      circle: circle,
    };
  },
};
</script>
