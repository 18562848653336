<template>
    <div v-if="!showDeepSettings" class="flex flex-col justify-center">
        <div class="w-[90%] mx-auto flex items-center gap-[10px] mt-[20px]">
            <div 
                :class="{
                    'border-[#C4C4C4CC] text-[#474849] bg-[#C59014]': scoreType === 'objective',
                    'border-[#A9AAAB66] text-[#C59014] bg-[#47484966]': scoreType != 'objective'
                }"
                @click="scoreType = 'objective'"
                class="w-max px-[20px] cursor-pointer select-none font-bold text-[16px] pt-[2px] border-[2px] flex justify-center items-center rounded-[8px]">
                Об'єктивні показники
            </div>
            <div
                :class="{
                    'border-[#C4C4C4CC] text-[#474849] bg-[#C59014]': scoreType === 'subjective',
                    'border-[#A9AAAB66] text-[#C59014] bg-[#47484966]': scoreType != 'subjective'
                }"
                @click="scoreType = 'subjective'"
                class="w-max px-[20px] cursor-pointer select-none font-bold text-[16px] pt-[2px] border-[2px] flex justify-center items-center rounded-[8px]">
                Суб'єктивні показники
            </div>
        </div>
        <div v-if="!dataIsLoading" class="w-[90%] mx-auto mt-[20px] flex justify-between">
            <div class="grid grid-cols-2 gap-[40px]">
                <div v-for="(scoreObj, id) in scores" class="w-[350px] mb-[20px]">
                    <div class="text-center mb-[10px]">
                        <div>
                            {{ scoreObj.score_field_name }}
                        </div>
                        <div v-if="scoreType == 'subjective'" class="text-[12px]">
                            {{ scoreObj.score_goalkeeper_name }}
                        </div>
                    </div>
                    <div class="w-[100%] h-[200px]">
                        <div v-for="result in scoreObj.data" class="w-[100%] flex items-center justify-start mb-[10px]">
                            <div class="mr-[5px] w-[30%] truncate">{{ result.player_name.split(' ')[1] + ' ' + result.player_name.split(' ')[0][0] }}.</div>
                            <div 
                                @click="$router.push(`students/${result.player_id}?tabName=MainTab`)"
                                class="border-[#C59014] text-[#C59014] bg-[#C59014] bg-opacity-20 rounded-md border-[4px] h-[20px]"
                                :style="[`width: ${10 + result.barWidth}%`]"    
                            >
                                <div class="text-[12px] text-[#C59014] text-center mb-[2px]">
                                    {{ result.rating.toFixed(2) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div v-if="balanceScores.length > 0" class="w-[350px] mb-[20px]">
                    <div class="text-center mb-[10px]">
                        <div>
                            Баланс
                        </div>
                    </div>
                    <div class="w-[100%] h-[200px]">
                        <div v-for="result in balanceScores" class="w-[100%] flex items-center justify-start mb-[10px]">
                            <div class="mr-[5px] w-[30%] truncate">{{ result.player_name.split(' ')[1] + ' ' + result.player_name.split(' ')[0][0] }}.</div>
                            <div 
                                class="border-[#339989] text-[#339989] bg-[#339989] bg-opacity-20 rounded-md border-[4px] h-[20px]"
                                :style="[`width: ${10 + result.barWidth}%`]"    
                            >
                                <div class="text-[12px] text-[#339989] text-center mb-[2px]">
                                    {{ result.rating.toFixed(2) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="dataIsLoading" class="w-[90%] mx-auto mt-[20px] flex justify-center">
            <div class="mb-[20px]">
                Збирається інформація<span class="animate-pulse">...</span>
            </div>
        </div>

        <div class="flex justify-center mb-[20px]">
            <ButtonComponent @click="showDeepSettings = true">Тонке налаштування</ButtonComponent>
        </div>
    </div>
    <AnalyticsDeepSettings v-else @close="showDeepSettings = false" />
</template>
<script>
import { onMounted, ref, watch } from 'vue';
import { getAllGroups, getScoreSettings, getObjectiveScoreSettings, getAnalyticsByPlayersInClub } from '@/services/apiRequests'
import AnalyticsDeepSettings from './components/AnalyticsDeepSettings.vue';

export default {
    name: 'AnalyticsView',

    setup() {
        const showDeepSettings = ref(false);

        const scoreType = ref('subjective');
        const positions = ref(['ВР', 'ЦЗ', 'ЛЗ', 'ПЗ', 'ЦОП', 'ЦП', 'ЦАП', 'ПП', 'ЛП', 'ПВ', 'ЛВ', 'НП'])
        const groups = ref([]);

        const dataIsLoading = ref(true);

        const objectiveScoreSettings = ref({
            field_player: [],
            goalkeeper: []
        });
        const subjectiveScoreSettings = ref({
            field_player: [],
            goalkeeper: []
        });

        const scores = ref([]);
        const balanceScores = ref([]);

        onMounted(async () => {
            const groupResponse = await getAllGroups()
            groups.value = groupResponse.data

            const groupIds = groups.value.map(group => group.group_id)

            const objectiveSettings = await getObjectiveScoreSettings();
            const subjectiveSettings = await getScoreSettings();

            subjectiveScoreSettings.value.field_player = [
                subjectiveSettings.field_player.first_point_name,
                subjectiveSettings.field_player.second_point_name,
                subjectiveSettings.field_player.third_point_name,
                subjectiveSettings.field_player.fourth_point_name,
                subjectiveSettings.field_player.fifth_point_name,
                subjectiveSettings.field_player.sixth_point_name,
                subjectiveSettings.field_player.seventh_point_name,
                subjectiveSettings.field_player.eighth_point_name,
                subjectiveSettings.field_player.ninth_point_name
            ].filter(name => name)
            subjectiveScoreSettings.value.goalkeeper = [
                subjectiveSettings.goalkeeper.first_point_name,
                subjectiveSettings.goalkeeper.second_point_name,
                subjectiveSettings.goalkeeper.third_point_name,
                subjectiveSettings.goalkeeper.fourth_point_name,
                subjectiveSettings.goalkeeper.fifth_point_name,
                subjectiveSettings.goalkeeper.sixth_point_name,
                subjectiveSettings.goalkeeper.seventh_point_name,
                subjectiveSettings.goalkeeper.eighth_point_name,
                subjectiveSettings.goalkeeper.ninth_point_name
            ].filter(name => name)

            objectiveScoreSettings.value.field_player = [
                objectiveSettings.field_player.first_point_name,
                objectiveSettings.field_player.second_point_name,
                objectiveSettings.field_player.third_point_name,
                objectiveSettings.field_player.fourth_point_name,
                objectiveSettings.field_player.fifth_point_name,
                objectiveSettings.field_player.sixth_point_name,
                objectiveSettings.field_player.seventh_point_name,
                objectiveSettings.field_player.eighth_point_name,
                objectiveSettings.field_player.ninth_point_name
            ].filter(name => name)
            objectiveScoreSettings.value.goalkeeper = [
                objectiveSettings.goalkeeper.first_point_name,
                objectiveSettings.goalkeeper.second_point_name,
                objectiveSettings.goalkeeper.third_point_name,
                objectiveSettings.goalkeeper.fourth_point_name,
                objectiveSettings.goalkeeper.fifth_point_name,
                objectiveSettings.goalkeeper.sixth_point_name,
                objectiveSettings.goalkeeper.seventh_point_name,
                objectiveSettings.goalkeeper.eighth_point_name,
                objectiveSettings.goalkeeper.ninth_point_name
            ].filter(name => name)

            await updateScoresDashboard()

            console.log('scores', scores.value)
        });

        const updateScoresDashboard = async () => {
            dataIsLoading.value = true;
            const groupIds = groups.value.map(group => group.group_id)
            const radarPoints = scoreType.value === 'objective' ? objectiveScoreSettings.value : subjectiveScoreSettings.value

            for (const scoreId of radarPoints.field_player.map((_, index) => index)) {
                let response = await getAnalyticsByPlayersInClub({
                    score_type: scoreType.value,
                    amplua_list: positions.value,
                    team_list: groupIds,
                    score_ids: [scoreId]
                })
                response = response.sort((a, b) => b.rating - a.rating)
                response = response.slice(0, 5)

                const minScore = 0
                const maxScore = Math.max(...response.map(player => player.rating))

                scores.value.push({
                    score_field_name: radarPoints.field_player[scoreId],
                    score_goalkeeper_name: radarPoints.goalkeeper[scoreId],
                    data: response.map(player => {
                        // barWidth min 0 max 70
                        const barWidth = (player.rating - minScore) / (maxScore - minScore) * 60
                        return {
                            ...player,
                            barWidth: barWidth
                        }
                    })
                })
            }

            let balanceResponse = await getAnalyticsByPlayersInClub({
                    score_type: scoreType.value,
                    amplua_list: positions.value,
                    team_list: groupIds,
                    score_ids: radarPoints.field_player.map((_, index) => index)
                })
            balanceResponse = balanceResponse.sort((a, b) => b.rating - a.rating)
            balanceResponse = balanceResponse.slice(0, 5)
            balanceScores.value = balanceResponse.map(player => {
                const minScore = 0
                const maxScore = Math.max(...balanceResponse.map(player => player.rating))
                const barWidth = (player.rating - minScore) / (maxScore - minScore) * 60
                return {
                    ...player,
                    barWidth: barWidth
                }
            })

            dataIsLoading.value = false;
        }

        watch(scoreType, async (value) => {
            scores.value = []
            balanceScores.value = []
            await updateScoresDashboard()
        })

        return {
            showDeepSettings,
            scoreType,
            objectiveScoreSettings,
            subjectiveScoreSettings,
            scores,
            balanceScores,
            dataIsLoading
        }
    },

    components: {
        AnalyticsDeepSettings
    }
}
</script>
<style lang="">
    
</style>