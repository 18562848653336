<template>
    <DeleteReportForm 
        v-if="deletionFormFlag" 
        :report="reportForDeletion" 
        @acceptDeleteReport="report => {$emit('deleteReport', report); deletionFormFlag = false}" 
        @close="deletionFormFlag = false" 
    />
    <div class="w-[90%] mx-auto mb-3 rounded-[10px] overflow-hidden">
        <table class="w-full">
            <thead class="">
                <tr class="text-white border-b-[1px] border-[#FFF] border-opacity-10">
                    <th class="px-3 py-2 text-center font-medium text-sm"></th>
                    <th class="px-3 py-2 text-start font-medium text-sm">гравець</th>
                    <th class="px-3 py-2 text-start font-medium text-sm">команда</th>
                    <th class="px-3 py-2 text-start font-medium text-sm">звіт</th>
                    <th class="px-3 py-2 text-start font-medium text-sm">скаут</th>
                    <th class="px-3 py-2 text-end font-medium text-sm">потенціал</th>
                    <th class="px-3 py-2 text-center font-medium text-sm">середня оцінка</th>
                    <th class="px-3 py-2 text-center font-medium text-sm">дивитися</th>
                    <th class="px-3 py-2 text-center font-medium text-sm">дата</th>
                    <th class="px-3 py-2 text-center font-medium text-sm"></th>
                </tr>
            </thead>
            <tbody>
                
                <tr v-for="report in allScoutReports">
                    <td class="w-[4%] px-3 py-2 text-end font-medium text-sm">
                        <div class="w-10 h-10 rounded-full overflow-hidden flex items-end bg-[#BD9135] bg-opacity-20 border-[#BD9135] border-[1px]">
                            <img v-if="report.student_photo" :src="apiURL + 'static/' + report.student_photo">
                            <div v-else class="w-full h-full  text-[#BD9135] flex justify-center items-center">
                                {{ report.student_name[0] + report.student_surname[0] }}
                            </div>
                        </div>
                    </td>
                    <td class="px-3 py-2 text-start font-medium text-sm">
                        <div>
                            {{ report.student_name + ' ' + report.student_surname }}
                        </div>
                        <div>
                            <span class="text-gray-400 text-[11px]">{{ report.student_age }}p. | {{ report.student_position }} | {{ formatDate(report.student_birth_date) }}</span>
                        </div>
                    </td>
                    <td class="px-3 py-2 text-start font-medium text-sm">{{ report.student_club }}</td>
                    <td class="px-3 py-2 text-start text-sm">Звіт по грі <b>{{ report.first_team_name }} - {{ report.second_team_name }}</b> від {{ formatDate(report.match_date) }}</td>
                    <td class="px-3 py-2 text-start font-medium text-sm">{{ report.scout_name + ' ' + report.scout_surname }}</td>
                    <td class="px-3 py-2 text-end font-medium text-sm">{{ report.grading }}</td>
                    <td class="px-3 py-2 text-start font-medium text-sm">
                        <StarsComponent :rating="calculateAverageRating(report)" />
                    </td>
                    <td class="px-3 py-2 text-end font-medium text-sm">
                        <div class="flex justify-center">
                            <button 
                                class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                @click="$router.push('/alien-player-scout-report-view/' + report.report_id)"
                            >
                                <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.544 7.04498C20.848 7.4713 21 7.68447 21 8C21 8.31553 20.848 8.52869 20.544 8.95501C19.1779 10.8706 15.6892 15 11 15C6.31078 15 2.8221 10.8706 1.45604 8.95502C1.15201 8.5287 1 8.31553 1 8C1 7.68447 1.15201 7.47131 1.45604 7.04499C2.8221 5.12944 6.31078 1 11 1C15.6892 1 19.1779 5.12944 20.544 7.04498Z" stroke="#339989" stroke-width="1.5"></path><path d="M14.0005 8C14.0005 6.34315 12.6573 5 11.0005 5C9.34363 5 8.00049 6.34315 8.00049 8C8.00049 9.65685 9.34363 11 11.0005 11C12.6573 11 14.0005 9.65685 14.0005 8Z" stroke="#339989" stroke-width="1.5"></path></svg>
                            </button>
                        </div>
                    </td>
                    <td class="px-3 py-2 text-end flex flex-col items-center justify-center font-medium text-sm">
                        <div class="text-gray-400 text-[11px]">
                            {{ formatDate(report.report_date) }}
                        </div>
                        <div>
                            <span class="text-gray-400 text-[11px]">{{ formatTime(report.report_date) }}</span>
                        </div>
                    </td>
                    <td>
                        <button @click="() => { reportForDeletion = report; deletionFormFlag = true }" class="px-2 py-1 rounded-[10px] text-sm font-medium text-red-600">
                            <img src="@/assets/icons/trash_icon.svg" class="w-6 h-6">
                        </button>
                    </td>
                </tr>

                <tr v-if="allScoutReports.length == 0">
                    <td class="px-3 py-2 text-center font-medium text-sm opacity-50" colspan="10">Звіти відсутні</td>
                </tr>

            </tbody>
        </table>
    </div>
</template>

<script>
import { ref } from 'vue'
import DeleteReportForm from './DeleteReportForm.vue';

export default {
    name: 'ScoutReportsTable',
    
    props: {
        allScoutReports: {
            type: Array,
            required: true
        }
    },

    setup() {
        const apiURL = ref(process.env.VUE_APP_API_URL)

        const reportForDeletion = ref({})
        const deletionFormFlag = ref(false)

        const formatDate = (date) => {
            const newDate = new Date(date)
            return newDate.toLocaleDateString()
        }

        const formatTime = (date) => {
            const newDate = new Date(date)
            return newDate.toLocaleTimeString()
        }

        const calculateAverageRating = (report) => {
            return (
                (report.first_point +
                report.second_point +
                report.third_point +
                report.fourth_point +
                report.fifth_point +
                report.sixth_point +
                report.seventh_point) /
                7
            );
        };

        return {
            apiURL,
            formatDate,
            formatTime,
            calculateAverageRating,
            reportForDeletion,
            deletionFormFlag
        }
    },

    components: {
        DeleteReportForm
    }
}
</script>

<style>
    
</style>