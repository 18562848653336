<template>
  <div class="parameters-tab">
    <div v-if="transferConfirmation" class="fixed shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] top-[50%] left-[50%] bg-[#252526] p-4 rounded-[10px] mt-[-20px] w-[250px]">
        <div class="text-center mb-3 text-[#FFF]">Ви дійсно хочете перевести гравця в клуб?</div>
        <div class="flex justify-between">
            <button @click="startTransfer" class="bg-[#DA4167] hover:bg-opacity-90 text-white px-4 py-1 rounded-[10px]">Так</button>
            <button @click="transferConfirmation = false" class="px-4 py-1 rounded-[10px] bg-[#BD9135]">Ні</button>
        </div>
    </div>
    <div class="parameters-tab__column">
      <InputComponent 
        :label="'клуб'" 
        :placeholder="''" 
        :name="'клуб'" 
        :value="alienPlayerInfo.teamName"
        @update:modelValue="alienPlayerInfo.teamName = $event"
      />
      <InputComponent 
        :label="'ім\'я'" 
        :placeholder="''" 
        :name="'ім\'я'" 
        :value="alienPlayerInfo.name"
        @update:modelValue="alienPlayerInfo.name = $event"
      />
      <InputComponent 
        :label="'прізвище'" 
        :placeholder="''" 
        :name="'прізвище'" 
        :value="alienPlayerInfo.surname"
        @update:modelValue="alienPlayerInfo.surname = $event"
      />
      <InputComponent 
        :label="'дата народження'" 
        :placeholder="''" 
        :name="'дата народження'" 
        :value="birthDate"
        @update:modelValue="birthDate = $event"
        type="date"
      />
      <div class=relative>
        <InputComponent 
          :label="'національність'" 
          :placeholder="''" 
          :name="'національність'" 
          :value="currentNationality"
          @update:modelValue="currentNationality = $event"
          @focusin="nationalityInputActive = true"
        />
        <div v-if="nationalityInputActive" :key="flagsKey" class="h-[100px] overflow-y-auto bg-[#000] scrolltab absolute w-[265px] bottom-[28px] left-[-4px]">
            <div v-for="flag in showFlags" @click="changeFlag(flag)" class="flex py-[3px] justify-between w-full text-[13px] hover:bg-[#333] cursor-pointer bg-[#000] px-[4px]">
                <div class="text-[#FFF]">{{ flag }}</div>
                <div><img :src="require(`@/assets/flags/${flag}.svg`)" class="w-[25px] ml-[5px]"></div>
            </div>
        </div>
      </div>
      <InputComponent 
        :label="'місце народження'" 
        :placeholder="''" 
        :name="'місце народження'" 
        :value="alienPlayerInfo.address"
        @update:modelValue="alienPlayerInfo.address = $event"
      />
      <InputComponent 
        :label="'закінчення контракта'" 
        :placeholder="''" 
        :name="'закінчення контракта'"
        :type="'date'" 
        :value="contractTerm"
        @update:modelValue="contractTerm = $event"
      />
      <InputComponent 
        :label="'агент'" 
        :placeholder="''" 
        :name="'агент'"
        :value="alienPlayerInfo.agent"
        @update:modelValue="alienPlayerInfo.agent = $event"
      />
    </div>
    <div class="parameters-tab__column">
      <SelectComponent 
        :label="'стандартна позиція'" 
        :options="['ВР', 'ЦЗ', 'ЛЗ', 'ПЗ', 'ЦОП', 'ЦП', 'ЦАП', 'ПП', 'ЛП', 'ПВ', 'ЛВ', 'НП']" 
        :name="'стандартна позиція'" 
        :default="defaultAmplua"
        :key="defaultAmplua"
        @change="alienPlayerInfo.amplua = $event"
        class="mb-[15px]"
      />
      <SelectComponent 
        :label="'роль'" 
        :options="['Стовп', 'Бомбардир', 'Мобільний', 'Інсайд', 'Плеймейкер', 'Конектор', 'Ранер', 'Латераль', 'Диспетчер', 'Руйнівник', 'Оборонець', 'Гравець', 'Атакуючий']" 
        :name="'роль'" 
        :default="defaultRole"
        :key="defaultRole"
        @change="alienPlayerInfo.role = $event"
        class="mb-[15px]"
      />
      <InputComponent 
        :label="'зріст'" 
        :placeholder="''" 
        :name="'зріст'" 
        :value="alienPlayerInfo.height"
        @update:modelValue="alienPlayerInfo.height = $event"
      />
      <InputComponent 
        :label="'вага'" 
        :placeholder="''" 
        :name="'вага'"
        :value="alienPlayerInfo.weight"
        @update:modelValue="alienPlayerInfo.weight = $event"
      />
      <InputComponent 
        :label="'нога'" 
        :placeholder="''" 
        :name="'нога'" 
        :value="alienPlayerInfo.leadingLeg"
        @update:modelValue="alienPlayerInfo.leadingLeg = $event"
      />
      <InputComponent 
        :label="'попередній клуб'" 
        :placeholder="''" 
        :name="'попередній клуб'" 
        :disabled="true"
        :value="careerList?.length != 0 ? careerList[careerList?.length - 1]?.club : ''"
      />
      <InputComponent 
        :label="'агенція'" 
        :placeholder="''" 
        :name="'агенція'"
        :value="alienPlayerInfo.agency"
        @update:modelValue="alienPlayerInfo.agency = $event"
      />
      <SelectComponent 
        :label="'пропонована команда'" 
        :options="['TEAM A', 'U-19', 'U-18', 'U-17', 'U-16', 'U-15', 'U-14', 'U-13', 'U-12', 'U-11', 'U-10', 'U-9', 'U-8', 'U-7', 'U-6', 'U-5']" 
        :name="'пропонована команда'" 
        :default="defaultSuggestedCommand"
        :key="defaultSuggestedCommand"
        @change="alienPlayerInfo.suggested_command = $event"
        class="mb-[15px]"
      />
      <div class="h-[45px]"></div>
      <div class="h-[45px]"></div>
    </div>
    <div class="parameters-tab__column--full-screen">
      <div class="parameters-tab__label">кар'єра</div>
      <div class="parameters-tab__block">
        <DoubleInput
          v-for="(item, index) in careerList"
          :buttonType="'remove'"
          :key="index"
          :placeholder_1="'час перебування'"
          :placeholder_2="'клуб'"
          v-model:value_1="item.time"
          v-model:value_2="item.club"
          @delete="deleteCareer(index)"
        />
        <DoubleInput
          :buttonType="'add'"
          :placeholder_1="'час перебування'"
          :placeholder_2="'клуб'"
          v-model:value_1="newCareerTime"
          v-model:value_2="newCareerClub"
          @add="addNewCareer"
        />
      </div>

      <div v-if="userRole != 'scout'" class="mt-[20px]">
        <div class="mb-[15px] text-[#BD9135] font-semibold">статус</div>
        <div>
          
          <div class="flex mb-[10px]">
            <div class="w-[250px]">на розгляді</div>
            <div>
              <label class="radio">
                <input type="radio" name="alienPlayerType" value="consideration" v-model="alienPlayerType">
                <span class="hidden"></span>
              </label>
            </div>
          </div>

          <div class="flex mb-[10px]">
            <div class="w-[250px]">в процесі підписання</div>
            <div>
              <label class="radio">
                <input type="radio" name="alienPlayerType" value="signing" v-model="alienPlayerType">
                <span class="hidden"></span>
              </label>
            </div>
          </div>
          
          <div class="flex mb-[10px]">
            <div class="w-[250px]">підписано</div>
            <div>
              <label class="radio">
                <input type="radio" name="alienPlayerType" value="started" v-model="alienPlayerType">
                <span class="hidden"></span>
              </label>
            </div>
          </div>

          <div class="flex mb-[10px]">
            <div class="w-[250px] text-[#BD9135]">недоступний</div>
            <div>
              <label class="radio">
                <input type="radio" name="alienPlayerType" value="not_available" v-model="alienPlayerType">
                <span class="hidden"></span>
              </label>
            </div>
          </div>

          <div class="flex mb-[10px]">
            <div class="w-[250px] text-[#DA4167]">не рекомендовано</div>
            <div>
              <label class="radio">
                <input type="radio" name="alienPlayerType" value="never" v-model="alienPlayerType">
                <span class="hidden"></span>
              </label>
            </div>
          </div>

        </div>

        <ButtonComponent 
          v-if="alienPlayerType == 'started'"
          class="mt-[20px]"
          @click="transferConfirmation = true"
        >Перевести гравця в клуб</ButtonComponent>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { uuid } from 'vue3-uuid';
import PlayerModel from '../widgets/PlayerModel.vue';
import { getAlienPlayerById, updateAlienPlayerById, transferPlayerToClub } from '@/services/apiRequests'
import ButtonComponent from '@/components/uikit/Button/ButtonComponent.vue';

export default {
  name: "ParametersTab",

  props: {
    updateId: {
      type: String,
      required: false
    },
  },

  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();

    const newCareerTime = ref('')
    const newCareerClub = ref('')

    const careerList = ref([])

    const alienPlayerInfo = ref({})

    const birthDate = ref('')
    const contractTerm = ref('')

    const defaultAmplua = ref('')
    const defaultRole = ref('')
    const defaultSuggestedCommand = ref('')

    const transferConfirmation = ref(false)

    const userRole = ref(localStorage.getItem('userType'))

    const alienPlayerType = ref(alienPlayerInfo.value.alienPlayerType || 'consideration')

    const deleteCareer = (id) => {
      careerList.value.splice(id, 1)
      emit('update:careerList', careerList.value)
    }

    const addNewCareer = () => {
      careerList.value.push({
        time: newCareerTime.value,
        club: newCareerClub.value
      })
      emit('update:careerList', careerList.value)
      newCareerClub.value = ''
      newCareerTime.value = ''
    }

    const flagsArray = ref(['AD', 'BH', 'CO', 'FM', 'ID', 'KY', 'MN', 'PE', 'SH', 'TR', 'AE', 'BI', 'CR', 'FR', 'IE', 'KZ', 'MO', 'PF', 'SI', 'TT', 'AF', 'BJ', 'CU', 'GA', 'IL', 'LA', 'MR', 'PG', 'SK', 'TW', 'AG', 'BM', 'CV', 'GB', 'IM', 'LB', 'MS', 'PH', 'SL', 'TZ', 'AI', 'BN', 'CY', 'GD', 'IN', 'LC', 'MT', 'PK', 'SM', 'UA', 'AL', 'BO', 'CZ', 'GE', 'IQ', 'LI', 'MU', 'PL', 'SN', 'UG', 'AM', 'BR', 'DE', 'GG', 'IR', 'LK', 'MV', 'PR', 'SO', 'US', 'AN', 'BS', 'DJ', 'GH', 'IS', 'LR', 'MW', 'PT', 'SR', 'UY', 'AO', 'BT', 'DK', 'GI', 'IT', 'LS', 'MX', 'PW', 'ST', 'UZ', 'AR', 'BW', 'DM', 'GM', 'JE', 'LT', 'MY', 'PY', 'SV', 'VC', 'AT', 'BY', 'DO', 'GN', 'JM', 'LU', 'MZ', 'QA', 'SY', 'VE', 'AU', 'BZ', 'DZ', 'GQ', 'JO', 'LV', 'NA', 'RO', 'SZ', 'VG', 'AW', 'CA', 'EC', 'GR', 'JP', 'LY', 'NE', 'RS', 'TC', 'VN', 'AX', 'CD', 'EE', 'GT', 'KE', 'MA', 'NG', 'TD', 'VU', 'AZ', 'CF', 'EG', 'GW', 'KG', 'MC', 'NI', 'RW', 'TG', 'WS', 'BA', 'CG', 'ER', 'GY', 'KH', 'MD', 'NL', 'SA', 'TH', 'YE', 'BB', 'CH', 'ES', 'HK', 'KM', 'ME', 'NO', 'SB', 'TJ', 'BD', 'CI', 'ET', 'HN', 'KN', 'MG', 'NP', 'SC', 'TL', 'BE', 'CL', 'FI', 'HR', 'KP', 'MK', 'NZ', 'SD', 'TM', 'BF', 'CM', 'FJ', 'HT', 'KR', 'ML', 'OM', 'SE', 'TN', 'BG', 'CN', 'FK', 'HU', 'KW', 'MM', 'PA', 'SG', 'TO'])
    const showFlags = ref([])
    const currentFlag = ref('UA')
    const linqToFlag = ref(require(`@/assets/flags/UA.svg`))

    const nationalityInputActive = ref(false)
    const flagsKey = ref(uuid.v4())

    const currentNationality = ref('')

    watch(currentNationality, () => {
      emit('update:nationality', currentNationality.value)
    })

    watch(() => props.updateId, async () => {
      console.log('alienPlayerInfo.value ----- ', alienPlayerInfo.value)

      try {
        alienPlayerInfo.value.birthdate = new Date(birthDate.value).toISOString()
      } catch (e) {
        alienPlayerInfo.value.birthdate = new Date().toISOString()
      }

      try {
        alienPlayerInfo.value.contractTerm = new Date(contractTerm.value).toISOString()
      } catch (e) {
        alienPlayerInfo.value.contractTerm = new Date().toISOString()
      }
      alienPlayerInfo.value.nationality = currentNationality.value

      alienPlayerInfo.value.careerList = careerList.value

      alienPlayerInfo.value.alienPlayerStatus = alienPlayerType.value

      await updateAlienPlayerById(route.params.player_id, alienPlayerInfo.value, null, null)

    })

    const changeFlag = (flag) => {
      currentFlag.value = flag
      linqToFlag.value = require(`@/assets/flags/${flag}.svg`)
      currentNationality.value = flag
      nationalityInputActive.value = false
      flagsKey.value = uuid.v4()
    }

    watch(currentNationality, () => {
      filterFlagsByNationality()
    })

    const filterFlagsByNationality = () => {
      const filteredFlags = currentNationality.value.length > 0 || currentNationality.value == 'UKR' ? flagsArray.value.filter(flag => flag.toLowerCase().includes(currentNationality.value.toLowerCase())) : flagsArray.value
      showFlags.value = filteredFlags
      flagsKey.value = uuid.v4()
    }

    const startTransfer = async () => {
      await transferPlayerToClub(route.params.player_id)
      transferConfirmation.value = false
    }

    onMounted(async () => {
      alienPlayerInfo.value = await getAlienPlayerById(route.params.player_id)
      console.log('alienPlayerInfo.value', alienPlayerInfo.value)

      birthDate.value = alienPlayerInfo.value.birthdate.split('T')[0]
      contractTerm.value = alienPlayerInfo.value?.contractTerm?.split('T')[0]

      defaultAmplua.value = '' + alienPlayerInfo.value.amplua
      defaultRole.value = '' + alienPlayerInfo.value.role
      defaultSuggestedCommand.value = '' + alienPlayerInfo.value.suggested_command

      currentNationality.value = alienPlayerInfo.value.nationality

      careerList.value = alienPlayerInfo.value.careerList ? alienPlayerInfo.value.careerList : []

      alienPlayerType.value = alienPlayerInfo.value.alienPlayerStatus || 'consideration'
    })

    return {
      careerList,
      newCareerTime,
      newCareerClub,
      alienPlayerInfo,
      birthDate,
      contractTerm,
      defaultAmplua,
      alienPlayerType,
      defaultSuggestedCommand,

      currentFlag,
      linqToFlag,
      flagsArray,
      showFlags,
      currentNationality,
      nationalityInputActive,
      flagsKey,
      changeFlag,
      transferConfirmation,
      startTransfer,
      defaultRole,
      userRole,

      deleteCareer,
      addNewCareer
    }
  },

  components: {
    PlayerModel
  },

  data() {
    return {
      items: [
        { date: "date1", team: "team1" },
        { date: "date1", team: "team1" },
        { date: "date1", team: "team1" },
        { date: "date1", team: "team1" },
      ],
    };
  },
};
</script>

<style scoped>

.radio{
  display: block;
  cursor: pointer; 
  user-select:none;
  text-align: left;
  &+.radio{
    margin-top: 0;
  }
  input{
    display: none;
    &+span{
      display: inline-block; 
      position: relative;
      padding-left: 0;
      &:before{
        content: '';
        display: block; 
        position: absolute;
        top: -14px;
        left: 8px;
        border-radius: 50%;
        margin-right: 5px;
        width: 16px;
        height: 16px;
        border: 1px solid #474849;
        background: #474849;
      }
      &:after{
        content: '';
        display: block; 
        width: 10px;
        height: 10px;
        background: #BD9135;
        position: absolute;
        border-radius: 50%;
        top: -11px;
        left: 11px;
        opacity: 0;
        transform: scale(0,0); 
        transition: all .2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
      }
    }
    &:checked+span:after{
      opacity: 1;
      transform: scale(1,1);
    }
  }
}

</style>