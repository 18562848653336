<template>
    <div>
        <MenuBar 
            :studentsType="studentsType" 
            @clubPlayers="setClubPlayers" 
            @otherPlayers="setOtherPlayers" 
            :currentPage="page"
            @update:currentPage="page = $event"
            :maxPage="maxPage"
            :searchQuery="searchQuery"    
            @chageQuery="searchQuery = $event"

            :clubPlayerReportsCount="clubPlayerReportsCount"
            :alienPlayerReportsCount="alienPlayerReportsCount"

            :maxAlienReports="maxAlienReports"
            :maxPlayerReports="maxPlayerReports"

            @setFilters="setFiltersFlag = true"
        />
        <FilterForm 
            v-if="setFiltersFlag && studentsType == 'club_players'" 
            :teams="filterClubPlayerTeams"
            :scouts="uniqueScouts"
            @applyFilters="processFilters" 
            @updateFilters="updateFilters"
            @close="setFiltersFlag = false" 
        />
        <FilterForm 
            v-if="setFiltersFlag && studentsType == 'other_players'" 
            :teams="alienUniqueTeams"
            :scouts="alienUniqueScouts"
            @applyFilters="processFilters" 
            @updateFilters="updateFilters"
            @close="setFiltersFlag = false"
        />

        <ScoutReportsTable v-if="studentsType == 'club_players'" :allScoutReports="allScoutReports" @deleteReport="deleteReport" />
        <ScoutAlienReportsTable v-else :allScoutReports="allScoutAlienReports" @deleteReport="deleteAlienReport" />
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getPlayerScoutReportsByStudentId, getAllGroups, getAlienUniqueScouts, getUniqueTeams, getUniqueScouts, getCountAlienPlayerScoutReports, getCountPlayerScoutReports, deleteAlienPlayerScoutReportById, getAlienPlayerScoutReports, getPlayerScoutReports, deletePlayerScoutReportById } from '@/services/apiRequests';
import { useGlobalStore } from '@/storage/globalStorage'

import MenuBar from './components/MenuBar.vue';
import ScoutReportsTable from './components/ScoutReportsTable.vue';
import StudentSelectionForm from './components/StudentSelectionForm.vue';
import ScoutAlienReportsTable from './components/ScoutAlienReportsTable.vue';
import FilterForm from './components/FiltersForm.vue';

export default {
    name: 'ScoutReports',  

    setup() {
        const router = useRouter();
        const route = useRoute();

        const store = useGlobalStore();

        const setFiltersFlag = ref(false);
        const filters = ref({
            age: null,
            potential: null,
            scout: null,
            team: null,
            position: null,
            minRating: null,
            maxRating: null
        });

        const filterClubPlayerTeams = ref([]);
        const uniqueScouts = ref([]);

        const alienUniqueScouts = ref([]);
        const alienUniqueTeams = ref([]);

        const processFilters = async (newFilters) => {
            filters.value = newFilters;
            setFiltersFlag.value = false;

            await getAllReports();
        }

        const updateFilters = async (newFilters) => {
            filters.value = newFilters;
            await getAllReports();
        }

        const allScoutReports = ref([]);
        const allScoutAlienReports = ref([]);

        const studentsType = ref(route.query.type || 'club_players');

        const setClubPlayers = () => {
            studentsType.value = 'club_players';
            router.push({ query: { type: 'club_players' } });
        }

        const setOtherPlayers = () => {
            studentsType.value = 'other_players';
            router.push({ query: { type: 'other_players' } });
        }

        const page = ref(1);
        const maxPage = ref(1);
        const searchQuery = ref('');

        const clubPlayerReportsCount = ref(0);
        const alienPlayerReportsCount = ref(0);

        const maxAlienReports = ref(0);
        const maxPlayerReports = ref(0);

        const getAllReports = async () => {
            if (studentsType.value == 'club_players') {
                allScoutReports.value = await getPlayerScoutReports(20, (page.value - 1) * 20, searchQuery.value, filters.value);
            } else {
                allScoutAlienReports.value = await getAlienPlayerScoutReports(20, (page.value - 1) * 20, searchQuery.value, filters.value);
            }
        }

        watch(studentsType, async (newValue) => {
            await getAllReports();
        });

        watch(page, async (newValue) => {
            await getAllReports();
        });

        watch(searchQuery, async (newValue) => {
            await getAllReports();
        });

        onMounted(async () => {
            filters.value = {
                age: route.query.age || null,
                potential: route.query.potential || null,
                scout: route.query.scout || null,
                team: route.query.team || null,
                position: route.query.position || null,
                minRating: route.query.minRating || null,
                maxRating: route.query.maxRating || null
            }

            await getAllReports();
            
            clubPlayerReportsCount.value = await getCountPlayerScoutReports();
            alienPlayerReportsCount.value = await getCountAlienPlayerScoutReports();    

            filterClubPlayerTeams.value = (await getAllGroups()).data;
            filterClubPlayerTeams.value = filterClubPlayerTeams.value.map(group => group.name);
            filterClubPlayerTeams.value.unshift('не вибрано');

            uniqueScouts.value = await getUniqueScouts();
            uniqueScouts.value = uniqueScouts.value.map(scout => scout.scout_name + ' ' + scout.scout_surname);
            uniqueScouts.value.unshift('не вибрано');

            alienUniqueScouts.value = await getAlienUniqueScouts();
            alienUniqueScouts.value = alienUniqueScouts.value.map(scout => scout.scout_name + ' ' + scout.scout_surname);
            alienUniqueScouts.value.unshift('не вибрано');

            alienUniqueTeams.value = await getUniqueTeams();
            alienUniqueTeams.value = alienUniqueTeams.value.map(team => team.team);
            alienUniqueTeams.value.unshift('не вибрано');

            maxPlayerReports.value = Math.ceil(clubPlayerReportsCount.value / 20);
            maxAlienReports.value = Math.ceil(alienPlayerReportsCount.value / 20);
        });

        const deleteReport = async (report) => {
            await deletePlayerScoutReportById(report.report_id);
            allScoutReports.value = await getAllReports();
        }

        const deleteAlienReport = async (report) => {
            await deleteAlienPlayerScoutReportById(report.report_id);
            console.log('report_id', report.report_id);
            allScoutAlienReports.value = await getAllReports();
        }

        return {
            allScoutReports,
            deleteReport,
            studentsType,
            allScoutAlienReports,
            deleteAlienReport,
            page,
            maxPage,
            searchQuery,
            clubPlayerReportsCount,
            alienPlayerReportsCount,
            maxAlienReports,
            maxPlayerReports,
            setClubPlayers,
            setOtherPlayers,
            setFiltersFlag,
            processFilters,
            filterClubPlayerTeams,
            uniqueScouts,
            alienUniqueScouts,
            alienUniqueTeams,
            updateFilters,
            store
        }
    },
    
    components: {
        MenuBar,
        ScoutReportsTable,
        StudentSelectionForm,
        ScoutAlienReportsTable,
        FilterForm
    }
}
</script>

<style>
    
</style>