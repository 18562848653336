<template>
    <div class="mt-[30px]">
        <div class="w-full text-center uppercase text-[16px]">Лікарі</div>

        <div class="flex overflow-x-auto mt-[32px]">
            <div v-for="scout in allDoctors" class="w-[275px] flex flex-col items-center">
                <div class="text-[15px] uppercase text-[#C59014]">{{ scout.name + ' ' + scout.surname }}</div>
                <div class="mt-[25px]">
                    <AvatarContainer :src="apiURL + 'static/' + scout.photo" :label="'tt'"/>
                </div>
                <div class="lowercase text-[14px] mt-[22px]">{{ scout.job_title }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'

export default {
    name: 'DoctorList',

    props: {
        allDoctors: {
            type: Array,
            required: true
        }
    },

    setup(props) {
        const apiURL = ref(process.env.VUE_APP_API_URL)

        return {
            apiURL
        }
    }
}
</script>

<style>
    
</style>