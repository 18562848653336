<template>
    <div>
        <div @click="$emit('close')" class="bg-[#BD9135] ml-[90px] mb-[20px] flex justify-center items-center w-[228px] h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
            <span>Назад</span>
        </div>
        <div class="text-[#C59014] text-center mb-[20px] font-bold">Тонке налаштування розподілення балів</div>
        <div class="charts">
            <div v-if="!moreIsBetter">
            <canvas id="lessIsBetterChart"></canvas>
            </div>
            <div v-else>
            <canvas id="moreIsBetterChart"></canvas>
            </div>
        </div>

        <div>
            <div class="flex justify-center mt-[20px]">
                <div @click="updateChartNewData" class="text-[12px] cursor-pointer select-none bg-[#474849] text-[#BD9135] px-[10px] py-[6px] border-[1px] border-[#8C8C8C] rounded-md">
                    Оновити графік
                </div>
            </div>
        </div>
        <div class="w-full grid grid-cols-3 gap-9 mt-[20px]">
            <div class="w-[150px] h-[27px] mx-auto">
                <div>
                    <div class="text-[#C59014] text-[11px] text-center mb-[5px]">Межа чудових результатів</div>
                </div>
                <input  
                    v-model="bestBoundary"
                    type="text" 
                    placeholder="0.00" 
                    class="bg-[#1e1e1e] text-center border-[#474849] px-[10px] border-[1px] mt-[10px] h-full relative bottom-[10px] outline-none text-white  text-[14px] w-[100%] py-[5px] rounded-[6px]"
                >
            </div>
            <div class="w-[150px] h-[27px] mx-auto">
                <div>
                    <div class="text-[#C59014] text-[11px] text-center mb-[5px]">Відхилення від гіршої межі</div>
                </div>
                <input  
                    v-model="minValue"
                    type="text" 
                    placeholder="0.00" 
                    :disabled="typeFullRange === 'auto'"
                    class="bg-[#1e1e1e] text-center disabled:opacity-60 border-[#474849] px-[10px] border-[1px] mt-[10px] h-full relative bottom-[10px] outline-none text-white  text-[14px] w-[100%] py-[5px] rounded-[6px]"
                >
            </div>
            <div class="w-[150px] h-[27px] mx-auto">
                <div class="text-[#C59014] text-[11px] text-center mb-[5px]">Тип шкали</div>
                <select v-model="chartType" class="bg-[#1e1e1e] text-center border-[#474849] px-[10px] border-[1px] mt-[10px] h-full relative bottom-[10px] outline-none text-white  text-[14px] w-[100%] py-[5px] rounded-[6px]">
                    <option value="moreIsBetter">Більше-краще</option>
                    <option value="lessIsBetter">Менше-краще</option>
                </select>
            </div>
            <div class="w-[150px] h-[27px] mx-auto">
                <div>
                    <div class="text-[#C59014] text-[11px] text-center mb-[5px]">Межа поганих результатів</div>
                </div>
                <input  
                    v-model="worstBoundary"
                    type="text" 
                    placeholder="0.00" 
                    class="bg-[#1e1e1e] text-center border-[#474849] px-[10px] border-[1px] mt-[10px] h-full relative bottom-[10px] outline-none text-white  text-[14px] w-[100%] py-[5px] rounded-[6px]"
                >
            </div>
            <div class="w-[150px] h-[27px] mx-auto">
                <div>
                    <div class="text-[#C59014] text-[11px] text-center mb-[5px]">Відхилення від кращої межі</div>
                </div>
                <input  
                    v-model="maxValue"
                    type="text" 
                    placeholder="0.00" 
                    :disabled="typeFullRange === 'auto'"
                    class="bg-[#1e1e1e] text-center disabled:opacity-60 border-[#474849] px-[10px] border-[1px] mt-[10px] h-full relative bottom-[10px] outline-none text-white  text-[14px] w-[100%] py-[5px] rounded-[6px]"
                >
            </div>

            <div class="w-[150px] h-[27px] mx-auto">
                <div class="text-[#C59014] text-[11px] text-center mb-[5px]">Задання мінімальних меж</div>
                <select v-model="typeFullRange" class="bg-[#1e1e1e] text-center border-[#474849] px-[10px] border-[1px] mt-[10px] h-full relative bottom-[10px] outline-none text-white  text-[14px] w-[100%] py-[5px] rounded-[6px]">
                    <option value="auto">Автоматично</option>
                    <option value="manual">Вручну</option>
                </select>
            </div>

            <div class="w-[150px] h-[27px] mx-auto">
                <div>
                    <div class="text-[#C59014] text-[11px] text-center mb-[5px]">Відхилення</div>
                </div>
                <input  
                    v-model="disp"
                    type="text" 
                    placeholder="0.00" 
                    :disabled="typeFullRange != 'auto'"
                    class="bg-[#1e1e1e] text-center border-[#474849] disabled:opacity-60 px-[10px] border-[1px] mt-[10px] h-full relative bottom-[10px] outline-none text-white  text-[14px] w-[100%] py-[5px] rounded-[6px]"
                >
            </div>

      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent, onMounted } from "vue";
  import {
    Chart,
    LineController,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Title,
    Tooltip,
    Legend,
  } from "chart.js";
  import annotationPlugin from "chartjs-plugin-annotation";
  import { ref, watch } from "vue";
import { maxValue, minValue } from "@vuelidate/validators";
  
  // Регистрация компонентов Chart.js и плагина аннотаций
  Chart.register(
    LineController,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Title,
    Tooltip,
    Legend,
    annotationPlugin
  );
  
  export default defineComponent({
    name: "RatingCharts",

    props: {
        moreIsBetter: {
            type: Boolean,
            required: true,
            default: true,
        },
        bestBoundary: {
            type: Number,
            required: true,
            default: 80,
        },
        worstBoundary: {
            type: Number,
            required: true,
            default: 120,
        },
        disp: {
            type: Number,
            required: true,
            default: 0.25,
        },
        minValue: {
            type: Number,
            required: true,
            default: 0,
        },
        maxValue: {
            type: Number,
            required: true,
            default: 0,
        },
    },

    setup(props, { emit }) {
        const bestBoundary = ref(props.moreIsBetter ? +props.worstBoundary : +props.bestBoundary || 0);
        const worstBoundary = ref(props.moreIsBetter ? +props.bestBoundary : +props.worstBoundary || 0);

        const cashedBestBoundary = ref(props.moreIsBetter ? +props.worstBoundary : +props.bestBoundary  || 0);
        const cashedWorstBoundary = ref(props.moreIsBetter ? +props.bestBoundary : +props.worstBoundary || 0);

        const disp = ref(+props.disp);
        const moreIsBetter = ref(props.moreIsBetter);

        const minValue = ref(+props.minValue ? +props.minValue : bestBoundary.value - disp.value * (worstBoundary.value - bestBoundary.value));
        const maxValue = ref(+props.maxValue ? +props.maxValue : worstBoundary.value + disp.value * (worstBoundary.value - bestBoundary.value));

        const chartKey = ref(new Date().getTime());
        const firstUpdate = ref(true);

        const typeFullRange = ref('auto');
        const chartType = ref(props.moreIsBetter ? 'moreIsBetter' : 'lessIsBetter');

        watch(chartType, (value) => {
            if (value === 'lessIsBetter') {
                moreIsBetter.value = false;
            } else {
                moreIsBetter.value = true;
            }
        });

        const bb = bestBoundary.value;
        const wb = worstBoundary.value;
        if (moreIsBetter.value) {
            bestBoundary.value = bb;
            worstBoundary.value = wb;
        } else {
            bestBoundary.value = wb;
            worstBoundary.value = bb;
        }
  
        const calculateScoreUniversal = (
            value,
            bestBoundaryT,
            worstBoundaryT,
            moreIsBetter = false
        ) => {
            if (!moreIsBetter) {
                if (value < bestBoundary.value) return 10;
                else if (value > worstBoundary.value) return 0;
                else
                    return (
                        10 - ((value - bestBoundary.value) / (worstBoundary.value - bestBoundary.value)) * 10
                    );
            } else {
                if (value < bestBoundary.value) return 0;
                else if (value > worstBoundary.value) return 10;
                else
                    return (
                        ((value - bestBoundary.value) / (worstBoundary.value - bestBoundary.value)) * 10
                    );
            }
        };
  
        let xValues = Array.from({ length: 
            100 * (worstBoundary.value > bestBoundary.value ? worstBoundary.value + minValue.value : bestBoundary.value + maxValue.value)
         }, (_, i) => (i + minValue.value) / 100);
        
        let yLessIsBetter = xValues.map((x) =>
            calculateScoreUniversal(x, bestBoundary.value, worstBoundary.value, false)
        );
        let yMoreIsBetter = xValues.map((x) =>
            calculateScoreUniversal(x, bestBoundary.value, worstBoundary.value, true)
        );
  
        let centerX = (bestBoundary.value + worstBoundary.value) / 2; // X-координата для точки в центре
  
        const initializeCharts = () => {
            console.log("props.bestBoundary", props.bestBoundary);
            console.log("props.worstBoundary", props.worstBoundary);
            console.log("moreIsBetter", moreIsBetter.value);

            // Удаление старых графиков
            // if (!firstUpdate.value) {
            Chart.getChart("lessIsBetterChart")?.destroy();
            Chart.getChart("moreIsBetterChart")?.destroy();

            if (typeFullRange.value === 'auto') {
                minValue.value = bestBoundary.value - disp.value * (worstBoundary.value - bestBoundary.value);
                maxValue.value = worstBoundary.value + disp.value * (worstBoundary.value - bestBoundary.value);

                cashedBestBoundary.value = bestBoundary.value;
                cashedWorstBoundary.value = worstBoundary.value;

                if (moreIsBetter.value) {
                    bestBoundary.value = maxValue.value;
                    worstBoundary.value = minValue.value;
                } else {
                    bestBoundary.value = minValue.value;
                    worstBoundary.value = maxValue.value;
                }
                // bestBoundary.value = minValue.value;
                // worstBoundary.value = maxValue.value;
            } else {
                cashedBestBoundary.value = bestBoundary.value;
                cashedWorstBoundary.value = worstBoundary.value;

                if (moreIsBetter.value) {
                    bestBoundary.value = maxValue.value;
                    worstBoundary.value = minValue.value;
                } else {
                    bestBoundary.value = minValue.value;
                    worstBoundary.value = maxValue.value;
                }
                // bestBoundary.value = minValue.value;
                // worstBoundary.value = maxValue.value;
            }

            console.log("bestBoundary.value", bestBoundary.value);
            console.log("worstBoundary.value", worstBoundary.value);


            xValues = Array.from({ length: 
                100 * (worstBoundary.value > bestBoundary.value ? worstBoundary.value + minValue.value : bestBoundary.value + maxValue.value)
            }, (_, i) => (i + minValue.value) / 100);

            yLessIsBetter = xValues.map((x) =>
                calculateScoreUniversal(x, bestBoundary.value, worstBoundary.value, false)
            );

            yMoreIsBetter = xValues.map((x) =>
                calculateScoreUniversal(x, bestBoundary.value, worstBoundary.value, true)
            );

            console.log("yLessIsBetter", yLessIsBetter);

            centerX = (bestBoundary.value + worstBoundary.value) / 2; // X-координата для точки в центре

            // }

            // График "Меньше — лучше"
            if (!moreIsBetter.value) {
                console.log("lessIsBetterChart");
                new Chart(document.getElementById("lessIsBetterChart"), {
                type: "line",
                data: {
                    labels: xValues,
                    datasets: [
                        {
                            label: "Рейтинговий бал:",
                            data: yLessIsBetter,
                            borderColor: "#C59014",
                            tension: 0.4,
                        },
                        {
                            label: "Рейтинговий бал:",
                            data: [{ x: centerX, y: calculateScoreUniversal(centerX, bestBoundary.value, worstBoundary.value, false) }],
                            borderColor: "#C59014",
                            backgroundColor: "#C59014",
                            pointRadius: 8,
                            pointHoverRadius: 7,
                            showLine: false,
                        },
                    ],
                },
                options: {
                    plugins: {
                    legend: { display: false },
                    annotation: {
                        annotations: [
                            {
                                type: "line",
                                xMin: cashedBestBoundary.value,
                                xMax: cashedBestBoundary.value,
                                borderColor: "green",
                                borderWidth: 2,
                                label: {
                                    content: "Межа кращих",
                                    enabled: true,
                                    position: "top",
                                    font: { size: 12 },
                                    backgroundColor: "white",
                                },
                            },
                            {
                                type: "line",
                                xMin: cashedWorstBoundary.value,
                                xMax: cashedWorstBoundary.value,
                                borderColor: "red",
                                borderWidth: 2,
                                label: {
                                    content: "Межа гірших",
                                    enabled: true,
                                    position: "top",
                                    font: { size: 12 },
                                    backgroundColor: "white",
                                },
                            },
                            {
                                type: "line",
                                xMin: minValue.value,
                                xMax: minValue.value,
                                borderColor: "gray",
                                borderWidth: 1,
                                borderDash: [5, 5],
                                label: {
                                    content: "Мінімальна межа",
                                    enabled: true,
                                    position: "top",
                                    font: { size: 12 },
                                    backgroundColor: "white",
                                },
                            },
                            {
                                type: "line",
                                xMin: maxValue.value,
                                xMax: maxValue.value,
                                borderColor: "gray",
                                borderWidth: 1,
                                borderDash: [5, 5],
                                label: {
                                    content: "Максимальна межа",
                                    enabled: true,
                                    position: "top",
                                    font: { size: 12 },
                                    backgroundColor: "white",
                                },
                            },
                            {
                                type: "line",
                                xMin: centerX,
                                xMax: centerX,
                                yMin: 0,
                                yMax: calculateScoreUniversal(centerX, bestBoundary.value, worstBoundary.value, false),
                                borderColor: "gray",
                                borderWidth: 1,
                                borderDash: [5, 5],
                                label: {
                                    content: "Межа центру",
                                    enabled: true,
                                    position: "top",
                                    font: { size: 12 },
                                    backgroundColor: "white",
                                },
                            },
                        ],
                    },
                    },
                    scales: {
                        x: {
                            type: "linear",
                            title: { display: true, text: "Результат тестування" },
                        },
                        y: {
                            type: "linear",
                            title: { display: true, text: "Рейтинговий бал" },
                        },
                    },
                },
                });
            } else {
                console.log("moreIsBetterChart");
                // График "Больше — лучше"
                new Chart(document.getElementById("moreIsBetterChart"), {
                type: "line",
                data: {
                    labels: xValues,
                    datasets: [
                        {
                            label: "Рейтинговий бал:",
                            data: yMoreIsBetter,
                            borderColor: "orange",
                            tension: 0.4,
                        },
                        {
                            label: "Рейтинговий бал:",
                            data: [{ x: centerX, y: calculateScoreUniversal(centerX, bestBoundary.value, worstBoundary.value, true) }],
                            borderColor: "orange",
                            backgroundColor: "orange",
                            pointRadius: 8,
                            pointHoverRadius: 7,
                            showLine: false,
                        },
                    ],
                },
                options: {
                    plugins: {
                    legend: { display: false },
                    annotation: {
                        annotations: [
                            {
                                type: "line",
                                xMin: cashedWorstBoundary.value,
                                xMax: cashedWorstBoundary.value,
                                borderColor: "red",
                                borderWidth: 2,
                                label: {
                                    content: "Межа гірших",
                                    enabled: true,
                                    position: "top",
                                    font: { size: 12 },
                                    backgroundColor: "white",
                                },
                            },
                            {
                                type: "line",
                                xMin: cashedBestBoundary.value,
                                xMax: cashedBestBoundary.value,
                                borderColor: "green",
                                borderWidth: 2,
                                label: {
                                    content: "Межа кращих",
                                    enabled: true,
                                    position: "top",
                                    font: { size: 12 },
                                    backgroundColor: "white",
                                },
                            },
                            {
                                type: "line",
                                xMin: minValue.value,
                                xMax: minValue.value,
                                borderColor: "gray",
                                borderWidth: 1,
                                borderDash: [5, 5],
                                label: {
                                    content: "Мінімальна межа",
                                    enabled: true,
                                    position: "top",
                                    font: { size: 12 },
                                    backgroundColor: "white",
                                },
                            },
                            {
                                type: "line",
                                xMin: maxValue.value,
                                xMax: maxValue.value,
                                borderColor: "gray",
                                borderWidth: 1,
                                borderDash: [5, 5],
                                label: {
                                    content: "Максимальна межа",
                                    enabled: true,
                                    position: "top",
                                    font: { size: 12 },
                                    backgroundColor: "white",
                                },
                            },
                            {
                                type: "line",
                                xMin: centerX,
                                xMax: centerX,
                                yMin: 0,
                                yMax: calculateScoreUniversal(centerX, bestBoundary.value, worstBoundary.value, true),
                                borderColor: "gray",
                                borderWidth: 1,
                                borderDash: [5, 5],
                                label: {
                                    content: "Центральна межа",
                                    enabled: true,
                                    position: "top",
                                    font: { size: 12 },
                                    backgroundColor: "white",
                                },
                            },
                        ],
                    },
                    },
                    scales: {
                        x: {
                            type: "linear",
                            title: { display: true, text: "Результат тестування" },
                        },
                        y: {
                            type: "linear",
                            title: { display: true, text: "Рейтинговий бал" },
                        },
                    },
                },
                });
            }

            bestBoundary.value = cashedBestBoundary.value;
            worstBoundary.value = cashedWorstBoundary.value;
            
            firstUpdate.value = false;
        };

        const updateChartNewData = () => {
            bestBoundary.value = +bestBoundary.value;
            worstBoundary.value = +worstBoundary.value;
            disp.value = +disp.value;
            minValue.value = +minValue.value;
            maxValue.value = +maxValue.value;


            

            initializeCharts();
            chartKey.value = new Date().getTime();
        };
    
        onMounted(() => {
            console.log("onMounted", props.moreIsBetter);

            bestBoundary.value = +bestBoundary.value;
            worstBoundary.value = +worstBoundary.value;
            disp.value = +disp.value;
            minValue.value = +minValue.value;
            maxValue.value = +maxValue.value;


            emit("update", {
                disp: disp.value,
                minValue: minValue.value,
                maxValue: maxValue.value,
                moreIsBetter: moreIsBetter.value,
                typeFullRange: typeFullRange.value,
                chartType: chartType.value,
            });


            initializeCharts();
        });
    
        return {
            updateChartNewData,
            bestBoundary,
            worstBoundary,
            disp,
            moreIsBetter,
            minValue,
            maxValue,
            typeFullRange,
            chartType,
            chartKey
        };
    },
  });
  </script>
  
  
  
  
<style scoped>
  .charts {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
  }
  canvas {
    max-width: 100%;
    height: 400px;
  }
</style>
  