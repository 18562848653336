<template>
    <div class="px-[60px]">
        <div class="flex justify-end mb-[20px]">
            <IconButton :src="arrow_icon" @click="$router.go(-1)" class="mr-[10px]"/>
            <ButtonComponent @click="saveTeam">
                Зберегти дошку
            </ButtonComponent>
        </div>
        <div class="flex gap-[30px] h-[100vh] justify-center">
            <div class="w-[300px] mt-[54px]">
                <div class="mb-[40px]">
                    <SelectComponent
                        class="start-player-select__scheme-and-field-part--select"
                        :options="allScheme" 
                        :label="'оберіть схему гри'"
                        @change="e => scheme = e"
                        :default="scheme"
                    />
                </div>
                <div v-for="amplua in Object.keys(playerList)" class="w-full py-[5px]">
                    <div class="flex items-center">
                        <div class="text-[#C59014] font-bold mr-[20px]">{{ amplua }}</div>
                        <div class="h-[2px] w-full bg-[#C59014]"></div>
                    </div>
                    <div class="pl-[35px] mt-[10px]">
                        <div v-for="player in playerList[amplua]" class="flex justify-between items-center mb-[15px]">
                            <div class="flex items-center cursor-pointer select-none" @click="selectPlayer(player)">
                                <div>
                                    <AvatarContainer v-if="player.photo" :label="'ТА'" :small="true" :src="apiURL + 'static/' + player.photo" />
                                    <div v-else class="avatar-container avatar-container--small">
                                        <div class="text-[#BD9135]">
                                            {{ player.name[0] + player.surname[0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="text-[14px] ml-[10px]">
                                    {{ player.name + ' ' + player.surname }}
                                </div>
                            </div>
                            <div class="flex gap-[10px]">
                                <svg v-if="player.onField" @click="player.onField = false" class="cursor-pointer" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="13" cy="13" r="13" fill="#DA4167"/>
                                    <line x1="5" y1="13" x2="21" y2="13" stroke="white" stroke-width="2"/>
                                </svg>
                                <svg v-else @click="player.onField = true" class="cursor-pointer" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="13" cy="13" r="13" fill="#339989"/>
                                    <line x1="13" y1="5" x2="13" y2="21" stroke="white" stroke-width="2"/>
                                    <line x1="5" y1="13" x2="21" y2="13" stroke="white" stroke-width="2"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>     
            </div>
            <div>
                <div>
                    <input v-model="teamName" type="text" placeholder="Назва команди" class="font-bold text-[20px] text-center w-full mb-[5px]">
                    <input v-model="teamDescription" type="text" placeholder="Коментар до команди" class="text-[14px] text-center w-full mb-[15px]">
                </div>
                <div class="relative select-none">
                    <img src="@/assets/shadow_team_field.svg" class="h-[880px] cursor-pointer" >
                    <div class="absolute h-full w-full top-0 left-0">
                        <div v-if="fourthLayer" class="flex gap-[5px] justify-center absolute top-[165px] w-full">
                            <div v-for="id in fourthLayer" :id="'НАП' + id" :key="id" id="НАП" class="w-[123px] h-[70px] overflow-y-auto bg-white bg-opacity-30 flex flex-col items-end justify-end">
                                <draggable 
                                    class="overflow-y-auto mini-scrollbar w-full h-full"
                                    v-model="playerListOnField['НАП' + id]" 
                                    group="people" 
                                    @start="drag=true" 
                                    @end="drag=false" 
                                    item-key="id"
                                >
                                    <template #item="{element}">
                                        <div class="flex mb-[2px] select-none cursor-pointer">
                                            <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">{{ element.name[0] + '. ' + element.surname }}</div>
                                            <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">{{ element.player_number || element.playerNumber }}</div>
                                        </div>
                                    </template>
                                </draggable>
                            </div>
                        </div>

                        <div v-if="thirdLayer" class="flex gap-[5px] justify-center absolute top-[378px] w-full">
                            <div v-for="id in thirdLayer" :id="'ПОЛ1' + id" :key="id" id="ПОЛ1" class="w-[123px] h-[70px] overflow-y-auto bg-white bg-opacity-30 flex flex-col items-end justify-end">
                                <draggable 
                                    class="overflow-y-auto mini-scrollbar w-full h-full"
                                    v-model="playerListOnField['ПОЛ1' + id]" 
                                    group="people" 
                                    @start="drag=true" 
                                    @end="drag=false" 
                                    item-key="id"
                                >
                                    <template #item="{element}">
                                        <div class="flex mb-[2px] select-none cursor-pointer">
                                            <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">{{ element.name[0] + '. ' + element.surname }}</div>
                                            <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">{{ element.player_number || element.playerNumber }}</div>
                                        </div>
                                    </template>
                                </draggable>
                            </div>
                        </div>

                        <div v-if="secondLayer" class="flex gap-[5px] justify-center absolute top-[505px] w-full">
                            <div v-for="id in secondLayer" :id="'ПОЛ' + id" :key="id" id="ПОЛ" class="w-[123px] h-[70px] overflow-y-auto bg-white bg-opacity-30 flex flex-col items-end justify-end">
                                <draggable 
                                    class="overflow-y-auto mini-scrollbar w-full h-full"
                                    v-model="playerListOnField['ПОЛ' + id]" 
                                    group="people" 
                                    @start="drag=true" 
                                    @end="drag=false" 
                                    item-key="id"
                                >
                                    <template #item="{element}">
                                        <div class="flex mb-[2px] select-none cursor-pointer">
                                            <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">{{ element.name[0] + '. ' + element.surname }}</div>
                                            <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">{{ element.player_number || element.playerNumber }}</div>
                                        </div>
                                    </template>
                                </draggable>
                            </div>
                        </div>

                        <div v-if="firstLayer" class="flex gap-[5px] justify-center absolute top-[630px] w-full">
                            <div v-for="id in firstLayer" :id="'ЗАЩ' + id" :key="id" class="w-[123px] h-[70px] overflow-y-auto bg-white bg-opacity-30 flex flex-col items-end justify-end">
                                <draggable 
                                    class="overflow-y-auto mini-scrollbar w-full h-full"
                                    v-model="playerListOnField['ЗАЩ' + id]" 
                                    group="people" 
                                    @start="drag=true" 
                                    @end="drag=false" 
                                    item-key="id"
                                >
                                    <template #item="{element}">
                                        <div class="flex mb-[2px] select-none cursor-pointer">
                                            <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">{{ element.name[0] + '. ' + element.surname }}</div>
                                            <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">{{ element.player_number || element.playerNumber }}</div>
                                        </div>
                                    </template>
                                </draggable>
                            </div>
                        </div>

                        <!-- <div id="SW" class="w-max absolute top-[719px] left-[28%]">
                            <div class="flex mb-[2px]">
                                <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">Я. Яремчук</div>
                                <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">51</div>
                            </div>
                            <div class="flex mb-[2px]">
                                <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">Я. Яремчук</div>
                                <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">51</div>
                            </div>
                            <div class="flex">
                                <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">Я. Яремчук</div>
                                <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">51</div>
                            </div>
                        </div> -->
                        <div id="GK" class="w-[123px] h-[70px] overflow-y-auto absolute bg-white bg-opacity-30 flex flex-col items-end justify-end top-[747px] left-[50%] translate-x-[-50%]">
                            <draggable 
                                class="overflow-y-auto mini-scrollbar w-full h-full"
                                v-model="playerListOnField['ВР']" 
                                group="people" 
                                @start="drag=true" 
                                @end="drag=false" 
                                item-key="id"
                            >
                                <template #item="{element}">
                                    <div class="flex mb-[2px] select-none cursor-pointer">
                                        <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">{{ element.name[0] + '. ' + element.surname }}</div>
                                        <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">{{ element.player_number || element.playerNumber }}</div>
                                    </div>
                                </template>
                            </draggable>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex justify-center mt-[67px] w-[260px]">
                <div v-if="isPlayerSelected" class="card">
                    <div class="flex justify-center">
                        <AvatarContainer v-if="selectedPlayer.photo" :src="apiURL + 'static/' + selectedPlayer.photo"/>
                        <div v-else class="avatar-container" :class="{ 'avatar-container--small': small }">
                            <div class="text-[#BD9135] text-[32px] ">
                                {{ selectedPlayer.name[0] + selectedPlayer.surname[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="text-[21px] truncate font-semibold mt-[10px]">
                        {{ selectedPlayer.name + ' ' + selectedPlayer.surname }}
                    </div>
    
                    <div class="w-full h-[74px] mt-[12px]">
                        <div class="flex justify-between w-full mx-[8px]">
                            <div class="flex  items-center justify-center font-normal">
                                <img src="@/assets/logo.svg" class="w-[30px]">
                            </div>
                            <div class="flex flex-col items-center justify-center">
                                <div class="text-[9px]">група</div>
                                <div class="text-[12px] font-semibold">
                                    <span v-if="selectedPlayer.group_name?.length > 0 || selectedPlayer.teamName?.length > 0">{{ selectedPlayer.group_name || selectedPlayer.teamName }}</span>
                                    <span v-else class="opacity-25">—</span>
                                </div>    
                            </div>
                            <div class="flex flex-col items-center justify-center  font-normal">
                                <div class="text-[9px]">вік</div>
                                <div class="text-[12px] font-semibold">
                                    <span v-if="selectedPlayer.age > 0 || countAge(selectedPlayer.birthdate) > 0">{{ selectedPlayer.age || countAge(selectedPlayer.birthdate) }}</span>
                                    <span v-else class="opacity-25">—</span>
                                </div>    
                            </div>
                            <div class="flex flex-col items-center justify-center  font-normal">
                                <div class="text-[9px]">амплуа</div>
                                <div class="text-[12px] font-semibold">
                                    <span v-if="selectedPlayer.amplua?.length > 0">{{ selectedPlayer.amplua }}</span>
                                    <span v-else class="opacity-25">—</span>
                                </div>    
                            </div>
                            <div class="flex flex-col items-center justify-center  font-normal">
                                <div class="text-[9px]">номер</div>
                                <div class="text-[12px] font-semibold">
                                    <span v-if="selectedPlayer.player_number?.length > 0 || selectedPlayer.playerNumber?.length > 0">{{ selectedPlayer.player_number || selectedPlayer.playerNumber }}</span>
                                    <span v-else class="opacity-25">—</span>
                                </div>    
                            </div>
                            <div class="flex  items-center justify-center font-normal">
                                <img src="@/assets/icons/ua_flag.svg" class="w-[20px] ml-[5px]">
                            </div>
                        </div>
                    </div>
    
                    <div class="relative top-[-39px] mx-auto">
                        <RadarChart :key="new Date().getTime()" :indicators="getCurrentScoreSettingsForAmplua(selectedPlayer.amplua)" :chartData="selectedPlayer.average_score" :max="10" :chart-size="{ width: '230px', height: '200px' }" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { addShadowTeam, getAdmin } from "@/services/apiRequests";
import { useGlobalStore } from "@/storage/globalStorage";

import draggable from 'vuedraggable'

import arrow_icon from "@/assets/refactored_assets/img/header/arrow.svg";

export default {
    name: 'CreateShadowTeam',

    setup() {
        const apiURL = process.env.VUE_APP_API_URL

        const route = useRoute()
        const router = useRouter()

        const store = useGlobalStore()

        const allScheme = [ '1-3-4-3', '1-3-4-2-1', '1-3-4-1-2', '1-5-3-2', '1-5-4-1', '1-4-4-2', '1-4-4-1-1', '1-4-2-3-1', '1-4-3-2-1', '1-4-3-3', '1-4-1-4-1', '1-4-1-3-2', '1-3-3-3-1', '1-4-5-1', '1-4-4-2 (diamond)']
        const scheme = ref('1-3-4-3')

        const firstLayer = ref(0)
        const secondLayer = ref(0)
        const thirdLayer = ref(0)
        const fourthLayer = ref(0)

        watch(scheme, (newValue) => {
            const layers = newValue.split('-')
            firstLayer.value = +layers[1]
            secondLayer.value = +layers[2]
            thirdLayer.value = +layers[3]
            fourthLayer.value = +layers[4]
        })

        const getCurrentScoreSettingsForAmplua = (amplua) => {
            return amplua == 'ВР' ? Object.values(store.$state.scoringRadarChartSettings.goalkeeper).slice(2).filter(value => value !== null).map(e => ({name: e, max: 10})) : Object.values(store.$state.scoringRadarChartSettings.field_player).slice(2).filter(value => value !== null).map(e => ({name: e, max: 10}))
        }

        const teamName = ref('')
        const teamDescription = ref('')

        const alienPlayers = ref(store.$state.shadowTeamAlienPlayers.map(player => {
            player.type = 'alien'
            return player
        }))
        const clubPlayers = ref(store.$state.shadowTeamClubPlayers.map(player => {
            player.type = 'club'
            return player
        }))

        const currentScout = ref({})

        const myArray = ref([
            {
                id: 1,
                name: 'test1'
            },
            {
                id: 2,
                name: 'test2'
            }
        ])

        const myArray2 = ref([
            {
                id: 3,
                name: 'test3'
            },
            {
                id: 4,
                name: 'test4'
            }
        ])

        const playerList = ref({
            'ВР': [], 
            'ЦЗ': [], 
            'ЛЗ': [], 
            'ПЗ': [], 
            'ЦОП': [], 
            'ЦП': [], 
            'ЦАП': [], 
            'ПП': [], 
            'ЛП': [], 
            'ПВ': [], 
            'ЛВ': [], 
            'НП': []
        })

        const playerListOnField = ref({
            'ВР': [], 
            'ЦЗ': [], 
            'ЛЗ': [], 
            'ПЗ': [], 
            'ЦОП': [], 
            'ЦП': [], 
            'ЦАП': [], 
            'ПП': [], 
            'ЛП': [], 
            'ПВ': [], 
            'ЛВ': [], 
            'НАП0': [],
            'НАП1': [],
            'НАП2': [],
            'НАП3': [],
            'НАП4': [],
            'НАП5': [],

            'ПОЛ0': [],
            'ПОЛ1': [],
            'ПОЛ2': [],
            'ПОЛ3': [],
            'ПОЛ4': [],

            'ПОЛ10': [],
            'ПОЛ11': [],
            'ПОЛ12': [],
            'ПОЛ13': [],
            'ПОЛ14': [],
            'ПОЛ15': [],

            'ЗАЩ0': [],
            'ЗАЩ1': [],
            'ЗАЩ2': [],
            'ЗАЩ3': [],
            'ЗАЩ4': [],
            'ЗАЩ5': [],
        })

        const selectedPlayer = ref(null)
        const isPlayerSelected = ref(false)

        const selectPlayer = (player) => {
            if (selectedPlayer.value && selectedPlayer.value === player) {
                selectedPlayer.value = null
                isPlayerSelected.value = false
                return
            } else {
                selectedPlayer.value = player
                isPlayerSelected.value = true
            }
        }

        const countAge = (date) => {
            const today = new Date()
            const birthDate = new Date(date)
            let age = today.getFullYear() - birthDate.getFullYear()
            const m = today.getMonth() - birthDate.getMonth()
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--
            }
            return age
        }

        const saveTeam = async () => {
            const players = []

            const allPlayersNotOnField = []
            const allPlayersOnField = []

            for (const amplua in playerList.value) {
                playerList.value[amplua].forEach(player => {
                    if (!player.onField) {
                        allPlayersNotOnField.push(player)
                    }
                })

                // playerListOnField.value[amplua].forEach(player => {
                //     player.onFieldAmplua = amplua
                //     allPlayersOnField.push(player)
                // })
            }

            for (const amplua in playerListOnField.value) {
                playerListOnField.value[amplua].forEach(player => {
                    player.onFieldAmplua = amplua
                    allPlayersOnField.push(player)
                })
            }

            players.push(...allPlayersNotOnField, ...allPlayersOnField)

            const data = {
                name: teamName.value,
                comment: teamDescription.value,
                players: players,
                scout_id: currentScout.value.admin_id,   
                scout_name: currentScout.value.admin_name,
                scout_surname: currentScout.value.admin_surname,
                scheme: scheme.value
            }

            await addShadowTeam(data)
            router.push('/shadow-teams')
        }

        watch(playerList, (newValue) => {
            const allCurrentPlayersOnField = []

            for (const amplua in newValue) {
                allCurrentPlayersOnField.push(...newValue[amplua].filter(player => player.onField))
            }

            // for (const amplua in newValue) {
            //     playerListOnField.value[amplua] = allCurrentPlayersOnField.filter(player => player.amplua === amplua)
            // }

            for (const amplua in playerListOnField.value) {
                playerListOnField.value[amplua] = allCurrentPlayersOnField.filter(player => player.amplua === amplua)
                // playerListOnField.value[amplua] = Array.from(playerListOnField.value[amplua])
            }

            playerListOnField.value['ВР'] = allCurrentPlayersOnField
            playerListOnField.value['ВР'] = new Set(playerListOnField.value['ВР'])
            playerListOnField.value['ВР'] = Array.from(playerListOnField.value['ВР'])
        }, { deep: true })

        onMounted(async () => {
            const allPlayers = alienPlayers.value.concat(clubPlayers.value)
            
            allPlayers.forEach(player => {
                player.onField = false
                playerList.value[player.amplua].push(player)
            })

            currentScout.value = (await getAdmin()).data
            console.log(currentScout)

            const layers = scheme.value.split('-')
            firstLayer.value = +layers[1]
            secondLayer.value = +layers[2]
            thirdLayer.value = +layers[3]
            fourthLayer.value = +layers[4]
        })

        return {
            apiURL,
            playerList,
            teamName,
            myArray,
            myArray2,
            playerListOnField,
            selectPlayer,
            isPlayerSelected,
            selectedPlayer,
            countAge,
            teamDescription,
            saveTeam,
            getCurrentScoreSettingsForAmplua,
            drag: false,
            arrow_icon: arrow_icon,
            allScheme,
            scheme,
            firstLayer,
            secondLayer,
            thirdLayer,
            fourthLayer,
            currentScout
        }
    },

    components: {
        draggable
    }
}
</script>

<style>
    .mini-scrollbar::-webkit-scrollbar {
        width: 0px; /* Ширина scrollbar */
    }

    .mini-scrollbar::-webkit-scrollbar-track {
        background-color: transparent; /* Цвет фона трека */
        border-radius: 10px; /* Скругление углов трека */
    }

    .mini-scrollbar::-webkit-scrollbar-thumb {
        background-color: #888; /* Цвет ползунка scrollbar */
        border-radius: 10px; /* Скругление углов ползунка */
        border: 2px solid transparent; /* Отступы для ползунка */
    }

    .mini-scrollbar::-webkit-scrollbar-thumb:hover {
        background-color: #555; /* Цвет ползунка при наведении */
    }

    .card {
        background-color: #47484966;
        border: 1px solid #A9AAAB66;
        border-radius: 8px;
        padding: 20px;
        width: 270px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        text-align: center;
        height: 400px;

        h3 {
            margin-bottom: 10px;
            font-size: 1.5rem;
            color: #333;
        }

        p {
            font-size: 1rem;
            color: #666;
        }
    }
</style>