<template>
    <svg v-if="screenWidth >= 1280" class="field" width="880" height="640" viewBox="0 0 880 640" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <marker
            id="arrow-end"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,5 L5,2.5 Z" fill="black" />
          </marker>
          <marker
            id="arrow-start"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto-start-reverse"
            markerUnits="strokeWidth"
          >
            <path d="M5,2.5 L0,5 L0,0 Z" fill="black" />
          </marker>
        </defs>
        <g>
          <rect x="0.000244141" y="640" width="640" height="880" transform="matrix(0 -1 1 0 -640 640)" fill="#40A769"/>
          <rect x="320" y="612.999" width="586" height="48" transform="rotate(-90 320 612.999)" fill="#4EC283"/>
          <rect x="224" y="612.999" width="586" height="48" transform="rotate(-90 224 612.999)" fill="#4EC283"/>
          <rect x="128" y="612.999" width="586" height="48" transform="rotate(-90 128 612.999)" fill="#4EC283"/>
          <rect x="31.0002" y="613" width="586" height="48" transform="rotate(-90 31.0002 613)" fill="#4EC283"/>
          <rect x="416" y="612.999" width="586" height="48" transform="rotate(-90 416 612.999)" fill="#4EC283"/>
          <rect x="512" y="612.999" width="586" height="48" transform="rotate(-90 512 612.999)" fill="#4EC283"/>
          <rect x="608" y="612.999" width="586" height="48" transform="rotate(-90 608 612.999)" fill="#4EC283"/>
          <rect x="704" y="612.999" width="586" height="48" transform="rotate(-90 704 612.999)" fill="#4EC283"/>
          <rect x="800" y="613" width="586" height="48" transform="rotate(-90 800 613)" fill="#4EC283"/>
        </g>
      </svg>
      <svg v-else-if="screenWidth < 1280 && screenWidth > 1000" width="660" height="480" viewBox="0 0 660 480" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <marker
            id="arrow-end"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,5 L5,2.5 Z" fill="black" />
          </marker>
          <marker
            id="arrow-start"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto-start-reverse"
            markerUnits="strokeWidth"
          >
            <path d="M5,2.5 L0,5 L0,0 Z" fill="black" />
          </marker>
          
        </defs>
        <g>
          <rect x="0.000244141" y="480" width="480" height="660" transform="matrix(0 -1 1 0 -480 480)" fill="#40A769"/>
          <rect x="240.002" y="459.756" width="439.497" height="36.0001" transform="rotate(-90 240.002 459.756)" fill="#4EC283"/>
          <rect x="167.991" y="459.756" width="439.497" height="36.0001" transform="rotate(-90 167.991 459.756)" fill="#4EC283"/>
          <rect x="96.0078" y="459.756" width="439.497" height="36.0001" transform="rotate(-90 96.0078 459.756)" fill="#4EC283"/>
          <rect x="23.2617" y="459.756" width="439.497" height="36.0001" transform="rotate(-90 23.2617 459.756)" fill="#4EC283"/>
          <rect x="312.016" y="459.756" width="439.497" height="36.0001" transform="rotate(-90 312.016 459.756)" fill="#4EC283"/>
          <rect x="384.005" y="459.755" width="439.497" height="36.0001" transform="rotate(-90 384.005 459.755)" fill="#4EC283"/>
          <rect x="455.979" y="459.755" width="439.497" height="36.0001" transform="rotate(-90 455.979 459.755)" fill="#4EC283"/>
          <rect x="527.993" y="459.756" width="439.497" height="36.0001" transform="rotate(-90 527.993 459.756)" fill="#4EC283"/>
          <rect x="600.006" y="459.756" width="439.497" height="36.0001" transform="rotate(-90 600.006 459.756)" fill="#4EC283"/>
          <path d="M329.309 195.611C353.779 195.611 373.636 215.611 373.636 240.306C373.636 265.001 353.779 285 329.309 285C304.838 285 284.981 265.001 284.981 240.306C284.981 215.611 304.838 195.611 329.309 195.611Z" stroke="#EFEFEF" stroke-width="3"/>
          <ellipse cx="329.654" cy="240.347" rx="2.65485" ry="2.63379" transform="rotate(-90 329.654 240.347)" fill="#EFEFEF"/>
          <rect x="329.979" y="458.252" width="436.497" height="304.5" transform="rotate(-90 329.979 458.252)" stroke="#EFEFEF" stroke-width="3"/>
          <mask id="path-14-inside-1_35_350" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M557.248 280.709V200.033C545.623 209.534 538.499 223.075 538.499 240.006C538.498 257.838 545.622 271.414 557.248 280.709Z"/>
          </mask>
          <path d="M557.248 280.709L555.375 283.052L560.248 286.949V280.709H557.248ZM557.248 200.033H560.248V193.706L555.35 197.71L557.248 200.033ZM538.499 240.006L535.499 240.006L538.499 240.006ZM560.248 280.709V200.033H554.248V280.709H560.248ZM541.499 240.006C541.499 224.008 548.192 211.308 559.147 202.356L555.35 197.71C543.053 207.76 535.499 222.141 535.499 240.006L541.499 240.006ZM559.122 278.366C548.22 269.649 541.498 256.941 541.499 240.006L535.499 240.006C535.498 258.734 543.025 273.179 555.375 283.052L559.122 278.366Z" fill="#EFEFEF" mask="url(#path-14-inside-1_35_350)"/>
          <rect x="607.481" y="285" width="89.9994" height="27" transform="rotate(-90 607.481 285)" stroke="#EFEFEF" stroke-width="3"/>
          <rect x="635.255" y="257.994" width="35.9998" height="13.5" transform="rotate(-90 635.255 257.994)" stroke="#EFEFEF" stroke-width="3"/>
          <ellipse cx="574.865" cy="240.344" rx="2.65485" ry="2.63379" transform="rotate(-90 574.865 240.344)" fill="#EFEFEF"/>
          <line y1="-1.5" x2="14.8083" y2="-1.5" transform="matrix(0.70711 0.707103 -0.701449 0.71272 623.999 21.7749)" stroke="#EFEFEF" stroke-width="3"/>
          <line y1="-1.5" x2="14.8083" y2="-1.5" transform="matrix(0.70711 -0.707103 0.701449 0.71272 625.495 459.752)" stroke="#EFEFEF" stroke-width="3"/>
          <rect x="556.503" y="337.497" width="194.999" height="78.0003" transform="rotate(-90 556.503 337.497)" stroke="#EFEFEF" stroke-width="3"/>
          <rect x="329.266" y="21.7506" width="436.497" height="304.5" transform="rotate(90 329.266 21.7506)" stroke="#EFEFEF" stroke-width="3"/>
          <mask id="path-23-inside-2_35_350" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M102.008 199.298L102.008 279.974C113.634 270.473 120.758 256.932 120.758 240.001C120.758 222.169 113.634 208.593 102.008 199.298Z"/>
          </mask>
          <path d="M102.008 199.298L103.881 196.955L99.0081 193.058L99.0081 199.298L102.008 199.298ZM102.008 279.974L99.0081 279.974L99.0081 286.301L103.907 282.297L102.008 279.974ZM120.758 240.001L123.758 240.001L120.758 240.001ZM99.0081 199.298L99.0081 279.974L105.008 279.974L105.008 199.298L99.0081 199.298ZM117.758 240.001C117.758 255.999 111.064 268.699 100.11 277.651L103.907 282.297C116.203 272.247 123.758 257.866 123.758 240.001L117.758 240.001ZM100.135 201.641C111.037 210.357 117.758 223.066 117.758 240.001L123.758 240.001C123.758 221.272 116.231 206.828 103.881 196.955L100.135 201.641Z" fill="#EFEFEF" mask="url(#path-23-inside-2_35_350)"/>
          <rect x="51.7637" y="195.005" width="89.9994" height="27" transform="rotate(90 51.7637 195.005)" stroke="#EFEFEF" stroke-width="3"/>
          <rect x="23.989" y="222.002" width="35.9998" height="13.5" transform="rotate(90 23.989 222.002)" stroke="#EFEFEF" stroke-width="3"/>
          <ellipse cx="84.3799" cy="239.65" rx="2.65485" ry="2.63379" transform="rotate(90 84.3799 239.65)" fill="#EFEFEF"/>
          <line y1="-1.5" x2="14.8083" y2="-1.5" transform="matrix(-0.70711 -0.707103 0.701449 -0.71272 35.2437 458.22)" stroke="#EFEFEF" stroke-width="3"/>
          <line y1="-1.5" x2="14.8083" y2="-1.5" transform="matrix(-0.70711 0.707103 -0.701449 -0.71272 33.7505 20.2551)" stroke="#EFEFEF" stroke-width="3"/>
          <rect x="102.739" y="142.505" width="194.999" height="78.0003" transform="rotate(90 102.739 142.505)" stroke="#EFEFEF" stroke-width="3"/>
        </g>
      </svg>
      <svg v-else-if="screenWidth <= 1000 && screenWidth > 880" width="550" height="400" viewBox="0 0 550 400" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <marker
            id="arrow-end"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,5 L5,2.5 Z" fill="black" />
          </marker>
          <marker
            id="arrow-start"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto-start-reverse"
            markerUnits="strokeWidth"
          >
            <path d="M5,2.5 L0,5 L0,0 Z" fill="black" />
          </marker>
          
        </defs>
        <g>
          <rect x="0.000244141" y="400" width="400" height="550" transform="matrix(0 -1 1 0 -400 400)" fill="#40A769"/>
          <rect x="200.002" y="383.13" width="366.248" height="30" transform="rotate(-90 200.002 383.13)" fill="#4EC283"/>
          <rect x="139.992" y="383.13" width="366.248" height="30" transform="rotate(-90 139.992 383.13)" fill="#4EC283"/>
          <rect x="80.0066" y="383.13" width="366.248" height="30" transform="rotate(-90 80.0066 383.13)" fill="#4EC283"/>
          <rect x="19.385" y="383.13" width="366.248" height="30" transform="rotate(-90 19.385 383.13)" fill="#4EC283"/>
          <rect x="260.013" y="383.13" width="366.248" height="30" transform="rotate(-90 260.013 383.13)" fill="#4EC283"/>
          <rect x="320.004" y="383.13" width="366.248" height="30" transform="rotate(-90 320.004 383.13)" fill="#4EC283"/>
          <rect x="379.983" y="383.13" width="366.248" height="30" transform="rotate(-90 379.983 383.13)" fill="#4EC283"/>
          <rect x="439.994" y="383.13" width="366.248" height="30" transform="rotate(-90 439.994 383.13)" fill="#4EC283"/>
          <rect x="500.005" y="383.13" width="366.248" height="30" transform="rotate(-90 500.005 383.13)" fill="#4EC283"/>
          <path d="M274.424 163.259C294.676 163.259 311.114 179.812 311.114 200.255C311.114 220.698 294.676 237.25 274.424 237.25C254.172 237.25 237.734 220.698 237.734 200.255C237.734 179.812 254.172 163.259 274.424 163.259Z" stroke="#EFEFEF" stroke-width="3"/>
          <ellipse cx="274.712" cy="200.289" rx="2.21238" ry="2.19482" transform="rotate(-90 274.712 200.289)" fill="#EFEFEF"/>
          <rect x="275.232" y="381.627" width="363.248" height="253.25" transform="rotate(-90 275.232 381.627)" stroke="#EFEFEF" stroke-width="3"/>
          <mask id="path-14-inside-1_35_350" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M464.373 233.924V166.694C454.686 174.612 448.749 185.896 448.749 200.005C448.749 214.864 454.685 226.178 464.373 233.924Z"/>
          </mask>
          <path d="M464.373 233.924L462.5 236.267L467.373 240.163V233.924H464.373ZM464.373 166.694H467.373V160.368L462.475 164.371L464.373 166.694ZM448.749 200.005L445.749 200.005L448.749 200.005ZM467.373 233.924V166.694H461.373V233.924H467.373ZM451.749 200.005C451.749 186.829 457.255 176.386 466.272 169.017L462.475 164.371C452.116 172.837 445.749 184.962 445.749 200.005L451.749 200.005ZM466.247 231.58C457.282 224.413 451.749 213.968 451.749 200.005L445.749 200.005C445.749 215.761 452.088 227.942 462.5 236.267L466.247 231.58Z" fill="#EFEFEF" mask="url(#path-14-inside-1_35_350)"/>
          <rect x="506.484" y="237.25" width="74.4995" height="22" transform="rotate(-90 506.484 237.25)" stroke="#EFEFEF" stroke-width="3"/>
          <rect x="529.63" y="214.745" width="29.4998" height="10.75" transform="rotate(-90 529.63 214.745)" stroke="#EFEFEF" stroke-width="3"/>
          <ellipse cx="479.054" cy="200.287" rx="2.21238" ry="2.19482" transform="rotate(-90 479.054 200.287)" fill="#EFEFEF"/>
          <line y1="-1.5" x2="12.3403" y2="-1.5" transform="matrix(0.70711 0.707103 -0.701449 0.71272 519.999 18.1459)" stroke="#EFEFEF" stroke-width="3"/>
          <line y1="-1.5" x2="12.3403" y2="-1.5" transform="matrix(0.70711 -0.707103 0.701449 0.71272 521.246 383.127)" stroke="#EFEFEF" stroke-width="3"/>
          <rect x="464.002" y="280.998" width="161.999" height="64.5002" transform="rotate(-90 464.002 280.998)" stroke="#EFEFEF" stroke-width="3"/>
          <rect x="274.138" y="18.3755" width="363.248" height="253.25" transform="rotate(90 274.138 18.3755)" stroke="#EFEFEF" stroke-width="3"/>
          <mask id="path-23-inside-2_35_350" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M85.0066 166.081L85.0066 233.312C94.6946 225.394 100.631 214.11 100.632 200.001C100.632 185.141 94.6949 173.827 85.0066 166.081Z"/>
          </mask>
          <path d="M85.0066 166.081L86.88 163.738L82.0066 159.842L82.0066 166.081L85.0066 166.081ZM85.0066 233.312L82.0066 233.312L82.0066 239.639L86.9051 235.635L85.0066 233.312ZM100.632 200.001L103.632 200.001L100.632 200.001ZM82.0066 166.081L82.0066 233.312L88.0066 233.312L88.0066 166.081L82.0066 166.081ZM97.6316 200.001C97.6315 213.177 92.1251 223.62 83.1082 230.989L86.9051 235.635C97.2642 227.169 103.631 215.044 103.632 200.001L97.6316 200.001ZM83.1332 168.425C92.0978 175.592 97.6317 186.038 97.6316 200.001L103.632 200.001C103.632 184.244 97.2919 172.063 86.88 163.738L83.1332 168.425Z" fill="#EFEFEF" mask="url(#path-23-inside-2_35_350)"/>
          <rect x="42.8862" y="162.755" width="74.4995" height="22" transform="rotate(90 42.8862 162.755)" stroke="#EFEFEF" stroke-width="3"/>
          <rect x="19.741" y="185.252" width="29.4998" height="10.75" transform="rotate(90 19.741 185.252)" stroke="#EFEFEF" stroke-width="3"/>
          <ellipse cx="70.3164" cy="199.709" rx="2.21238" ry="2.19482" transform="rotate(90 70.3164 199.709)" fill="#EFEFEF"/>
          <line y1="-1.5" x2="12.3403" y2="-1.5" transform="matrix(-0.70711 -0.707103 0.701449 -0.71272 29.3696 381.85)" stroke="#EFEFEF" stroke-width="3"/>
          <line y1="-1.5" x2="12.3403" y2="-1.5" transform="matrix(-0.70711 0.707103 -0.701449 -0.71272 28.1252 16.8793)" stroke="#EFEFEF" stroke-width="3"/>
          <rect x="85.3655" y="119.004" width="161.999" height="64.5002" transform="rotate(90 85.3655 119.004)" stroke="#EFEFEF" stroke-width="3"/>
        </g>
      </svg>
      <svg v-else-if="screenWidth <= 880 && screenWidth > 600" width="550" height="400" viewBox="0 0 550 400" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <marker
            id="arrow-end"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,5 L5,2.5 Z" fill="black" />
          </marker>
          <marker
            id="arrow-start"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto-start-reverse"
            markerUnits="strokeWidth"
          >
            <path d="M5,2.5 L0,5 L0,0 Z" fill="black" />
          </marker>
          
        </defs>
        <g>
          <rect x="0.000244141" y="400" width="400" height="550" transform="matrix(0 -1 1 0 -400 400)" fill="#40A769"/>
          <rect x="200.002" y="383.13" width="366.248" height="30" transform="rotate(-90 200.002 383.13)" fill="#4EC283"/>
          <rect x="139.992" y="383.13" width="366.248" height="30" transform="rotate(-90 139.992 383.13)" fill="#4EC283"/>
          <rect x="80.0066" y="383.13" width="366.248" height="30" transform="rotate(-90 80.0066 383.13)" fill="#4EC283"/>
          <rect x="19.385" y="383.13" width="366.248" height="30" transform="rotate(-90 19.385 383.13)" fill="#4EC283"/>
          <rect x="260.013" y="383.13" width="366.248" height="30" transform="rotate(-90 260.013 383.13)" fill="#4EC283"/>
          <rect x="320.005" y="383.129" width="366.248" height="30" transform="rotate(-90 320.005 383.129)" fill="#4EC283"/>
          <rect x="379.983" y="383.129" width="366.248" height="30" transform="rotate(-90 379.983 383.129)" fill="#4EC283"/>
          <rect x="439.994" y="383.13" width="366.248" height="30" transform="rotate(-90 439.994 383.13)" fill="#4EC283"/>
          <rect x="500.005" y="383.13" width="366.248" height="30" transform="rotate(-90 500.005 383.13)" fill="#4EC283"/>
          <path d="M274.424 163.259C294.676 163.259 311.114 179.812 311.114 200.255C311.114 220.698 294.676 237.25 274.424 237.25C254.172 237.25 237.734 220.698 237.734 200.255C237.734 179.812 254.172 163.259 274.424 163.259Z" stroke="#EFEFEF" stroke-width="3"/>
          <ellipse cx="274.712" cy="200.289" rx="2.21238" ry="2.19482" transform="rotate(-90 274.712 200.289)" fill="#EFEFEF"/>
          <rect x="275.232" y="381.627" width="363.248" height="253.25" transform="rotate(-90 275.232 381.627)" stroke="#EFEFEF" stroke-width="3"/>
          <mask id="path-14-inside-1_35_350" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M464.373 233.923L464.373 166.694C454.686 174.612 448.749 185.896 448.749 200.005C448.749 214.864 454.685 226.177 464.373 233.923Z"/>
          </mask>
          <path d="M464.373 233.923L462.499 236.266L467.373 240.163L467.373 233.923L464.373 233.923ZM464.373 166.694L467.373 166.694L467.373 160.368L462.474 164.371L464.373 166.694ZM448.749 200.005L445.749 200.005L448.749 200.005ZM467.373 233.923L467.373 166.694L461.373 166.694L461.373 233.923L467.373 233.923ZM451.749 200.005C451.749 186.829 457.255 176.386 466.271 169.017L462.474 164.371C452.116 172.837 445.749 184.962 445.749 200.005L451.749 200.005ZM466.246 231.58C457.282 224.413 451.749 213.967 451.749 200.005L445.749 200.005C445.749 215.76 452.088 227.942 462.499 236.266L466.246 231.58Z" fill="#EFEFEF" mask="url(#path-14-inside-1_35_350)"/>
          <rect x="506.484" y="237.25" width="74.4995" height="22" transform="rotate(-90 506.484 237.25)" stroke="#EFEFEF" stroke-width="3"/>
          <rect x="529.629" y="214.745" width="29.4998" height="10.75" transform="rotate(-90 529.629 214.745)" stroke="#EFEFEF" stroke-width="3"/>
          <ellipse cx="479.055" cy="200.287" rx="2.21238" ry="2.19482" transform="rotate(-90 479.055 200.287)" fill="#EFEFEF"/>
          <line y1="-1.5" x2="12.3403" y2="-1.5" transform="matrix(0.70711 0.707103 -0.701449 0.71272 519.999 18.146)" stroke="#EFEFEF" stroke-width="3"/>
          <line y1="-1.5" x2="12.3403" y2="-1.5" transform="matrix(0.70711 -0.707103 0.701449 0.71272 521.246 383.127)" stroke="#EFEFEF" stroke-width="3"/>
          <rect x="464.002" y="280.998" width="161.999" height="64.5002" transform="rotate(-90 464.002 280.998)" stroke="#EFEFEF" stroke-width="3"/>
          <rect x="274.138" y="18.3755" width="363.248" height="253.25" transform="rotate(90 274.138 18.3755)" stroke="#EFEFEF" stroke-width="3"/>
          <mask id="path-23-inside-2_35_350" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M85.0066 166.081L85.0066 233.312C94.6946 225.394 100.631 214.11 100.632 200.001C100.632 185.141 94.6949 173.827 85.0066 166.081Z"/>
          </mask>
          <path d="M85.0066 166.081L86.88 163.738L82.0066 159.842L82.0066 166.081L85.0066 166.081ZM85.0066 233.312L82.0066 233.312L82.0066 239.638L86.9051 235.635L85.0066 233.312ZM100.632 200.001L97.6316 200.001L100.632 200.001ZM82.0066 166.081L82.0066 233.312L88.0066 233.312L88.0066 166.081L82.0066 166.081ZM97.6316 200.001C97.6315 213.177 92.1251 223.62 83.1081 230.989L86.9051 235.635C97.2642 227.169 103.631 215.044 103.632 200.001L97.6316 200.001ZM83.1332 168.424C92.0978 175.592 97.6317 186.038 97.6316 200.001L103.632 200.001C103.632 184.244 97.2919 172.063 86.88 163.738L83.1332 168.424Z" fill="#EFEFEF" mask="url(#path-23-inside-2_35_350)"/>
          <rect x="42.8865" y="162.754" width="74.4995" height="22" transform="rotate(90 42.8865 162.754)" stroke="#EFEFEF" stroke-width="3"/>
          <rect x="19.741" y="185.252" width="29.4998" height="10.75" transform="rotate(90 19.741 185.252)" stroke="#EFEFEF" stroke-width="3"/>
          <ellipse cx="70.3167" cy="199.709" rx="2.21238" ry="2.19482" transform="rotate(90 70.3167 199.709)" fill="#EFEFEF"/>
          <line y1="-1.5" x2="12.3403" y2="-1.5" transform="matrix(-0.70711 -0.707103 0.701449 -0.71272 29.3694 381.85)" stroke="#EFEFEF" stroke-width="3"/>
          <line y1="-1.5" x2="12.3403" y2="-1.5" transform="matrix(-0.70711 0.707103 -0.701449 -0.71272 28.1252 16.8792)" stroke="#EFEFEF" stroke-width="3"/>
          <rect x="85.3655" y="119.004" width="161.999" height="64.5002" transform="rotate(90 85.3655 119.004)" stroke="#EFEFEF" stroke-width="3"/>
        </g>
      </svg>
      <svg v-else-if="screenWidth <= 600" width="344" height="250" viewBox="0 0 344 250" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <marker
            id="arrow-end"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,5 L5,2.5 Z" fill="black" />
          </marker>
          <marker
            id="arrow-start"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto-start-reverse"
            markerUnits="strokeWidth"
          >
            <path d="M5,2.5 L0,5 L0,0 Z" fill="black" />
          </marker>
          
        </defs>
        <g>
          <rect x="0.000244141" y="250" width="250" height="343.75" transform="matrix(0 -1 1 0 -250 250)" fill="#40A769"/>
          <rect x="125.001" y="239.456" width="228.905" height="18.75" transform="rotate(-90 125.001 239.456)" fill="#4EC283"/>
          <rect x="87.4954" y="239.456" width="228.905" height="18.75" transform="rotate(-90 87.4954 239.456)" fill="#4EC283"/>
          <rect x="50.0042" y="239.456" width="228.905" height="18.75" transform="rotate(-90 50.0042 239.456)" fill="#4EC283"/>
          <rect x="12.1157" y="239.456" width="228.905" height="18.75" transform="rotate(-90 12.1157 239.456)" fill="#4EC283"/>
          <rect x="162.508" y="239.456" width="228.905" height="18.75" transform="rotate(-90 162.508 239.456)" fill="#4EC283"/>
          <rect x="200.003" y="239.456" width="228.905" height="18.75" transform="rotate(-90 200.003 239.456)" fill="#4EC283"/>
          <rect x="237.49" y="239.456" width="228.905" height="18.75" transform="rotate(-90 237.49 239.456)" fill="#4EC283"/>
          <rect x="274.997" y="239.456" width="228.905" height="18.75" transform="rotate(-90 274.997 239.456)" fill="#4EC283"/>
          <rect x="312.503" y="239.456" width="228.905" height="18.75" transform="rotate(-90 312.503 239.456)" fill="#4EC283"/>
          <path d="M171.515 101.6C184.418 101.6 194.884 112.144 194.884 125.159C194.884 138.175 184.418 148.719 171.515 148.719C158.613 148.719 148.146 138.175 148.146 125.159C148.146 112.144 158.613 101.6 171.515 101.6Z" stroke="#EFEFEF"/>
          <circle cx="171.5" cy="125.5" r="1.5" transform="rotate(-90 171.5 125.5)" fill="#EFEFEF"/>
          <rect x="171.583" y="238.954" width="227.905" height="159.156" transform="rotate(-90 171.583 238.954)" stroke="#EFEFEF"/>
          <mask id="path-14-inside-1_35_350" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M290.233 146.202V104.184C284.178 109.133 280.468 116.185 280.468 125.003C280.468 134.29 284.178 141.361 290.233 146.202Z"/>
          </mask>
          <path d="M290.233 146.202L289.608 146.983L291.233 148.282V146.202H290.233ZM290.233 104.184H291.233V102.075L289.6 103.41L290.233 104.184ZM280.468 125.003L279.468 125.003L280.468 125.003ZM291.233 146.202V104.184H289.233V146.202H291.233ZM281.468 125.003C281.468 116.496 285.035 109.724 290.866 104.958L289.6 103.41C283.322 108.541 279.468 115.874 279.468 125.003L281.468 125.003ZM290.857 145.421C285.044 140.773 281.468 133.991 281.468 125.003L279.468 125.003C279.468 134.589 283.313 141.949 289.608 146.983L290.857 145.421Z" fill="#EFEFEF" mask="url(#path-14-inside-1_35_350)"/>
          <rect x="316.115" y="148.719" width="47.4372" height="14.625" transform="rotate(-90 316.115 148.719)" stroke="#EFEFEF"/>
          <rect x="330.581" y="134.653" width="19.3124" height="7.59377" transform="rotate(-90 330.581 134.653)" stroke="#EFEFEF"/>
          <ellipse cx="299.409" cy="125.179" rx="1.38274" ry="1.37176" transform="rotate(-90 299.409 125.179)" fill="#EFEFEF"/>
          <line y1="-0.5" x2="7.71267" y2="-0.5" transform="matrix(0.70711 0.707103 -0.701449 0.71272 324.999 11.3412)" stroke="#EFEFEF"/>
          <line y1="-0.5" x2="7.71267" y2="-0.5" transform="matrix(0.70711 -0.707103 0.701449 0.71272 325.779 239.454)" stroke="#EFEFEF"/>
          <rect x="289.564" y="176.061" width="102.125" height="41.1876" transform="rotate(-90 289.564 176.061)" stroke="#EFEFEF"/>
          <rect x="171.774" y="11.0471" width="227.905" height="159.156" transform="rotate(90 171.774 11.0471)" stroke="#EFEFEF"/>
          <mask id="path-23-inside-2_35_350" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M53.1292 103.801L53.1292 145.82C59.1842 140.871 62.8947 133.819 62.8948 125C62.8949 115.713 59.1844 108.642 53.1292 103.801Z"/>
          </mask>
          <path d="M53.1292 103.801L53.7537 103.02L52.1292 101.721L52.1292 103.801L53.1292 103.801ZM53.1292 145.82L52.1292 145.82L52.1292 147.929L53.762 146.594L53.1292 145.82ZM62.8948 125L61.8948 125L61.8948 125L62.8948 125ZM52.1292 103.801L52.1292 145.82L54.1292 145.82L54.1292 103.801L52.1292 103.801ZM61.8948 125C61.8948 133.508 58.3277 140.28 52.4964 145.046L53.762 146.594C60.0408 141.463 63.8947 134.13 63.8948 125L61.8948 125ZM52.5048 104.582C58.3187 109.23 61.8949 116.012 61.8948 125L63.8948 125C63.8949 115.414 60.0501 108.054 53.7537 103.02L52.5048 104.582Z" fill="#EFEFEF" mask="url(#path-23-inside-2_35_350)"/>
          <rect x="27.2417" y="101.284" width="47.4372" height="14.625" transform="rotate(90 27.2417 101.284)" stroke="#EFEFEF"/>
          <rect x="12.7756" y="115.345" width="19.3124" height="7.59377" transform="rotate(90 12.7756 115.345)" stroke="#EFEFEF"/>
          <ellipse cx="43.9481" cy="124.818" rx="1.38274" ry="1.37176" transform="rotate(90 43.9481 124.818)" fill="#EFEFEF"/>
          <line y1="-0.5" x2="7.71267" y2="-0.5" transform="matrix(-0.70711 -0.707103 0.701449 -0.71272 18.356 238.656)" stroke="#EFEFEF"/>
          <line y1="-0.5" x2="7.71267" y2="-0.5" transform="matrix(-0.70711 0.707103 -0.701449 -0.71272 17.5784 10.5494)" stroke="#EFEFEF"/>
          <rect x="53.791" y="73.9401" width="102.125" height="41.1876" transform="rotate(90 53.791 73.9401)" stroke="#EFEFEF"/>
        </g>
      </svg>
</template>

<script>
    export default {
        name: 'Field',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
        }
    }
</script>