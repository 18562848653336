<template>
  <div ref="chart" style="width: 100%; height: 400px;"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'PieChart',

  props: {
    data: {
      type: Array,
      default: [0, 0, 0]
    }
  },

  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    // Инициализация диаграммы
    this.initChart();
  },
  beforeDestroy() {
    // Уничтожение диаграммы при удалении компонента
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    initChart() {
      const chartDom = this.$refs.chart;
      this.chart = echarts.init(chartDom);

      const option = {
        tooltip: {
          trigger: 'item',
        },
        legend: {
          top: '5%',
          left: 'center',
          show: false,
        },
        series: [
          {
            name: 'Стан гравця',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: this.data[0], name: 'Здоровий', color: '#339989' },
              { value: this.data[1], name: 'Одужуючий', color: '#C59014' },
              { value: this.data[2], name: 'Травма', color: '#DA4167' },
            ],
          },
        ],
      };

      // Установка цветов из data
      option.series[0].data.forEach((item) => {
        item.itemStyle = { color: item.color };
      });

      this.chart.setOption(option);
    },
  },
};
</script>

<style scoped>
/* Можно добавить стили для контейнера */
</style>
