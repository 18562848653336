<template>
  <div class="polygon-container" ref="container">
    <svg class="polygon-svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <polygon :points="svgPoints" class="polygon-shape" />
    </svg>

    <div
      v-for="(vertex, index) in vertices"
      :key="index"
      class="polygon-item"
      :style="{ left: `${vertex.x}%`, top: `${vertex.y}%` }"
    >
      <div class="w-[50px] flex flex-col items-center justify-center">
        <div
          @click="$emit('select', index)" 
          class="border-[1px] cursor-pointer px-[10px] py-[3px] rounded-[6px] mb-[5px]"
          :class="{
            'bg-[#474849] border-[#A9AAAB66]' : !values[index]?.selected,
            'bg-[#C59014] border-[#A9AAAB66]' : values[index]?.selected,
          }"
        >
          <div 
            class="font-[600] truncate w-[50px] text-center text-[12px]"
            :class="{
              'text-[#C59014]' : !values[index]?.selected,
              'text-[#474849]' : values[index]?.selected,
            }"  
          >
            {{ values[index].name }}
          </div> 
        </div>
        <div>
          <input 
            :value="values[index].value"
            @input="$emit('update', {index: index, value: $event.target.value})"
            :disabled="!values[index]?.selected"
            :placeholder="values[index]?.selected ? '0.00' : ''" 
            type="text" 
            class="w-[50px] py-[2px] text-center text-[12px] bg-[#47484966] border-[#A9AAAB66] border-[1px] rounded-[4px]">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';

export default {
  props: {
    sides: {
      type: Number,
      default: 3, 
      validator: (value) => value >= 3,
    },
    values: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const container = ref(null);

    const vertices = computed(() => {
      const points = [];
      const radius = 47; 
      const angleStep = (2 * Math.PI) / props.sides;

      for (let i = 0; i < props.sides; i++) {
        const angle = i * angleStep;
        const x = 50 + radius * Math.cos(angle);
        const y = 50 + radius * Math.sin(angle);
        points.push({ x, y });
      }
      return points;
    });

    const polygonVertices = computed(() => {
      const points = [];
      const radius = 30; 
      const angleStep = (2 * Math.PI) / props.sides;

      for (let i = 0; i < props.sides; i++) {
        const angle = i * angleStep;
        const x = 50 + radius * Math.cos(angle);
        const y = 50 + radius * Math.sin(angle);
        points.push({ x, y });
      }

      return points;
    });

    const svgPoints = computed(() =>
      polygonVertices.value.map((vertex) => `${vertex.x},${vertex.y}`).join(' ')
    );

    return { container, vertices, svgPoints };
  },
};
</script>

<style scoped>
.polygon-container {
  position: relative;
  width: 250px;
  height: 250px;
  /* border: 1px solid #ccc; */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.polygon-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.polygon-shape {
  fill: none;
  stroke: #C59014;
  background-color: #C59014;
  stroke-width: 1;

  fill: #C590141A;
}

.polygon-item {
  position: absolute;
  width: 50px;
  transform: translate(-50%, -50%);
  /* background-color: #C59014; */
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
