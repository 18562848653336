<template>
  <div v-if="addMedicalReportFlag || selectedReport" @click="addMedicalReportFlag = false; selectedReport = null" class="absolute left-0 top-0 w-full h-full"></div>
  <div v-if="addMedicalReportFlag" class="relative">
    <MedicalReportForm 
      @save="addMedicalReport"
    />
  </div>

  <div v-if="selectedReport" class="relative">
    <MedicalReportForm 
      :report="selectedReport"
      @save="updateMedicalReport"
    />
  </div>

  <div v-if="reportFilesViewFlag" class="relative">
    <FileManager 
      :filesUrls="reportFilesForView"
      :canDelete="false"
      @close="reportFilesViewFlag = false"
    />
  </div>
  <div v-if="deleteFlag" class="relative">
    <DeleteForm 
      @yes="deleteReport"
      @no="deleteFlag = false"
    />
  </div>
  <div class="healthcare-tab">
    <div class="grid grid-cols-4">
      <div class=" flex flex-col justify-between border-[red]">
        <div class="healthcare-tab__top-section ">
          <div class="healthcare-tab__block">
            <InputComponent 
              :label="'група крові'" 
              :placeholder="'група крові'" 
              :name="'s'" 
              :value="blood_group"
              @update:modelValue="e => blood_group = e"
            />
            <InputComponent 
              :label="'кров\'яний тиск'" 
              :placeholder="'кров\'яний тиск'" 
              :name="'s'" 
              :value="blood_pressure"
              @update:modelValue="e => blood_pressure = e"
            />
            <!-- <ButtonComponent @click="canPlay = false" v-if="canPlay" :positive="true" :small="true">може грати</ButtonComponent>
            <ButtonComponent @click="canPlay = true" v-else :negative="true" :small="true">не може грати</ButtonComponent> -->
          </div>
          <div class="healthcare-tab__block">
            <InputComponent
              :label="'середній серцевий ритм'"
              :placeholder="'середній серцевий ритм'"
              :name="'s'"
              :value="heart_rate"
              @update:modelValue="e => heart_rate = e"
            />
            <InputComponent
              :label="'кардіозагартованість'"
              :placeholder="'кардіозагартованість'"
              :name="'s'"
              :value="cardio"
              @update:modelValue="e => cardio = e"
            />
            <!-- <ButtonComponent @click="canTrain = false" v-if="canTrain" :positive="true" :small="true">може тренуватися</ButtonComponent>
            <ButtonComponent @click="canTrain = true" v-else :negative="true" :small="true">не може тренуватися</ButtonComponent> -->
          </div>
          <div class="healthcare-tab__block">
            <InputComponent
              :label="'зріст'"
              :placeholder="'зріст'"
              :name="'s'"
              :value="height"
              @update:modelValue="e => $emit('update:height', e)"  
            />
            <InputComponent
              :label="'окружність грудної клітки'"
              :placeholder="'окружність грудної клітки'"
              :name="'s'"
              :value="breastVolume"
              @update:modelValue="e => $emit('update:breastVolume', e)"  
            />
          </div>
          <div class="healthcare-tab__block">
            <InputComponent
              :label="'розмір ноги'"
              :placeholder="'розмір ноги'"
              :name="'s'"
              :value="footSize"
              @update:modelValue="e => $emit('update:footSize', e)"  
            />
            <InputComponent
              :label="'вага'"
              :placeholder="'вага'"
              :name="'s'"
              :value="weight"
              @update:modelValue="e => $emit('update:weight', e)"  
            />
          </div>
        </div>
        <div class="">
          <div class="flex justify-center">
            <div class="px-[15px] py-[3px] rounded-full mt-[20px] bg-[#C59014] text-center w-max text-[12px] font-semibold">діаграми (динаміка)</div>
          </div>
        </div>
      </div>

      <div class="relative  border-[red] h-[360px]">
        <div class="absolute bottom-0 left-[50%] translate-x-[-50%]">
          <img src="./healthcare-assets/player-params-model.png" class="h-[300px] ">
        </div>

        <div class="absolute flex flex-col items-center top-0 left-[50%] translate-x-[-50%] justify-center">
          <div class="font-bold text-[20px]">{{ calculateClothingSize(height, breastVolume, weight) }}</div>
          <div class="text-[10px]">розмір одягу</div>
        </div>

        <div class="grid grid-cols-3 absolute bottom-0 left-[50%] translate-x-[-50%]">
          <div class=" flex items-end justify-end border-[yellow]">
            <div class="">
              <img v-if="leadingLeg == 'Права'" src="./healthcare-assets/right-leg-cap.svg">
              <img v-if="leadingLeg == 'Ліва'" src="./healthcare-assets/left-leg-cap.svg">
              <img v-if="leadingLeg == 'Обидві'" src="./healthcare-assets/all-leg-cap.svg">
            </div>
          </div>
          <div class="w-[80px]  border-[yellow]">
            
          </div>
          <div class="flex gap-[10px]  border-[yellow]">
            <div class=" flex flex-col justify-center items-center w-max">
              <div><span class="font-bold">{{ footSize }}</span>р.</div>
              <div class="h-[30px] flex items-center"><img src="./healthcare-assets/shoe-icon.svg"></div>
            </div>
            <div class=" flex flex-col justify-center items-center w-max">
              <div><span class="font-bold">{{ weight }}</span>кг</div>
              <div class="h-[30px] flex items-center"><img src="./healthcare-assets/gemini-icon.svg"></div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-3 absolute bottom-[0] left-[50%] translate-x-[-50%]">
          <div class=" relative flex items-end justify-start border-[green]">
            <img src="./healthcare-assets/vertical-ruler.svg" alt="">
            <div class="-rotate-90 absolute top-[44%] "><span class="font-bold">{{ height }}</span>см</div>
          </div>
          <div class="w-[80px]  border-[green]">
            
          </div>
          <div class="relative flex justify-end gap-[10px]  border-[green]">
            <div class="absolute top-[27%]">
              <div><span class="font-bold">{{ breastVolume }}</span>см.</div>
              <img src="./healthcare-assets/simple-ruler.svg" class="w-[33px]" alt="">
            </div>
          </div>
        </div>

      </div>
      <div class="relative right-[70px] border-[red] h-[360px]">
        <div class="absolute bottom-0 left-[50%] translate-x-[-50%]">
          <PlayerBMIModel :color="determineBmiCategory(calculateBMI(weight, height)) == 'Extremely Obese' ? '#DA4167' : 
                  determineBmiCategory(calculateBMI(weight, height)) == 'Obese' ? '#F27E30' : 
                  determineBmiCategory(calculateBMI(weight, height)) == 'Overweight' ? '#C59014' :
                  determineBmiCategory(calculateBMI(weight, height)) == 'Normal' ? '#339989' :
                  determineBmiCategory(calculateBMI(weight, height)) == 'Underweight' ? '#1B4079' : 'gray'"/>
        </div>

        <div class="absolute flex flex-col items-center top-0 left-[50%] translate-x-[-50%] justify-center">
          <div class="font-bold text-[20px]">BMI</div>
          <div class="text-[10px]">індекс маси тіла</div>
        </div>

       

        <div class="grid grid-cols-3 absolute top-[50%] translate-y-[-40%] left-[50%] translate-x-[-50%]">
          <div class=" relative flex items-end justify-start border-[green]">

          </div>
          <div class=" border-[green]">
            
          </div>
          <div class="w-[120px] relative flex justify-end gap-[10px]  border-[green]">
            <img src="./healthcare-assets/imt-scale-type.svg" class="" alt="">
            <div 
              class="w-[60px] h-[5px] absolute rounded-lg top-[70%]" 
              :style="[
                `top: ${calculateScalePosition(calculateBMI(weight, height))}%`,
                `background-color: ${determineBmiCategory(calculateBMI(weight, height)) == 'Extremely Obese' ? '#DA4167' : 
                  determineBmiCategory(calculateBMI(weight, height)) == 'Obese' ? '#F27E30' : 
                  determineBmiCategory(calculateBMI(weight, height)) == 'Overweight' ? '#C59014' :
                  determineBmiCategory(calculateBMI(weight, height)) == 'Normal' ? '#339989' :
                  determineBmiCategory(calculateBMI(weight, height)) == 'Underweight' ? '#1B4079' : 'gray'
                }`  
              ]"
            ></div>
          </div>
        </div>

      </div>
      <div class="relative  border-[red] h-[360px]">
        <div class="flex gap-[10px] justify-center">
          <div :class="{'opacity-50' : playerCurrentStatus.status != 'healthy'}" class="px-[15px] h-max text-center rounded-xl bg-[#339989] text-[12px] py-[2px]">Здоровий</div>
          <div :class="{'opacity-50' : playerCurrentStatus.status != 'recover'}" class="px-[15px] h-max text-center rounded-xl bg-[#C59014] text-[12px] py-[2px]">Відновлення</div>
          <div :class="{'opacity-50' : playerCurrentStatus.status != 'injury'}" class="px-[15px] h-max text-center rounded-xl bg-[#DA4167] text-[12px] py-[2px]">Травма</div>
        </div>
        <div>
          <div v-if="playerCurrentStatus.status == 'injury'" class="flex flex-col items-center mt-[7px] justify-center">
            <div class="text-[#DA4167] font-bold text-[12px]">{{ playerCurrentStatus.recoverDate.split('T')[0].split('-').reverse().join('.') }}</div>
            <div class="text-[#DA4167] text-[10px]">прогноз одужання</div>
          </div>
          <div v-else class="h-[40px]"></div>
        </div>
        <div class="flex justify-center">
          <MiniSkeletonBack :selectedParts="playerCurrentStatus.backBodyInjuredParts" />
          <MiniSkeletonFront :selectedParts="playerCurrentStatus.frontBodyInjuredParts" />
        </div>
      </div>

    </div>
    <div class="healthcare-tab__button-wrapper ">
      <ButtonComponent @click="addMedicalReportFlag = true">Додати документ</ButtonComponent>
    </div>

    <TableWrapper class="">
      <TableComponent :fullWidth="true">
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell :width="40" :value="'#'" />
            <TableHeaderCell :width="64" :value="'дата'" />
            <TableHeaderCell :width="348" :value="'аналіз/довідка/інше'" />
            <TableHeaderCell :width="60" :value="'документ'" />
            <TableHeaderCell :width="194" :value="'лікар'" />
            <TableHeaderCell :width="194" :value="'статус'" />
            <TableHeaderCell :width="60" :value="''" />
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          <TableRow v-for="(item, index) in reports" :key="index">
            <TableCell :width="40"  :value="index + 1 + '.'" />
            <TableCell :width="64" :asChild="true">
              <DateComponent :day="item.date" :time="item.time" />
            </TableCell>
            <TableCell :width="348" @click="selectedReport = item" :value="item.document_name" class="cursor-pointer" />
            <TableCell :width="60" :asChild="true">
              <div class="healthcare-tab__document-icon cursor-pointer">
                <img @click="selectRepostFiles(item.documents)" :src="document" alt="document" class="w-[17px] h-[20px]" />
              </div>
            </TableCell>
            <TableCell :width="194" :value="item.doctor" />
            <TableCell :width="194" :asChild="true">
              <div class="flex justify-center">
                <div v-if="item.health_status == 'healthy'" class="px-[15px] w-[100px] h-max text-center rounded-xl bg-[#339989] text-[12px] py-[2px]">Здоровий</div>
                <div v-if="item.health_status == 'injury'" class="px-[15px] h-max text-center rounded-xl bg-[#DA4167] text-[12px] py-[2px]">Травма</div>
                <div v-if="item.health_status == 'recover'" class="px-[15px] h-max text-center rounded-xl bg-[#C59014] text-[12px] py-[2px]">Відновлення</div>
              </div>
            </TableCell>
            <TableCell :width="60" :asChild="true">
              <div class="flex justify-end">
                <svg @click="() => { reportForDeletion = item; deleteFlag = true }" class="cursor-pointer" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="8" cy="8" r="8" fill="#DA4167"/>
                <line x1="3.07812" y1="7.625" x2="12.9243" y2="7.625" stroke="white" stroke-width="2"/>
                </svg>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </TableComponent>
    </TableWrapper>
  </div>
  <div class="w-full h-full ">

  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { updatePlayerHealthParamsByStudentId, getPlayerHealthStatus, getPlayerHealthParamsByStudentId, addHealthReportByStudentId, deleteHealthReportByStudentId, updateHealthReportByStudentId } from "@/services/apiRequests";

import MedicalReportForm from "../MedicalReportForm.vue";
import FileManager from "../widgets/FileManager.vue";
import DeleteForm from "../widgets/DeleteForm.vue";
import PlayerBMIModel from '../PlayerBMIModel.vue'

import MiniSkeletonBack from "../widgets/MiniSkeletonBack.vue";
import MiniSkeletonFront from "../widgets/MiniSkeletonFront.vue";

import document from "@/assets/refactored_assets/img/players/document.svg";

export default {
  name: "HealthcareTab",
  props: {
    playerId: {
      type: String,
      required: true,
    },
    updateFlag: {
      type: Boolean,
      required: true,
    },
    height: {
      type: String,
      default: ''
    },
    weight: {
      type: String,
      default: ''
    },
    footSize: {
      type: String,
      default: ''
    },
    breastVolume: {
      type: String,
      default: ''
    },
    leadingLeg: {
      type: String,
      default: ''
    },
  },

  setup(props, { emit }) {
    const blood_group = ref('');
    const heart_rate = ref('');
    const blood_pressure = ref('');
    const cardio = ref('');

    const canPlay = ref(false);
    const canTrain = ref(false);

    const addMedicalReportFlag = ref(false);

    const reports = ref([]);

    const reportFilesForView = ref([]);
    const reportFilesViewFlag = ref(false);

    const selectedReport = ref(null);

    const selectRepostFiles = (files) => {
      reportFilesForView.value = files;
      reportFilesViewFlag.value = true;
    };

    const calculateScalePosition = (bmi, bmiMin = 0, bmiMax = 50, scaleMin = 0, scaleMax = 95) => {
      // Убедимся, что значение BMI находится в пределах шкалы
      if (bmi < bmiMin) bmi = bmiMin;
      if (bmi > bmiMax) bmi = bmiMax;

      if (bmi < 18.5) {
        scaleMin = 0
        scaleMax = 15
      } else if (bmi >= 18.5 && bmi < 25) {
        scaleMin = 15
        scaleMax = 35
      } else if (bmi >= 25 && bmi < 30) {
        scaleMin = 35
        scaleMax = 55
      } else if (bmi >= 30 && bmi < 40) {
        scaleMin = 55
        scaleMax = 75
      } else if (bmi >= 40) {
        scaleMin = 75
        scaleMax = 95
      } else {
        return "Invalid BMI"; // Если значение некорректное
      }

      // Линейная интерполяция
      const position = ((bmi - bmiMin) / (bmiMax - bmiMin)) * (scaleMax - scaleMin) + scaleMin;
      return 95 - position;
    }

    const determineBmiCategory = (bmi) => {
      if (bmi < 18.5) {
        return "Underweight";
      } else if (bmi >= 18.5 && bmi < 25) {
        return "Normal";
      } else if (bmi >= 25 && bmi < 30) {
        return "Overweight";
      } else if (bmi >= 30 && bmi < 40) {
        return "Obese";
      } else if (bmi >= 40) {
        return "Extremely Obese";
      } else {
        return "Invalid BMI"; // Если значение некорректное
      }
    }

    const calculateClothingSize = (height, chestCircumference, weight) => {
      if (typeof height !== "number" || typeof chestCircumference !== "number" || typeof weight !== "number") {
          height = parseFloat(height)
          chestCircumference = parseFloat(chestCircumference)
          weight = parseFloat(weight)
      }

      let size = "";

      // Условная логика расчета размера (можно адаптировать под конкретную систему):
      if (height < 160) {
          if (chestCircumference < 85 && weight < 50) {
              size = "XS";
          } else if (chestCircumference >= 85 && chestCircumference < 95) {
              size = "S";
          } else {
              size = "M";
          }
      } else if (height >= 160 && height < 180) {
          if (chestCircumference < 90 && weight < 70) {
              size = "S";
          } else if (chestCircumference >= 90 && chestCircumference < 100) {
              size = "M";
          } else {
              size = "L";
          }
      } else if (height >= 180) {
          if (chestCircumference < 95 && weight < 80) {
              size = "M";
          } else if (chestCircumference >= 95 && chestCircumference < 105) {
              size = "L";
          } else {
              size = "XL";
          }
      }

      return size;
    }

    const calculateBMI = (weight, height) => {
      if (weight <= 0 || height <= 0) {
        return 0;
      }

      const bmi = weight / ((height / 100) ** 2);
      return parseFloat(bmi.toFixed(2)); // Округляем результат до двух знаков после запятой
    }

    const deleteFlag = ref(false);
    const reportForDeletion = ref(null);

    const healthResult = ref('healthy');
    const playerCurrentStatus = ref({});

    watch(() => props.updateFlag, async () => {
      if (props.updateFlag === false) return;

      await updatePlayerHealthParamsByStudentId(props.playerId, {
        student_id: props.playerId,
        blood_group: blood_group.value,
        average_heart_rate: heart_rate.value,
        blood_pressure: blood_pressure.value,
        cardio_hardening: cardio.value,
        can_play: canPlay.value,
        can_train: canTrain.value,

        reports: reports.value,
      });

      emit('updated');
    });

    const addMedicalReport = async (data) => {
      const report = {
        id: data.id,
        document_name: data.document_name,
        date: data.date,
        time: data.time,
        documents: data.documentsUrls,
        descriptions: data.descriptions,
        doctor: data.doctor,
        health_status: data.health_status,
        backBodyInjuredParts: data.backBodyInjuredParts,
        frontBodyInjuredParts: data.frontBodyInjuredParts,
        recoverDate: data.recoverDate
      }
      await addHealthReportByStudentId(props.playerId, report, data.documentsObjects);
      await updateAllReports();
      addMedicalReportFlag.value = false;
    };

    const updateMedicalReport = async (data) => {
      const report = {
        id: data.id,
        document_name: data.document_name,
        date: data.date,
        time: data.time,
        documents: data.documentsUrls,
        descriptions: data.descriptions,
        doctor: data.doctor,
        health_status: data.health_status,
        backBodyInjuredParts: data.backBodyInjuredParts,
        frontBodyInjuredParts: data.frontBodyInjuredParts,
        recoverDate: data.recoverDate
      }
      await updateHealthReportByStudentId(props.playerId, report, data.documentsObjects);
      await updateAllReports();
      selectedReport.value = null;
    };

    const deleteReport = async () => {
      await deleteHealthReportByStudentId(props.playerId, reportForDeletion.value.id);
      await updateAllReports();
      deleteFlag.value = false;
    };

    const updateAllReports = async () => {
      const response = await getPlayerHealthParamsByStudentId(props.playerId);

      blood_group.value = response.blood_group || '';
      heart_rate.value = response.average_heart_rate || '';
      blood_pressure.value = response.blood_pressure || '';
      cardio.value = response.cardio_hardening || '';

      reports.value = response.reports || [];

      canPlay.value = response?.can_play;
      canTrain.value = response?.can_train;
    };

    onMounted(async () => {
      await updateAllReports();

      playerCurrentStatus.value = await getPlayerHealthStatus(props.playerId)
    });

    return {
      blood_group,
      heart_rate,
      blood_pressure,
      cardio,
      canPlay,
      canTrain,
      addMedicalReportFlag,
      reports,
      addMedicalReport,
      
      reportFilesForView,
      reportFilesViewFlag,
      selectRepostFiles,
      updateMedicalReport,

      calculateClothingSize,
      playerCurrentStatus,

      calculateBMI,
      calculateScalePosition,

      deleteFlag,
      reportForDeletion,
      deleteReport,
      selectedReport,
      healthResult,
      determineBmiCategory
    };
  },

  components: {
    MedicalReportForm,
    FileManager,
    DeleteForm,
    PlayerBMIModel,

    MiniSkeletonBack,
    MiniSkeletonFront
  },

  data() {
    return {
      document: document,
      data: [
        {
          id: 1,
          date: { day: "06.06-2023", time: "12:00" },
          report: "Загальний аналіз крові",
          document: "",
          doctor: "О.Масловський",
          rating: 4,
        },
      ],
    };
  },
};
</script>
