<template>
    <div class="px-[65px]">
        <div @click="$router.go(-1)" class="bg-[#BD9135] mb-[20px] flex justify-center items-center w-[228px] h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
            <span>Назад</span>
        </div>

        <div class="search-block flex justify-end mx-auto">
            <div class="flex justify-end gap-[10px] mt-2">
                <div class="ml-[20px] select-none flex items-center w-[110px] justify-between mr-[140px] text-[#FFF]">
                    <img @click="prevPage" src="@/assets/icons/left_arrow.svg" class="cursor-pointer">
                    <div class="flex mx-[20px]">Сторінка: <input :value="1" type="number" class="bg-transparent mx-2 w-[5ch] outline-none px-1"> / <span class="ml-[10px]">{{ 1 }}</span></div>
                    <img @click="nextPage" src="@/assets/icons/right_arrow.svg" class="cursor-pointer">
                </div>
                <InputComponent :value="searchQuery" @update:modelValue="e => searchQuery = e" placeholder="Пошук..." class="w-[250px]"/>
            </div>
        </div>

        <div class="w-[100%] mx-auto flex gap-[10px] mt-[20px]">
            <!-- :style="[name.selected ? `color: ${generateColor(name.name)}; border-color: ${generateColor(name.name)};` : '']" -->
            <div 
                v-for="name in objectiveScoreNames"
                :class="{
                    'border-[#C59014]': name.selected,
                    'border-[#A9AAAB66]': !name.selected
                }"
                @click="name.selected = !name.selected"
                class="bg-[#47484966] w-[150px] cursor-pointer select-none font-bold text-[16px] pt-[2px] border-[2px] text-[#C59014] flex justify-center items-center rounded-[8px]"
            >
                {{ name.name }}
            </div>
        </div>

        <div class="mt-[20px]">
            <TableWrapper>
                <TableComponent :fullWidth="true">
                    <TableHeader>
                    <TableHeaderRow>
                        <TableHeaderCell :textAlign="'start'" :value="'назва нормативу'" />
                        <TableHeaderCell :value="'направленість'" />
                        <TableHeaderCell :value="'тестувань проведено'" />
                        <TableHeaderCell :value="''" />
                    </TableHeaderRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow 
                            v-for="item in elements"  
                        >
                            <TableCell @click="$router.push(`/testing/${groupId}/${item.element_id}`)" class="cursor-pointer" :textAlign="'start'" :asChild="true">
                                {{ item.name }}
                            </TableCell>
                            <TableCell :asChild="true">
                                <div class="flex gap-[5px] justify-start"> 
                                    <div v-for="scoreType in item.distributionWeightsIndicators.values.filter(e => e.value > 0)">
                                        <div v-if="scoreType.value > 0" class="lowercase px-[10px] text-center rounded-md bg-[#4E4E4E] bg-opacity-50" :style="[`color: ${generateColor(scoreType.name)};`]">
                                            <span v-if="scoreType.value > 0" class="truncate">{{ scoreType.name }}<span class="text-[11px]"> ({{ scoreType.value }})</span></span>
                                        </div>
                                    </div>
    
                                </div>
    
                            </TableCell>
                            <TableCell :value="item.completed_count" />
                            <TableCell :asChild="true">
                                <div @click="$router.push(`/testing/${groupId}/${item.element_id}`)" class="cursor-pointer">
                                    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.544 7.04498C20.848 7.4713 21 7.68447 21 8C21 8.31553 20.848 8.52869 20.544 8.95501C19.1779 10.8706 15.6892 15 11 15C6.31078 15 2.8221 10.8706 1.45604 8.95502C1.15201 8.5287 1 8.31553 1 8C1 7.68447 1.15201 7.47131 1.45604 7.04499C2.8221 5.12944 6.31078 1 11 1C15.6892 1 19.1779 5.12944 20.544 7.04498Z" stroke="#339989" stroke-width="1.5"/>
                                        <path d="M14.0005 8C14.0005 6.34315 12.6573 5 11.0005 5C9.34363 5 8.00049 6.34315 8.00049 8C8.00049 9.65685 9.34363 11 11.0005 11C12.6573 11 14.0005 9.65685 14.0005 8Z" stroke="#339989" stroke-width="1.5"/>
                                    </svg>
                                </div>
                            </TableCell>
                            
                        </TableRow>
                        <td
                            colspan="4"
                            class="text-center text-gray-400 py-10"
                            v-if="elements.length <= 0"
                        >
                            Немає даних
                        </td>
                    </TableBody>
                </TableComponent>
            </TableWrapper>
        </div>
    </div>
</template>
<script>
import { ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import md5 from 'blueimp-md5'
import { getObjectiveScoreSettings, getElementsByRadarWeights, getElementTrainingsCompletedCount, getAllStandardGroups, getAllStandardsRequest } from '@/services/apiRequests'
import filter_icon from '@/assets/icons/mini_filter_icon.svg';

function generateColor(word) {
  // Хешируем слово с использованием алгоритма MD5

  const hash = md5(word.toLowerCase());
  
  // Преобразуем первые 6 символов хеша в компоненты RGB
  const r = parseInt(hash.substring(0, 2), 16);
  const g = parseInt(hash.substring(2, 4), 16);
  const b = parseInt(hash.substring(4, 6), 16);

  // Возвращаем цвет в формате rgb
  return `rgba(${r}, ${g}, ${b})`;
}

export default {
    name: 'TestingGroup',

    setup(props, { emit }) {
        const objectiveScoreNames = ref(null)

        const elements = ref([])

        const router = useRouter()
        const route = useRoute()

        const groupId = ref(route.params.group_id)
        const groupObj = ref(null)
        const baseStandardsIds = ref([])

        watch(objectiveScoreNames, async (value) => {
            const response = await getElementsByRadarWeights(objectiveScoreNames.value.filter(e => e.selected).map(name => name.name), baseStandardsIds.value)
            elements.value = response

            for (const element of elements.value) {
                element.completed_count = await getElementCompletedTrainingsCount(element.element_id)
            }
        }, { deep: true })

        const getElementCompletedTrainingsCount = async (elementId) => {
            const response = await getElementTrainingsCompletedCount(elementId)
            return response
        }   

        onMounted(async () => {
            const response = (await getObjectiveScoreSettings()).field_player

            const allGroups = await getAllStandardGroups()
            groupObj.value = allGroups.find(group => group.group_id == groupId.value)

            const allStandards = await getAllStandardsRequest()
            console.log('allStandards', allStandards)
            const currentGroupStandards = allStandards.filter(standard => standard.group_id == groupId.value)
            baseStandardsIds.value = currentGroupStandards.map(standard => standard.id)

            emit('updatePageName', 'Тестування | ' + groupObj.value.group_name)

            objectiveScoreNames.value = [
                response.first_point_name,
                response.second_point_name,
                response.third_point_name,
                response.fourth_point_name,
                response.fifth_point_name,
                response.sixth_point_name,
                response.seventh_point_name,
                response.eighth_point_name,
                response.ninth_point_name
            ].filter(name => name).map(name => {
                return {
                    name,
                    selected: true
                }
            })
            console.log(objectiveScoreNames.value)
        })

        return {
            objectiveScoreNames,
            elements,
            getElementCompletedTrainingsCount,
            groupObj,
            groupId,
            filter_icon: filter_icon,
            generateColor: generateColor
        }
    }
}
</script>
<style lang="">
    
</style>