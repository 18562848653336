<template>
    <div @click="exitFlag = true" class="absolute w-full h-full left-0 top-0"></div>
    <ExitConfirmForm 
        class="fixed top-[50%] ml-[110px] w-[260px] left-[50%] translate-x-[-90%] translate-y-[-50%]" 
        v-if="exitFlag" 

        :alertText="'Ви впевнені, що хочете оновити дані медичного працівника?'"
        
        @returnToCurrent="exitFlag = false"
        @doSave="addNewScout"
        @dontSave="$emit('close')"
    />
    <div v-if="!exitFlag" style="box-shadow: 4px 4px 4px 0px #00000040;" class="main-info absolute left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%] bg-[#252526] pt-2 h-[580px] w-[250px] flex flex-col mr-[24px] justify-center items-center rounded-[10px] border-[1px] border-[#FFF] border-opacity-10">
        <div>
            <img 
                @click="uploadPhoto"
                src="@/assets/icons/upload_icon_light.svg" 
                class="absolute left-[210px] top-[20px] cursor-pointer z-40"
            >
            <input 
                ref="fileInput"
                type="file" 
                class="hidden"
                :value="inputValue"
                @change="loadImage"
            />
            <ImageCropper 
                v-if="showImageCropper" 
                :image="imageToCrop" 
                @processedImage="processImage"
                class="absolute left-0"
            />
        </div>
        <img :src="photo" @error="photo = require('@/assets/student_photo_placeholder.svg')" class="h-[153px]">
        
        <div class="w-full bg-[#BD9135] pb-[6px] font-['Avenir Roman'] z-20">
            <div class="text-[#252526] text-center text-3xl align-middle font-semibold mt-[7px] h-[34px] w-full bg-transparent border-none">
                <div class="h-[34px] align-middle" v-if="scoutObject.name?.length > 0 || scoutObject.surname?.length > 0">
                    <marquee v-if="scoutObject.name?.length + scoutObject.surname?.length + 1 > 13" behavior="scroll" loop="-1" direction="left">
                        <span v-for="id in 100" class="mx-3 pb-2 text-3xl">{{ scoutObject.name + ' ' + scoutObject.surname }}</span>
                    </marquee>
                    <span v-else class="text-3xl">{{ scoutObject.name + ' ' + scoutObject.surname }}</span>
                </div>
                <span v-else class="opacity-25 text-3xl">Лєо Мессі</span>
            </div>
            <div class="flex justify-center h-[32px]">
                <div class="flex items-center mx-2">
                    <img src="@/assets/logo.svg" class="w-[30px]">
                    <div class="text-[#252526] mx-1 mb-[2px]">|</div>
                    <img src="@/assets/icons/uk_flag_dark.svg" class="w-[20px] ml-[5px]">
                </div>
            </div>
        </div>
        <div class="w-full px-[13px] mt-[15px]">
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input v-model="scoutObject.surname" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" type="text" placeholder="Іваненко">
                </div>
                <div class="text-[#BD9135] font-normal opacity-90 text-[11px]">
                    прізвище
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input v-model="scoutObject.name" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" type="text" placeholder="Іван">
                </div>
                <div class="text-[#BD9135] font-normal opacity-90 text-[11px]">
                    ім'я
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="w-[50%]">
                    <input v-model="scoutObject.patronymic" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[70%] truncate placeholder:opacity-60" type="text" placeholder="Іванович">
                </div>
                <div class="text-[#BD9135] font-normal opacity-90 text-[11px]">
                    по батькові
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="w-[50%]">
                    <input v-model="scoutObject.birthdate" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[80%] truncate placeholder:opacity-60" type="date">
                </div>
                <div class="text-[#BD9135] font-normal opacity-90 text-[11px]">
                    дата народження
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="w-[70%]">
                    <input v-model="scoutObject.phone" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[70%] truncate placeholder:opacity-60" type="text" placeholder="+380000000000">
                </div>
                <div class="text-[#BD9135] font-normal opacity-90 text-[11px]">
                    номер
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="w-[70%]">
                    <input v-model="scoutObject.email" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[70%] truncate placeholder:opacity-60" type="text" placeholder="Email">
                </div>
                <div class="text-[#BD9135] font-normal opacity-90 text-[11px]">
                    email
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="w-[70%]">
                    <input v-model="scoutObject.login" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[70%] truncate placeholder:opacity-60" type="text" placeholder="****">
                </div>
                <div class="text-[#BD9135] font-normal opacity-90 text-[11px]">
                    логін
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="w-[70%]">
                    <input v-model="scoutObject.password" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[70%] truncate placeholder:opacity-60" type="text" placeholder="****">
                </div>
                <div class="text-[#BD9135] font-normal opacity-90 text-[11px]">
                    пароль
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="w-[70%]">
                    <input v-model="scoutObject.job_title" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[70%] truncate placeholder:opacity-60" type="text" placeholder="****">
                </div>
                <div class="text-[#BD9135] font-normal opacity-90 text-[11px]">
                    посада
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="w-[50%]">
                    <input v-model="scoutObject.start_working_date" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[80%] truncate placeholder:opacity-60" type="date">
                </div>
                <div class="text-[#BD9135] font-normal opacity-90 text-[11px]">
                    початок роботи
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch, computed } from 'vue'
    import DataInput from '../ui/DataInput.vue'
    import ExitConfirmForm from './ExitConfirmForm.vue'
    import ImageCropper from '../ui/ImageCropper.vue'
    
    function formateDate(date) {
        const dateObj = new Date(date)
        const year = dateObj.getFullYear()
        const month = dateObj.getMonth() + 1 > 9 ? dateObj.getMonth() + 1 : `0${dateObj.getMonth() + 1}`
        const day = dateObj.getDate() > 9 ? dateObj.getDate() : `0${dateObj.getDate()}`
        return `${year}-${month}-${day}`
    }

    function getMimeType(file, fallback = null) {
        const byteArray = (new Uint8Array(file)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < byteArray.length; i++) {
            header += byteArray[i].toString(16);
        }
        switch (header) {
            case "89504e47":
                return "image/png";
            case "47494638":
                return "image/gif";
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
                return "image/jpeg";
            default:
                return fallback;
        }
    }

    export default {
        name: 'scout-form',

        props: {
            scout: {
                type: Object,
                default: () => ({
                    name: '',
                    surname: '',
                    patronymic: '',
                    birthdate: '',
                    phone: '',
                    email: '',
                    password: '',
                    job_title: '',
                    start_working_date: '',
                    login: '',
                    photo: null
                }),
                required: false
            }
        },

        setup(props, { emit }) {
            const apiURL = ref(process.env.VUE_APP_API_URL)
            const fileInput = ref(null)
            const inputValue = ref(null)
            const photo = ref(apiURL.value + 'static/' + props.scout.photo)

            const scoutObject = ref(props.scout)

            const exitFlag = ref(false)

            const uploadPhoto = () => {
                fileInput.value.click()
            }

            const addNewScout = () => {

                emit('addNewScout', {scout: scoutObject.value, photo: photo.value})
            }

            onMounted(() => {
                if(photo.value === null || photo.value === 'null') {
                    photo.value = require('@/assets/student_photo_placeholder.svg')
                }

                scoutObject.value.start_working_date = formateDate(scoutObject.value.start_working_date)
                scoutObject.value.birthdate = formateDate(scoutObject.value.birthdate)

                console.log(scoutObject.value)
            })

            const newScoutPicture = ref(null);
            const showImageCropper = ref(false)
            const imageToCrop = ref({
                src: '',
                type: ''
            })

            const loadImage = (event) => {
                const { files } = event.target
                if (files && files[0]) {
                    if (imageToCrop.value.src) {
                        URL.revokeObjectURL(imageToCrop.value.src)
                    }
                    const blob = URL.createObjectURL(files[0])
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        imageToCrop.value.src = blob
                        imageToCrop.value.type = getMimeType(e.target.result, files[0].type)
                        showImageCropper.value = true
                    }
                    reader.readAsArrayBuffer(files[0])
                }
            }

            const processImage = (image) => {
                photo.value = image
                showImageCropper.value = false
            }

            return {
                uploadPhoto,
                photo,
                fileInput,
                inputValue,
                scoutObject,
                exitFlag,
                addNewScout,
                newScoutPicture,
                showImageCropper,
                imageToCrop,
                loadImage,
                processImage
            }
        },

        components: {
            DataInput,
            ExitConfirmForm,
            ImageCropper
        },
    }
</script>

<style scoped>

    .student-logo {
        -webkit-filter: drop-shadow(0px 0px 10px #222);
        filter: drop-shadow(0px 0px 10px #222);
    }

    ::-webkit-calendar-picker-indicator {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23BD9135" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
    }

    .avenir-bold {
        font-family: 'Avenir Cyrillic Bold';
    }

    .avenir-standart {
        font-family: 'Avenir Cyrillic';
    }

</style>