<template>
    <div @click="$router.go(-1)" class="bg-[#BD9135] ml-[70px] mb-[20px] flex justify-center items-center w-[228px] h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
        <span>Назад</span>
    </div>
    <div v-if="loaded" class="px-[70px] flex">
        <div>
            <div class="card">
                <div class="flex justify-center">
                    <AvatarContainer v-if="player?.photo" :src="apiURL + 'static/' + player.photo"/>
                    <div v-else class="avatar-container" :class="{ 'avatar-container--small': small }">
                        <div class="text-[#BD9135] text-[32px] ">
                            {{ player?.name[0] + player?.surname[0] }}
                        </div>
                    </div>
                </div>
                <div class="text-[21px] truncate font-semibold mt-[10px]">
                    {{ player?.name + ' ' + player?.surname }}
                </div>

                <div class="w-full h-[74px] mt-[12px]">
                    <div class="flex justify-between w-full mx-[8px]">
                        <div class="flex  items-center justify-center font-normal">
                            <img src="@/assets/logo.svg" class="w-[30px]">
                        </div>
                        <div class="flex flex-col items-center justify-center">
                            <div class="text-[9px]">група</div>
                            <div class="text-[12px] font-semibold">
                                <span v-if="player?.group_name?.length > 0">{{ player?.group_name }}</span>
                                <span v-else class="opacity-25">—</span>
                            </div>    
                        </div>
                        <div class="flex flex-col items-center justify-center  font-normal">
                            <div class="text-[9px]">вік</div>
                            <div class="text-[12px] font-semibold">
                                <span v-if="player?.age > 0">{{ player?.age }}</span>
                                <span v-else class="opacity-25">—</span>
                            </div>    
                        </div>
                        <div class="flex flex-col items-center justify-center  font-normal">
                            <div class="text-[9px]">амплуа</div>
                            <div class="text-[12px] font-semibold">
                                <span v-if="player?.amplua?.length > 0">{{ player?.amplua }}</span>
                                <span v-else class="opacity-25">—</span>
                            </div>    
                        </div>
                        <div class="flex flex-col items-center justify-center  font-normal">
                            <div class="text-[9px]">номер</div>
                            <div class="text-[12px] font-semibold">
                                <span v-if="player?.player_number?.length > 0">{{ player?.player_number }}</span>
                                <span v-else class="opacity-25">—</span>
                            </div>    
                        </div>
                        <div class="flex  items-center justify-center font-normal">
                            <img src="@/assets/icons/ua_flag.svg" class="w-[20px] ml-[5px]">
                        </div>
                    </div>
                </div>

                <div class="relative top-[-39px] mx-auto">
                    <RadarChart :key="new Date().getTime()" :indicators="radarSettings" :chartData="objectiveRadarValue" :max="10" :chart-size="{ width: '230px', height: '200px' }" />
                </div>
            </div>
        </div>
        <div class="w-[80%]">
            <StandardsChart :chartData="chartData" @pointClick="openReport"/>
        </div>
    </div>

    <!-- <div class="px-[70px]">
        <div class="flex gap-[10px]">
            <div class="text-[#C59014]">14.08.2023</div>
            <div class="text-[#C59014] font-bold">U-2011</div>
        </div>
    </div> -->
</template>
<script>
import { ref, onMounted, watch } from 'vue'
import { getAllStandardsRequest, getStudentById, getObjectiveScoreSettings, getObjectiveRadarForStudent, getAllStudentStandardByStudentIdAndStandardId, getPlayersThatCompleteStandardByTraining } from '@/services/apiRequests'
import { useRoute, useRouter } from 'vue-router';
import StandardsChart from './StandardsChart.vue'

export default {
    name: 'CurrentPlayerReports',

    setup() {
        const route = useRoute()
        const router = useRouter()
        const apiURL = process.env.VUE_APP_API_URL

        const playerId = ref(route.params.player_id)
        const baseStandardId = ref(route.params.base_standard_id)
        const player = ref(null)
        const allStandards = ref([])

        const radarSettings = ref([])

        const reports = ref([])
        const chartData = ref({
            categoryData: [],
            values: []
        })

        const loaded = ref(false)

        const allReports = ref([])

        const objectiveRadarValue = ref([])

        const openReport = async (ctx) => {
            const reportDate = ctx.date
            const reportResult = ctx.value

            const currentReport = reports.value.find(report => report.date?.split('T')[0].split('-').reverse().join('.') === reportDate && +report.result === +reportResult)
            console.log('currentReport ------ ', currentReport)
            allReports.value = await getPlayersThatCompleteStandardByTraining(currentReport.base_standard_id, currentReport.training_id)
            console.log('allReports.value ------ ', allReports.value)
        }

        onMounted(async () => {
            allStandards.value = await getAllStandardsRequest(playerId.value)
            let radarList = (await getObjectiveScoreSettings()).field_player
            radarList = [
                radarList.first_point_name,
                radarList.second_point_name,
                radarList.third_point_name,
                radarList.fourth_point_name,
                radarList.fifth_point_name,
                radarList.sixth_point_name,
                radarList.seventh_point_name,
                radarList.eighth_point_name,
                radarList.ninth_point_name
            ].filter(name => name)
            
            console.log('radarSettings.value ------ ', radarList)
            radarList.forEach((item) => {
                radarSettings.value.push({
                    name: item,
                    max: 10
                })    
            })  

            const objectiveRadar = await getObjectiveRadarForStudent(playerId.value)
            objectiveRadarValue.value = objectiveRadar
            
            player.value = (await getStudentById(playerId.value)).data.student
            
            reports.value = await getAllStudentStandardByStudentIdAndStandardId(playerId.value, baseStandardId.value)
            console.log('reports.value ------ ', reports.value)
            reports.value.forEach((report) => {
                chartData.value.categoryData.push(report.date?.split('T')[0].split('-').reverse().join('.'))
                chartData.value.values.push((+report.result).toFixed(2))
            })

            loaded.value = true
        })

        return {
            player,
            allStandards,
            radarSettings,
            loaded,
            chartData,
            openReport,
            objectiveRadarValue,
            apiURL
        }
    },

    components: {
        StandardsChart
    }
}
</script>
<style lang="">
    
</style>