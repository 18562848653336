<template>
    <div></div>
</template>

<script>
import { onMounted, ref } from 'vue';
import * as THREE from 'three';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js';
import axios from 'axios';

async function downloadImageAsBlob(url) {
  try {
    const response = await axios.get(url, {
      responseType: 'blob', // Указываем, что хотим получить ответ в виде blob
    });

    // Возвращаем blob
    return response.data;
  } catch (error) {
    console.error('Ошибка при скачивании изображения:', error);
    throw error;
  }
}

async function imageToBase64(url) {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'image/svg+xml',
        },
    });
    //no-cors
    const blob = await response.blob();
    console.log('blob', blob);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

async function generateSvgImageTag(imageUrl, width, height) {
  // Функция для преобразования изображения в Base64

  // Преобразование изображения в Base64
  const base64Image = await imageToBase64(imageUrl);

  // Генерация SVG тега <image>
  const svgImageTag = `<image id="image0_1230_1143" width="${width}" height="${height}" xlink:href="${base64Image}" />`;

  console.log('svgImageTag', svgImageTag);
  return svgImageTag;
}


export default {
    name: 'PlayerCap',

    props: {
        position: {
            type: Object,
            required: true
        },
        rotation: {
            type: Object,
            required: true
        },
        scale: {
            type: Object,
            required: true
        },
        playerObj: {
            type: Object,
            required: true
        }
    },

    setup(props, { emit }) {
        const apiURL = ref(process.env.VUE_APP_API_URL);

        const svgToTexture = async (svgData, renderer) => {
            const loader = new SVGLoader();
            const svgDataParsed = loader.parse(svgData);

            // Создаем canvas для растеризации SVG
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = 512;
            canvas.height = 512;

            // Очищаем canvas
            context.clearRect(0, 0, canvas.width, canvas.height);

            // Парсим SVG для поиска тегов <image>
            const parser = new DOMParser();
            const svgDocument = parser.parseFromString(svgData, 'image/svg+xml');
            const images = svgDocument.querySelectorAll('image');
            const texts = svgDocument.querySelectorAll('text');

            // Загружаем и рендерим изображения
            for (const image of images) {
                const href = image.getAttribute('href') || image.getAttribute('xlink:href');
                const x = parseFloat(image.getAttribute('x')) || 50;
                const y = parseFloat(image.getAttribute('y')) || -10;
                const width = parseFloat(image.getAttribute('width')) || 0;
                const height = parseFloat(image.getAttribute('height')) || 0;

                if (href) {
                    // Загружаем изображение
                    const img = await new Promise((resolve, reject) => {
                        const imgElement = new Image();
                        imgElement.crossOrigin = 'anonymous'; // Для CORS
                        imgElement.onload = () => resolve(imgElement);
                        imgElement.onerror = reject;
                        imgElement.src = href;
                    });

                    // Рисуем изображение на canvas
                    context.drawImage(img, x, y, width, height);
                }
            }

            // Теперь рендерим векторные элементы (paths)
            const { paths } = svgDataParsed;
            paths.forEach((path) => {
                const fillColor = path.userData.style.fill;
                if (fillColor !== undefined && fillColor !== 'none') {
                    context.fillStyle = fillColor;

                    const shapes = path.toShapes(true);
                    shapes.forEach((shape) => {
                        const shapePoints = shape.getPoints();
                        context.beginPath();

                        shapePoints.forEach((point, index) => {
                            if (index === 0) {
                                context.moveTo(point.x, point.y);
                            } else {
                                context.lineTo(point.x, point.y);
                            }
                        });

                        context.closePath();
                        context.fill();
                    });
                }
            });

            // Рендерим текстовые элементы
            for (const text of texts) {
                let x = parseFloat(text.getAttribute('x')) || 0;
                let y = parseFloat(text.getAttribute('y')) || 0;
                const fontFamily = text.getAttribute('font-family') || 'Arial';
                const fontSize = text.getAttribute('font-size') || '16';
                const fontWeight = text.getAttribute('font-weight') || 'normal'; // Добавлена обработка font-weight
                const fill = text.getAttribute('fill') || '#000000';
                const textAnchor = text.getAttribute('text-anchor') || 'start';
                const dominantBaseline = text.getAttribute('dominant-baseline') || 'alphabetic';
                const content = text.textContent.trim();

                // Настройка шрифта и цвета
                context.font = `${fontWeight} ${fontSize}px ${fontFamily}`; // Учет font-weight
                context.fillStyle = fill;

                // Обработка текстового выравнивания
                if (textAnchor === 'middle') {
                    context.textAlign = 'center';
                } else if (textAnchor === 'end') {
                    context.textAlign = 'right';
                } else {
                    context.textAlign = 'left';
                }

                // Обработка вертикального выравнивания
                if (dominantBaseline === 'middle') {
                    y += parseFloat(fontSize) / 2;
                } else if (dominantBaseline === 'hanging') {
                    y += parseFloat(fontSize);
                }

                // Рисуем текст
                context.fillText(content, x, y);
            }

            // Создаем текстуру из canvas
            const texture = new THREE.CanvasTexture(canvas);
            texture.anisotropy = renderer.capabilities.getMaxAnisotropy();
            texture.encoding = THREE.sRGBEncoding;
            return texture;
        };

        onMounted(async () => {
            // Создание рендера
            const renderer = new THREE.WebGLRenderer();
            renderer.setSize(512, 512);

            console.log('PlayerCap props', apiURL.value + 'static/' + props.playerObj.photo);
            const imageUrl = apiURL.value + 'static/' + props.playerObj.photo;
            let imageBase = '';
            try {
                imageBase = await generateSvgImageTag(apiURL.value + 'static_files/' + props.playerObj.photo, 412, 412);
            } catch (e) {
                console.error('Error while loading image', e);
            }

            // Загрузка текстур
            // Пример SVG-кода
            const frontSvg = `<svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <rect x="0.5" y="376.49998" width="519" height="139" rx="249.5" fill="#C59014" stroke="#C59014" stroke-width="13"/>
            <text x="256" y="426" dominant-baseline="middle" text-anchor="middle" font-family="Arial" font-size="29" fill="white">${props.playerObj.name}</text>
            <text x="256" y="456" dominant-baseline="middle" text-anchor="middle" font-weight="bold" font-family="Arial" font-size="29" fill="white">#${props.playerObj.number}</text>
            <defs>
            ${imageBase}
            </defs>
                </svg>`;
            const backSvg = `<svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M256 6.50024C393.795 6.50024 505.5 118.205 505.5 256C505.5 393.795 393.795 505.5 256 505.5C118.205 505.5 6.5 393.795 6.5 256C6.5 118.205 118.205 6.50024 256 6.50024Z" fill="black" stroke="black" stroke-width="13"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M293.311 355.277H269.514V316.549L239.635 355.4H214L269.514 284.576V256.347L190.125 355.339H164.98L230.875 269.043L155 157L180.66 157.054L246.429 253.15L258.349 238.594L204.755 157L232.682 157.054L269.514 213.192V157.082H293.311V249.145L295.387 252.599L312.958 226.89V157.082H337.49V355.4H313.57V259.35L293.311 285.28V355.277Z" fill="white"/>
                </svg>`;

            // Преобразуем SVG в текстуры
            const playerTexture = await svgToTexture(frontSvg, renderer);
            const clubLogoTexture = await svgToTexture(backSvg, renderer);


            // Создание геометрии шайбы
            const geometry = new THREE.CylinderGeometry(1, 1, 0.25, 32);

            // Создание материалов
            const playerMaterial = new THREE.MeshStandardMaterial({ map: playerTexture });
            const logoMaterial = new THREE.MeshStandardMaterial({ map: clubLogoTexture });
            const sideMaterial = new THREE.MeshStandardMaterial({ color: 0xBD9135 });


            // Композитный материал
            const materials = [
                sideMaterial, // Боковая часть
                playerMaterial, // Верхняя часть (фото футболиста)
                logoMaterial, // Нижняя часть (логотип клуба)
            ];

            // Создание объекта шайбы
            const puck = new THREE.Mesh(geometry, materials);

            // puck.rotation.z = Math.PI / 2; // Поворот шайбы на 90 градусов

            puck.rotation.z = props.rotation.z
            puck.rotation.x = props.rotation.x
            puck.rotation.y = props.rotation.y

            puck.scale.set(0.5, 0.5, 0.5);

            puck.position.z = props.position.z
            puck.position.x = props.position.x
            puck.position.y = props.position.y

            // Добавление объекта на сцену
            emit('add-object', puck);
        });

        return { };
    },
};
</script>

<style>
/* Добавьте стили, если нужно */
</style>
