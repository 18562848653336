<template>

    <TableRow>
        <TableCell :width="200" textAlign="start" :asChild="true">
            <div class="flex">
                <div @click="closed = !closed" class="cursor-pointer">
                    <svg v-if="!closed" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 9L11.2929 14.2929C11.6262 14.6262 11.7929 14.7929 12 14.7929C12.2071 14.7929 12.3738 14.6262 12.7071 14.2929L18 9" stroke="#BD9135" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>    
                    </svg>
                    <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.99991 15.0001L11.2928 9.70717C11.6261 9.37383 11.7928 9.20717 11.9999 9.20717C12.207 9.20717 12.3737 9.37383 12.707 9.70717L17.9999 15.0001" stroke="#BD9135" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <input v-model="group.group_name" type="text" placeholder="Назва групи">
            </div>
        </TableCell>
        <TableCell :width="200" textAlign="start" :asChild="true"></TableCell>
        <TableCell :width="200" textAlign="start" :asChild="true">
            <div class="flex justify-end gap-[10px]">
                <img v-if="!group.updating" @click="group.updating = true" src="@/assets/icons/pen_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                <img v-else @click="() => { $emit('updateGroup', {group_id: group.group_id, group_name: group.group_name}); group.updating = false}" src="@/assets/icons/green_check_point.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                <img @click="$emit('deleteGroup', group.group_id)" src="@/assets/icons/trash_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" />
            </div>
        </TableCell>
    </TableRow>

    <TableRow v-if="closed" v-for="standard in group.standards" :key="standard.id">
        <TableCell textAlign="start" :asChild="true"></TableCell>
        <TableCell textAlign="start" :asChild="true">
            <input v-if="!standard.updating" :value="standard.name" disabled type="text" placeholder="Назва нормативу">
            <input v-else v-model="standard.name" type="text" placeholder="Назва нормативу">
        </TableCell>
        <TableCell textAlign="start" :asChild="true">
            <div class="flex justify-end gap-[10px]">
                <img v-if="!standard.updating" @click="standard.updating = true" src="@/assets/icons/pen_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                <img v-else @click="() => { $emit('updateStandard', {standard_id: standard.id, standard_name: standard.name}); standard.updating = false}" src="@/assets/icons/green_check_point.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                <img @click="deleteStandard(standard.id)" src="@/assets/icons/trash_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" />
            </div>
        </TableCell>
    </TableRow>

    <TableRow v-if="closed"> 
        <TableCell textAlign="start" :asChild="true"></TableCell>
        <TableCell textAlign="start" :asChild="true">
            <input v-model="newStandard" type="text" placeholder="Назва нормативу">
        </TableCell>
        <TableCell textAlign="start" :asChild="true">
            <div class="flex justify-end gap-[10px]">
                <img @click="addStandard" src="@/assets/icons/green_check_point.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                <img @click="clearNewStandard" src="@/assets/icons/trash_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" />
            </div>
        </TableCell>
    </TableRow>

</template>

<script>
import { ref } from 'vue'

export default {
    name: 'GroupAndStandardsRow',
    props: {
        group: {
            type: Object,
            required: true
        }
    },

    setup(props, { emit }) {
        const newStandard = ref('')
        const closed = ref(false)

        const addStandard = () => {
            emit('addStandard', {group_id: props.group.group_id, group_name: props.group.group_name, standard_name: newStandard.value})
            newStandard.value = ''
        }

        const clearNewStandard = () => {
            newStandard.value = ''
        }

        const updateStandard = (id, name) => {
            emit('updateStandard', id, name)
        }

        const deleteStandard = (id) => {
            emit('deleteStandard', id)
        }

        return {
            newStandard,
            addStandard,
            clearNewStandard,
            updateStandard,
            deleteStandard,
            closed
        }
    }
}
</script>

<style>
    
</style>