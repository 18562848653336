<template>
  <div ref="chart" style="width: 200px; height: 80px;"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  props: {
    values: {
      type: Array,
      required: true
    },
    dates: {
      type: Array,
      required: true
    }
  },
  mounted() {
    console.log('this.values', this.values);
    console.log('this.dates', this.dates);
    this.createChart();
  },
  methods: {
    createChart() {
      const chartDom = this.$refs.chart;
      const myChart = echarts.init(chartDom, null, { renderer: 'svg' });

      const option = {
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            // Отображение даты и значения в подсказке
            return `Дата: ${this.dates[params[0].dataIndex]}<br>Результат: ${params[0].data}`;
          },
          backgroundColor: 'rgba(50, 50, 50, 0.7)',
          textStyle: {
            color: '#fff'
          }
        },
        xAxis: {
          type: 'category',
          // data: this.dates, // Используем массив дат для оси X
          show: true,
          axisLine: {
            lineStyle: {
              color: 'rgb(30, 30, 30)'
            }
          },
          axisLabel: null
        },
        yAxis: {
          type: 'value',
          show: true,
          axisLine: {
            lineStyle: {
              color: 'rgb(30, 30, 30)'
            }
          },
          splitLine: {
            lineStyle: {
              color: 'rgb(30, 30, 30)'
            }
          }
        },
        series: [{
          data: this.values,
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 5,
          lineStyle: {
            color: '#FFA500',
            width: 2
          },
          itemStyle: {
            color: '#FFA500'
          }
        }]
      };

      myChart.setOption(option);
    }
  }
};
</script>

<style>
/* Стили могут быть добавлены сюда */
</style>
