<template>
  <div class="select-component">
    <label :for="name" class="select-component__label">{{ label }}</label>
    <div class="select-component__container" :tabindex="tabindex" @blur="open = false">
      <div class="select-component__selected" :class="{ 'select-component__selected--open': open }" @click="open = !open">
        {{ options ? selected : objectOptions.find(option => option.id === selected).label }}
        <img src="@/assets/refactored_assets/img/form/arrow.svg" alt="Arrow" class="select-component__arrow" :class="{ 'select-component__arrow--open': open }">
      </div>
      <div class="select-component__items h-[150px] overflow-y-scroll" :class="{ 'select-component__items--hidden': !open }">
        <div
          v-if="options"
          v-for="(option, i) of options"
          :key="i"
          @click="
            () => {
              selected = option;
              open = false;
              $emit('input', option);
            }
          "
          class="select-component__item"
        >
          {{ option }}
        </div>
        <div
          v-else
          v-for="(option, i) of objectOptions"
          :key="option.id"
          @click=" () => {
            selected = option.id;
            open = false;
            $emit('input', option.id);
          }
          "
          class="select-component__item"
        >
          {{ option.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectComponent',
  props: {
    label: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      default: null,
      required: false,
    },
    objectOptions: {
      type: Array,
      default: null,
      required: false,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    name: {
      type: String,
      required: true
    },
  },
  watch: {
    selected(value) {
      this.$emit('change', value);
      console.log('this.selected ======== ', this.selected);
      console.log('this.default ======== ', this.default);
    },

    objectOptions(value) {
      this.selected = value[0].id;
    },

    options(value) {
      this.selected = value[0];
    },
  },
  data() {
    return {
      selected: this.default ? this.default : this.options ? this.options[0] : this.objectOptions[0].id,
      open: false,
    };
  },
  mounted() {
    console.log('this.objectOptions ======== ', this.objectOptions);

    this.$emit("input", this.selected);
  },
};
</script>