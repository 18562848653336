<template>
    <div @click="toggle" class="switch__1">
        <input  type="checkbox" :checked="checked" />
        <label ></label>
    </div>
</template>
<script>
export default {
    name: 'IphoneSwitch',
    props: {
        value: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            checked: this.value
        }
    },
    watch: {
        value(newVal) {
            this.checked = newVal;
        }
    },
    methods: {
        toggle() {
            this.checked = !this.checked;
            this.$emit('input', this.checked);
        }
    }
}
</script>
<style>
    
</style>