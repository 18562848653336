<template>
<Renderer  ref="renderer" :width="width" :height="height * 0.87" antialias :orbit-ctrl="{ enableDamping: true, }" shadow  :pointer="{ intersectRecursive: true }">
    <Camera :position="{ x: 20, y: 8, z: 0 }"  />
    <Scene background="rgb(30, 30, 30)">
    <AmbientLight :intensity="1"></AmbientLight>
    <!-- <PointLight ref="light1" color="#FFF" :intensity="0.8" :position="{ x: 20, y: 10, z: 0 }" />
    <PointLight ref="light1" color="#FFF" :intensity="0.8" :position="{ x: -20, y: -10, z: 0 }" />
    <PointLight ref="light2" color="#FFF" :intensity="0.75" :position="{ x: 120, y: 50, z: 30 }" /> -->
   
    <Group>
        <GltfModel ref="field" src="/models3d/FIELD.glb" />
    </Group>

    <Group v-if="fieldPart == 'left'">
        <!-- <GltfModel 
        v-for="player in positionsFirstTeam[currentPositionFirstTeam]" 
        src="/models3d/player1.glb"  

        :position="player.position"
        :rotation="player.rotation"
        :scale="player.scale"
        /> -->
        <PlayerCap 
            @add-object="addObjectToScene" 
            v-for="(player, id) in formattedTeamObjects"          
            :position="player.position"
            :rotation="player.rotation"
            :scale="player.scale" 
            :key="key"
            :playerObj="player.playerObj"
        />
    </Group>
    <Group v-else-if="fieldPart == 'right'">
        <!-- <GltfModel 
        v-for="player in positionsSecondTeam[currentPositionFirstTeam]" 
        src="/models3d/player1.glb"  

        :position="player.position"
        :rotation="player.rotation"
        :scale="player.scale"
        /> -->
        <PlayerCap 
            @add-object="addObjectToScene" 
            v-for="(player, id) in formattedTeamObjects"          
            :position="player.position"
            :rotation="player.rotation"
            :scale="player.scale" 
            :key="key"
            :playerObj="player.playerObj"
        />
    </Group>
    </Scene>
</Renderer>
<!-- <div class="interface">
    <div>
    <div class="text">Команда 1</div>
    <select v-model="currentPositionFirstTeam" name="position">
        <option value="4-4-2">4-4-2</option>
        <option value="4-5-1">4-5-1</option>
        <option value="4-3-3">4-3-3</option>
        <option value="4-3-2-1">4-3-2-1</option>
        <option value="4-1-3-2">4-1-3-2</option>
        <option value="5-4-1">5-4-1</option>
        <option value="5-3-2">5-3-2</option>
        <option value="4-2-3-1">4-2-3-1</option>
    </select>
    </div>
    <div>
    <div class="text">Команда 2</div>
    <select v-model="currentPositionSecondTeam" name="position">
        <option value="4-4-2">4-4-2</option>
        <option value="4-5-1">4-5-1</option>
        <option value="4-3-3">4-3-3</option>
        <option value="4-3-2-1">4-3-2-1</option>
        <option value="4-1-3-2">4-1-3-2</option>
        <option value="5-4-1">5-4-1</option>
        <option value="5-3-2">5-3-2</option>
        <option value="4-2-3-1">4-2-3-1</option>
    </select>
    </div>
    <button @click="startGoalAnimation">Гол</button>
</div> -->
</template>

<script>
import { AnimationMixer, Clock, Fog, GridHelper, Vector3 } from 'three';
import { FbxModel } from 'troisjs';
import PlayerCap from './PlayerCap.vue';
import axios from 'axios';

export default {

props: {
    currentPositionFirstTeam: {
        type: String,
        default: '4-4-2',
    },
    fieldPart: {
        type: String,
        default: 'left',
    },
    teamPlayersObjects: {
        type: Array,
        default: () => [],
    },
},

data() {
    return {
    width: window.innerWidth,
    height: window.innerHeight,

    currentPositionSecondTeam: '4-4-2',
    key: 0,

    formattedTeamObjects: [],

    positionsFirstTeam: {
        '4-4-2' : [
        //вратарь
        {
            amplua: ['ВР'],
            position: { x: 0, y: 0.5, z: 7.5 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        //защитники
        {
            amplua: ['ЛЗ'],
            position: { x: 2, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: 4, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: -2, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПЗ'],
            position: { x: -4, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //полузащитники
        {
            amplua: ['ЛП'],
            position: { x: 0, y: 0.5, z: 4 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: 3, y: 0.5, z: 3 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: -3, y: 0.5, z: 3 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПП'],
            position: { x: 0, y: 0.5, z: 2 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        //нападающие
        {
            amplua: ['НП'],
            position: { x: 2, y: 0.5, z: 0.6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['НП'],
            position: { x: -2, y: 0.5, z: 0.6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        ],
        '4-5-1' : [
        //вратарь
        {
            amplua: ['ВР'],
            position: { x: 0, y: 0.5, z: 7.5 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        

        //защитники
        {
            amplua: ['ЛЗ'],
            position: { x: 2, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: 4, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: -2, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПЗ'],
            position: { x: -4, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //полузащитники
        {
            amplua: ['ЛП'],
            position: { x: 0, y: 0.5, z: 5 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЛП'],
            position: { x: 2, y: 0.5, z: 4 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: 4, y: 0.5, z: 3 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПП'],
            position: { x: -2, y: 0.5, z: 4 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПП'],
            position: { x: -4, y: 0.5, z: 3 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //нападающие
        {
            amplua: ['НП'],
            position: { x: 0, y: 0.5, z: 2 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        ],
        '4-3-3' : [
        //вратарь
        {
            amplua: ['ВР'],
            position: { x: 0, y: 0.5, z: 7.5 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        //защитники
        {
            amplua: ['ЛЗ'],
            position: { x: 2, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: 4, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: -2, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПЗ'],
            position: { x: -4, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //полузащитники
        {
            amplua: ['ЛП'],
            position: { x: 0, y: 0.5, z: 3 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: 3, y: 0.5, z: 3 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПП'],
            position: { x: -3, y: 0.5, z: 3 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        //нападающие
        {
            amplua: ['НП'],
            position: { x: 0, y: 0.5, z: 0.6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['НП'],
            position: { x: 2, y: 0.5, z: 0.6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['НП'],
            position: { x: -2, y: 0.5, z: 0.6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        ],
        '4-3-2-1' : [
        //вратарь
        {
            amplua: ['ВР'],
            position: { x: 0, y: 0.5, z: 7.5 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        //защитники
        {
            amplua: ['ЛЗ'],
            position: { x: 2, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: 4, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: -2, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПЗ'],
            position: { x: -4, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //полузащитники
        {
            amplua: ['ЛП', 'ЦП', 'ЦОП', 'ЦАП'],
            position: { x: 0, y: 0.5, z: 4 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: 3, y: 0.5, z: 4 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПП', 'ЦП', 'ЦОП', 'ЦАП'],
            position: { x: -3, y: 0.5, z: 4 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //нападающие
        {
            amplua: ['НП'],
            position: { x: -2, y: 0.5, z: 2 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['НП'],
            position: { x: 2, y: 0.5, z: 2 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //нападающие
        {
            amplua: ['НП'],
            position: { x: 0, y: 0.5, z: 0.6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        ],
        '4-1-3-2' : [
        //вратарь
        {
            amplua: ['ВР'],
            position: { x: 0, y: 0.5, z: 7.5 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        //защитники
        {
            amplua: ['ЛЗ'],
            position: { x: 2, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: 4, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: -2, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПЗ'],
            position: { x: -4, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //полузащитники
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: 0, y: 0.5, z: 5 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //нападающие
        {
            amplua: ['НП'],
            position: { x: -2, y: 0.5, z: 3 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['НП'],
            position: { x: 2, y: 0.5, z: 3 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['НП'],
            position: { x: 0, y: 0.5, z: 3 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //нападающие
        {
            amplua: ['НП'],
            position: { x: -1.5, y: 0.5, z: 1 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['НП'],
            position: { x: 1.5, y: 0.5, z: 1 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        ],
        '5-4-1' : [
        //вратарь
        {
            amplua: ['ВР'],
            position: { x: 0, y: 0.5, z: 7.5 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        //защитники
        {
            amplua: ['ЛЗ'],
            position: { x: 2, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: 4, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: -2, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: -4, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПЗ'],
            position: { x: 0, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //полузащитники
        {
            amplua: ['ЛП'],
            position: { x: -3, y: 0.5, z: 3.5 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: 1, y: 0.5, z: 3.5 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: -1, y: 0.5, z: 3.5 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПП'],
            position: { x: 3, y: 0.5, z: 3.5 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        //нападающие
        {
            amplua: ['НП'],
            position: { x: 0, y: 0.5, z: 0.6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        ],
        '5-3-2' : [
        //вратарь
        {
            amplua: ['ВР'],
            position: { x: 0, y: 0.5, z: 7.5 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        //защитники
        {
            amplua: ['ЛЗ'],
            position: { x: 2, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: 4, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: -2, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: -4, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПЗ'],
            position: { x: 0, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //полузащитники
        {
            amplua: ['ЛП'],
            position: { x: -3, y: 0.5, z: 3.5 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: 0, y: 0.5, z: 3.5 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПП'],
            position: { x: 3, y: 0.5, z: 3.5 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        //нападающие
        {
            amplua: ['НП'],
            position: { x: -2, y: 0.5, z: 0.6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['НП'],
            position: { x: 2, y: 0.5, z: 0.6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        ],
        '4-2-3-1' : [
        //вратарь
        {
            amplua: ['ВР'],
            position: { x: 0, y: 0.5, z: 7.5 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        //защитники
        {
            amplua: ['ЛЗ'],
            position: { x: 2, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: 4, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: -2, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПЗ'],
            position: { x: -4, y: 0.5, z: 6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //полузащитники
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: -1, y: 0.5, z: 4 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: 1, y: 0.5, z: 4 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //нападающие
        {
            amplua: ['НП'],
            position: { x: -2, y: 0.5, z: 2 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['НП'],
            position: { x: 0, y: 0.5, z: 2 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['НП'],
            position: { x: 2, y: 0.5, z: 2 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //нападающие
        {
            amplua: ['НП'],
            position: { x: 0, y: 0.5, z: 0.6 },
            rotation: { x: 0, y: 4.71, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        ],
        '3-4-3' : [
            //вратарь
            {
                amplua: ['ВР'],
                position: { x: 0, y: 0.5, z: 7.5 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            
            //защитники
            {
                amplua: ['ЛЗ'],
                position: { x: 0, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦЗ'],
                position: { x: 3, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПЗ'],
                position: { x: -3, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //полузащитники
            {
                amplua: ['ЛП'],
                position: { x: 2, y: 0.5, z: 3 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: 4, y: 0.5, z: 3 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: -2, y: 0.5, z: 3 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПП'],
                position: { x: -4, y: 0.5, z: 3 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            
            //нападающие
            {
                amplua: ['НП'],
                position: { x: 0, y: 0.5, z: 0.6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['НП'],
                position: { x: 2, y: 0.5, z: 0.6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['НП'],
                position: { x: -2, y: 0.5, z: 0.6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
        ],
        '3-5-3' : [
            //вратарь
            {
                amplua: ['ВР'],
                position: { x: 0, y: 0.5, z: 7.5 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            
            //защитники
            {
                amplua: ['ЛЗ'],
                position: { x: 0, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦЗ'],
                position: { x: 3, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПЗ'],
                position: { x: -3, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //полузащитники
            {
                amplua: ['ЛП'],
                position: { x: 2, y: 0.5, z: 3 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: 4, y: 0.5, z: 3 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: 0, y: 0.5, z: 3 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: -2, y: 0.5, z: 3 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПП'],
                position: { x: -4, y: 0.5, z: 3 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            
            //нападающие
            {
                amplua: ['НП'],
                position: { x: 0, y: 0.5, z: 0.6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['НП'],
                position: { x: 2, y: 0.5, z: 0.6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['НП'],
                position: { x: -2, y: 0.5, z: 0.6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
        ],
        '3-4-2-1' : [
            //вратарь
            {
                amplua: ['ВР'],
                position: { x: 0, y: 0.5, z: 7.5 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            
            //защитники
            {
                amplua: ['ЛЗ'],
                position: { x: 0, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦЗ'],
                position: { x: 3, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПЗ'],
                position: { x: -3, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //полузащитники
            {
                amplua: ['ЛП'],
                position: { x: 2, y: 0.5, z: 4 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: 4, y: 0.5, z: 4 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: -2, y: 0.5, z: 4 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПП'],
                position: { x: -4, y: 0.5, z: 4 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //нападающие
            {
                amplua: ['НП'],
                position: { x: -2, y: 0.5, z: 2 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['НП'],
                position: { x: 2, y: 0.5, z: 2 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //нападающие
            {
                amplua: ['НП'],
                position: { x: 0, y: 0.5, z: 0.6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
        ],
        '3-4-1-2' : [
            //вратарь
            {
                amplua: ['ВР'],
                position: { x: 0, y: 0.5, z: 7.5 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            
            //защитники
            {
                amplua: ['ЛЗ'],
                position: { x: 0, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦЗ'],
                position: { x: 3, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПЗ'],
                position: { x: -3, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //полузащитники
            {
                amplua: ['ЛП'],
                position: { x: 2, y: 0.5, z: 4 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: 4, y: 0.5, z: 4 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: -2, y: 0.5, z: 4 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПП'],
                position: { x: -4, y: 0.5, z: 4 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //нападающие
            {
                amplua: ['НП'],
                position: { x: 0, y: 0.5, z: 2 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //нападающие
            {
                amplua: ['НП'],
                position: { x: -2, y: 0.5, z: 0.6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['НП'],
                position: { x: 2, y: 0.5, z: 0.6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
        ],
        '3-3-3-1' : [
            //вратарь
            {
                amplua: ['ВР'],
                position: { x: 0, y: 0.5, z: 7.5 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            
            //защитники
            {
                amplua: ['ЛЗ'],
                position: { x: 0, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦЗ'],
                position: { x: 3, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПЗ'],
                position: { x: -3, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //полузащитники
            {
                amplua: ['ЛП'],
                position: { x: 0, y: 0.5, z: 4 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: 3, y: 0.5, z: 4 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПП'],
                position: { x: -3, y: 0.5, z: 4 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //нападающие
            {
                amplua: ['НП'],
                position: { x: 0, y: 0.5, z: 2 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['НП'],
                position: { x: 3, y: 0.5, z: 2 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['НП'],
                position: { x: -3, y: 0.5, z: 2 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //нападающие
            {
                amplua: ['НП'],
                position: { x: 0, y: 0.5, z: 0.6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

        ],
        '4-4-1-1' : [
            //вратарь
            {
                amplua: ['ВР'],
                position: { x: 0, y: 0.5, z: 7.5 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            
            //защитники
            {
                amplua: ['ЛЗ'],
                position: { x: 2, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦЗ'],
                position: { x: 4, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦЗ'],
                position: { x: -2, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПЗ'],
                position: { x: -4, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //полузащитники
            {
                amplua: ['ЛП'],
                position: { x: 2, y: 0.5, z: 4 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: 4, y: 0.5, z: 4 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: -2, y: 0.5, z: 4 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПП'],
                position: { x: -4, y: 0.5, z: 4 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //нападающие
            {
                amplua: ['НП'],
                position: { x: 0, y: 0.5, z: 2 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //нападающие
            {
                amplua: ['НП'],
                position: { x: 0, y: 0.5, z: 0.6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
        ],
        '4-1-4-1' : [
            //вратарь
            {
                amplua: ['ВР'],
                position: { x: 0, y: 0.5, z: 7.5 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            
            //защитники
            {
                amplua: ['ЛЗ'],
                position: { x: 2, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦЗ'],
                position: { x: 4, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦЗ'],
                position: { x: -2, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПЗ'],
                position: { x: -4, y: 0.5, z: 6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //полузащитники
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: 0, y: 0.5, z: 4 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //нападающие
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП', 'ЛП', 'ПП'],
                position: { x: 2, y: 0.5, z: 2 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП', 'ЛП', 'ПП'],
                position: { x: 4, y: 0.5, z: 2 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП', 'ЛП', 'ПП'],
                position: { x: -2, y: 0.5, z: 2 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП', 'ЛП', 'ПП'],
                position: { x: -4, y: 0.5, z: 2 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //нападающие
            {
                amplua: ['НП'],
                position: { x: 0, y: 0.5, z: 0.6 },
                rotation: { x: 0, y: 4.71, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
        ],
    },

    positionsSecondTeam: {
        '4-4-2' : [
        //вратарь
        {
            amplua: ['ВР'],
            position: { x: 0, y: 0.5, z: -7.5 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
            src: '/player_anim.fbx'
        },
        //защитники
        {
            amplua: ['ЛЗ'],
            position: { x: -2, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: -4, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: 2, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПЗ'],
            position: { x: 4, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //полузащитники
        {
            amplua: ['ЛП'],
            position: { x: 0, y: 0.5, z: -4 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: 3, y: 0.5, z: -3 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: -3, y: 0.5, z: -3 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПП'],
            position: { x: 0, y: 0.5, z: -2 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        //нападающие
        {
            amplua: ['НП'],
            position: { x: 2, y: 0.5, z: -0.6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['НП'],
            position: { x: -2, y: 0.5, z: -0.6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        ],
        '4-5-1' : [
        //вратарь
        {
            amplua: ['ВР'],
            position: { x: 0, y: 0.5, z: -7.5 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //защитники
        {
            amplua: ['ЛЗ'],
            position: { x: 2, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: 4, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: -2, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПЗ'],
            position: { x: -4, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //полузащитники
        {
            amplua: ['ЛП'],
            position: { x: 0, y: 0.5, z: -5 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: 2, y: 0.5, z: -4 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: 4, y: 0.5, z: -3 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: -2, y: 0.5, z: -4 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПП'],
            position: { x: -4, y: 0.5, z: -3 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //нападающие
        {
            amplua: ['НП'],
            position: { x: 0, y: 0.5, z: -2 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        ],
        '4-3-3' : [
        //вратарь
        {
            amplua: ['ВР'],
            position: { x: 0, y: 0.5, z: -7.5 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        //защитники
        {
            amplua: ['ЛЗ'],
            position: { x: 2, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: 4, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: -2, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПЗ'],
            position: { x: -4, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //полузащитники
        {
            amplua: ['ЛП'],
            position: { x: 0, y: 0.5, z: -3 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: 3, y: 0.5, z: -3 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПП'],
            position: { x: -3, y: 0.5, z: -3 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        //нападающие
        {
            amplua: ['НП'],
            position: { x: 0, y: 0.5, z: -0.6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['НП'],
            position: { x: 2, y: 0.5, z: -0.6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['НП'],
            position: { x: -2, y: 0.5, z: -0.6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        ],
        '4-3-2-1' : [
        //вратарь
        {
            amplua: ['ВР'],
            position: { x: 0, y: 0.5, z: -7.5 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        //защитники
        {
            amplua: ['ЛЗ'],
            position: { x: 2, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: 4, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: -2, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПЗ'],
            position: { x: -4, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //полузащитники
        {
            amplua: ['ЛП'],
            position: { x: 0, y: 0.5, z: -4 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: 3, y: 0.5, z: -4 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПП'],
            position: { x: -3, y: 0.5, z: -4 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //нападающие
        {
            amplua: ['НП'],
            position: { x: -2, y: 0.5, z: -2 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['НП'],
            position: { x: 2, y: 0.5, z: -2 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //нападающие
        {
            amplua: ['НП'],
            position: { x: 0, y: 0.5, z: -0.6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        ],
        '4-1-3-2' : [
        //вратарь
        {
            amplua: ['ВР'],
            position: { x: 0, y: 0.5, z: -7.5 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        //защитники
        {
            amplua: ['ЛЗ'],
            position: { x: 2, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: 4, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: -2, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПЗ'],
            position: { x: -4, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //полузащитники
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: 0, y: 0.5, z: -5 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //нападающие
        {
            amplua: ['НП'],
            position: { x: -2, y: 0.5, z: -3 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['НП'],
            position: { x: 2, y: 0.5, z: -3 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['НП'],
            position: { x: 0, y: 0.5, z: -3 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //нападающие
        {
            amplua: ['НП'],
            position: { x: -1.5, y: 0.5, z: -1 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['НП'],
            position: { x: 1.5, y: 0.5, z: -1 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        ],
        '5-4-1' : [
        //вратарь
        {
            amplua: ['ВР'],
            position: { x: 0, y: 0.5, z: -7.5 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        //защитники
        {
            amplua: ['ЛЗ'],
            position: { x: 2, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: 4, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: -2, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: -4, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПЗ'],
            position: { x: 0, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //полузащитники
        {
            amplua: ['ЛП'],
            position: { x: -3, y: 0.5, z: -3.5 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: 1, y: 0.5, z: -3.5 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: -1, y: 0.5, z: -3.5 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПП'],
            position: { x: 3, y: 0.5, z: -3.5 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        //нападающие
        {
            amplua: ['НП'],
            position: { x: 0, y: 0.5, z: -0.6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        ],
        '5-3-2' : [
        //вратарь
        {
            amplua: ['ВР'],
            position: { x: 0, y: 0.5, z: -7.5 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        //защитники
        {
            amplua: ['ЛЗ'],   
            position: { x: 2, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: 4, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: -2, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: -4, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПЗ'],
            position: { x: 0, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //полузащитники
        {
            amplua: ['ЛП'],
            position: { x: -3, y: 0.5, z: -3.5 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: 0, y: 0.5, z: -3.5 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПП'],
            position: { x: 3, y: 0.5, z: -3.5 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        //нападающие
        {
            amplua: ['НП'],
            position: { x: -2, y: 0.5, z: -0.6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['НП'],
            position: { x: 2, y: 0.5, z: -0.6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        ],
        '4-2-3-1' : [
        //вратарь
        {
            amplua: ['ВР'],
            position: { x: 0, y: 0.5, z: -7.5 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        
        //защитники
        {
            amplua: ['ЛЗ'],
            position: { x: 2, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: 4, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦЗ'],
            position: { x: -2, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ПЗ'],
            position: { x: -4, y: 0.5, z: -6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //полузащитники
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: -1, y: 0.5, z: -4 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['ЦП', 'ЦОП', 'ЦАП'],
            position: { x: 1, y: 0.5, z: -4 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //нападающие
        {
            amplua: ['НП'],
            position: { x: -2, y: 0.5, z: -2 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['НП'],
            position: { x: 0, y: 0.5, z: -2 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        {
            amplua: ['НП'],
            position: { x: 2, y: 0.5, z: -2 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },

        //нападающие
        {
            amplua: ['НП'],
            position: { x: 0, y: 0.5, z: -0.6 },
            rotation: { x: 0, y: 1.57, z: 1.57 },
            scale: { x: 0.004, y: 0.004, z: 0.004 },
        },
        ],
        '3-4-3' : [
            //вратарь
            {
                amplua: ['ВР'],
                position: { x: 0, y: 0.5, z: -7.5 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            
            //защитники
            {
                amplua: ['ЛЗ'],
                position: { x: 0, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦЗ'],
                position: { x: 3, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПЗ'],
                position: { x: -3, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //полузащитники
            {
                amplua: ['ЛП'],
                position: { x: 2, y: 0.5, z: -3 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: 4, y: 0.5, z: -3 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: -2, y: 0.5, z: -3 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПП'],
                position: { x: -4, y: 0.5, z: -3 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            
            //нападающие
            {
                amplua: ['НП'],
                position: { x: 0, y: 0.5, z: -0.6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['НП'],
                position: { x: 2, y: 0.5, z: -0.6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['НП'],
                position: { x: -2, y: 0.5, z: -0.6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
        ],
        '3-5-3' : [
            //вратарь
            {
                amplua: ['ВР'],
                position: { x: 0, y: 0.5, z: -7.5 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            
            //защитники
            {
                amplua: ['ЛЗ'],
                position: { x: 0, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦЗ'],
                position: { x: 3, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПЗ'],
                position: { x: -3, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //полузащитники
            {
                amplua: ['ЛП'],
                position: { x: 2, y: 0.5, z: -3 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: 4, y: 0.5, z: -3 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: 0, y: 0.5, z: -3 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПП'],
                position: { x: -2, y: 0.5, z: -3 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПП'],
                position: { x: -4, y: 0.5, z: -3 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            
            //нападающие
            {
                amplua: ['НП'],
                position: { x: 0, y: 0.5, z: -0.6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['НП'],
                position: { x: 2, y: 0.5, z: -0.6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['НП'],
                position: { x: -2, y: 0.5, z: -0.6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
        ],
        '3-4-2-1' : [
            //вратарь
            {
                amplua: ['ВР'],
                position: { x: 0, y: 0.5, z: -7.5 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            
            //защитники
            {
                amplua: ['ЛЗ'],
                position: { x: 0, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦЗ'],
                position: { x: 3, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПЗ'],
                position: { x: -3, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //полузащитники
            {
                amplua: ['ЛП'],
                position: { x: 2, y: 0.5, z: -4 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: 4, y: 0.5, z: -4 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: -2, y: 0.5, z: -4 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПП'],
                position: { x: -4, y: 0.5, z: -4 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //нападающие
            {
                amplua: ['НП'],
                position: { x: -2, y: 0.5, z: -2 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['НП'],
                position: { x: 2, y: 0.5, z: -2 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //нападающие
            {
                amplua: ['НП'],
                position: { x: 0, y: 0.5, z: -0.6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
        ],
        '3-4-1-2' : [
            //вратарь
            {
                amplua: ['ВР'],
                position: { x: 0, y: 0.5, z: -7.5 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            
            //защитники
            {
                amplua: ['ЛЗ'],
                position: { x: 0, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦЗ'],
                position: { x: 3, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПЗ'],
                position: { x: -3, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //полузащитники
            {
                amplua: ['ЛП'],
                position: { x: 2, y: 0.5, z: -4 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: 4, y: 0.5, z: -4 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: -2, y: 0.5, z: -4 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПП'],
                position: { x: -4, y: 0.5, z: -4 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //нападающие
            {
                amplua: ['НП'],
                position: { x: 0, y: 0.5, z: -2 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //нападающие
            {
                amplua: ['НП'],
                position: { x: -2, y: 0.5, z: -0.6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['НП'],
                position: { x: 2, y: 0.5, z: -0.6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
        ],
        '3-3-3-1' : [
            //вратарь
            {
                amplua: ['ВР'],
                position: { x: 0, y: 0.5, z: -7.5 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            
            //защитники
            {
                amplua: ['ЛЗ'],
                position: { x: 0, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦЗ'],
                position: { x: 3, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПЗ'],
                position: { x: -3, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //полузащитники
            {
                amplua: ['ЛП'],
                position: { x: 0, y: 0.5, z: -4 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: 3, y: 0.5, z: -4 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПП'],
                position: { x: -3, y: 0.5, z: -4 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //нападающие
            {
                amplua: ['НП'],
                position: { x: 0, y: 0.5, z: -2 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['НП'],
                position: { x: 3, y: 0.5, z: -2 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['НП'],
                position: { x: -3, y: 0.5, z: -2 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //нападающие
            {
                amplua: ['НП'],
                position: { x: 0, y: 0.5, z: -0.6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

        ],
        '4-4-1-1' : [
            //вратарь
            {
                amplua: ['ВР'],
                position: { x: 0, y: 0.5, z: -7.5 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            
            //защитники
            {
                amplua: ['ЛЗ'],
                position: { x: 2, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦЗ'],
                position: { x: 4, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦЗ'],
                position: { x: -2, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПЗ'],
                position: { x: -4, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //полузащитники
            {
                amplua: ['ЛП'],
                position: { x: 2, y: 0.5, z: -4 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: 4, y: 0.5, z: -4 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: -2, y: 0.5, z: -4 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПП'],
                position: { x: -4, y: 0.5, z: -4 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //нападающие
            {
                amplua: ['НП'],
                position: { x: 0, y: 0.5, z: -2 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //нападающие
            {
                amplua: ['НП'],
                position: { x: 0, y: 0.5, z: -0.6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
        ],
        '4-1-4-1' : [
            //вратарь
            {
                amplua: ['ВР'],
                position: { x: 0, y: 0.5, z: -7.5 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            
            //защитники
            {
                amplua: ['ЛЗ'],
                position: { x: 2, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦЗ'],
                position: { x: 4, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦЗ'],
                position: { x: -2, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ПЗ'],
                position: { x: -4, y: 0.5, z: -6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //полузащитники
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП'],
                position: { x: 0, y: 0.5, z: -4 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //нападающие
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП', 'ЛП'],
                position: { x: 2, y: 0.5, z: -2 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП', 'ЛП'],
                position: { x: 4, y: 0.5, z: -2 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП', 'ЛП'],
                position: { x: -2, y: 0.5, z: -2 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
            {
                amplua: ['ЦП', 'ЦОП', 'ЦАП', 'ЛП'],
                position: { x: -4, y: 0.5, z: -2 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },

            //нападающие
            {
                amplua: ['НП'],
                position: { x: 0, y: 0.5, z: -0.6 },
                rotation: { x: 0, y: 1.57, z: 1.57 },
                scale: { x: 0.004, y: 0.004, z: 0.004 },
            },
        ],
    },

    bannerRefs: [],
    };
},
methods: {
    setItemRef(el) {
        if (el) {
            this.bannerRefs.push(el)
        }
    },

    onLoad(object) {
        this.mixer = new AnimationMixer(object);
        const action = this.mixer.clipAction(object.animations[0]);
        action.play();

        object.traverse(function (child) {
            if (child.isMesh) {
                child.castShadow = true;
                child.receiveShadow = true;
            }
        });

        console.log('object', object.animations);

        this.clock = new Clock();
        this.$refs.renderer.onBeforeRender(this.updateMixer);
    },

    updateMixer() {
        this.mixer.update(this.clock.getDelta());
    },

    startGoalAnimation() {
        const object = this.bannerRefs[0]

        this.mixer = new AnimationMixer(object);
        const action = this.mixer.clipAction(object.animations[3]);
        action.play();

        object.traverse(function (child) {
            if (child.isMesh) {
                child.castShadow = true;
                child.receiveShadow = true;
            }
        });

        console.log('object', object.animations);

        this.clock = new Clock();
        this.$refs.renderer.onBeforeRender(this.updateMixer);
    },

    formattedTeam() {
        let currentTeam = []
        if (this.fieldPart == 'left') {
            currentTeam = this.positionsFirstTeam[this.currentPositionFirstTeam]
        } else if (this.fieldPart == 'right') {
            currentTeam = this.positionsSecondTeam[this.currentPositionFirstTeam]
        }

        const userIds = []

        currentTeam = currentTeam.map((player) => {
            const currentPlayer = this.teamPlayersObjects.filter(p => !userIds.includes(p.playerId)).find((playerObj) => player.amplua.includes(playerObj.position)) 
            console.log('currentPlayer', currentPlayer)
            if (currentPlayer) {
                userIds.push(currentPlayer.playerId)
            } else {
                return {
                    name: 'Test',
                    number: '#1',
                    photo: ''
                }
            }
            return {
                ...player,
                playerObj: currentPlayer
            }})

        console.log('currentTeam', currentTeam)
        return currentTeam
    },  

    addObjectToScene(object) {
        this.$refs.renderer.scene.add(object);
        console.log('Object added to scene:', object);
    },
},
async mounted() {
    this.formattedTeamObjects = this.formattedTeam()
},

watch: {
    
},

components: {
    PlayerCap
}
};
</script>

<style>


.interface {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.text {
    color: white;
    font-size: 20px;
    margin-right: 10px;
    font-family: 'Courier New', Courier, monospace;
}

</style>

