<template>
    <div>
        <svg width="35" height="88" viewBox="0 0 35 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M2.58301 16.4543L26.1297 13.1557" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 18.6535L26.1297 15.3549" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 20.8528L26.1297 17.5541" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 23.052L26.1297 19.7533" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 14.2551L26.1297 10.9565" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 12.0559L26.1297 8.75725" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 9.85667L26.1297 6.55804" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 29.6496L26.1297 26.351" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 31.8489L26.1297 28.5502" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 34.0481L26.1297 30.7494" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 36.2473L26.1297 32.9487" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 27.4504L26.1297 24.1518" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 25.2512L26.1297 21.9526" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 45.0432L26.1297 41.7446" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 47.2424L26.1297 43.9438" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 49.4416L26.1297 46.143" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 51.6409L26.1297 48.3422" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 42.845L26.1297 39.5463" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 40.6457L26.1297 37.3471" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 38.4465L26.1297 35.1479" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 58.2385L26.1297 54.9399" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 60.4377L26.1297 57.1391" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 62.6369L26.1297 59.3383" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 64.8362L26.1297 61.5375" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 56.0393L26.1297 52.7407" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 53.8401L26.1297 50.5414" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 71.4319L26.1297 68.1332" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 73.6311L26.1297 70.3325" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 75.8303L26.1297 72.5317" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 78.0295L26.1297 74.7309" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 69.2327L26.1297 65.934" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 67.0334L26.1297 63.7348" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 80.2278L26.1297 76.9291" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 82.427L26.1297 79.1283" stroke="white" stroke-width="0.2"/>
                <path d="M9.08301 84.9586L27.083 83.4586" stroke="white" stroke-width="0.2"/>
                <path d="M3.58301 83.9582L26.1298 81.3276" stroke="white" stroke-width="0.2"/>
                <path d="M6.58301 2.95824L6.58301 84.9582" stroke="white" stroke-width="0.2"/>
                <path d="M4.58301 2.95824L4.58301 84.9582" stroke="white" stroke-width="0.2"/>
                <path d="M8.58301 2.95824L8.58301 84.9582" stroke="white" stroke-width="0.2"/>
                <path d="M10.583 2.95824L10.583 84.9582" stroke="white" stroke-width="0.2"/>
                <path d="M12.9346 2.16177L12.9346 84.9586" stroke="white" stroke-width="0.2"/>
                <path d="M15.1338 1.06105L15.1338 84.9585" stroke="white" stroke-width="0.2"/>
                <path d="M17.333 1.06205L17.333 85.5961" stroke="white" stroke-width="0.2"/>
                <path d="M19.5322 1.06091L19.5322 86.1131" stroke="white" stroke-width="0.2"/>
                <path d="M21.7314 1.06192L21.7314 86.1132" stroke="white" stroke-width="0.2"/>
                <path d="M23.9307 1.06078L23.9307 85.9582" stroke="white" stroke-width="0.2"/>
                <path d="M26.1299 4.36053L26.1299 85.9582" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 7.65746L26.1297 4.35882" stroke="white" stroke-width="0.2"/>
                <path d="M2.58301 5.45824L26.1297 2.1596" stroke="white" stroke-width="0.2"/>
                <path class="color_changed_stroke" d="M33.8263 7.65873L27.229 1.06145M27.229 1.06145L1.93945 3.26055L1.93945 84.627L27.229 86.826M27.229 1.06145L27.229 86.826M27.229 86.826C27.229 86.826 31.2499 82.8052 33.8263 80.2288" stroke="white" stroke-width="2"/>
                <path d="M27.083 86.4579L2.08301 83.9579L2.08301 3.45789L27.083 1.45789L27.083 86.4579Z" fill="#D9D9D9" fill-opacity="0.05"/>
            </g>
        </svg>


    </div>

    
</template>

<script>
    export default {
        name: 'TopLeftGates',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>