<template>
    <div>
        <div class="w-[90%] mx-auto ">
            <ButtonComponent @click="$emit('close')">Назад</ButtonComponent>
        </div>
        <div class="w-[90%] mx-auto flex items-center gap-[10px] mt-[20px]">
            <div 
                :class="{
                    'border-[#C4C4C4CC] text-[#474849] bg-[#C59014]': scoreType === 'objective',
                    'border-[#A9AAAB66] text-[#C59014] bg-[#47484966]': scoreType != 'objective'
                }"
                @click="scoreType = 'objective'"
                class="w-max px-[20px] cursor-pointer select-none font-bold text-[16px] pt-[2px] border-[2px] flex justify-center items-center rounded-[8px]">
                Об'єктивні показники
            </div>
            <div
                :class="{
                    'border-[#C4C4C4CC] text-[#474849] bg-[#C59014]': scoreType === 'subjective',
                    'border-[#A9AAAB66] text-[#C59014] bg-[#47484966]': scoreType != 'subjective'
                }"
                @click="scoreType = 'subjective'"
                class="w-max px-[20px] cursor-pointer select-none font-bold text-[16px] pt-[2px] border-[2px] flex justify-center items-center rounded-[8px]">
                Суб'єктивні показники
            </div>
        </div>
        <div class="w-[90%] mx-auto flex items-center gap-[10px] mt-[20px]">
            <div class="text-[#C59014] text-[12px]">
                амплуа
            </div>
            <div 
                v-for="amplua in positions" 
                @click="currentPositions.includes(amplua) ? currentPositions = currentPositions.filter(item => item !== amplua) : currentPositions.push(amplua)"
                :class="{
                    'border-[#C4C4C4CC] text-[#474849] bg-[#C59014]': currentPositions.includes(amplua),
                    'border-[#A9AAAB66] text-[#C59014] bg-[#47484966]': !currentPositions.includes(amplua)
                }"
                class="w-full cursor-pointer select-none font-bold text-[16px] pt-[2px] border-[2px] flex justify-center items-center rounded-[8px]">
                {{ amplua }}
            </div>
        </div>
        <div class="w-[90%] mx-auto flex items-center gap-[10px] mt-[20px]">
            <div class="text-[#C59014] text-[12px]">
                команда
            </div>
            <div 
                v-for="group in groups" 
                @click="setCurrentGroups(group)"
                :class="{
                    'border-[#C4C4C4CC] text-[#474849] bg-[#C59014]': isGroupSelected(group),
                    'border-[#A9AAAB66] text-[#C59014] bg-[#47484966]': !isGroupSelected(group)
                }"
                class="w-full cursor-pointer select-none font-bold text-[16px] pt-[2px] border-[2px] flex justify-center items-center rounded-[8px]">
                {{ group.name }}
            </div>
        </div>
        <div class="w-[90%] mx-auto flex items-center gap-[10px] mt-[20px]">
            <div class="text-[#C59014] text-[12px]">
                показник
            </div>
            <div 
                v-for="(scoreName, id) in currentScoreNames.fieldPlayer" 
                @click="setNewScoreIds(scoreName)"
                :class="{
                    'border-[#C4C4C4CC] text-[#474849] bg-[#C59014]': currentScoreIds.includes(id),
                    'border-[#A9AAAB66] text-[#C59014] bg-[#47484966]': !currentScoreIds.includes(id)
                }"
                class="w-full cursor-pointer select-none font-bold text-[16px] pt-[2px] border-[2px] flex flex-col justify-center items-center rounded-[8px]">
                {{ scoreName }}
                <div v-if="scoreType == 'subjective'" class="text-[12px] font-normal text-inherit">
                    {{ currentScoreNames.goalkeeper[id] }}
                </div>
            </div>
        </div>
        <div class="w-[90%] flex justify-end mt-[20px] mx-auto">
            <ButtonComponent @click="confirmFilters">Застосувати</ButtonComponent>
        </div>

        <div class="w-[90%] mx-auto mt-[20px]">
            <div v-for="result in allPlayers" v-if="allPlayers.length > 0" class="flex justify-start items-center mb-[10px]">
                <div class="w-[250px] truncate">{{ result.player_name }}</div>
                <div class="w-[100%]">
                    <div 
                        class="text-center h-[30px] cursor-pointer rounded-md border-[4px] bg-[#474849]"
                        :class="{
                                'border-[#339989] text-[#339989]': result.bar_width + 10 >= 90,
                                'border-[#BD9135] text-[#BD9135]': result.bar_width + 10 < 90 && result.bar_width + 10 > 25,
                                'border-[#DA4167] text-[#DA4167]': result.bar_width + 10 <= 25
                            }"    
                        :style="[`width: ${10 + result.bar_width}%`]"
                        @click="$router.push(`/students/${result.player_id}?tabName=MainTab`)"
                    >
                        {{ (+result.rating).toFixed(2) }}
                    </div>
                </div>
            </div>
            <div v-if="allPlayers.length === 0">
                <div class="text-[#C59014] text-[16px] text-center mt-[20px]">Немає даних</div>
            </div>
            <div v-if="!validations.isGroupSelected">
                <div class="text-[#DA4167] text-[16px] text-center mt-[20px]">Не вибрана команда</div>
            </div>
            <div v-if="!validations.isScoreSelected">
                <div class="text-[#DA4167] text-[16px] text-center mt-[20px]">Не вибраний показник</div>
            </div>
            <div v-if="!validations.isAmpluaSelected">
                <div class="text-[#DA4167] text-[16px] text-center mt-[20px]">Не вибраний амплуа</div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onMounted, watch } from 'vue'
import { getAllGroups, getScoreSettings, getObjectiveScoreSettings, getAnalyticsByPlayersInClub } from '@/services/apiRequests'

export default {
    name: 'AnalyticsDeepSettings',

    setup() {
        const scoreType = ref('subjective')

        const positions = ref(['ВР', 'ЦЗ', 'ЛЗ', 'ПЗ', 'ЦОП', 'ЦП', 'ЦАП', 'ПП', 'ЛП', 'ПВ', 'ЛВ', 'НП'])
        const currentPositions = ref([])

        const groups = ref([])
        const currentGroups = ref([])

        const setCurrentGroups = (group) => {
            console.log(group)
            if (currentGroups.value.includes(group)) {
                currentGroups.value = currentGroups.value.filter(item => item !== group)
            } else {
                currentGroups.value.push(group)
            }
        }

        const isGroupSelected = (group) => {
            return currentGroups.value.map(item => item.group_id).includes(group.group_id)
        }

        const subjectScoreFieldPlayer = ref([])
        const subjectScoreGoalkeeper = ref([])
        const objectiveScoreFieldPlayer = ref([])
        const objectiveScoreGoalkeeper = ref([])

        const currentScoreNames = ref([])
        const currentScoreIds = ref([])

        const validations = ref({
            isGroupSelected: false,
            isScoreSelected: false,
            isAmpluaSelected: false
        })

        const setNewScoreIds = (scoreName) => {
            const index = currentScoreNames.value.fieldPlayer.indexOf(scoreName)
            if (currentScoreIds.value.includes(index)) {
                currentScoreIds.value = currentScoreIds.value.filter(item => item !== index)
            } else {
                currentScoreIds.value.push(index)
            }
        }

        watch(scoreType, (newVal) => {
            if (newVal === 'subjective') {
                currentScoreNames.value = {
                    fieldPlayer: subjectScoreFieldPlayer.value,
                    goalkeeper: subjectScoreGoalkeeper.value
                }
            } else {
                currentScoreNames.value = {
                    fieldPlayer: objectiveScoreFieldPlayer.value,
                    goalkeeper: objectiveScoreGoalkeeper.value
                }
            }
        })

        onMounted(async () => {
            const groupResponse = await getAllGroups()
            groups.value = groupResponse.data

            const scoreResponse = await getScoreSettings()

            const objectiveScoreResponse = await getObjectiveScoreSettings()
            
            subjectScoreFieldPlayer.value = [
                scoreResponse.field_player.first_point_name,
                scoreResponse.field_player.second_point_name,
                scoreResponse.field_player.third_point_name,
                scoreResponse.field_player.fourth_point_name,
                scoreResponse.field_player.fifth_point_name,
                scoreResponse.field_player.sixth_point_name,
                scoreResponse.field_player.seventh_point_name,
                scoreResponse.field_player.eighth_point_name,
                scoreResponse.field_player.ninth_point_name
            ].filter(name => name)
            subjectScoreGoalkeeper.value = [
                scoreResponse.goalkeeper.first_point_name,
                scoreResponse.goalkeeper.second_point_name,
                scoreResponse.goalkeeper.third_point_name,
                scoreResponse.goalkeeper.fourth_point_name,
                scoreResponse.goalkeeper.fifth_point_name,
                scoreResponse.goalkeeper.sixth_point_name,
                scoreResponse.goalkeeper.seventh_point_name,
                scoreResponse.goalkeeper.eighth_point_name,
                scoreResponse.goalkeeper.ninth_point_name
            ].filter(name => name)

            objectiveScoreFieldPlayer.value = [
                objectiveScoreResponse.field_player.first_point_name,
                objectiveScoreResponse.field_player.second_point_name,
                objectiveScoreResponse.field_player.third_point_name,
                objectiveScoreResponse.field_player.fourth_point_name,
                objectiveScoreResponse.field_player.fifth_point_name,
                objectiveScoreResponse.field_player.sixth_point_name,
                objectiveScoreResponse.field_player.seventh_point_name,
                objectiveScoreResponse.field_player.eighth_point_name,
                objectiveScoreResponse.field_player.ninth_point_name
            ].filter(name => name)
            objectiveScoreGoalkeeper.value = [
                objectiveScoreResponse.goalkeeper.first_point_name,
                objectiveScoreResponse.goalkeeper.second_point_name,
                objectiveScoreResponse.goalkeeper.third_point_name,
                objectiveScoreResponse.goalkeeper.fourth_point_name,
                objectiveScoreResponse.goalkeeper.fifth_point_name,
                objectiveScoreResponse.goalkeeper.sixth_point_name,
                objectiveScoreResponse.goalkeeper.seventh_point_name,
                objectiveScoreResponse.goalkeeper.eighth_point_name,
                objectiveScoreResponse.goalkeeper.ninth_point_name
            ].filter(name => name)

            if (scoreType.value === 'subjective') {
                currentScoreNames.value = {
                    fieldPlayer: subjectScoreFieldPlayer.value,
                    goalkeeper: subjectScoreGoalkeeper.value
                }
            } else {
                currentScoreNames.value = {
                    fieldPlayer: objectiveScoreFieldPlayer.value,
                    goalkeeper: objectiveScoreGoalkeeper.value
                }
            }
        })

        const allPlayers = ref([])

        const confirmFilters = async () => {
            validations.value.isGroupSelected = currentGroups.value.length > 0
            validations.value.isScoreSelected = currentScoreIds.value.length > 0
            validations.value.isAmpluaSelected = currentPositions.value.length > 0

            if (!validations.value.isGroupSelected || !validations.value.isScoreSelected || !validations.value.isAmpluaSelected) {
                return
            }

            const response = await getAnalyticsByPlayersInClub({
                score_type: scoreType.value,
                amplua_list: currentPositions.value,
                team_list: currentGroups.value.map(group => group.group_id),
                score_ids: currentScoreIds.value
            })
            allPlayers.value = response

            const maxRating = Math.max(...allPlayers.value.map(player => +player.rating))
            const minRating = 0

            allPlayers.value = allPlayers.value.map(player => {
                player.bar_width = (player.rating - minRating) / (maxRating - minRating) * 90
                return player
            })

            allPlayers.value = allPlayers.value.sort((a, b) => +b.rating - +a.rating)
        }
     
        return {
            positions,
            currentPositions,

            groups,
            currentGroups,
            setCurrentGroups,
            isGroupSelected,

            subjectScoreFieldPlayer,
            subjectScoreGoalkeeper,
            objectiveScoreFieldPlayer,
            objectiveScoreGoalkeeper,
            currentScoreNames,

            setNewScoreIds,
            currentScoreIds,
            
            scoreType,

            confirmFilters,
            allPlayers,
            validations
        }
    }
}
</script>
<style lang="scss">
    
</style>