<template>
    <div class="w-[90%] mx-auto mt-[20px]">
        <div v-if="errorValidationFlag" class="absolute flex flex-col left-[50%] translate-x-[-50%] px-[20px] justify-evenly top-[50%] translate-y-[-50%] ml-[150px] rounded-[10px] w-[300px] h-[200px] bg-[#252526]">
            <div class="flex justify-center items-center">
                <div class="text-white text-[16px] text-center font-semibold">
                    В тренуванні є незаповнені поля або елементи з не вказаним часом
                </div>
            </div>
            <div @click="errorValidationFlag = false" class="bg-[#BD9135] text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
                OK
            </div>
        </div>
        <div class="flex flex-col justify-between">
            <div class="line border-[1px] rounded-[10px] w-full flex h-[35px] overflow-hidden">

                        <div 
                            v-for="element in elements"
                            @click="elementViewFlag = true; selectedElement = element" 
                            class="h-full flex justify-center items-center cursor-pointer px-[10px] bg-[#BD9135] border-x-[1px] border-black" 
                            :style="{width: isElementsWithoutTime() ? (100 / elements.length) + '%' : ((100 * element.time) / getFulltimeOfElements()) + '%'}"
                            :class="{'opacity-[0.6]' : !element.time, 'bg-[#339989]': selectedElement.current_id === element.current_id && elementViewFlag}"
                            :title="element.name"
                        >
                        <div class="text-black text-center text-[1rem] flex items-center overflow-hidden whitespace-nowrap overflow-ellipsis">
                        
                            <div>
                                {{ 100 / elements.length > 10 ? element.name : element.name[0] }}
                            </div>

                            <svg v-if="!element.time" class="ml-[10px]" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_23_10)">
                                <circle cx="8.5" cy="8.5" r="6.25" stroke="black" stroke-width="1.5"/>
                                <path d="M8.5 6V8.5L9.75 9.75" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <path d="M16 1L1 16" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
                                <defs>
                                <clipPath id="clip0_23_10">
                                <rect width="15" height="15" fill="black" transform="translate(1 1)"/>
                                </clipPath>
                                </defs>
                            </svg>


                        </div>
                    </div>

                <div @click="showElements = true" class="text-center w-[30px] h-full cursor-pointer text-[21px] text-white">+</div>
            </div>
            <div class="text-white text-center mt-[12px]">{{timelineTime}} ЗАГАЛЬНИЙ ЧАС</div>

            <div v-if="!showElements && !elementViewFlag">
                <div class="mt-4 text-start flex">
                    <div class="w-[70%] mr-[20px]">
                        <div class="text-[#BD9135] text-[14px] font-bold">Опис тренування</div>
                        <textarea 
                            v-model="trainingDescription" 
                            class="w-full h-[350px] border-[1px] border-white border-opacity-10 outline-none text-white bg-transparent rounded-[10px] p-[10px]" 
                            placeholder="Введіт опис..."
                            :class="{
                                'border-[#DA4167] border-[3px]': !validation.description
                            }"     
                        ></textarea>
                    </div>
                    <div class="w-[30%]">
                        <div class="w-[100%] flex flex-col items-start justify-start mb-3">
                            <div class="text-[#BD9135] text-[14px] font-bold">Назва тренування</div>
                            <input 
                                v-model="trainingName" 
                                class="bg-[#252526] outline-none text-white border-[1px] border-white border-opacity-10 text-[18px] w-[100%] py-[5px] px-[5px] rounded-[6px]" 
                                placeholder="Введіть назву..."
                                :class="{
                                    'border-[#DA4167] border-[3px]': !validation.name
                                }"         
                            >    
                        </div>
                        <div class="w-[100%] flex flex-col items-start justify-start">
                            <div class="text-[#BD9135] text-[14px] font-bold">Вікова група</div>
                            <select 
                                v-model="trainingAgeGroup" 
                                class="bg-[#252526] outline-none text-white  text-[18px] w-[100%] py-[5px] px-[5px] rounded-[6px]"
                                :class="{
                                    'text-[#8C8C8C]': trainingAgeGroup === '',
                                    'border-[#DA4167] border-[3px] ': !validation.ageGroup
                                }"    
                            >
                                <option value="" disabled>Виберіть вікову категорію</option>
                                <option class="text-white" value="6_years">6 років</option>
                                <option class="text-white" value="7_years">7 років</option>
                                <option class="text-white" value="8_years">8 років</option>
                                <option class="text-white" value="9_years">9 років</option>
                                <option class="text-white" value="10_years">10 років</option>
                                <option class="text-white" value="11_years">11 років</option>
                                <option class="text-white" value="12_years">12 років</option>
                                <option class="text-white" value="13_years">13 років</option>
                                <option class="text-white" value="14_years">14 років</option>
                                <option class="text-white" value="15_years">15 років</option>
                                <option class="text-white" value="16_years">16 років</option>
                                <option class="text-white" value="17_years">17 років</option>
                                <option class="text-white" value="18_years">18 років</option>
                                <option class="text-white" value="19_years">19 років</option>
                                <option class="text-white" value="20_years">20 років</option>
                                <option class="text-white" value="21_years">21 років</option>
                                <option class="text-white" value="22_years">22 років</option>
                                <option class="text-white" value="23_years">23 років</option>
                                <option class="text-white" value="main_team">Основна команда</option>
                            </select>    
                        </div>
                        <!-- <div class="w-[100%] flex flex-col items-start justify-start">
                            <div class="bg-[#252526] text-[#BD9135] cursor-pointer mt-[20px] border-[1px] text-center border-[#BD9135] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]">Додати до програми</div>
                        </div> -->
                        <!-- <div class="w-[100%] flex flex-col items-start justify-start">
                            <div @click="" class="bg-[#339989] text-[#252526] cursor-pointer mt-[20px] border-[1px] text-center border-[#339989] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]">Завершити тренування</div>
                        </div> -->
                        <div class="w-[100%] flex flex-col items-start justify-start">
                            <div @click="$emit('close')" class="bg-[#BD9135] text-[#252526] cursor-pointer mt-[20px] border-[1px] text-center border-[#BD9135] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]">Назад</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="showElements && !elementViewFlag">
                <div class="w-[100%] flex flex-col items-center justify-center">
                    <div @click="showElements = false" class="bg-[#BD9135] text-[#252526] cursor-pointer mt-[20px] border-[1px] text-center border-[#BD9135] text-[18px] w-[20%] py-[3px] px-[5px] rounded-[6px]">Назад</div>
                </div>
                <table class="w-full mt-[40px]">
                    <thead class="text-white select-none">
                        <tr class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                            <th class="px-3 py-2 w-[5%] text-center font-bold text-sm"></th>
                            <th class="px-3 py-2 w-[20%] text-start font-bold text-sm">Назва елементу</th>
                            <th class="px-3 py-2 w-[10%] text-center font-bold text-sm">Елемент</th>
                            <th class="px-3 py-2 w-[10%] text-end font-bold text-sm">Вік. кат.</th>
                            <th class="w-[15%] py-2 cursor-pointer select-none text-end font-bold text-sm">
                                Тривалість
                            </th>
                            <th class="w-[5%]" ></th>
                            <th class="px-3 py-2 text-end w-[204px] relative font-bold text-sm">
                                Оцінювання
                            </th>
                            <th class="px-3 w-[25%] py-2 text-end font-bold text-sm"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="element in allTrainingElements" class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                            <td class="px-3 py-2 w-[5%] text-center font-medium text-sm">
                                <div class="rounded-full border-[1px] bg-[#BD9135] border-white w-[50px] h-[50px] flex justify-center items-center text-center align-middle">
                                    <span class="text-[21px]">{{ element.name ? element.name[0].toUpperCase() : 'N' }}</span>
                                </div>
                            </td>
                            <td class="px-3 py-2 w-[20%] text-start font-medium text-sm">{{ element.name ? element.name : 'Немає назви' }}</td>
                            <td class="px-3 py-2 w-[10%] text-center font-medium text-sm text-[#BD9135]">{{ parseElementType(element.type) }}</td>
                            <td class="px-3 py-2 w-[10%] text-end font-medium text-sm">{{ parseAgeGroup(element.training_program_years) }}</td>
                            <td class="w-[15%] py-2 select-none text-end font-medium text-sm">
                                {{ element.time ?? 'Налаштовувана' }}
                            </td>
                            <td class="w-[5%]" ></td>
                            <td class="px-3 py-2 text-end w-[204px] relative font-medium text-sm">
                                {{ element.type == 'task' ? '-' : parseScoring(element.score_board_type) }}
                            </td>
                            <td class="px-3 w-[25%] py-2 text-end font-medium text-sm">
                                <button 
                                class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                @click="addElementToTraining(element)"
                                >
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="13" cy="13" r="13" fill="#339989"/>
                                    <line x1="13" y1="5" x2="13" y2="21" stroke="white" stroke-width="2"/>
                                    <line x1="5" y1="13" x2="21" y2="13" stroke="white" stroke-width="2"/>
                                </svg>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else-if="elementViewFlag" class="mt-[100px]">
                <ElementView 
                    @back="elementViewFlag = false" 
                    @changeTime="changeTimeSelectedElement" 
                    @deleteElement="deleteElementFromTimeline" 
                    :element="selectedElement" 
                    :key="selectedElement"
                    :training="training"    
                />
            </div>
        </div> 
    </div>
</template>

<script>
    import { ref, onMounted, computed, watch } from 'vue'
    import { addTrainingRequest, getAllTrainingElementsRequest, addNewTrainingSummary } from '@/services/apiRequests'
    import ElementView from './ElementView.vue'
    import draggable from 'vuedraggable'

    export default {
        name: 'TrainingForm',

        props: {
            summary: {
                type: Object,
                default: () => ({})
            },
            training: {
                type: Object,
                default: () => ({})
            }
        },

        setup(props, { emit }) {
            const ageGroup = ref('6_years')
            const showElements = ref(false)

            const allTrainingElements = ref([])
            const elements = ref(props.summary?.elements ? props.summary?.elements : [])
            const minutes = ref(0)
            const hours = ref(0)

            const selectedElement = ref({})

            const elementViewFlag = ref(false)

            const trainingName = ref(props.summary?.name ? props.summary?.name : '')
            const trainingDescription = ref(props.summary?.info ? props.summary?.info : '')
            const trainingAgeGroup = ref(props.summary?.age_group ? props.summary?.age_group : '')

            const errorValidationFlag = ref(false)

            const validation = ref({
                name: true,
                ageGroup: true,
                description: true,
                exercises: true
            })

            watch(() => trainingName.value, (newValue) => {
                validation.value.name = !!newValue
            })

            watch(() => trainingAgeGroup.value, (newValue) => {
                validation.value.ageGroup = !!newValue
            })

            watch(() => trainingDescription.value, (newValue) => {
                validation.value.description = !!newValue
            })

            const checkValidation = () => {
                if (!trainingName.value) {
                    validation.value.name = false
                } else {
                    validation.value.name = true
                }

                if (!trainingAgeGroup.value) {
                    validation.value.ageGroup = false
                } else {
                    validation.value.ageGroup = true
                }

                if (!trainingDescription.value) {
                    validation.value.description = false
                } else {
                    validation.value.description = true
                }

                if (!elements.value.length) {
                    validation.value.exercises = false
                } else {
                    validation.value.exercises = true
                }

                if (elements.value.some(el => !el.time)) {
                    validation.value.exercises = false
                } else { 
                    validation.value.exercises = true
                }
            }

            const timelineTime = computed(() => {
                let minutesTimeline = 0
                let hoursTimeline = 0 
                elements.value.forEach(el => {
                    minutesTimeline += +el.time
                })

                if (minutesTimeline >= 60) {
                    hoursTimeline += Math.floor(minutesTimeline / 60)
                    minutesTimeline = minutesTimeline % 60
                }

                return `${hoursTimeline}:${minutesTimeline}`
            })

            onMounted(async () => {
                // const response = await getAllTrainingElementsRequest(20, 0)
                // allTrainingElements.value = response
                allTrainingElements.value = []
                // console.log(response)
            })

            const addTraining = async () => {
                checkValidation()

                if (!Object.values(validation.value).some(el => !el)) {
                    emit('saveTraining', {
                        name: trainingName.value,
                        info: trainingDescription.value,
                        age_group: trainingAgeGroup.value,
                        elements: elements.value
                    })
                } else {
                    console.log('Validation error')
                    errorValidationFlag.value = true
                }
            }

            const addElementToTraining = async (elementObject) => {
                elements.value.push(Object.assign({}, elementObject))
                elements.value[elements.value.length - 1].current_id = elements.value.length
                minutes.value += +elementObject?.time
                if (minutes.value >= 60) {
                    hours.value += Math.floor(minutes.value / 60)
                    minutes.value = minutes.value % 60
                }
            }

            const parseElementType = (type) => {
                switch (type) {
                    case 'standard':
                        return 'Норматив'
                    case 'exercise':
                        return 'Вправа'
                    case 'task':
                        return 'Завдання'
                    default:
                        return 'Невідомий тип елементу'
                }
            }

            const parseAgeGroup = (ageGroup) => {
                switch (ageGroup) {
                    case '6_years':
                        return '6 років'
                    case '7_years':
                        return '7 років'
                    case '8_years':
                        return '8 років'
                    case '9_years':
                        return '9 років'
                    case '10_years':
                        return '10 років'
                    case '11_years':
                        return '11 років'
                    case '12_years':
                        return '12 років'
                    case '13_years':
                        return '13 років'
                    case '14_years':
                        return '14 років'
                    case '15_years':
                        return '15 років'
                    case '16_years':
                        return '16 років'
                    case '17_years':
                        return '17 років'
                    case '18_years':
                        return '18 років'
                    case '19_years':
                        return '19 років'
                    case '20_years':
                        return '20 років'
                    case '21_years':
                        return '21 рік'
                    case '22_years':
                        return '22 роки'
                    case '23_years':
                        return '23 роки'
                    case 'main_team':
                        return 'Основний склад'
                    default:
                        return 'Невідома вікова категорія'
                }
            }

            const parseScoring = (scoring) => {
                switch (scoring) {
                    case 'time':
                        return 'Час'
                    case 'point':
                        return 'Оцінка'
                    case 'length':
                        return 'Відстань'
                    default:
                        return '-'
                }
            }

            const deleteElementFromTimeline = (element) => {
                const index = elements.value.indexOf(element)
                elements.value.splice(index, 1)
                minutes.value -= +element?.time
                if (minutes.value < 0) {
                    hours.value -= Math.floor(minutes.value / 60)
                    minutes.value = 60 + (minutes.value % 60)
                }
                elementViewFlag.value = false
                selectedElement.value = {}
            }

            const getFulltimeOfElements = () => {
                let minutes = 0
                elements.value.forEach(el => {
                    minutes += +el.time
                })
                return minutes
            }

            const isElementsWithoutTime = () => {
                return elements.value.some(el => !el.time)
            }

            const changeTimeSelectedElement = (time) => {
                minutes.value -= +selectedElement.value.time
                minutes.value += +time
                if (minutes.value >= 60) {
                    hours.value += Math.floor(minutes.value / 60)
                    minutes.value = minutes.value % 60
                }
                selectedElement.value.time = time
            }

            const updateTrainingSummary = () => {
                checkValidation()
                if (!Object.values(validation.value).some(el => !el)) {
                    const newTrainingObject = {
                        name: trainingName.value,
                        info: trainingDescription.value,
                        age_group: trainingAgeGroup.value,
                        elements: elements.value
                    } 
                    emit('saveTraining', newTrainingObject)
                } else {
                    console.log('Validation error')
                    errorValidationFlag.value = true
                }
            }

            return {
                ageGroup,
                showElements,
                allTrainingElements,
                addTraining,
                parseElementType,
                parseAgeGroup,
                parseScoring,
                addElementToTraining,
                elements,
                minutes,
                hours,
                elementViewFlag,
                selectedElement,
                deleteElementFromTimeline,
                getFulltimeOfElements,
                isElementsWithoutTime,
                changeTimeSelectedElement,
                timelineTime,
                trainingName,
                trainingDescription,
                trainingAgeGroup,
                updateTrainingSummary,
                validation,
                errorValidationFlag
            }
        },

        components: {
            ElementView,
            draggable
        }
    }
</script>

<style lang="scss" scoped>

</style>
