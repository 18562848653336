<template>
    <div class="start-player-select"
    :class="{
        'hidden-block': addOtherPlayersToGameFlag
    }"
    >
        <div v-if="alertFlag" class="fixed top-[100px] right-[20px] font-bold w-[200px] py-[10px] text-center rounded-md bg-[#DA4167]">
            <div>Заповніть всі поля</div>
        </div>
        <div class="start-player-select__scheme-and-field-part mb-[20px]">
            <SelectComponent
                class="start-player-select__scheme-and-field-part--select"
                :options="allScheme" 
                :label="'оберіть схему гри'"
                @change="e => scheme = e"
                :default="scheme"
            />
            <SelectComponent
                class="start-player-select__scheme-and-field-part--select"
                :options="['Ліва', 'Права']" 
                :label="'оберіть початкову частину поля'"
                @change="e => startFieldPart = e"
            />
        </div>

        <div class="start-player-select__persons-inputs">
            <InputComponent 
                @update:modelValue="e => arbitrator = e" 
                :value="arbitrator" 
                placeholder="арбітр" 
                label="арбітр" 
                :class="{
                    'border-red-500': !validation.arbitrator
                }"    
            />
            <InputComponent @update:modelValue="e => assistant1 = e" :value="assistant1" placeholder="асистент 1" label="асистент 1" />
            <InputComponent @update:modelValue="e => assistant2 = e" :value="assistant2" placeholder="асистент 2" label="асистент 2" />
            <InputComponent @update:modelValue="e => delegate = e" :value="delegate" placeholder="делегат" label="делегат" />
            <!-- <InputComponent @update:modelValue="e => trainer = e" :value="trainer" placeholder="тренер" label="тренер" /> -->
            <InputComponent @update:modelValue="e => fourthArbitrator = e" :value="fourthArbitrator" placeholder="4-й арбітр" label="4-й арбітр" />
        </div>

        <div class="start-player-select__player-to-start-selection mt-[30px] select-none">

            <div class="mt-[33px]">
                <TableWrapper>
                    <TableComponent :width="400">
                        <TableHeader>
                            <TableHeaderRow>
                                <TableHeaderCell :value="'Гравець'" class="text-left" />
                                <TableHeaderCell :value="'Додати'" class="text-end" />
                            </TableHeaderRow>
                        </TableHeader>
                    </TableComponent>
                </TableWrapper>
                <TableWrapper>
                    <TableComponent :width="400">
                        <TableBody class="body_scroll w-[100%]">
                            <TableRow v-for="student in allStudents">
                                <TableCell v-if="student?.name" class="start-player-select__player-row" :asChild="true">
                                    <AvatarContainer v-if="student?.photo" :src="apiURL + 'static/' + student.photo" :small="true" />
                                    <div v-else class="avatar-container avatar-container--small text-[#BD9135]">
                                        {{ student.name[0] }}{{ student.surname[0] }}
                                    </div>
                                    <div class="flex flex-col justify-start">
                                        <div>
                                            {{ student.name }} {{ student.surname }}
                                        </div>
                                        <!-- <div>{{ student.player_number }}|{{ student.amplua }}</div> -->
                                    </div>
                                </TableCell>
                                <TableCell v-if="student?.name" :width="50" class="start-player-select__button-row" :asChild="true">
                                    <div class="w-full justify-center flex">
                                        <svg @click="addPlayerToStart(student)" class="cursor-pointer" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="13" cy="13" r="13" fill="#339989"/>
                                            <line x1="13" y1="5" x2="13" y2="21" stroke="white" stroke-width="2"/>
                                            <line x1="5" y1="13" x2="21" y2="13" stroke="white" stroke-width="2"/>
                                        </svg>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </TableComponent>
                </TableWrapper>

                <div class="mt-[20px] flex justify-center">
                    <ButtonComponent @click="addOtherPlayersToGameFlag = true">
                        Додати сторонніх гравців
                    </ButtonComponent>
                </div>
            </div>

            <div>
                <div class="w-full text-center mb-[15px]">СКЛАД НА МАТЧ</div>

                <TableWrapper>
                    <TableComponent :width="700">
                        <TableHeader>
                                <TableHeaderRow>
                                <TableHeaderCell :value="'гравець'" class="text-left w-[173px]" />
                                <TableHeaderCell :value="'номер'" class="text-center w-[112px]"/>
                                <TableHeaderCell :value="'позиція'" class="text-center w-[140px]"/>
                                <TableHeaderCell :value="'основа/запас'" class="text-center w-[169px]"/>
                                <TableHeaderCell :value="'вилучити'" class="text-end"/>
                            </TableHeaderRow>
                        </TableHeader>
                    </TableComponent>
                </TableWrapper>

                <TableWrapper class="h-[400px] overflow-y-auto overflow-x-hidden ">
                    <TableComponent :width="700">
                        <TableBody class="">
                            <TableRow v-for="student in startTeam" >
                                <TableCell class="start-player-select__player-row" :asChild="true">
                                    <AvatarContainer v-if="student.photo" :src="apiURL + 'static/' + student.photo" :small="true" />
                                    <div v-else class="avatar-container avatar-container--small text-[#BD9135]">
                                        {{ student.surname ? student.name[0] + ' ' + student.surname[0] : student.name.split(' ')[0][0] + '' + student.name.split(' ')[1][0]}}
                                    </div>
                                    <div class="flex flex-col justify-start">
                                        <div>
                                            {{ student.name }} {{ student.surname }}
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell class="start-player-select__button-row" :asChild="true">
                                    <input v-model="student.number" class="w-[18px] bg-[#474849] p-[2px] rounded-md text-center" type="text" />
                                </TableCell>
                                <TableCell class="start-player-select__button-row" :asChild="true">
                                    <select v-model="student.position" class="w-[100px] bg-[#474849] p-[2px] rounded-md text-center">
                                        <option v-for="position in positions" :value="position">{{ position }}</option>
                                    </select>
                                </TableCell>
                                <TableCell class="start-player-select__status-button w-[200px]" :asChild="true">
                                    <div v-if="student.status == 'start'" @click="student.status = 'reserve'" class="bg-[#339989] mx-auto select-none cursor-pointer w-[107px] text-center rounded-full">основа</div>
                                    <div v-if="student.status == 'reserve'" @click="student.status = 'start'" class="bg-[#474849] mx-auto select-none cursor-pointer w-[107px] text-center rounded-full">запас</div>
                                </TableCell>
                                <TableCell class="start-player-select__button-row" :asChild="true">
                                    <div class="w-full justify-end flex">
                                        <svg @click="deletePlayerFromStart(student)" class="cursor-pointer" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="13" cy="13" r="13" fill="#DA4167"/>
                                            <line x1="5" y1="13" x2="21" y2="13" stroke="white" stroke-width="2"/>
                                        </svg>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </TableComponent>
                </TableWrapper>
            </div>
        </div>

        <div class="flex flex-col gap-4 items-end mt-[20px]">
            <ButtonComponent @click="onlySave" >
                Зберегти
            </ButtonComponent>
            <ButtonComponent @click="saveAndToReport" :outlined="true">
                Зберегти та перейти до звіта
            </ButtonComponent>
            <ButtonComponent :negative="true" @click="$emit('close')" >
                Назад
            </ButtonComponent>
        </div>
    </div>
    <AddOtherPlayerSelection v-if="addOtherPlayersToGameFlag" @updateNewPlayers="allStudents.push(...$event)" @close="addOtherPlayersToGameFlag = false" />
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { getStudentsCountByGroupId, getAllStudentsListInGroup } from '@/services/apiRequests';

import SelectComponent from '@/components/uikit/Select/SelectComponent.vue';
import InputComponent from '@/components/uikit/Input/InputComponent.vue';
import TableWrapper from '@/components/uikit/TableComponent/table/TableWrapper.vue';
import ButtonComponent from '@/components/uikit/Button/ButtonComponent.vue';
import AddOtherPlayerSelection from './AddOtherPlayerSelection.vue';

export default {
    name: 'SetPlayersStart',

    props: {
        matchReport: {
            type: Object,
            default: () => {}
        }
    },
    
    setup(props, { emit }) {
        const allScheme = [ '3-4-3', '3-5-3', '3-4-2-1', '3-4-1-2', '5-3-2', '5-4-1', '4-4-2', '4-4-1-1', '4-2-3-1', '4-3-2-1', '4-3-3', '4-1-4-1', '4-1-3-2', '3-3-3-1', '4-5-1', ]
        const allStudents = ref([]);
        const allStudentsBase = ref([]);

        const addOtherPlayersToGameFlag = ref(false)

        const matchId = ref(props.matchReport.match_report_id)

        const positions = ref(['ВР', 'ЦЗ', 'ЛЗ', 'ПЗ', 'ЦОП', 'ЦП', 'ЦАП', 'ПП', 'ЛП', 'ПВ', 'ЛВ', 'НП'])

        const startTeam = ref([]);

        const apiURL = process.env.VUE_APP_API_URL;

        const scheme = ref(props.matchReport.scheme || '3-4-3')

        const arbitrator = ref('')
        const assistant1 = ref('')
        const assistant2 = ref('')
        const delegate = ref('')
        const trainer = ref('')
        const fourthArbitrator = ref('')
        const startFieldPart = ref('Ліва')

        const validation = ref({
            arbitrator: false,
            assistant1: false,
            assistant2: false,
            delegate: false,
            trainer: false,
        })

        const alertFlag = ref(false)

        watch([arbitrator, assistant1, assistant2, delegate, trainer], () => {
            validation.value = {
                arbitrator: arbitrator.value.length > 0,
                assistant1: assistant1.value.length > 0,
                assistant2: assistant2.value.length > 0,
                delegate: delegate.value.length > 0,
                trainer: trainer.value.length > 0,
            }
        })

        const addPlayerToStart = (student) => {
            startTeam.value.push(
                {
                    photo: student.photo,
                    number: student.player_number,
                    name: student.name[0] + '. ' + student.surname,
                    position: student.amplua,
                    playerId: student.student_id,
                    status: 'start'
                },
            )
            const startPlayerIds = startTeam.value.map(item => item.playerId)
            allStudents.value = allStudents.value.filter(item => item.student_id !== student.student_id)
            allStudents.value = allStudents.value.filter(item => !startPlayerIds.includes(item.student_id))
        }

        const deletePlayerFromStart = (student) => {
            startTeam.value = startTeam.value.filter(item => item.playerId !== student.playerId)

            const currentStudent = allStudentsBase.value.find(item => item.student_id === student.playerId)
            console.log('currentStudent ----- ', student.playerId);

            allStudents.value.push(
                currentStudent
            )
        }

        const onlySave = () => {
            const startTeamFinal = startTeam.value.filter(item => item.status === 'start')
            const reserveTeam = startTeam.value.filter(item => item.status === 'reserve')

            if (!validation.value.arbitrator) {
                alertFlag.value = true

                setTimeout(() => {
                    alertFlag.value = false
                }, 2000)
                
                return
            }
            // if (!validation.value.arbitrator || !validation.value.assistant1 || !validation.value.assistant2 || !validation.value.delegate || !validation.value.trainer) {
            //     alertFlag.value = true

            //     setTimeout(() => {
            //         alertFlag.value = false
            //     }, 2000)
                
            //     return
            // }

            emit('onlySave', {
                startTeam: startTeamFinal,
                reserveTeam,
                scheme: scheme.value,
                arbitrator: arbitrator.value,
                assistant1: assistant1.value,
                assistant2: assistant2.value,
                delegate: delegate.value,
                trainer: trainer.value,
                fourthArbitrator: fourthArbitrator.value,
                fieldPart: startFieldPart.value == 'Ліва' ? 'left' : 'right'
            })
        }

        const saveAndToReport = () => {
            const startTeamFinal = startTeam.value.filter(item => item.status === 'start')
            const reserveTeam = startTeam.value.filter(item => item.status === 'reserve')

            if (!validation.value.arbitrator) {
                alertFlag.value = true

                setTimeout(() => {
                    alertFlag.value = false
                }, 2000)
                
                return
            }
            // if (!validation.value.arbitrator || !validation.value.assistant1 || !validation.value.assistant2 || !validation.value.delegate || !validation.value.trainer) {
            //     alertFlag.value = true

            //     setTimeout(() => {
            //         alertFlag.value = false
            //     }, 2000)
                
            //     return
            // }

            emit('saveAndToReport', {
                startTeam: startTeamFinal,
                reserveTeam,
                scheme: scheme.value,
                arbitrator: arbitrator.value,
                assistant1: assistant1.value,
                assistant2: assistant2.value,
                delegate: delegate.value,
                trainer: trainer.value,
                fourthArbitrator: fourthArbitrator.value,
                fieldPart: startFieldPart.value == 'Ліва' ? 'left' : 'right'
            })
        }

        onMounted(async () => {
            allStudents.value = await getAllStudentsListInGroup(props.matchReport.clubTeamId)
            allStudentsBase.value = Object.assign([], allStudents.value)

            arbitrator.value = props.matchReport.arbitrator || ''
            assistant1.value = props.matchReport.assistant1 || ''
            assistant2.value = props.matchReport.assistant2 || ''
            delegate.value = props.matchReport.delegate || ''
            trainer.value = props.matchReport.trainer || ''
            fourthArbitrator.value = props.matchReport.fourthArbitrator || ''


            if (props.matchReport.scheme) {
                scheme.value = props.matchReport.scheme
            }

            if (props.matchReport.fieldPart) {
                startFieldPart.value = props.matchReport.fieldPart == 'left' ? 'Ліва' : 'Права'
            }
            

            if (props.matchReport.startTeam) {
                startTeam.value = props.matchReport.startTeam.concat(props.matchReport.reserveTeam)
                console.log('startTeam.value ----- ', startTeam.value);
                const startTeamIds = startTeam.value.map(item => item.playerId)
                allStudents.value = allStudents.value.filter(item => !startTeamIds.includes(item.student_id))
            }
        })

        return {
            allScheme,
            apiURL,
            allStudents,
            startTeam,
            addPlayerToStart,
            deletePlayerFromStart,
            onlySave,
            scheme,
            arbitrator,
            assistant1,
            assistant2,
            delegate,
            trainer,
            startFieldPart,
            validation,
            saveAndToReport,
            alertFlag,
            fourthArbitrator,
            positions,
            matchId,
            addOtherPlayersToGameFlag
        }
    },

    components: {
        AddOtherPlayerSelection
    }
}
</script>

<style lang="scss" scoped>
    .hidden-block {
        display: none !important;
    }
</style>