<template>
  <div ref="chart" style="width: 100%; height: 100%;"></div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import * as echarts from 'echarts';

// Определение пропсов
const props = defineProps({
  data1: {
    type: Array,
    required: true
  },
  data2: {
    type: Array,
    required: true
  },
  xAxisData: {
    type: Array,
    required: true
  },
  firstPlayerName: {
    type: String,
    required: true
  },
  secondPlayerName: {
    type: String,
    required: true
  }
});

const chart = ref(null);
let chartInstance = null;

const createChart = () => {
  if (!chartInstance) {
    chartInstance = echarts.init(chart.value);
  }

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      data: [props.firstPlayerName, props.secondPlayerName],
      textStyle: {
        color: '#fff'
      }
    },
    yAxis: {
      type: 'category',
      axisTick: { show: false },
      data: props.xAxisData
    },
    xAxis: {
      type: 'value'
    },
    series: [
      {
        name: props.firstPlayerName,
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        data: props.data1.map((value) => value.toFixed(1))
      },
      {
        name: props.secondPlayerName,
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        data: props.data2.map((value) => value.toFixed(1))
      }
    ]
  };

  chartInstance.setOption(option);
};

onMounted(() => {
  createChart();
  window.addEventListener('resize', resizeChart);
});

onBeforeUnmount(() => {
  if (chartInstance) {
    chartInstance.dispose();
  }
  window.removeEventListener('resize', resizeChart);
});

// Watch для отслеживания изменений в пропсах и обновления графика
watch([() => props.data1, () => props.data2, () => props.xAxisData], createChart);

function resizeChart() {
  if (chartInstance) {
    chartInstance.resize();
  }
}
</script>

<style scoped>
/* Добавьте стили, если нужно */
</style>
