<template>
    <div>
        <div class="text-center text-white font-semibold">
            Виберіть групу тестувань
        </div>
        <div>
            <div v-for="group in standardGroups" @click="$router.push('/testing/' + group.group_id)" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                <span><span class="text-black">{{ group.group_name }}</span></span>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onMounted, onUpdated } from 'vue'
import { getAllStandardGroups } from '@/services/apiRequests'

export default {
    name: 'TestingView',

    setup() {
        const standardGroups = ref([])

        const updateAllData = async () => {
            standardGroups.value = await getAllStandardGroups()
            console.log(standardGroups.value)
        }

        onMounted(() => {
            updateAllData()
        })

        return {
            standardGroups
        }
    }
}
</script>
<style lang="">
    
</style>