<template>
    <div class="px-[30px]">

        <div class="flex justify-end">
            <ButtonComponent :gray="true" class="mr-[10px]" @click="$router.push('/add-gps-report-for-match/' + matchId)">
                Данні GPS
            </ButtonComponent>
            <ButtonComponent @click="$router.push('/match-center/matches')">
                Назад
            </ButtonComponent>
        </div>
        <div class="flex justify-center mt-[20px] border-y-[1px] border-[#BD913533] items-center">
            <div class="w-[33.3333%] flex items-center justify-center mt-[16px] ml-[90px]">
            </div>

            <div class="w-[33.3333%] flex justify-center">
                <div class="match-report__banner" style="background-color: transparent; border: none;">
                    <div class="match-report__banner--title">
                        <div>{{ matchReport?.league }}</div>
                        <div>Тур {{ matchReport?.tour }}</div>
                    </div>

                    <div class="match-report__banner--teams">
                        <div class="match-report__banner--team">
                            <img :src="matchReport?.firstTeamLogo ? apiURL + 'static/' + matchReport?.firstTeamLogo : require('@/assets/kolos_team.svg')" class="w-max" alt="">
                            <div class="truncate">{{ matchReport?.firstTeamName }}</div>
                        </div>
                        <div class="match-report__banner--score">
                            <div>{{ matchReport?.firstTeamScore }}:{{ matchReport?.secondTeamScore }}</div>
                            <div>{{ matchReport?.matchTime }}''</div>

                        </div>
                        <div class="match-report__banner--team">
                            <img :src="matchReport?.secondTeamLogo ? apiURL + 'static/' + matchReport?.secondTeamLogo : require('@/assets/kolos_team.svg')" class="w-max" alt="">
                            <div class="truncate">{{ matchReport?.secondTeamName }}</div>
                        </div>
                    </div>

                    <div class="match-report__banner--info">
                        <div>({{ matchReport?.time }})</div>
                        <div>{{ matchReport?.stadium }}</div>
                    </div>
                </div>
            </div>

            <div class="w-[33.3333%] flex items-center justify-end mt-[16px] ml-[90px]">
                <StarsComponent :rating="groupAverageScore" class="scale-[1.5] origin-right mr-[10px]"/>
                <div class="relative top-[1px]">
                    <div>
                    {{ isNaN(groupAverageScore.toFixed(2)) ? '0.00' : groupAverageScore.toFixed(2) || '0.00'}}
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-[15px] mb-[20px]">
            <div class="text-center font-bold">склад на матч</div>

            <div>
                <TableWrapper>
                    <TableComponent :fullWidth="true">
                        <TableHeader>
                        <TableHeaderRow>
                            <TableHeaderCell :value="''" class="truncate"/>
                            <TableHeaderCell :value="'амплуа'" />
                            <TableHeaderCell :value="'голів'" class="w-[100px]" />
                            <TableHeaderCell :value="'пропущені'" class="w-[100px]"/>
                            <TableHeaderCell :value="'автогол'" class="w-[100px]"/>
                            <TableHeaderCell :value="'асист'" class="w-[100px]"/>
                            <TableHeaderCell :value="'жовтих'" class="w-[100px]"/>
                            <TableHeaderCell :value="'черв.'" class="w-[100px]"/>
                            <TableHeaderCell :value="'хв на полі'" />
                            <TableHeaderCell :value="'заг. оцінка'" />
                            <TableHeaderCell :value="''" />
                        </TableHeaderRow>
                        </TableHeader>
                        <TableBody>
                            <TableRow  
                                v-for="player in startPlayers"
                            >
                                <TableCell :asChild="true" :width="50">
                                    <div class="text-start flex">
                                        <div class="text-[#C59014] font-bold w-[30px] text-end">
                                            {{ player.number }} 
                                        </div>
                                        <div class="text-[#339989] ml-[10px] truncate" :title="player.name">
                                            {{ player.name }}
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div class="text-center">
                                        {{ player.position || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div 
                                        class="text-center"
                                        :class="{
                                            'opacity-30': !player.player_goals
                                        }"         
                                    >
                                        {{ player.player_goals || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div 
                                        class="text-center" 
                                        :class="{
                                            'opacity-30': !player.player_missed_goals
                                        }"
                                    >
                                        {{ player.player_missed_goals || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div 
                                        class="text-center"
                                        :class="{
                                            'opacity-30': !player.player_autogoals
                                        }"    
                                    >
                                        {{ player.player_autogoals || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div 
                                        class="text-center"
                                        :class="{
                                            'opacity-30': !player.player_assist
                                        }"     
                                    >
                                        {{ player.player_assist || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div 
                                        class="text-center"
                                        :class="{
                                            'opacity-30': !player.player_yellow_card
                                        }"        
                                    >
                                        {{ player.player_yellow_card || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div 
                                        class="text-center"
                                        :class="{
                                            'opacity-30': !player.player_red_card
                                        }"     
                                    >
                                        {{ player.player_red_card || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div 
                                        class="text-center"
                                        :class="{
                                            'opacity-30': !player.player_game_time
                                        }"     
                                    >
                                        {{ player.player_game_time || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div class="text-center"
                                    :class="{
                                        'opacity-60': player.player_average_score == null || player.player_average_score == undefined || player.player_average_score == 0 
                                    }"
                                    >
                                        {{ isNaN(player.player_average_score?.toFixed(1)) ? '-' : player.player_average_score?.toFixed(1) || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <button 
                                        class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                        @click="$router.push(`/player-mark/${player.playerId}/${matchId}`)"
                                    >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 20H21" stroke="#F8FF82" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12 20H21" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12 20H21" stroke="#ABD3EC" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#F8FF82" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#ABD3EC" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>

                                </TableCell>
                            </TableRow>
                            <TableRow  
                                v-for="player in reserveTeam"
                            >
                                <TableCell :asChild="true" :width="50">
                                    <div class="text-start flex">
                                        <div class="text-[#FFFFFF99] font-bold w-[30px] text-end">
                                            {{ player.number }} 
                                        </div>
                                        <div class="text-[#FFFFFF99] ml-[10px] truncate" :title="player.name">
                                            {{ player.name }}
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div class="text-center">
                                        {{ player.position || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div 
                                        class="text-center"
                                        :class="{
                                            'opacity-30': !player.player_goals
                                        }"         
                                    >
                                        {{ player.player_goals || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div 
                                        class="text-center" 
                                        :class="{
                                            'opacity-30': !player.player_missed_goals
                                        }"
                                    >
                                        {{ player.player_missed_goals || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div 
                                        class="text-center"
                                        :class="{
                                            'opacity-30': !player.player_autogoals
                                        }"    
                                    >
                                        {{ player.player_autogoals || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div 
                                        class="text-center"
                                        :class="{
                                            'opacity-30': !player.player_assist
                                        }"     
                                    >
                                        {{ player.player_assist || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div 
                                        class="text-center"
                                        :class="{
                                            'opacity-30': !player.player_yellow_card
                                        }"        
                                    >
                                        {{ player.player_yellow_card || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div 
                                        class="text-center"
                                        :class="{
                                            'opacity-30': !player.player_red_card
                                        }"     
                                    >
                                        {{ player.player_red_card || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div 
                                        class="text-center"
                                        :class="{
                                            'opacity-30': !player.player_game_time
                                        }"     
                                    >
                                        {{ player.player_game_time || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div class="text-center" :class="{
                                        'opacity-60': player.player_average_score == null || player.player_average_score == undefined || player.player_average_score == 0 
                                    }">
                                        {{ isNaN(player.player_average_score?.toFixed(1)) ? '-' : player.player_average_score?.toFixed(1) || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <button 
                                        class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                        @click="$router.push(`/player-mark/${player.playerId}/${matchId}`)"
                                    >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 20H21" stroke="#F8FF82" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12 20H21" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12 20H21" stroke="#ABD3EC" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#F8FF82" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#ABD3EC" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>

                                </TableCell>
                            </TableRow>
                            
                        </TableBody>
                    </TableComponent>
                </TableWrapper>
            </div>
        </div>

    </div>
</template>

<script>
import { getStudentById, getMatchReportById, getPlayerStatisticsByMatchReportIdAndPlayerId, getAdmin, updateGroupAveragePoint, updatePlayerStatisticsByMatchReportIdAndPlayerId } from '@/services/apiRequests';
import { ref, onMounted, watch } from 'vue';
import { useRoute, useRouter } from "vue-router";

export default {
    name: 'PlayerMatchReportForm',
    
    props: {
        
    },

    setup(props, { emit }) {
        const route = useRoute();
        const router = useRouter();

        const matchId = route.params.match_id;

        const apiURL = process.env.VUE_APP_API_URL;

        const matchReport = ref(null);

        const startPlayers = ref([]);
        const reserveTeam = ref([]);

        const groupAverageScore = ref(0);

        onMounted(async () => {
            matchReport.value = await getMatchReportById(matchId);
            console.log('matchReport.value ----- ', matchReport.value);
            startPlayers.value = matchReport.value?.bufferedStartTeam || matchReport.value?.startTeam;
            reserveTeam.value = matchReport.value?.bufferedReserveTeam || matchReport.value?.reserveTeam;

            for (let player of startPlayers.value) {
                const playerStatistics = await getPlayerStatisticsByMatchReportIdAndPlayerId(matchId, player.playerId);
                console.log('playerStatistics ----- ', playerStatistics);

                playerStatistics.player_average_score = !playerStatistics.player_match_reports ? 0 : playerStatistics.player_match_reports.reduce((acc, report) => {
                    return acc + +report.rating;
                }, 0) / playerStatistics.player_match_reports.length;
                startPlayers.value = startPlayers.value.map(startPlayer => {
                    if (startPlayer.playerId == player.playerId) {
                        return {
                            ...startPlayer,
                            ...playerStatistics
                        }
                    }
                    return startPlayer;
                });
            }

            for (let player of reserveTeam.value) {
                const playerStatistics = await getPlayerStatisticsByMatchReportIdAndPlayerId(matchId, player.playerId);
                reserveTeam.value = reserveTeam.value.map(reservePlayer => {
                    if (reservePlayer.playerId == player.playerId) {
                        return {
                            ...reservePlayer,
                            ...playerStatistics
                        }
                    }
                    return reservePlayer;
                });
            }

            console.log('startPlayers.value ----- ', startPlayers.value);

            const countStartPlayerWithScore = startPlayers.value.filter(player => +player.player_average_score > 0).length;
            const countReservePlayerWithScore = reserveTeam.value.filter(player => +player.player_average_score > 0).length;

            console.log('countStartPlayerWithScore ----- ', countStartPlayerWithScore);
            console.log('countReservePlayerWithScore ----- ', countReservePlayerWithScore);

            groupAverageScore.value = (startPlayers.value.reduce((acc, player) => {
                return acc + +player.player_average_score;
            }, 0) + reserveTeam.value.reduce((acc, player) => {
                return acc + +player.player_average_score;
            }, 0)) / (countStartPlayerWithScore + countReservePlayerWithScore == 0 ? 1 : countStartPlayerWithScore + countReservePlayerWithScore);

            console.log('groupAverageScore.value ----- ', isNaN(groupAverageScore.value) ? 0 : groupAverageScore.value);
            await updateGroupAveragePoint(matchReport.value.match_report_id, isNaN(groupAverageScore.value) ? 0 : groupAverageScore.value);
        });

        return {
            matchReport,
            startPlayers,
            reserveTeam,
            matchId,
            apiURL,
            groupAverageScore
        }
    }
}
</script>

<style>
    
</style>