<template>
    <div>
        <div class="search-block w-[90%] mx-auto">
        </div>

        <div class="border-b-[1px] border-white w-[90%] mx-auto flex justify-between mt-[16px] mb-[16px] pb-[10px]">
            <div class="flex justify-between mt-2">
                <InputComponent :value="query" @update:modelValue="e => $emit('update:query', e)" placeholder="Пошук..." class="w-[250px]"/>
            </div>
            <div class="text-white ml-[20px]">
                <span class="text-[28px] font-semibold">{{ scoutCount }}</span> записів
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MenuBar',    

    props: {
        scoutCount: {
            type: Number,
            default: 0
        },

        query: {
            type: String,
            default: ''
        }
    }
}
</script>

<style>
    
</style>